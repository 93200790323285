import { render, staticRenderFns } from "./OrgServiceBilling.vue?vue&type=template&id=a927fc44&scoped=true&"
import script from "./OrgServiceBilling.vue?vue&type=script&lang=js&"
export * from "./OrgServiceBilling.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a927fc44",
  null
  
)

export default component.exports