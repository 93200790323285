import Toasted from 'vue-toasted'
import Vue from 'vue'

Vue.use(Toasted)

let successOptions = {
  type: 'default',
  duration: 5000,
  className: ['toasted-action-success'],
  position: 'bottom-center'
}
let errorOptions = {
  type: 'default',
  duration: 5000,
  className: ['toasted-action-error'],
  position: 'bottom-center'
}
Vue.toasted.register(
  'actionSuccess',
  message => {
    // if there is no message passed show default message
    if (Object.keys(message).length === 0) {
      return 'Action successful'
    }
    // if there is a message show it with the message
    return message
  },
  successOptions
)
Vue.toasted.register(
  'actionError',
  message => {
    // if there is no message passed show default message
    if (!message) {
      return 'An error occured'
    }
    if (message instanceof Error) {
      return message
    }
    if (Object.keys(message).length === 0) {
      return 'An error occured'
    }
    // if there is a message show it with the message
    return message
  },
  errorOptions
)
