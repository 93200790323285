import { computed } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'

export function findOrgs() {
  const { Org } = models.api
  const params = computed(() => {
    return {
      query: {
        $limit: 2000
      }
    }
  })
  const queryWhen = computed(() => {
    return true
  })
  const { items: orgs } = useFind({
    model: Org,
    params,
    queryWhen
  })

  return { orgs }
}
