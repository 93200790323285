import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'
const { iff, discard } = require('feathers-hooks-common')

class FileUrl extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'FileUrl'
}
const servicePath = 'file-urls'
const servicePlugin = makeServicePlugin({
  Model: FileUrl,
  service: feathersClient.service(servicePath),
  servicePath
})

export default servicePlugin
