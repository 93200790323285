import { ref, watch, computed } from '@vue/composition-api'

const defaults = {
  name: 'editor',
  watchInQuery: []
}

export default function useSidebarTabState(options) {
  const { name: sidebar, watchInQuery, context } = Object.assign({}, defaults, options)
  if (!context) {
    throw new Error('You must pass `context` in the options')
  }
  const current = ref({ query: { sidebar } })

  /**
   * Watch for updates on all of the watchInQuery attrs and automatically store
   * the most recent query attribute combination.
   */
  watchInQuery.unshift('sidebar')
  const toWatch = computed(() => watchInQuery.map(attr => context.root.$route.query[attr]))
  watchInQuery.forEach(attr => {
    watch(
      () => toWatch.value,
      values => {
        // Make sure we only
        if (values[0] === sidebar) {
          const query = {}
          watchInQuery.forEach((key, index) => {
            if (values[index] !== undefined) {
              query[key] = values[index]
            }
          })
          current.value = { query }
        }
      },
      { immediate: true }
    )
  })

  function openEditor(data) {
    if (data.sidebar !== sidebar) {
      data.sidebar = sidebar
    }
    context.root.$router.push({ query: data }, () => {})
  }

  function showMostRecentEditor() {
    context.root.$router.push(current.value, () => {})
  }

  return { current, openEditor, showMostRecentEditor }
}
