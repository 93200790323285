import { models, useFind, useGet } from 'feathers-vuex'
import { ref, computed } from '@vue/composition-api'

/**
 * Create an Environment
 *
 * Creating an environment requires setting the org permission inside the
 * environment.orgs[] permissions array.  The server will automatically set
 * the user permissions to include the current user.
 */
export async function createEnv({ name = 'New Environment', org }) {
  const { Environment } = models.api
  if (!name) {
    throw new Error('name is required to create an environment.')
  }
  const env = await new Environment({
    name,
    orgs: [
      {
        orgId: org._id,
        orgName: org.name,
        accessType: 'owner'
      }
    ],
    slug: 'new-environment'
  }).save()
  return { env }
}

export function getRovitEnvFromStore() {
  const { Environment } = models.api
  const params = computed(() => {
    return { query: { slug: 'rovit' } }
  })
  const { items: envs } = useFind({ model: Environment, params, local: true })
  const rovit = computed(() => {
    return envs[0] || null
  })
  return { rovit }
}

export const rovit = ref(null)

export async function fetchRovitEnv() {
  const { Environment } = models.api

  if (rovit.value) {
    return { rovit: rovit.value }
  } else {
    const params = {
      query: { slug: 'rovit' },
      $populateParams: { name: 'app' }
    }
    const response = await Environment.find(params)
    rovit.value = response.data[0]
    return { rovit: rovit.value }
  }
}

export function getEnv(envId) {
  const { Environment } = models.api
  const params = computed(() => {
    return {
      $populateParams: {
        name: 'adminArea'
      }
    }
  })
  const queryWhen = computed(() => {
    return !Environment.getFromStore(envId)
  })
  const { item: env } = useGet({
    model: Environment,
    id: envId,
    params,
    queryWhen
  })

  return { env }
}

export function findEnvs(options) {
  const { org = null, filterByOrg = false, search = '' } = options || {}
  const { Environment } = models.api

  const envsParams = computed(() => {
    const query = {
      $limit: 288,
      $sort: { name: 1 }
    }
    if (filterByOrg.value) {
      query['orgs.orgId'] = org._id
    }
    if (search.value) {
      Object.assign(query, {
        name: { $regex: search.value, $options: 'igm' }
      })
    }
    return { query, debounce: 300 }
  })
  const envsQueryWhen = computed(() => {
    // const queryWhen = Environment.findInStore({ query: {} }).data.length < 5
    // return queryWhen
    return true
  })
  const { items: environments } = useFind({
    model: Environment,
    params: envsParams,
    queryWhen: envsQueryWhen
  })
  return { environments }
}
