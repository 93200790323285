<template>
  <Layout>
    <template #content>
      <NavbarInfobox
        v-if="routeName === 'InfoboxEditor' && currentEnv && service && infobox"
        :service="service"
        :infobox="infobox"
      />
      <NavbarEnvironmentService
        v-if="routeName === 'EnvServiceInfoboxEditor' && currentEnv && service"
        :env="currentEnv"
        :service="service"
      />

      <div class="px-8">
        <div class="flex flex-row items-center">
          <h1 class="content-heading-1 mb-0">Infobox Editor</h1>
        </div>

        <p v-if="currentEnv && service && infobox" class="leading-none">
          In the <strong>{{ currentEnv.name }}</strong> environment, the
          <strong>{{ service.name }}</strong> service displays the
          <strong>{{ infobox.infoboxName }}</strong> infobox

          {{ isInfoboxForThisEnv }}
        </p>

        <InfoboxBrowserPopper
          v-if="infobox"
          :service="service"
          :current-env="currentEnv"
          :current-infobox="infobox"
          @select-infobox="selectInfobox"
        />

        <InfoboxBrowser
          v-else
          class="mt-2"
          :service="service"
          :current-env="currentEnv"
          :current-infobox="infobox"
          @select-infobox="selectInfobox"
        />

        <h2 class="text-2xl font-semibold mt-2">Scenes</h2>

        <InfoboxScenes
          v-if="infobox"
          :current-env="currentEnv"
          :service="service"
          :infobox="infobox"
          :scenes="allScenes"
          :selected-scene="selectedScene"
          @scene-click="handleSceneClick"
        />

        <hr class="border-b-2 border-gray-500 dark:border-gray-800 my-2" />
      </div>

      <!-- Background Feature -->
      <div class="px-8">
        <SceneNoBackgroundFeature v-if="scene && !scene.featureType" :scene="scene" />
        <SceneFeaturePano v-if="scene && scene.featureType === 'pano'" :scene="scene" />
        <SceneFeatureMap v-if="scene && scene.featureType === 'map'" :scene="scene" />
        <ItemFeatureAsset
          v-if="scene && scene.featureType === 'asset'"
          :item="scene"
          @header-click="openAssetSidebar"
        />
        <SceneFeatureVideo v-if="scene && scene.featureType === 'video'" :scene="scene" />
      </div>

      <!-- Infobox/Scene content -->
      <Scene
        v-if="scene && scene.service"
        class="scene"
        :models="models"
        :env="currentEnv"
        :service="scene.service"
        :scene="scene"
        :components="components"
        :current-index="currentIndex"
        :additional-props="additionalProps"
        edit
        @activate="handleActivate"
        @add-before="handleAddBefore"
        @add-after="handleAddAfter"
        @remove-module="handleRemoveModule"
      />

      <!-- Empty Scene: Add section -->
      <div
        v-if="scene && !scene.sceneModulesMeta.length"
        class="h-32 bg-gray-200 dark:bg-gray-800 rounded flex flex-col items-center justify-center mt-3 mx-8 border border-gray-500 dark:border-gray-700"
      >
        <p class="text-lg">This scene is empty</p>

        <div class="flex flex-row w-3/5 mt-3">
          <!-- Start with Template -->
          <!-- <div class="w-1/2 flex justify-center">
              <button
                type="button"
                class="form-button primary"
                @click="addTemplate"
              >
                Start with a Template
              </button>
            </div> -->

          <div class="w-full flex justify-center">
            <!-- Start with Section -->
            <button
              type="button"
              class="form-button primary"
              @click="() => handleAddAfter({ index: -1 })"
            >
              Add a Section
            </button>
          </div>
        </div>
      </div>
    </template>

    <template #sidebar>
      <div>
        <InfoboxSidebarTabs />

        <InfoboxSidebarSupport v-if="sidebar === 'support'" />
        <InfoboxSidebarSections v-if="sidebar === 'sections'" @add="addModule" />
        <InfoboxSidebarTemplates v-if="sidebar === 'templates'" />

        <InfoboxSidebarInfobox
          v-if="sidebar === 'infobox'"
          :infobox="infobox"
          :scenes="allScenes"
        />
        <InfoboxSidebarScene v-if="sidebar === 'scene' && scene" :scene="scene" :env-id="envId" />

        <!-- Module Sidebars -->
        <InfoboxSidebarSectionBlank
          v-if="sidebar === 'section' && sectionSidebarType === 'blank'"
        />
        <TmServiceInfoEditor
          v-if="showForModule('TmServiceInfo')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
          :model="models.api.Asset"
          :category-model="models.api.Category"
          :service-model="models.api.Service"
          :icon-model="models.api.Icon"
          :org="currentOrg"
        />
        <TmContactInfoEditor
          v-if="showForModule('TmContactInfo')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
          :mapbox-token="mapboxToken"
        />
        <TmScheduleEditor
          v-if="showForModule('TmSchedule')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
        />
        <TmDirectionsEditor
          v-if="showForModule('TmDirections')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
          :model="models.api.Asset"
          :mapbox-token="mapboxToken"
        />
        <TmLocationInfoEditor
          v-if="showForModule('TmLocationInfo')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
          :mapbox-token="mapboxToken"
        />
        <TmSceneTourEditor
          v-if="showForModule('TmSceneTour')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
        />
        <TmInfoEditor
          v-if="showForModule('TmInfo')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
        />
        <TmAdvertisementEditor
          v-if="showForModule('TmAdvertisement')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
          :model="models.api.Asset"
        />
        <TmImageEditor
          v-if="showForModule('TmImage')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
          :model="models.api.Asset"
        />
        <TmGalleryEditor
          v-if="showForModule('TmGallery')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
          :model="models.api.Asset"
        />
        <TmMapShowcaseEditor
          v-if="showForModule('TmMapShowcase')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
          :model="models.api.Asset"
          :mapbox-token="mapboxToken"
        />
        <TmGoogleStreetViewEditor
          v-if="showForModule('TmGoogleStreetView')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
          :model="models.api.Asset"
        />
        <TmSketchFabEditor
          v-if="showForModule('TmSketchFab')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
          :model="models.api.Asset"
        />
        <TmVideoEditor
          v-if="showForModule('TmVideo')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
          :model="models.api.Asset"
        />
        <TmTrailForksTrailDetailsEditor
          v-if="showForModule('TmTrailForksTrailDetails')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
        />
        <!-- <TmRecommendedItemsEditor
          v-if="showForModule('TmRecommendedItems')"
          :env="currentEnv"
          :service="service"
          :scene="scene"
          :module="currentModule"
        /> -->

        <InfoboxSidebarAddScene v-if="sidebar === 'add-scene'" @add-scene="handleAddScene" />
      </div>
    </template>
  </Layout>
</template>

<script>
/* eslint vue/no-unused-components:0 */
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { models, useFind, useGet } from 'feathers-vuex'
import { getEnv, getRovitEnvFromStore } from '@/use/environments.js'
import {
  createInfobox,
  createSceneForInfobox,
  getScene,
  findScenesForInfobox,
  getInfoboxForEnvService,
  setInfoboxForEnvService
} from '@/use/scenes.js'
import { getService } from '@/use/services'
import useSidebarTabState from '@/use/sidebar-tab-state'
import _isEqual from 'lodash/isEqual.js'
import _clone from 'lodash/clone.js'

import { Scene } from '@rovit/scene'
import { TmServiceInfo, TmServiceInfoEditor } from '@rovit/tm-service-info'
import { TmContactInfo, TmContactInfoEditor } from '@rovit/tm-contact-info'
import { TmSchedule, TmScheduleEditor } from '@rovit/tm-schedule'
import { TmDirections, TmDirectionsEditor } from '@rovit/tm-directions'
import { TmLocationInfo, TmLocationInfoEditor } from '@rovit/tm-location-info'
import { TmSceneTour, TmSceneTourEditor } from '@rovit/tm-scene-tour'
import { TmInfo, TmInfoEditor } from '@rovit/tm-info'
import { TmAdvertisement, TmAdvertisementEditor } from '@rovit/tm-advertisement'
import { TmImage, TmImageEditor } from '@rovit/tm-image'
import { TmGallery, TmGalleryEditor } from '@rovit/tm-gallery'
import { TmMapShowcase, TmMapShowcaseEditor } from '@rovit/tm-map-showcase'
import { TmGoogleStreetView, TmGoogleStreetViewEditor } from '@rovit/tm-google-street-view'
import { TmSketchFab, TmSketchFabEditor } from '@rovit/tm-sketch-fab'
import { TmVideo, TmVideoEditor } from '@rovit/tm-video'
import {
  TmTrailForksTrailDetails,
  TmTrailForksTrailDetailsEditor
} from '@rovit/tm-trail-forks-trail-details'
// import { TmRecommendedItems, TmRecommendedItemsEditor } from '@rovit/tm-recommended-items'

import Layout from '../../layouts/Layout.vue'
import { PlusIcon } from 'vue-feather-icons'
import NavbarInfobox from '../NavbarInfobox/NavbarInfobox.vue'
import NavbarEnvironmentService from '../NavbarEnvironmentService/NavbarEnvironmentService.vue'
import InfoboxBrowserPopper from '../InfoboxBrowserPopper/InfoboxBrowserPopper.vue'
import InfoboxBrowser from '../InfoboxBrowser/InfoboxBrowser.vue'
import InfoboxScenes from '../InfoboxScenes/InfoboxScenes.vue'

import InfoboxSidebarTabs from '../Infobox/SidebarTabs.vue'
import InfoboxSidebarSupport from '../Infobox/SidebarSupport.vue'
import InfoboxSidebarSections from '../Infobox/SidebarSections.vue'
import InfoboxSidebarTemplates from '../Infobox/SidebarTemplates.vue'
import InfoboxSidebarSectionBlank from '../Infobox/SidebarSectionBlank.vue'
import InfoboxSidebarService from '../Infobox/SidebarService.vue'
import InfoboxSidebarScene from '../Infobox/SidebarScene.vue'
import InfoboxSidebarInfobox from '../Infobox/SidebarInfobox.vue'
import InfoboxSidebarAddScene from '../Infobox/SidebarAddScene.vue'

import SceneNoBackgroundFeature from '../SceneNoBackgroundFeature/SceneNoBackgroundFeature.vue'
import SceneFeaturePano from '../SceneFeaturePano/SceneFeaturePano.vue'
import SceneFeatureMap from '../SceneFeatureMap/SceneFeatureMap.vue'
import ItemFeatureAsset from '../ItemFeatureAsset/ItemFeatureAsset.vue'
import SceneFeatureVideo from '../SceneFeatureVideo/SceneFeatureVideo.vue'

export default {
  name: 'InfoboxEditor',
  metaInfo: {
    title: 'Infobox Editor'
  },
  components: {
    // Scene Components
    Scene,

    // Page Layout Components
    Layout,
    PlusIcon,
    NavbarInfobox,
    NavbarEnvironmentService,
    InfoboxBrowserPopper,
    InfoboxBrowser,
    InfoboxScenes,

    InfoboxSidebarTabs,
    InfoboxSidebarSupport,
    InfoboxSidebarSections,
    InfoboxSidebarTemplates,
    InfoboxSidebarSectionBlank,
    InfoboxSidebarService,
    InfoboxSidebarScene,
    InfoboxSidebarInfobox,
    InfoboxSidebarAddScene,
    SceneNoBackgroundFeature,
    SceneFeaturePano,
    SceneFeatureMap,
    ItemFeatureAsset,
    SceneFeatureVideo,

    // Editors
    TmServiceInfoEditor,
    TmContactInfoEditor,
    TmScheduleEditor,
    TmDirectionsEditor,
    TmLocationInfoEditor,
    TmSceneTourEditor,
    TmInfoEditor,
    TmAdvertisementEditor,
    TmImageEditor,
    TmGalleryEditor,
    TmMapShowcaseEditor,
    TmGoogleStreetViewEditor,
    TmSketchFabEditor,
    TmVideoEditor,
    TmTrailForksTrailDetailsEditor
    // TmRecommendedItemsEditor
  },
  setup(props, context) {
    const { Service, SceneModule } = models.api
    const components = [
      TmServiceInfo,
      TmContactInfo,
      TmSchedule,
      TmDirections,
      TmLocationInfo,
      TmSceneTour,
      TmInfo,
      TmAdvertisement,
      TmImage,
      TmGallery,
      TmMapShowcase,
      TmGoogleStreetView,
      TmSketchFab,
      TmVideo,
      TmTrailForksTrailDetails
      // TmRecommendedItems
    ]
    const user = context.root.$store.state.auth.user
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    const routeName = computed(() => context.root.$route.name)

    // Current Env (for the EnvServiceInfoboxEditor route)
    const { rovit } = getRovitEnvFromStore()
    const envId = computed(() => context.root.$route.params.envId)
    const { env } = getEnv(envId)
    const currentEnv = computed(() => (env.value ? env.value : rovit))

    // Service
    const serviceId = computed(() => context.root.$route.params.serviceId)
    const { service } = getService(serviceId)

    // Infobox
    const infoboxId = computed(() => context.root.$route.params.infoboxId || null)
    const { scene: infobox } = getScene({ id: infoboxId })

    const isInfoboxForThisEnv = computed(() => {
      if (!infobox.value) {
        return null
      }
      const envMeta = (infobox.value.envsMeta || []).find(em => {
        return em.envId === currentEnv.value._id
      })
      return !!envMeta
    })

    // If there's no infoboxId, check for an existing infobox for this environment.
    // If an existing infobox is found, redirect to it.
    if (!infoboxId.value && envId) {
      const { infobox: existingInfobox } = getInfoboxForEnvService({ env: currentEnv, service })
      watch(
        () => existingInfobox.value,
        val => {
          if (val) {
            openInfobox(val)
          }
        },
        { immediate: true }
      )
    }

    /**
     * Current Scene. `sceneId` will always be found in the $route.query object.
     */
    const sceneId = computed(() => context.root.$route.query.sceneId || infoboxId.value)
    const selectedScene = computed(() => models.api.Scene.getFromStore(sceneId))
    const scene = computed(() => {
      if (!sceneId.value) {
        return infobox.value
      } else {
        const { scene } = getScene({ id: sceneId, local: true })
        return scene.value
      }
    })

    // All Scenes for Infobox
    const { scenes } = findScenesForInfobox({ infobox })
    const allScenes = computed(() => {
      return infobox.value ? [infobox.value, ...scenes.value] : []
    })

    const mapboxToken = context.root.$store.state.mapboxToken
    const additionalProps = {
      all: {
        mapboxToken
      },
      TmServiceInfo: {},
      TmContactInfo: {},
      TmSchedule: {},
      TmDirections: {},
      TmLocationInfo: {},
      TmSceneTour: {},
      TmInfo: {},
      TmAdvertisement: {},
      TmImage: {},
      TmGallery: {},
      TmMapShowcase: {},
      TmSketchFab: {}
      // TmRecommendedItems: {}
    }

    /**
     * Highlight the current scene on page load.
     */
    // let initialSelectionComplete = false
    // watch(
    //   () => scene.value,
    //   scene => {
    //     if (allScenes.value.length && scene && !initialSelectionComplete) {
    //       selectedScene.value = scene
    //       initialSelectionComplete = true
    //     } else if (!allScenes.value.length) {
    //       initialSelectionComplete = false
    //     }
    //   },
    //   { immediate: true }
    // )

    /* Sidebar Editor Management */
    const sidebar = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sectionSidebarType = computed(() => {
      return currentModule.value?.componentName || 'blank'
    })

    /**
     * Creates a new service with the currentOrg as the initial owner.
     */
    async function handleAddScene() {
      const { scene } = await createSceneForInfobox({ infobox })
      openSceneInSidebar(scene)
    }

    /**
     * Select a scene
     */
    function handleSceneClick({ item, e }) {
      if (Array.isArray(selectedScene.value)) {
        if (!selectedScene.value.length) return
        // TODO: handle when multiple orgs are in `selectedScene` array.
      } else {
        if (!selectedScene.value) return
        openSceneInSidebar(item)
      }
    }

    function openSceneInSidebar(scene) {
      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'scene',
        sceneId: scene._id
      })
      context.root.$router.push({ query }, () => {})
    }

    /**
     * Create an infobox and associate it with the current environment.
     * This will only ever be called from the EnvServiceInfoboxEditor
     */
    async function handleCreateInfobox() {
      const { infobox } = await createInfobox({ service, org: currentOrg })
      await associateInfoboxWithEnvService({ infobox, envService })
    }

    // Current Module
    const currentModule = ref(null)

    /**
     * Set the currentModule and show the sidebar for the selected component.
     */
    function handleActivate({ module, index, meta }) {
      const { event, component, section } = meta
      currentModule.value = module
      currentIndex.value = index
      updateQueryInRoute({ sidebar: 'section' })
    }

    /* Add Module */
    const currentIndex = ref(-1)
    function handleAddBefore({ index }) {
      currentIndex.value = index - 1
      updateQueryInRoute({ sidebar: 'sections' })
    }
    function handleAddAfter({ index }) {
      currentIndex.value = index + 1
      updateQueryInRoute({ sidebar: 'sections' })
    }

    /**
     * Creates a new SceneModule and saves its reference to the `scene.sceneModulesMeta`
     */
    async function addModule({ event, moduleComponent }) {
      const newModule = new SceneModule({
        componentName: moduleComponent.componentName,
        moduleComponentId: moduleComponent._id,
        serviceId: service.value._id
      })
      const module = await newModule.save()

      const clone = scene.value.clone()
      clone.sceneModulesMeta.splice(currentIndex.value, 0, {
        sceneModuleId: module._id,
        componentName: moduleComponent.componentName
      })
      currentIndex.value++
      await clone.save({
        $populateParams: {
          name: 'infoboxBuilder'
        }
      })
    }

    async function addTemplate() {
      const query = { sidebar: 'templates' }
      context.root.$router.push({ query }, () => {})
    }

    async function handleRemoveModule({ module, index }) {
      const clone = scene.value.clone({
        sceneModulesMeta: scene.value.sceneModulesMeta.filter((item, i) => {
          return i !== index
        })
      })
      await clone.save({
        $populateParams: {
          name: 'infoboxBuilder'
        }
      })
      await module.remove()
      // If we deleted the currentIndex, decrease the index so we can add a module in its place.
      if (currentIndex.value === index) {
        currentIndex.value--
      }
    }

    /**
     * Route to the provided query, keeping the sceneId in place.
     */
    function updateQueryInRoute(query) {
      sceneId.value && (query.sceneId = sceneId.value)
      context.root.$router.push({ query }, () => {})
    }

    /**
     * Open the asset selector in the sidebar.
     */
    function openAssetSidebar() {
      const currentQuery = context.root.$route.query
      const query = Object.assign({}, currentQuery, { sidebar: 'scene' })
      if (!_isEqual(currentQuery, query)) {
        context.root.$router.push({ query })
      }
    }

    function showForModule(componentName) {
      return sidebar.value === 'section' && sectionSidebarType.value === componentName
    }

    function handleAddSceneTab() {
      updateQueryInRoute({ sidebar: 'add-scene' })
    }

    function openInfobox(infobox) {
      const newRoute = _clone(context.root.$route)
      newRoute.params.infoboxId = infobox._id
      context.root.$router.replace(newRoute, () => {})
    }

    async function selectInfobox(infobox) {
      await setInfoboxForEnvService({ env: currentEnv, service, infobox })
      openInfobox(infobox)
    }

    return {
      envId,
      models,
      service,
      components, // template modules
      routeName,
      currentEnv,
      infoboxId,
      infobox,
      mapboxToken,
      additionalProps,
      scene,
      allScenes,
      scenes,
      currentModule,
      currentIndex,
      currentOrg,
      sidebar,
      user,
      showForModule,
      isInfoboxForThisEnv,

      // Handlers
      sectionSidebarType,
      selectedScene,
      handleCreateInfobox,
      handleAddScene,
      handleSceneClick,
      handleAddSceneTab,
      openAssetSidebar,
      selectInfobox,

      // Scene Handlers
      handleActivate,
      handleAddBefore,
      handleAddAfter,
      addModule,
      addTemplate,
      handleRemoveModule
    }
  }
}
</script>

<style lang="postcss" scoped></style>
