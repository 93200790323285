<template>
  <DangerZoneDelete @remove="remove" />
</template>

<script>
import DangerZoneDelete from '../DangerZoneDelete/DangerZoneDelete'

export default {
  name: 'EnvironmentEditorDanger',
  components: {
    DangerZoneDelete
  },
  props: {
    remove: {
      type: Function,
      required: true
    }
  }
}
</script>

<style lang="postcss"></style>
