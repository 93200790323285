<template>
  <div class="group relative">
    <div
      class="bg-purple-600 rounded-t px-3 py-1 font-medium group-hover:bg-purple-700 flex flex-row items-center"
      @click="handleHeaderClick"
    >
      <div class="flex-grow">Image Feature</div>
      <button type="button" class="flex flex-row items-center" @click="handleAddHotspotClick">
        <PlusIcon />
        Add Hotspot
      </button>
    </div>

    <div class="border border-purple-600 group-hover:border-purple-700 rounded-b">
      <FeatureHotspots
        v-if="item.assetUrl && asset"
        :item="item"
        :src="() => sameSizeProgressive(item.assetUrl).url"
        :current-hotspot-index="hotspotIndex"
        :aspect-ratio="aspectRatio"
        @image-click="handleHeaderClick"
        @hotspot-click="handleHotspotClick"
      />

      <button
        v-else
        type="button"
        class="bg-gray-800 h-24 rounded-b w-full flex flex-col items-center justify-center"
        @click="openAssetSelector"
      >
        <ImageIcon />
        Select Image
      </button>

      <!-- Hotspot Editor Popper -->
      <div
        v-show="popper ? popper.isVisible : !!popper"
        ref="popperMenu"
        class="popup absolute z-50 bg-white dark:bg-gray-700 border border-gray-500 px-3 pb-3 rounded"
      >
        <div v-if="popper" class="w-64">
          <div class="flex flex-row">
            <div class="flex-grow"></div>
            <button type="button" class="p-1" @click="popper.close()">Close</button>
          </div>

          <ItemHotspotEditor :item="item" :hotspot-index="hotspotIndex" class="w-full" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleClones } from '@rovit/utils'
import { ref, computed } from '@vue/composition-api'
import { useModal } from '@rovit/use-modal'
import _isEqual from 'lodash/isEqual.js'
import { sameSizeProgressive } from '@/use/image-transforms/index'
import { usePopper } from '@rovit/popper'
import { addHotspotToItem } from '@/use/scenes.js'
import { getAsset } from '@/use/assets.js'

import FeatureHotspots from '../FeatureHotspots/FeatureHotspots.vue'
import ItemHotspotEditor from '../ItemHotspotEditor/ItemHotspotEditor.vue'
import { ImageIcon, PlusIcon } from 'vue-feather-icons'
import { AspectRatio } from '@rovit/aspect-ratio'

export default {
  name: 'ItemFeatureAsset',
  components: {
    FeatureHotspots,
    ItemHotspotEditor,
    ImageIcon,
    PlusIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _item } = clones
    const { save_item } = saveHandlers

    const popperMenu = ref(null)
    const popper = ref(null)
    const hotspotIndex = ref(NaN)

    // Asset & Aspect Ratio
    const assetId = computed(() => {
      return _item.value.assetId || null
    })
    const { asset } = getAsset({ id: assetId })
    const aspectRatio = computed(() => {
      if (asset.value) {
        return `${asset.value.width}:${asset.value.height}`
      } else {
        return '2:1'
      }
    })

    // Destroy the previous popper before dynamically creating a new one.
    function handleHotspotClick({ hotspot, offset, event, index }) {
      if (popper.value) {
        popper.value.close()
        popper.value.destroy()
        // popper.value = null
      }
      // popperMenu.value.setAttribute('data-show', '')
      popper.value = usePopper(event.target, popperMenu.value, {
        placement: 'bottom',
        skipUnmount: true
      })
      popper.value.open()
      hotspotIndex.value = index
    }

    // Asset Selector
    const assetModal = useModal('assetSelector')
    function openAssetSelector() {
      assetModal.open({ applyAsset })
    }
    function applyAsset(asset) {
      return save_item({
        assetId: asset._id,
        assetUrl: asset.url
      })
    }
    function removeAssetFromItem() {
      save_item({ assetId: null, assetUrl: '' })
    }

    function handleAddHotspotClick() {
      addHotspotToItem({ _item, save_item })
    }

    function handleHeaderClick() {
      context.emit('header-click')
    }

    return {
      popperMenu,
      popper,
      hotspotIndex,

      sameSizeProgressive,

      // Asset
      asset,
      aspectRatio,

      handleHotspotClick,
      openAssetSelector,
      handleAddHotspotClick,
      handleHeaderClick
    }
  }
}
</script>

<style lang="postcss"></style>
