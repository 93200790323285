<template>
  <div v-if="value.length" class="bg-gray-300 dark:bg-gray-800 px-2 py-1 rounded">
    <ul class="list-inside">
      <li
        v-for="(itemMeta, index) in value"
        :key="itemMeta._id"
        class="flex justify-between items-center"
      >
        <button
          type="button"
          class="px-2 py-1.5 w-full block text-left"
          @click="() => $emit('activate-env-id', itemMeta.envId)"
        >
          {{ itemMeta.envName }}
        </button>
        <button class="px-2 py-1.5 text-red-500" @click="() => handleRemove(index)">
          <Trash2Icon />
        </button>
      </li>
    </ul>
  </div>
  <div
    v-else
    class="border border-gray-200 dark:border-gray-600 px-2 py-1 rounded cursor-not-allowed"
  >
    None
  </div>
</template>

<script>
import { Trash2Icon } from 'vue-feather-icons'

export default {
  name: 'EnvsMetaDisplay',
  components: {
    Trash2Icon
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  setup(props, context) {
    function handleRemove(index) {
      const envMeta = props.value.slice()
      envMeta.splice(index, 1)
      context.emit('input', envMeta)
      context.emit('update', envMeta)
    }
    return {
      handleRemove
    }
  }
}
</script>
