import { useCdnUrl } from './use-cdn-url'

function makeTransform(defaults) {
  return function (url, options) {
    const params = Object.assign(defaults, options)
    return { params, url: url ? useCdnUrl(params)(url) : '' }
  }
}

// No transform, just proxies the image through
export function proxy(url, options) {
  const params = Object.assign({}, options)
  return { params, url: url ? useCdnUrl(params)(url) : '' }
}

export const thumbnail = makeTransform({
  width: 660,
  height: 330,
  progressive: true,
  fit: 'cover',
  doNotEnlarge: false,
  output: 'jpg'
})

export const mediumImage = makeTransform({
  width: 1200,
  height: 600,
  progressive: true,
  fit: 'contain',
  doNotEnlarge: true,
  output: 'jpg'
})

export const largeImage = makeTransform({
  width: 3000,
  height: 1500,
  progressive: true,
  fit: 'contain',
  doNotEnlarge: true,
  output: 'jpg'
})

export const sameSizeProgressive = makeTransform({
  progressive: true,
  doNotEnlarge: true,
  output: 'jpg'
})

export const sameSizeProgressiveQ5 = makeTransform({
  progressive: true,
  doNotEnlarge: true,
  output: 'jpg',
  quality: 5
})

export const sameSizeProgressiveQ20 = makeTransform({
  progressive: true,
  doNotEnlarge: true,
  output: 'jpg',
  quality: 20
})

export const sameSizeProgressiveQ40 = makeTransform({
  progressive: true,
  doNotEnlarge: true,
  output: 'jpg',
  quality: 40
})

export const sameSizeProgressiveQ80 = makeTransform({
  progressive: true,
  doNotEnlarge: true,
  output: 'jpg',
  quality: 80
})

export const tile150 = makeTransform({
  width: 150,
  height: 150,
  progressive: true,
  fit: 'contain',
  doNotEnlarge: true,
  output: 'jpg'
})
