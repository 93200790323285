<template>
  <Layout>
    <template #content>
      <NavbarInfobox v-if="service && infobox" :service="service" :infobox="infobox" />

      <div class="px-12">
        <div class="flex flex-row items-center">
          <h1 class="content-heading-1 mb-0">Infobox Team</h1>
        </div>

        <p class="content-subheading">
          The following people have access to the
          <strong>{{ infobox && infobox.infoboxName }}</strong>
          infobox.
        </p>
      </div>
    </template>

    <template #sidebar>
      <div>
        <InfoboxSidebarTabs
          @editor-tab-click="showMostRecentEditor"
          @add-service-click="openAddServiceSidebar"
        />

        <InfoboxSidebarSupport v-if="sidebarName === 'support'" />

        <InfoboxSidebarSectionBlank
          v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
        />
        <InfoboxSidebarScene v-if="sidebarName === 'editor' && sidebarEditorType === 'scene'" />

        <InfoboxSidebarAddScene
          v-if="sidebarName === 'add-service'"
          :services="services"
          @create-service="createScene"
          @add-service="addServiceToEnv"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
/* eslint vue/no-unused-components:0 */
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { models, useFind, useGet } from 'feathers-vuex'
import { getScene } from '@/use/scenes'
import useSidebarTabState from '@/use/sidebar-tab-state'

import { Scene } from '@rovit/scene'
import { TmServiceInfo } from '@rovit/tm-service-info'

import Layout from '../layouts/Layout'
import { PlusIcon } from 'vue-feather-icons'
import NavbarInfobox from '../components/NavbarInfobox/NavbarInfobox'
import GridSelector from '../components/GridSelector/GridSelector'

import InfoboxSidebarTabs from '../components/Infobox/SidebarTabs.vue'
import InfoboxSidebarSupport from '../components/Infobox/SidebarSupport.vue'
import InfoboxSidebarSectionBlank from '../components/Infobox/SidebarSectionBlank.vue'
import InfoboxSidebarScene from '../components/Infobox/SidebarScene.vue'
import InfoboxSidebarAddScene from '../components/Infobox/SidebarAddScene.vue'

export default {
  name: 'InfoboxTeam',
  metaInfo: {
    title: 'Infobox Team'
  },
  components: {
    // Scene Components
    Scene,

    // Page Layout Components
    Layout,
    PlusIcon,
    NavbarInfobox,
    GridSelector,
    InfoboxSidebarTabs,
    InfoboxSidebarSupport,
    InfoboxSidebarSectionBlank,
    InfoboxSidebarScene,
    InfoboxSidebarAddScene
  },
  setup(props, context) {
    const { Environment, Service, EnvService, Scene } = models.api
    const components = [TmServiceInfo]
    const selectedService = ref(null)
    const user = context.root.$store.state.auth.user
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    // Current Service
    const { item: service } = useGet({
      model: Service,
      id: context.root.$route.params.serviceId
    })

    // Current Infobox
    const { scene: infobox } = getScene(context.root.$route.params.infoboxId)
    // Current Scene
    const { scene } = getScene(context.root.$route.params.sceneId)
    const scenes = computed(() => {
      const response = Scene.findInStore({
        query: {
          $or: [
            { _id: context.root.$route.params.infoboxId },
            { parentInfoboxId: context.root.$route.params.infoboxId }
          ]
        }
      })
      return response.data
    })

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      const { $route } = context.root
      if ($route.query.serviceId) {
        return 'scene'
      } else {
        return 'blank'
      }
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      watchInQuery: ['serviceId'],
      context
    })

    /**
     * Create an EnvService record for the provided service and environent.
     */
    async function addEnvToService(service, environment) {
      const envService = await new EnvService({
        envId: environment.value._id,
        envName: environment.value.name,
        serviceId: service._id,
        serviceName: service.name
      }).save({
        $populateParams: {
          name: 'service'
        }
      })
      return envService
    }
    /**
     * Show the add service sidebar, which gives the user the option
     * to create a new service or search existing services.
     */
    async function openAddServiceSidebar() {
      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'add-service'
      })
      context.root.$router.push({ query }, () => {})
    }
    /**
     * Creates a new service with the currentOrg as the initial owner.
     */
    async function createScene() {
      const newService = await new Service({
        name: 'New Service',
        orgs: [
          {
            orgId: currentOrg._id,
            orgName: currentOrg.name,
            accessType: 'owner'
          }
        ]
      }).save()

      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'editor',
        serviceId: newService._id
      })
      context.root.$router.push({ query })
    }
    /**
     * Handles the currently selected environment(s). A different sidebar is shown for
     * arrays vs single objects.
     */
    function handleServiceClick({ item, e }) {
      if (Array.isArray(selectedService.value)) {
        if (!selectedService.value.length) return
        // TODO: handle when multiple orgs are in `selectedService` array.
      } else {
        if (!selectedService.value) return
        openEditor({ serviceId: selectedService.value._id })
      }
    }
    /**
     * When an environment is double-clicked, switch to it and open its dashboard page.
     */
    function handleServiceDblClick({ item, e }) {
      context.root.$router.push({
        name: 'Environment',
        params: { serviceId: item._id }
      })
    }

    function handleActivate(data) {
      const { module, meta } = data
      const fromComponent = module.moduleComponentName
    }
    function handleAddBefore(data) {
      console.log(data)
    }
    function handleAddAfter(data) {
      console.log(data)
    }
    function handleRemoveModule(data) {
      console.log(data)
    }

    return {
      service,
      components, // template modules
      infobox,
      scene,
      scenes,
      showMostRecentEditor,
      sidebarName,
      sidebarEditorType,
      selectedService,
      openAddServiceSidebar,
      addServiceToEnv,
      createScene,
      handleServiceClick,
      handleServiceDblClick,
      user,
      currentOrg,
      // Scene Events
      handleActivate,
      handleAddBefore,
      handleAddAfter,
      handleRemoveModule
    }
  }
}
</script>

<style lang="postcss" scoped></style>
