<template>
  <div class="sidebar-edit-icon">
    <div v-if="icon">
      <!-- Sidebar Modal with Icon Selector -->
      <SidebarModal v-model="isUploaderOpen" class="z-10">
        <template #default="{ close }">
          <SidebarUploadSelector
            :model="models.api.Icon"
            :new-files="newFiles"
            :current-tab="currentUploaderTab"
            :endpoint="$store.state.uploadUrlEndpoint"
            qid="iconSelector"
            resource-name="Icon"
            @select="handleFileSelect"
            @tab-change="handleTabChange"
            @uploaded="handleUploaded"
            @close="close"
          />
        </template>
      </SidebarModal>

      <h1 class="sidebar-heading-1 mt-2">Edit Icon</h1>

      <label class="block">
        <span class="text-gray-700">Name</span>
        <input
          v-model="_icon.name"
          type="text"
          class="form-input block w-full"
          :placeholder="icon.originalFileName"
          @blur="e => save_icon('name')"
        />
      </label>

      <div class="mt-3 ml-1">
        <label class="flex flex-row items-center text-lg">
          <input
            class="form-checkbox mr-1"
            type="checkbox"
            v-model="_icon.isMapIcon"
            @change="() => save_icon(['isMapIcon'])"
          />
          Use as Map Icon
          <img v-if="icon.dataUrl" :src="_icon.dataUrl" class="ml-2" />
        </label>
      </div>

      <div class="block mt-3 w-3/4">
        <span class="block mb-1">Icon</span>

        <!-- Image -->
        <ImageChangeRemove
          ref="imageChangeRemove"
          v-if="icon.url"
          :src="_icon.url"
          fit="contain"
          hide-fit
          @dragenter="openSelector"
          @change-image="openSelector"
          @remove-image="handleRemoveIcon"
        />

        <!-- No Image -->
        <NoImage v-else button-text="Select Icon" @click="openSelector" @dragenter="openSelector" />
      </div>

      <DangerZoneDelete @remove="remove" />
    </div>

    <div v-else>Loading Icon</div>
  </div>
</template>

<script>
import { models } from 'feathers-vuex'
import { ref, computed, watch, onMounted, nextTick } from '@vue/composition-api'
import { handleClones } from '@rovit/utils/handle-clones.js'
import fastCopy from 'fast-copy'
import _omit from 'lodash/omit'
import { prepareUploadData } from '@rovit/file-upload'
import { toDataUrl } from '@/use/data-url.js'

import { SidebarModal } from '@rovit/sidebar-modal'
import { ImageChangeRemove, NoImage } from '@rovit/image-display-sidebar'
import { SidebarUploadSelector } from '@rovit/sidebar-upload-selector'
import DangerZoneDelete from '../../components/DangerZoneDelete/DangerZoneDelete.vue'

export default {
  name: 'EnvLibIconsSidebarEditIcon',
  components: {
    ImageChangeRemove,
    NoImage,
    SidebarModal,
    SidebarUploadSelector,
    DangerZoneDelete
  },
  props: {
    icon: {
      validator: () => true,
      default: null
    },
    envId: {
      type: String,
      default: null
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _icon } = clones
    const { save_icon } = saveHandlers
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    function remove() {
      props.icon.remove()
      const query = {}
      context.root.$router.push({ query })
    }

    /**
     * Primary Asset Selection
     */
    const currentUploaderTab = ref('library')
    const isUploaderOpen = ref(false)
    const newFiles = ref([])
    function openSelector() {
      isUploaderOpen.value = true
    }
    function closeSelector() {
      isUploaderOpen.value = false
    }
    function handleTabChange(tabName) {
      currentUploaderTab.value = tabName
    }
    /**
     * Uploading works like normal, but as soon as you click a file, the most recently-uploaded
     * icon will replace the current (see the handleFileSelect handler).
     */
    async function handleUploaded(data) {
      const uploadData = await prepareUploadData(data)
      Object.assign(uploadData, {
        envId: props.envId,
        orgId: currentOrg._id
      })
      const icon = await new models.api.Asset(uploadData).save()
      newFiles.value.push(icon)
    }
    /**
     * This is different than most handleFileSelect functions. Since we're overwriting the original
     * icon in the database, it creates a copy of the original as a new record and then overwrites
     * the data for the original record with the new icon's data.
     */
    async function handleFileSelect(item) {
      // Save a copy of the old icon.
      const oldData = _omit(props.icon, ['_id'])
      oldData.envId = props.envId
      oldData.name += '[old icon]'
      await new models.api.Icon(oldData).save()

      // Overwrite the current record with the selection
      const newData = _omit(item, ['_id'])
      newData.envId = props.envId
      save_icon(newData)

      closeSelector()
    }
    function handleRemoveIcon() {
      Object.assign(_icon.value, {
        primaryAssetId: null,
        primaryAssetUrl: ''
      })
      save_icon(['primaryAssetId', 'primaryAssetUrl'])
    }

    // When _icon.isMapIcon is true, save the base64 string to db.
    const imageChangeRemove = ref(null)
    onMounted(() => {
      watch(
        () => _icon.value.isMapIcon,
        async val => {
          if (val) {
            if (!_icon.value.dataUrl) {
              const image = imageChangeRemove.value.$refs.imageEl
              const dataUrl = await toDataUrl(image)
              save_icon({ dataUrl })
            }
          } else {
            if (_icon.value.dataUrl) {
              save_icon({ dataUrl: '' })
            }
          }
        }
      )
    })

    return {
      models,
      ...clones,
      ...saveHandlers,
      remove,

      imageChangeRemove,

      // SidebarUploadSelector
      currentUploaderTab,
      isUploaderOpen,
      newFiles,
      openSelector,
      closeSelector,
      handleTabChange,
      handleUploaded,
      handleFileSelect,
      handleRemoveIcon
    }
  }
}
</script>

<style lang="postcss"></style>
