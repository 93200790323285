var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('NavbarEnvironment',{attrs:{"env":_vm.currentEnv}}),_c('div',{staticClass:"px-12"},[_c('h1',{staticClass:"content-heading-1"},[_vm._v("Environment Library")]),_c('p',{staticClass:"content-subheading"},[_vm._v(" Browse and build the asset libraries for the "),_c('strong',[_vm._v(_vm._s(_vm.currentEnv && _vm.currentEnv.name))]),_vm._v(" environment. ")]),_c('div',{staticClass:"flex flex-row mt-3 -m-1"},[_c('router-link',{staticClass:"flex-1 mx-1",attrs:{"to":{
            name: 'EnvLibIcons',
            params: {
              envId: _vm.currentEnv && _vm.currentEnv._id
            }
          }}},[_c('div',{staticClass:"h-32 library-button"},[_c('MapPinIcon',{staticClass:"w-10 h-10"})],1),_c('p',{staticClass:"mt-2 mb-1 text-2xl font-medium leading-none"},[_vm._v("Icons")]),_c('p',{staticClass:"leading-tight"},[_vm._v("Build an icon set for clear communication.")])]),_c('router-link',{staticClass:"flex-1 mx-1",attrs:{"to":{
            name: 'EnvLibCategories',
            params: {
              envId: _vm.currentEnv && _vm.currentEnv._id
            }
          }}},[_c('div',{staticClass:"h-32 library-button"},[_c('ListIcon',{staticClass:"w-10 h-10"})],1),_c('p',{staticClass:"mt-2 mb-1 text-2xl font-medium leading-none"},[_vm._v("Categories")]),_c('p',{staticClass:"leading-tight"},[_vm._v("Choose categories to organize services.")])]),_c('router-link',{staticClass:"flex-1 mx-1",attrs:{"to":{
            name: 'EnvLibBadges',
            params: {
              envId: _vm.currentEnv && _vm.currentEnv._id
            }
          }}},[_c('div',{staticClass:"h-32 library-button"},[_c('AwardIcon',{staticClass:"w-10 h-10"})],1),_c('p',{staticClass:"mt-2 mb-1 text-2xl font-medium leading-none"},[_vm._v("Badges")]),_c('p',{staticClass:"leading-tight"},[_vm._v("Setup badges to describe & attribute services.")])]),_c('router-link',{staticClass:"flex-1 mx-1",attrs:{"to":{
            name: 'EnvLibPhotos',
            params: {
              envId: _vm.currentEnv && _vm.currentEnv._id
            }
          }}},[_c('div',{staticClass:"h-32 library-button"},[_c('ImageIcon',{staticClass:"w-10 h-10"})],1),_c('p',{staticClass:"mt-2 mb-1 text-2xl font-medium leading-none"},[_vm._v("Photo Library")]),_c('p',{staticClass:"leading-tight"},[_vm._v("Manage photos to promote services.")])]),_c('router-link',{staticClass:"flex-1 mx-1",attrs:{"to":{
            name: 'EnvLibPanos',
            params: {
              envId: _vm.currentEnv && _vm.currentEnv._id
            }
          }}},[_c('div',{staticClass:"h-32 library-button"},[_c('PanoIcon',{staticClass:"w-10 h-10"})],1),_c('p',{staticClass:"mt-2 mb-1 text-2xl font-medium leading-none"},[_vm._v("Panos")]),_c('p',{staticClass:"leading-tight"},[_vm._v("Paroramic images for 360° views and tours.")])]),_c('router-link',{staticClass:"flex-1 mx-1",attrs:{"to":{
            name: 'EnvLibLinkedEnvs',
            params: {
              envId: _vm.currentEnv && _vm.currentEnv._id
            }
          }}},[_c('div',{staticClass:"h-32 library-button"},[_c('GlobeIcon',{staticClass:"w-10 h-10"})],1),_c('p',{staticClass:"mt-2 mb-1 text-2xl font-medium leading-none"},[_vm._v("Linked Envs.")]),_c('p',{staticClass:"leading-tight"},[_vm._v("Connect with other environments.")])])],1),_c('hr',{staticClass:"pb-1 my-4 border-t border-gray-200"}),_c('router-link',{attrs:{"to":{
          name: 'EnvLibStore',
          params: {
            envId: _vm.currentEnv && _vm.currentEnv._id
          }
        }}},[_c('p',{staticClass:"mt-4 text-2xl font-medium leading-none"},[_vm._v("Rovit Store")]),_c('p',{staticClass:"mb-1 leading-tight"},[_vm._v("Browse for resources to start your environment.")]),_c('div',{staticClass:"h-32 library-button"},[_c('ShoppingCartIcon',{staticClass:"w-10 h-10"})],1)])],1)]},proxy:true},{key:"sidebar",fn:function(){return [_vm._v(" Environment Sidebar ")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }