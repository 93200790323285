<template>
  <XSelect
    v-model="serviceProxy"
    :items="services"
    :label="i => i.name"
    :search.sync="search"
    placeholder="Select Service"
    :create="handleCreateService"
    :clearable="clearable"
  />
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { findServices, createService } from '@/use/services.js'

import { XSelect } from '@rovit/x-select'

export default {
  name: 'ServiceSelect',
  components: {
    XSelect
  },
  model: {
    prop: 'service'
  },
  props: {
    service: {
      required: true,
      default: null,
      validator: () => true
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    const serviceProxy = computed({
      get: () => props.service,
      set: val => context.emit('input', val)
    })
    const search = ref('')
    const pagination = ref({
      $limit: 50,
      $skip: 0
    })
    const sort = { name: 1 }
    const queryWhen = computed(() => {
      return true
      // return context.root.$store.state.services.ids.length < 30
    })
    const { services } = findServices({ sort, queryWhen, search, pagination })

    async function handleCreateService(name) {
      const { service } = await createService({ name, org: currentOrg })
      return service
    }

    return { serviceProxy, search, pagination, sort, services, handleCreateService }
  }
}
</script>

<style lang="postcss"></style>
