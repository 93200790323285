<template>
  <div class="sidebar-support">
    <h1 class="sidebar-heading-1 mt-2">About Scenes</h1>

    <p class="sidebar-body">
      Each scene will have a scene published in this environment. Once you bring a scene into an
      environment, you can then begin creating scenes for that scene.
    </p>

    <p class="sidebar-subheading font-bold mt-2">Watch a video</p>

    <div
      class="w-full h-48 bg-gray-800 flex flex-col items-center justify-center rounded-lg text-white"
    >
      <PlayIcon class="w-12 h-12" />
      This is just a placeholder.
    </div>

    <h1 class="sidebar-heading-1 mt-2">Scenes in Environments</h1>

    <p class="sidebar-body">
      Adding a scene to an environment eventually allows the scene to show on the map when a user
      opens the environment. You can add a scene to this environment by clicking the "Add Service"
      button in the content area, or the
      <PlusIcon class="inline w-5 h-5 relative" style="bottom: 2px;" /> button at the top of this
      sidebar.
    </p>

    <h1 class="sidebar-heading-1 mt-2">Scenes and Scenes</h1>

    <p class="sidebar-body">
      Before a scene can show up in your environment, it needs to have an associated scene also
      added to your environment. Once you bring a scene into an environment, you can then begin
      creating scenes for that scene. For now, when you're searching for existing scenes to add to
      this environment, clicking on a scene will automatically add it. In the future, clicking a
      scene will show a preview overlay here in the sidebar. You will then be able to view and
      select the scene's existing scenes. Selecting a scene will add the scene AND the scene to the
      environment in one click.
    </p>
  </div>
</template>

<script>
import { PlusIcon, PlayIcon } from 'vue-feather-icons'

export default {
  name: 'EnvScenesSidebarSupport',
  components: {
    PlusIcon,
    PlayIcon
  },
  props: {}
}
</script>

<style lang="postcss"></style>
