<template>
  <div v-if="env" class="admin-navbar">
    <UserMenu class="mr-1" />

    <router-link to="/" class="link mr-2.5" :class="{ active: $route.name === 'OrgHome' }">
      <div>
        <HomeIcon />
      </div>
      Home
    </router-link>

    <router-link
      :to="{ name: 'OrgEnvironments' }"
      class="link"
      :class="{ active: $route.name === 'OrgEnvironments' }"
    >
      <div>
        <BriefcaseIcon />
      </div>
      Environs
    </router-link>

    <div class="text-green-900 mt-4">
      <ChevronRightIcon />
    </div>

    <!-- Environment Name -->
    <router-link
      :to="{
        name: 'EnvironmentServices',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link active-crumb"
      style="max-width: 92px;"
      :class="{ active: $route.name === 'OrgProjects' }"
    >
      <div class="flex flex-row justify-center bg-green-200">
        <EditIcon />
      </div>

      <VClamp autoresize :max-lines="2" class="leading-none mt-1" tag="p">
        {{ env && env.name }}
      </VClamp>
    </router-link>

    <div class="text-green-900 mt-4">
      <ChevronRightIcon />
    </div>

    <!-- Environment Services -->
    <router-link
      :to="{
        name: 'EnvironmentLibrary',
        params: {
          envId: env && env._id
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvironmentLibrary' }"
    >
      <div style="max-width: 52px; margin: 0 auto;">
        <BookIcon />
      </div>
      Library
    </router-link>

    <!-- Divider -->
    <div class="navbar-divider mr-2"></div>

    <router-link
      :to="{
        name: 'EnvLibStore',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page mr-1.5"
      :class="{ active: $route.name === 'EnvLibStore' }"
    >
      <div>
        <ShoppingCartIcon />
      </div>
      Store
    </router-link>

    <!-- Icons -->
    <router-link
      :to="{
        name: 'EnvLibIcons',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvLibIcons' }"
    >
      <div>
        <MapPinIcon />
      </div>
      Icons
    </router-link>

    <router-link
      :to="{
        name: 'EnvLibCategories',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page"
      :class="{ active: $route.name === 'EnvLibCategories' }"
    >
      <div class="mx-1.5" style="max-width: 52px;">
        <ListIcon />
      </div>
      <p class="leading-none mt-1">
        Categories
      </p>
    </router-link>

    <router-link
      :to="{
        name: 'EnvLibBadges',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page mr-0.5"
      :class="{ active: $route.name === 'EnvLibBadges' }"
    >
      <div class="mx-1.5" style="max-width: 52px;">
        <AwardIcon />
      </div>
      <p class="leading-none mt-1">
        Badges
      </p>
    </router-link>

    <!-- Photos -->
    <router-link
      :to="{
        name: 'EnvLibPhotos',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvLibPhotos' }"
    >
      <div>
        <ImageIcon />
      </div>
      Photos
    </router-link>

    <!-- Panos -->
    <router-link
      :to="{
        name: 'EnvLibPanos',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvLibPanos' }"
    >
      <div>
        <PanoIcon />
      </div>
      Panos
    </router-link>

    <!-- Linked Envs -->
    <router-link
      :to="{
        name: 'EnvLibLinkedEnvs',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvLibLinkedEnvs' }"
    >
      <div>
        <GlobeIcon />
      </div>

      <p class="leading-none mt-1">
        Linked <br />
        Environs
      </p>
    </router-link>
  </div>
</template>

<script>
import UserMenu from '../UserMenu/UserMenu'
import VClamp from 'vue-clamp'
import { PanoIcon } from '@rovit/icons'
import {
  HomeIcon,
  BriefcaseIcon,
  BookIcon,
  ShoppingCartIcon,
  EditIcon,
  ChevronRightIcon,
  MapPinIcon,
  ImageIcon,
  ListIcon,
  AwardIcon,
  GlobeIcon
} from 'vue-feather-icons'

export default {
  name: 'NavbarEnvironmentLibrary',
  components: {
    UserMenu,
    HomeIcon,
    BriefcaseIcon,
    BookIcon,
    EditIcon,
    ShoppingCartIcon,
    ChevronRightIcon,
    MapPinIcon,
    PanoIcon,
    AwardIcon,
    ImageIcon,
    GlobeIcon,
    ListIcon,
    VClamp
  },
  props: {
    env: {
      type: Object,
      default: () => null
    },
    envService: {
      type: Object,
      default: () => null
    }
  }
}
</script>

<style lang="postcss"></style>
