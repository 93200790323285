<template>
  <div class="pano-map">
    <!-- Toolbar -->
    <div class="flex flex-row">
      <div class="flex-grow">
        <p v-if="!pano.location">
          <button
            type="button"
            class="rounded px-2 text-sm mr-2"
            :class="isEditLocationEnabled ? 'bg-yellow-600 text-black' : 'bg-red-600 text-white'"
            @click="isEditLocationEnabled = true"
          >
            Add Location
          </button>

          <span v-if="isEditLocationEnabled">Click or search to set the location.</span>
          <span v-else>Current pano is not on the map.</span>
        </p>
      </div>

      <!-- Edit Button -->
      <button
        type="button"
        class="flex flex-row items-center p-1 focus:outline-none rounded-t"
        :class="isEditLocationEnabled ? 'bg-yellow-600 text-black' : ''"
        @click="isEditLocationEnabled = !isEditLocationEnabled"
      >
        <p v-if="isEditLocationEnabled" class="-my-1 mr-1">Done</p>
        <Edit2Icon size="1x" />
      </button>

      <!-- "Delete Environment" Button -->
      <XPopper placement="bottom">
        <template #reference="{ toggle }">
          <!-- Menu Button -->
          <button
            ref="reference"
            type="button"
            class="flex flex-row items-center p-1 focus:outline-none rounded-t"
            @click.stop="toggle"
          >
            <MenuIcon size="1x" />
          </button>
        </template>

        <template #popper="{ toggle }">
          <div
            ref="popper"
            v-click-outside="toggle"
            class="bg-white bg-gray-300 black:bg-gray-800 p-3 rounded text-black z-50 flex flex-col dark:text-white"
          >
            <label>Map Theme</label>
            <div class="flex flex-row items-center">
              <button
                type="button"
                class="form-button bg-gray-400 hover:bg-gray-500 dark:bg-gray-500 dark:hover:bg-gray-700 mr-2"
                @click="() => setMapStyle('default')"
              >
                Classic
              </button>
              <button
                type="button"
                class="form-button danger"
                @click="() => setMapStyle('satellite')"
              >
                Satellite
              </button>
            </div>
          </div>
        </template>
      </XPopper>
    </div>

    <div class="h-64">
      <MglMap
        :access-token="$store.state.mapboxToken"
        :map-style="mapStyle"
        :zoom="zoom"
        :center="center"
        class="w-full h-full rounded overflow-hidden"
        @load="handleMapLoad"
        @click="handleMapClick"
        @styledata="handleMapChange"
      >
        <MglMarker
          v-if="shouldShowMarker && isMarkerVisible"
          :coordinates="markerCoords"
          :draggable="isEditLocationEnabled"
          :color="isEditLocationEnabled ? 'orange' : '#46B2CC'"
          @dragend="handleMarkerDragEnd"
        />

        <!-- Panos with Scenes -->
        <MglGeojsonLayer
          :key="'panos-with-scenes' + randomKey"
          source-id="panos-with-scenes"
          :source="panosWithScenesSource"
          :layer-id="panosWithScenesLayer.id"
          :layer="panosWithScenesLayer"
          @click="handleLayerClick"
          @mouseenter="handleLayerMouseEnter"
          @mouseleave="handleLayerMouseLeave"
        />

        <!-- Panos without Scenes -->
        <MglGeojsonLayer
          :key="'panos-without-scenes' + randomKey"
          source-id="panos-without-scenes"
          :source="panosWithoutScenesSource"
          :layer-id="panosWithoutScenesLayer.id"
          :layer="panosWithoutScenesLayer"
          @click="handleLayerClick"
          @mouseenter="handleLayerMouseEnter"
          @mouseleave="handleLayerMouseLeave"
        />
      </MglMap>
    </div>
    <Geocode
      v-if="isEditLocationEnabled"
      :mapbox-token="$store.state.mapboxToken"
      direction="forward"
      placeholder="search"
      class="w-full mt-1"
      @input="setMarkerFromGeocode"
    />
    <div v-if="isEditLocationEnabled" class="mt-2 flex">
      <GpsCoordinateInput v-model="_pano.location" @save="updatePanoLocation" />
    </div>

    <!-- Env Bounds -->
    <div v-if="isEditLocationEnabled" class="flex mt-3">
      <button type="button" class="form-button primary py-1 ml-1" @click="setEnvBounds">
        Set Bounds
      </button>
      <button type="button" class="form-button primary py-1 ml-1" @click="resetMapBounds">
        Reset Bounds
      </button>
    </div>
  </div>
</template>

<script>
import { models } from 'feathers-vuex'
import { ref, computed, watch } from '@vue/composition-api'
import _get from 'lodash/get.js'
import _has from 'lodash/has.js'
import fastCopy from 'fast-copy'
import { handleClones } from '@rovit/utils/handle-clones.js'
import { mapStyles } from '@rovit/map'
import { GpsCoordinateInput } from '@rovit/gps-coordinate-input'

import { MglMap, MglMarker, MglGeojsonLayer } from 'vue-mapbox'
import Geocode from '@rovit/map'
import { Edit2Icon, MenuIcon } from 'vue-feather-icons'
import { XPopper } from '@ionomy/x-popper'

export default {
  name: 'PanoMap',
  components: {
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    Edit2Icon,
    Geocode,
    MenuIcon,
    GpsCoordinateInput,
    XPopper
  },
  props: {
    pano: {
      type: Object,
      required: true
    },
    panosWithScenes: {
      type: Array,
      required: true
    },
    env: {
      validator: val => typeof val === 'object',
      required: true
    },
    panosWithoutScenes: {
      type: Array,
      required: true
    }
  },
  setup(props, context) {
    /**
     * Loading
     */
    const { clones, saveHandlers } = handleClones(props)
    const { save_pano } = saveHandlers
    const { _pano } = clones

    const isMapReady = ref(false)
    const vueMapbox = {}
    const longitude = ref(
      _get(props.pano, 'location.coordinates') != null
        ? _get(props.pano, 'location.coordinates')[0]
        : null
    )
    const latitude = ref(
      _get(props.pano, 'location.coordinates') != null
        ? _get(props.pano, 'location.coordinates')[1]
        : null
    )
    function handleMapLoad(event) {
      Object.assign(vueMapbox, event)
      isMapReady.value = true
      resetMapBounds()
    }
    function resetMapBounds() {
      if (props.env.bbox && props.env.bbox.length === 2) {
        fitToBounds(props.env.bbox)
      }
    }
    function setEnvBounds() {
      if (isMapReady.value == true) {
        var bounds = vueMapbox.component.map.getBounds()
        const data = { bbox: bounds.toArray() }
        const _env = props.env
          .clone(data)
          .save({ data })
          .then(res => {
            this.$toasted.global.actionSuccess('Bounds updated for environment')
          })
          .catch(error => {
            this.$toasted.global.actionError('Could not update bounds for env')
          })
      } else {
        console.log('Map is not ready')
      }
    }
    function fitToBounds(bounds) {
      if (isMapReady.value) {
        vueMapbox.component.map.fitBounds(bounds)
      }
    }
    function handleMapChange(event) {
      randomKey.value = Math.random()
    }

    /**
     * Map
     */
    const zoom = ref(5.87)
    const defaultCenter = [-111.768, 39.428]
    const center = ref(_get(props.pano, 'location.coordinates') || defaultCenter)
    const randomKey = ref(Math.random())

    // mapStyle
    const mapStyle = ref('mapbox://styles/mapbox/streets-v9')
    function setMapStyle(name) {
      mapStyle.value = mapStyles[name]
    }

    watch(
      () => _get(props.pano, 'location.coordinates'),
      coords => {
        if (coords && coords.length === 2) {
          longitude.value = coords[0]
          latitude.value = coords[1]

          if (isMapReady.value) {
            vueMapbox.component.actions.easeTo({
              center: coords
            })
          }
        } else {
          longitude.value = null
          latitude.value = null
        }
      }
    )

    async function handleMapClick({ component, map, mapboxEvent }) {
      const { lng, lat } = mapboxEvent.lngLat
      if (isEditLocationEnabled.value && !props.pano.location) {
        updatePanoLocation = updatePanoLocation.bind(this)
        updatePanoLocation([lng, lat])
      }
    }

    /**
     * Marker
     */
    const shouldShowMarker = computed(() => _has(props.pano, 'location.coordinates'))
    const markerCoords = computed({
      get: () => _get(props.pano, 'location.coordinates')
    })
    const isEditLocationEnabled = ref(false)
    const isMarkerVisible = ref(true)

    function handleMarkerDragEnd({ component, map, mapboxEvent, marker }) {
      const { lng, lat } = mapboxEvent.target.getLngLat()
      const coordinates = [lng, lat]
      updatePanoLocation = updatePanoLocation.bind(this)
      updatePanoLocation(coordinates)
    }
    function updatePanoLocation(coordinates) {
      if (coordinates && coordinates.length == 2) {
        const location = { type: 'Point', coordinates }
        Object.assign(_pano.value, { location })
      }

      save_pano(['location'])
        .then(res => {
          this.$toasted.global.actionSuccess('Updated location')
        })
        .catch(error => {
          this.$toasted.global.actionError('Failed to update location')
        })
    }
    function setMarkerFromGeocode(data) {
      const coords = data.geometry.coordinates
      updatePanoLocation = updatePanoLocation.bind(this)
      updatePanoLocation(coords)
    }

    // For resetting color style the marker must be removed and re-added
    watch(
      () => isEditLocationEnabled.value,
      edit => {
        isMarkerVisible.value = false
        setTimeout(() => {
          isMarkerVisible.value = true
        }, 0)
      }
    )

    watch(
      () => context.root.$route.query.panoId,
      panoId => {
        if (panoId) {
          isEditLocationEnabled.value = false
        }
      },
      { immediate: true }
    )

    function areCoordsValidFor(item) {
      const coords = _get(item, 'location.coordinates')
      return Array.isArray(coords) && coords[0] != null && coords[1] != null
    }
    function makeSource(items) {
      return {
        data: {
          type: 'FeatureCollection',
          features: items
            // remove invalid coordinates and current record
            .filter(item => {
              return areCoordsValidFor(item) && item._id !== props.pano._id
            })
            .map((item, index) => {
              const _item = fastCopy(item)
              _item.id = index
              _item.properties = { _id: item._id }
              _item.geometry = item.location
              return _item
            })
        }
      }
    }

    /**
     * panosWithScenes layer
     */
    const panosWithScenesSource = computed(() => makeSource(props.panosWithScenes))
    const panosWithScenesLayer = ref({
      id: 'panos-with-scenes-layer',
      type: 'circle',
      source: 'panos-with-scenes',
      paint: {
        'circle-radius': ['case', ['boolean', ['feature-state', 'active'], false], 9, 6],
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#0FFF18',
          '#1E99FF'
        ],
        'circle-stroke-width': ['case', ['boolean', ['feature-state', 'active'], false], 2, 0],
        'circle-stroke-color': '#FFFFFF'
      }
    })

    const panosWithoutScenesSource = computed(() => makeSource(props.panosWithoutScenes))
    const panosWithoutScenesLayer = ref({
      id: 'panos-without-scenes-layer',
      type: 'circle',
      source: 'panos-without-scenes',
      paint: {
        'circle-radius': ['case', ['boolean', ['feature-state', 'active'], false], 9, 6],
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#EA2626',
          '#C62F2F'
        ],
        'circle-stroke-width': ['case', ['boolean', ['feature-state', 'active'], false], 2, 0],
        'circle-stroke-color': '#FFFFFF'
      }
    })

    const targetPanoId = ref(null)
    const targetPano = computed(() => models.api.Pano.getFromStore(targetPanoId.value))

    /**
     * Layer events
     */
    let hoveredFeature = null
    function handleLayerMouseEnter({ mapboxEvent, layerId, map, component }) {
      map.getCanvas().style.cursor = 'pointer'
      const feature = _get(mapboxEvent, 'features.0')
      if (feature) {
        map.setFeatureState(feature, { hover: true })
        hoveredFeature = feature
      }
    }
    function handleLayerMouseLeave({ mapboxEvent, layerId, map, component }) {
      map.getCanvas().style.cursor = ''
      const feature = _get(mapboxEvent, 'features.0') || hoveredFeature
      if (feature) {
        map.setFeatureState(feature, { hover: false })
      }
      hoveredFeature = null
    }
    function handleLayerClick({ mapboxEvent, layerId, map, component }) {
      const feature = mapboxEvent.features[0]
      targetPanoId.value = feature.properties._id
      context.emit('target-pano', targetPano.value)
    }

    return {
      // Loading
      isMapReady,
      handleMapLoad,
      handleMapChange,
      randomKey,

      // Map
      mapStyle,
      setMapStyle,
      zoom,
      center,
      handleMapClick,

      // Marker
      shouldShowMarker,
      markerCoords,
      isEditLocationEnabled,
      isMarkerVisible,
      handleMarkerDragEnd,
      setMarkerFromGeocode,

      // panosWithScenes
      panosWithScenesSource,
      panosWithScenesLayer,
      panosWithoutScenesSource,
      panosWithoutScenesLayer,

      // Layer Events
      handleLayerMouseEnter,
      handleLayerMouseLeave,
      handleLayerClick,

      // Target Pano
      targetPanoId,
      targetPano,

      setEnvBounds,
      resetMapBounds,

      // Add geo location
      updatePanoLocation,
      latitude,
      longitude,
      _pano
    }
  }
}
</script>

<style lang="postcss">
/* Shrink the mapbox logo for small maps */
.pano-map .mapboxgl-ctrl-bottom-left {
  transform: scale(0.65) translate(-28px, 12px);
}
.pano-map .mapboxgl-ctrl-bottom-right {
  visibility: hidden;
}
</style>
