<template>
  <div class="admin-navbar">
    <UserMenu class="mr-1" />

    <router-link to="/" class="link mr-2.5" :class="{ active: $route.name === 'OrgHome' }">
      <div>
        <HomeIcon />
      </div>
      Home
    </router-link>

    <router-link to="/services" class="link" :class="{ active: $route.name === 'OrgServices' }">
      <div>
        <BriefcaseIcon />
      </div>
      Services
    </router-link>

    <div class="text-green-900 mt-4">
      <ChevronRightIcon />
    </div>

    <!-- Service Name -->
    <router-link
      :to="{
        name: 'OrgServiceInfoboxes',
        params: {
          serviceId: (service && service._id) || ''
        }
      }"
      class="link active-crumb mr-2"
      style="max-width: 92px;"
      :class="{ active: $route.name === 'OrgProjects' }"
    >
      <div class="flex flex-row justify-center bg-green-200">
        <EditIcon />
      </div>

      <VClamp autoresize :max-lines="2" class="leading-none mt-1" tag="p">
        {{ service && service.name }}
      </VClamp>
    </router-link>

    <!-- Divider -->
    <div class="navbar-divider mr-2"></div>

    <!-- Infoboxes -->
    <router-link
      :to="{
        name: 'OrgServiceInfoboxes',
        params: {
          serviceId: (service && service._id) || ''
        }
      }"
      class="link page mr-1.5"
      :class="{ active: $route.name === 'OrgServiceInfoboxes' }"
    >
      <div style="max-width: 52px; margin: 0 auto;">
        <InfoIcon />
      </div>
      Infoboxes
    </router-link>

    <!-- Service Details -->
    <router-link
      :to="{
        name: 'OrgServiceDetails',
        params: {
          serviceId: (service && service._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'OrgServiceDetails' }"
    >
      <div>
        <FileTextIcon />
      </div>
      Details
    </router-link>

    <router-link
      :to="{
        name: 'OrgServiceAnalytics',
        params: {
          serviceId: (service && service._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'OrgServiceAnalytics' }"
    >
      <div>
        <BarChart2Icon />
      </div>
      Analytics
    </router-link>

    <!-- Team -->
    <router-link
      :to="{
        name: 'OrgServiceTeam',
        params: {
          serviceId: (service && service._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'OrgServiceTeam' }"
    >
      <div>
        <UsersIcon />
      </div>
      Team
    </router-link>

    <!-- Billing -->
    <router-link
      :to="{
        name: 'OrgServiceBilling',
        params: {
          serviceId: (service && service._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'OrgServiceBilling' }"
    >
      <div>
        <CreditCardIcon />
      </div>
      Billing
    </router-link>
  </div>
</template>

<script>
import UserMenu from '../UserMenu/UserMenu'
import VClamp from 'vue-clamp'
import {
  HomeIcon,
  BriefcaseIcon,
  InfoIcon,
  EditIcon,
  ChevronRightIcon,
  FileTextIcon,
  BarChart2Icon,
  UsersIcon,
  CreditCardIcon
} from 'vue-feather-icons'

export default {
  name: 'NavbarService',
  components: {
    UserMenu,
    HomeIcon,
    BriefcaseIcon,
    InfoIcon,
    EditIcon,
    ChevronRightIcon,
    FileTextIcon,
    BarChart2Icon,
    UsersIcon,
    CreditCardIcon,
    VClamp
  },
  props: {
    service: {
      type: Object,
      default: () => null
    }
  }
}
</script>

<style lang="postcss"></style>
