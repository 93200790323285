<template>
  <div class="mt-3 transition-all">
    <p class="sidebar-heading-1 flex flex-row items-center">
      <span class="pr-1">Infobox Layouts</span>
      <XPopper placement="below">
        <template #reference="{ toggle }">
          <button ref="reference" type="button" class="icon-button small" @click.stop="toggle">
            <PlusIcon />
          </button>
        </template>

        <template #popper="{ toggle }">
          <div
            ref="popper"
            v-click-outside="toggle"
            class="z-50 shadow px-3 py-2 bg-white dark:bg-gray-700 rounded-lg"
          >
            <button class="text-lg text-black dark:text-white" @click="handleCreateInfobox">
              Create new Infobox
            </button>
          </div>
        </template>
      </XPopper>
    </p>

    <div class="sidebar-subheading">
      <div v-if="infoboxes.length">
        This service has the following infobox{{ infoboxes.length === 1 ? '' : 'es' }}.
        <!-- If an envService is passed, these instructions show. -->
        <div v-if="envService">Click to set as the Infobox for {{ envService.envName }}.</div>
      </div>
      <div v-else>The service does not have any infoboxes</div>
    </div>

    <div class="flex flex-row flex-wrap mt-1.5 -mx-1">
      <!-- Infobox with Label -->
      <div
        v-for="infobox in infoboxes"
        :key="infobox._id"
        class="w-1/3 px-0.5 py-0.5 text-center border-2 rounded"
        :class="[infobox === selectedInfobox ? 'border-green-500' : 'border-transparent']"
        @click="() => handleInfoboxClick(infobox)"
        @dblclick="() => handleInfoboxDblClick(infobox)"
      >
        <div class="relative">
          <button
            type="button"
            class="absolute bottom-0 right-0 rounded-full bg-rovit-green text-white p-1 m-0.5"
            @click="() => handleInfoboxEditClick(infobox)"
          >
            <EditIcon class="p-1" />
          </button>
          <InfoboxPreviewTile class="w-full" />
        </div>
        {{ infobox.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { handleClones } from '@rovit/utils/handle-clones.js'
import { findInfoboxesForService, createInfobox } from '@/use/scenes.js'
import { XPopper } from '@ionomy/x-popper'

import { InfoboxPreviewTile } from '@rovit/scene-tiles'
import { PlusIcon, EditIcon } from 'vue-feather-icons'
import { computed } from '@vue/composition-api'

export default {
  name: 'EnvServiceLayoutSelector',
  components: {
    InfoboxPreviewTile,
    PlusIcon,
    EditIcon,
    XPopper
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    envService: {
      validator: val => true,
      default: null
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props, { useExisting: true })
    const { _envService } = clones

    /* Find Infobox Scenes for current service (item) */
    const service = computed(() => props.service)
    const { infoboxes } = findInfoboxesForService({ service })
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    const selectedInfobox = computed(() => {
      if (_envService && _envService.value) {
        return infoboxes.value.find(ib => ib._id === _envService.value.sceneId)
      } else {
        return null
      }
    })

    function handleInfoboxClick(infobox) {
      if (props.envService) {
        setInfoboxToEnvService(infobox)
      }
    }

    function handleInfoboxDblClick(infobox) {}

    function handleInfoboxEditClick(infobox) {
      takeToInfoboxDetails(infobox)
    }

    function takeToInfoboxDetails(infobox) {
      const { envId } = context.root.$route.params
      const { serviceId, _id: infoboxId } = infobox
      const query = Object.assign({}, { sidebar: 'editor', infoboxId: infobox._id })
      let name = ''
      let params = null
      /**
       * user can either come from environments page
       * or from services page.
       * check if params has envId, which means the user
       * came to this page from environments.
       *
       * take them to respective root page
       */
      if (envId) {
        name = 'EnvServiceInfoboxEditor'
        params = { envId, serviceId, infoboxId }
      } else {
        name = 'InfoboxEditor'
        params = { serviceId, infoboxId }
      }
      context.root.$router.replace({ name, params, query })
    }

    /**
     * Adds the provided infobox's sceneId to the EnvService
     */
    function setInfoboxToEnvService(infobox) {
      const data = {
        sceneId: infobox._id,
        sceneName: infobox.name
      }
      _envService.value.save({ data })
    }

    function openInfobox(infobox) {
      console.log('implement double click', infobox)
    }

    async function handleCreateInfobox() {
      const { infobox } = await createInfobox({ service, org: currentOrg })
      takeToInfoboxDetails(infobox)
    }

    return {
      ...clones,
      ...saveHandlers,
      infoboxes,
      handleInfoboxClick,
      handleInfoboxEditClick,
      handleInfoboxDblClick,
      handleCreateInfobox,
      selectedInfobox
    }
  }
}
</script>

<style lang="postcss"></style>
