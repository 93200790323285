<template>
  <label>
    <p class="sidebar-subheading mt-2 font-medium">Add to Environment</p>
    <div class="flex flex-row items-center">
      <XSelect
        v-model="selectedEnv"
        :items="environments"
        :label="i => i && i.name"
        clearable
        placeholder="Select an Environment"
        class="block flex-grow"
      />
      <button
        type="button"
        :disabled="alreadyInEnv"
        class="form-button ml-1 py-1"
        :class="[alreadyInEnv ? 'bg-gray-500' : 'primary']"
        @click="handleAddToEnv"
      >
        <div v-if="!alreadyInEnv && envService">Replace in Env</div>
        <div v-else>Add to Env</div>
      </button>
    </div>
    <div v-if="alreadyInEnv" class="ml-2 text-red-600">Already in Environment</div>
    <div v-else-if="envService" class="ml-2 text-red-600">
      Replace current infobox? <br />
      {{ envService.sceneName }}
    </div>
  </label>
</template>

<script>
import { ref, computed, toRefs } from '@vue/composition-api'
import { findEnvs } from '@/use/environments.js'
import {
  createEnvService,
  findEnvService,
  associateInfoboxWithEnvService
} from '@/use/env-services.js'

import { XSelect } from '@rovit/x-select'

export default {
  name: 'AddInfoboxToEnv',
  components: {
    XSelect
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    infobox: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const selectedEnv = ref(null)
    const { environments } = findEnvs()
    const service = computed(() => props.service)

    const { envService } = findEnvService({ env: selectedEnv, service })

    const alreadyInEnv = computed(() => {
      return envService.value && envService.value.sceneId === props.infobox._id
    })

    function handleAddToEnv() {
      // Update existing envService
      if (envService.value) {
        associateInfoboxWithEnvService({ infobox: props.infobox, envService })
      } else {
        createEnvService({ service: props.service, env: selectedEnv.value, scene: props.infobox })
      }
    }

    return { selectedEnv, environments, envService, alreadyInEnv, handleAddToEnv }
  }
}
</script>

<style lang="postcss"></style>
