var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-layout"},[_c('div',{staticClass:"content flex-grow relative"},[_c('ContentModal',{staticClass:"z-20 overflow-y-auto",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"flex flex-col items-center justify-center h-full"},[_c('div',{staticClass:"bg-white text-black rounded pb-3 px-3 dark:bg-gray-700"},[_c('div',{staticClass:"flex flex-row pointer-events-none"},[_c('div',{staticClass:"flex-grow"}),_c('button',{staticClass:"pointer-events-auto mt-0.4 py-1 mt-0.5 px-1.5 text-black dark:text-gray-300",attrs:{"type":"button"},on:{"click":close}},[_vm._v(" Close ")])]),(_vm.featureHotspotsModal.isOpen && _vm.featureHotspotsModal.data.item)?_c('ItemFeatureAsset',{attrs:{"item":_vm.featureHotspotsModal.data.item}}):_vm._e()],1)])]}}]),model:{value:(_vm.featureHotspotsModal.isOpen),callback:function ($$v) {_vm.$set(_vm.featureHotspotsModal, "isOpen", $$v)},expression:"featureHotspotsModal.isOpen"}}),_vm._t("content")],2),_c('div',{staticClass:"sidebar"},[_c('SidebarModal',{staticClass:"z-20 overflow-y-auto",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('SidebarUploadSelector',{attrs:{"model":_vm.models.api.Asset,"new-files":_vm.newFilesAsset,"current-tab":_vm.currentUploaderTabAsset,"endpoint":_vm.$store.state.uploadUrlEndpoint,"qid":"assetSelector","resource-name":"Asset"},on:{"select":_vm.handleFileSelectAsset,"tab-change":function (val) { return (_vm.currentUploaderTabAsset = val); },"uploaded":_vm.handleUploadedAsset,"close":close}})]}}]),model:{value:(_vm.assetModal.isOpen),callback:function ($$v) {_vm.$set(_vm.assetModal, "isOpen", $$v)},expression:"assetModal.isOpen"}}),_c('SidebarModal',{staticClass:"z-20 overflow-y-auto",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('SidebarUploadSelector',{attrs:{"model":_vm.models.api.Icon,"new-files":_vm.newFilesIcon,"current-tab":_vm.currentUploaderTabIcon,"endpoint":_vm.$store.state.uploadUrlEndpoint,"qid":"iconSelector","resource-name":"Icon"},on:{"select":_vm.handleFileSelectIcon,"tab-change":function (val) { return (_vm.currentUploaderTabIcon = val); },"uploaded":_vm.handleUploadedIcon,"close":close}})]}}]),model:{value:(_vm.iconModal.isOpen),callback:function ($$v) {_vm.$set(_vm.iconModal, "isOpen", $$v)},expression:"iconModal.isOpen"}}),_c('SidebarModal',{staticClass:"z-20 overflow-y-auto",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('SidebarUploadSelector',{attrs:{"model":_vm.models.api.Pano,"new-files":_vm.newFilesPano,"current-tab":_vm.currentUploaderTabPano,"endpoint":_vm.$store.state.uploadUrlEndpoint,"qid":"panoSelector","resource-name":"Pano"},on:{"select":_vm.handleFileSelectPano,"tab-change":function (val) { return (_vm.currentUploaderTabPano = val); },"uploaded":_vm.handleUploadedPano,"close":close}})]}}]),model:{value:(_vm.panoModal.isOpen),callback:function ($$v) {_vm.$set(_vm.panoModal, "isOpen", $$v)},expression:"panoModal.isOpen"}}),_c('SidebarModal',{staticClass:"z-20 overflow-y-auto",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [(_vm.badgeSelectorModal.isOpen)?_c('div',{staticClass:"bg-gray-300 dark:bg-gray-700 border border-gray-500 dark:border-gray-800 rounded h-full mr-1 p-2"},[_c('div',{staticClass:"flex flex-row items-center"},[_c('h2',{staticClass:"flex-grow"}),_c('button',{staticClass:"form-button text-black dark:text-white",attrs:{"type":"button"},on:{"click":close}},[_vm._v(" Close ")])]),_c('BadgeSelector',{on:{"input":_vm.saveBadgesMeta},model:{value:(_vm.badgeSelectorModal.data.item.value.badgesMeta),callback:function ($$v) {_vm.$set(_vm.badgeSelectorModal.data.item.value, "badgesMeta", $$v)},expression:"badgeSelectorModal.data.item.value.badgesMeta"}})],1):_vm._e()]}}]),model:{value:(_vm.badgeSelectorModal.isOpen),callback:function ($$v) {_vm.$set(_vm.badgeSelectorModal, "isOpen", $$v)},expression:"badgeSelectorModal.isOpen"}}),_vm._t("sidebar")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }