<template>
  <div>
    <XDataSelector
      v-model="selectedProxy"
      class="flex flex-row"
      :class="[wrap ? 'flex-wrap' : 'flex-no-wrap overflow-x-auto']"
      :items="items"
      :multiple="multiple"
    >
      <template #default="{ item, isSelected, select }">
        <div class="project-tile-wrapper relative">
          <slot name="icon" v-bind="{ item, isSelected, select, handleItemClick }"></slot>

          <slot name="item" v-bind="{ item, isSelected, select, handleItemClick }">
            <ProjectTile
              :label="label(item)"
              :src="src(item)"
              :fit="fit(item)"
              :selected="isSelected"
              class="w-full"
              @click="e => handleItemClick({ e, item, select })"
              @dblclick="e => $emit('item-dblclick', { e, item })"
            />
          </slot>
        </div>
      </template>
      <template #list-end>
        <slot name="list-end"></slot>
      </template>

      <template #no-items>
        <slot name="no-items"> GridSelector #no-items </slot>
      </template>
    </XDataSelector>
  </div>
</template>

<script>
import { XDataSelector } from '@rovit/x-data-selector'
import ProjectTile from '../ProjectTile/ProjectTile'
import { ref, watch, computed } from '@vue/composition-api'
import get from 'lodash/get'

export default {
  name: 'GridSelector',
  components: {
    XDataSelector,
    ProjectTile
  },
  props: {
    value: {
      type: [Array, Object],
      default: () => null
    },
    items: {
      type: Array,
      required: true
    },
    label: {
      type: Function,
      default: function defaultGetLabel() {
        return item => (item && item.name) || ''
      }
    },
    src: {
      type: Function,
      required: true,
      default: function defaultGetSrc() {
        return item => (item && item.url) || ''
      }
    },
    fit: {
      type: Function,
      required: true,
      default: function defaultGetFit() {
        return item => (item && item.fit) || 'cover'
      }
    },
    wrap: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const selected = ref(null)

    const selectedProxy = computed({
      get: () => props.value,
      set: val => context.emit('input', val)
    })

    function handleItemClick({ e, item, select }) {
      select()
      setTimeout(() => context.emit('item-click', { e, item }), 0)
    }

    return { selectedProxy, handleItemClick }
  }
}
</script>

<style lang="postcss">
.project-tile-wrapper {
  @apply w-full pr-2 mb-2;
}
@screen sm {
  .project-tile-wrapper {
    @apply min-w-1/2 max-w-1/2;
  }
}
@screen md {
  .project-tile-wrapper {
    @apply min-w-1/2 max-w-1/2;
  }
}
@screen lg {
  .project-tile-wrapper {
    @apply min-w-1/3 max-w-1/3;
  }
}
@screen xl {
  .project-tile-wrapper {
    @apply min-w-1/4 max-w-1/4;
  }
}
@media (min-width: 1600px) {
  .project-tile-wrapper {
    @apply min-w-1/5 max-w-1/5;
  }
}
@media (min-width: 1800px) {
  .project-tile-wrapper {
    @apply min-w-1/6 max-w-1/6;
  }
}
</style>
