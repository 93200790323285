import { computed } from '@vue/composition-api'

export function useCurrentUser(context) {
  const userId = computed(() => context.root.$store.state.auth.user._id || null)
  const user = computed(() => {
    if (userId.value) {
      return context.root.$store.state.users.keyedById[userId.value]
    }
    return null
  })

  return { userId, user }
}
