<template>
  <div class="edit-category-modal dark:text-gray-200">
    <div class="flex items-center">
      <h1 class="sidebar-heading-1 flex-grow">Edit Category</h1>
      <button
        v-if="showCloseButton"
        type="button"
        class="bg-gray-600 text-white text-lg rounded w-20 py-1"
        @click="$emit('close', false)"
      >
        Close
      </button>
    </div>

    <div v-if="category">
      <label class="block">
        <span>Name</span>
        <input
          v-model="_category.name"
          type="text"
          class="form-input block w-full"
          placeholder="Name of Category"
          @blur="e => save_category('name', { autoToast: true })"
        />
      </label>

      <!-- Icon -->
      <div class="block mt-3 w-3/4">
        <span class="block mb-1">Icon</span>

        <!-- Image -->
        <ImageChangeRemove
          v-if="_category.iconUrl"
          :src="_category.iconUrl"
          :fit.sync="_category.iconFit"
          @update:fit="() => save_category('iconFit', { autoToast: true })"
          @dragenter="openIconSelector"
          @change-image="openIconSelector"
          @remove-image="clearIcon"
        />

        <!-- No Image -->
        <NoImage v-else @click="openIconSelector" @dragenter="openIconSelector" />
      </div>

      <div class="mt-3 flex flex-row">
        <label class="p-2 border border-gray-200 dark:border-gray-700 rounded">
          <p>Background Color</p>
          <ColorPicker
            v-model="_category.bgColor"
            prefix="bg"
            class="pb-2 inline-block"
            @input="() => save_category(['bgColor'], { autoToast: true })"
          />
        </label>
      </div>

      <!-- Parent Category Select -->
      <div v-if="filteredCategories.length" class="mt-3">
        <span class="block mb-1">Parent Category</span>
        <XSelect
          v-model="selectedParentCategory"
          :items="filteredCategories"
          :label="i => i.name"
          placeholder="No Parent Category"
          clearable
        />
      </div>

      <FeatureSelect v-model="_category.featureType" @input="() => save_category('featureType')" />

      <ItemFeatureAssetEditor
        v-if="category.featureType === 'asset'"
        class="mt-2"
        :item="category"
      />

      <button
        v-if="category.featureType === 'asset' && category.assetUrl"
        type="button"
        class="mt-3 bg-gray-400 text-black dark:bg-gray-700 dark:text-gray-300 form-button w-full flex flex-row items-center justify-center"
        @click="handleEditHotspots"
      >
        Edit Hotspots
      </button>
    </div>

    <div v-else>Loading Category</div>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { models } from 'feathers-vuex'
import { handleClones } from '@rovit/utils/handle-clones'
import { useModal } from '@rovit/use-modal'

import { ImageChangeRemove, NoImage } from '@rovit/image-display-sidebar'
import { XSelect } from '@rovit/x-select'
import { ColorPicker } from '@rovit/colors'
import { FeatureSelect } from '@rovit/feature-select'
import { ItemFeatureAssetEditor } from '@rovit/item-feature-asset-editor'

export default {
  name: 'CategoryEditor',
  components: {
    NoImage,
    ImageChangeRemove,
    XSelect,
    ColorPicker,
    FeatureSelect,
    ItemFeatureAssetEditor
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    envId: {
      type: String,
      required: true
    },
    orgId: {
      type: String,
      required: true
    },
    iconModel: {
      type: Function,
      required: true
    },
    showCloseButton: {
      type: Boolean,
      required: false,
      default: false
    },
    categories: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  // eslint-disable-next-line
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _category } = clones
    const { save_category } = saveHandlers

    const iconModal = useModal('iconSelector')
    function openIconSelector() {
      iconModal.open({ _item: _category.value, handler: performCategoryUpdate })
    }

    function clearIcon() {
      Object.assign(_category.value, {
        iconId: null,
        iconUrl: '',
        iconMimeType: ''
      })
      save_category(['iconId', 'iconUrl'], { autoToast: true })
    }

    // Asset Selector
    const assetModal = useModal('assetSelector')
    function openAssetSelector() {
      assetModal.open({ applyAsset })
    }
    function applyAsset(asset) {
      return save_category({
        assetId: asset._id,
        assetUrl: asset.url
      })
    }
    function removeAssetFromItem() {
      save_category({ assetId: null, assetUrl: '' })
    }

    const selectedParentCategory = computed({
      get: () => {
        const parentId = _category.value.parentCategoryId
        return parentId ? props.categories.find(cat => cat._id === parentId) : null
      },
      set: val => {
        if (val) {
          save_category(
            { parentCategoryId: val._id, parentCategoryName: val.name },
            { autoToast: true }
          )
        } else {
          save_category({ parentCategoryId: null, parentCategoryName: '' }, { autoToast: true })
        }
      }
    })
    const filteredCategories = computed(() => {
      return props.categories.filter(cat => cat._id !== props.category._id)
    })

    // Feature Hotspots Modal
    const featureHotspotsModal = useModal('featureHotspotsModal')
    function handleEditHotspots() {
      featureHotspotsModal.open({ item: props.category })
    }

    function performCategoryUpdate(item) {
      Object.assign(_category.value, {
        iconId: item._id,
        iconUrl: item.url,
        iconFit: 'contain',
        iconMimeType: item.mimeType
      })
      save_category(['iconId', 'iconUrl', 'iconFit', 'iconMimeType'], { autoToast: true })
    }

    return {
      ...saveHandlers,
      ...clones,

      selectedParentCategory,
      filteredCategories,

      // Uploader
      openIconSelector,
      clearIcon,
      handleEditHotspots
    }
  }
}
</script>

<style lang="postcss">
.edit-category-modal {
  @apply bg-gray-300 border border-gray-400 p-3 rounded shadow-lg mb-2;
}
@screen dark {
  .edit-category-modal {
    @apply bg-gray-800 border-gray-600;
  }
}
</style>
