<template>
  <form @submit.prevent="e => {}">
    <h1 class="sidebar-heading-1 mt-2">Edit Pano</h1>

    <SidebarPanoTabs />

    <keep-alive>
      <PanoEditorInfo
        v-if="$route.query.panoSection === 'info'"
        :pano="pano"
        :panos-with-scenes="panosWithScenes"
        :panos-without-scenes="panosWithoutScenes"
        :env="env"
        :scenes="scenes"
      />
    </keep-alive>

    <PanoEditorDanger v-if="$route.query.panoSection === 'danger'" :remove="remove" />
  </form>
</template>

<script>
import SidebarPanoTabs from './SidebarPanoTabs.vue'
import PanoEditorInfo from './PanoEditorInfo.vue'
import PanoEditorDanger from './PanoEditorDanger.vue'
import { watch } from '@vue/composition-api'

export default {
  name: 'PanoSidebar',
  components: {
    SidebarPanoTabs,
    PanoEditorInfo,
    PanoEditorDanger
  },
  props: {
    pano: {
      validator: () => true,
      default: null
    },
    panosWithScenes: {
      type: Array,
      required: true
    },
    panosWithoutScenes: {
      type: Array,
      required: true
    },
    env: {
      validator: val => typeof val === 'object',
      default: null
    },
    scenes: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup(props, context) {
    watch(
      () => context.root.$route.query.panoSection,
      panoSection => {
        if (!panoSection) {
          const newRoute = Object.assign({}, context.root.$route)
          newRoute.query = Object.assign({}, newRoute.query, { panoSection: 'info' })
          context.root.$router.push(newRoute)
        }
      },
      { immediate: true }
    )

    function remove() {
      props.pano.remove()
      const query = {}
      context.root.$router.push({ query })
    }
    return { remove }
  }
}
</script>

<style lang="postcss"></style>
