<template>
  <div class="sidebar-support">
    <ServiceSidebar v-if="service" :service="service"></ServiceSidebar>

    <div v-else>
      Loading Service
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { getService } from '@/use/services.js'

import ServiceSidebar from '../ServiceSidebar/ServiceSidebar.vue'

export default {
  name: 'OrgServicesSidebarEditorService',
  components: {
    ServiceSidebar
  },
  setup(props, context) {
    const serviceId = computed(() => context.root.$route.query.serviceId)
    const { service } = getService(serviceId)

    return { service }
  }
}
</script>

<style lang="postcss"></style>
