<template>
  <div class="edit-badge-modal dark:text-gray-200">
    <div class="flex items-center">
      <h1 class="sidebar-heading-1 flex-grow">Edit Badge</h1>
      <button
        v-if="showCloseButton"
        type="button"
        class="bg-gray-600 text-white text-lg rounded w-20 py-1"
        @click="$emit('close', false)"
      >
        Close
      </button>
    </div>

    <div v-if="badge">
      <label class="block">
        <span>Name</span>
        <input
          v-model="_badge.name"
          type="text"
          class="form-input block w-full"
          placeholder="Name of Badge"
          @blur="e => save_badge('name', e)"
        />
      </label>

      <div v-if="allowGlobal" class="flex mt-3 px-0.5">
        <label class="flex items-center">
          <input
            v-model="_badge.isGlobal"
            type="checkbox"
            class="form-checkbox"
            @change="save_badge('isGlobal')"
          />
          <span class="ml-2">Show in Global Rovit</span>
        </label>
      </div>

      <!-- Sidebar Modal with Icon Selector -->
      <SidebarModal v-model="isUploaderOpen">
        <template>
          <SidebarUploadSelector
            :model="iconModel"
            button-text="Upload New Icon"
            :new-files="newFiles"
            :current-tab="currentUploaderTab"
            :endpoint="$store.state.uploadUrlEndpoint"
            qid="iconSelector"
            resource-name="Icon"
            @select="handleFileSelect"
            @tab-change="handleTabChange"
            @uploaded="handleUploaded"
            @close="closeSelector"
          />
        </template>
      </SidebarModal>

      <!-- Icon -->
      <div class="block mt-3 w-3/4">
        <span class="block mb-1">Icon</span>

        <!-- Image -->
        <ImageChangeRemove
          v-if="_badge.iconUrl"
          :src="_badge.iconUrl"
          :fit.sync="_badge.iconFit"
          @update:fit="() => save_badge('iconFit')"
          @dragenter="openIconSelector"
          @change-image="openIconSelector"
          @remove-image="clearIcon"
        />

        <!-- No Image -->
        <NoImage v-else @click="openIconSelector" @dragenter="openIconSelector" />
      </div>

      <label class="mt-3 block">
        <p>Container Shape</p>

        <label class="inline-flex items-center">
          <input
            v-model="_badge.containerShape"
            type="radio"
            class="form-radio"
            value="circle"
            @change="() => save_badge(['containerShape'])"
          />
          <span class="ml-2">Circle</span>
        </label>
        <label class="inline-flex items-center ml-6">
          <input
            v-model="_badge.containerShape"
            type="radio"
            class="form-radio"
            value="square"
            @change="() => save_badge(['containerShape'])"
          />
          <span class="ml-2">Square</span>
        </label>
      </label>

      <div class="mt-3 flex flex-row">
        <label
          v-if="_badge.iconMimeType && _badge.iconMimeType.includes('svg')"
          class="mr-2 border border-gray-200 dark:border-gray-700 rounded p-2"
        >
          <p>Icon Color</p>
          <ColorPicker
            v-model="_badge.iconColor"
            prefix="text"
            class="pb-2 inline-block"
            @input="() => save_badge(['iconColor'])"
          />
          <div>
            <label class="block">
              <input
                v-model="_badge.iconAdaptiveFill"
                type="checkbox"
                class="form-checkbox"
                @change="() => save_badge(['iconAdaptiveFill'])"
              />
              Fill Color
            </label>

            <label class="block">
              <input
                v-model="_badge.iconAdaptiveStroke"
                type="checkbox"
                class="form-checkbox"
                @change="() => save_badge(['iconAdaptiveStroke'])"
              />
              Outline Color
            </label>
          </div>
        </label>

        <label class="p-2 border border-gray-200 dark:border-gray-700 rounded">
          <p>Background Color</p>
          <ColorPicker
            v-model="_badge.bgColor"
            prefix="bg"
            class="pb-2 inline-block"
            @input="() => save_badge(['bgColor'])"
          />
        </label>
      </div>

      <!-- Badge Groups Select -->
      <div class="mt-3">
        <span class="block mb-1">Badge Groups</span>
        <XSelect
          v-model="selectedBadgeGroups"
          :items="badgeGroups"
          :label="i => i.name"
          :create="createBadgeGroup"
          placeholder="Select or Create Badge Groups"
          multiple
          clearable
        />
      </div>

      <div class="mt-12">
        Danger Zone
        <button
          type="button"
          class="block bg-red-700 p-2 rounded text-white"
          @click="badge.remove()"
        >
          Delete Badge
        </button>
      </div>
    </div>

    <div v-else>Loading Badge</div>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { models } from 'feathers-vuex'
import { handleClones } from '@rovit/utils/handle-clones'
import _keyBy from 'lodash/keyBy.js'

import { SidebarModal } from '@rovit/sidebar-modal'
import { SidebarUploadSelector } from '@rovit/sidebar-upload-selector'
import { ImageChangeRemove, NoImage } from '@rovit/image-display-sidebar'
import { XSelect } from '@rovit/x-select'
import { ColorPicker } from '@rovit/colors'

export default {
  name: 'BadgeEditor',
  components: {
    SidebarModal,
    SidebarUploadSelector,
    SidebarModal,
    NoImage,
    ImageChangeRemove,
    XSelect,
    ColorPicker
  },
  props: {
    badge: {
      type: Object,
      required: true
    },
    envId: {
      type: String,
      required: true
    },
    orgId: {
      type: String,
      required: true
    },
    iconModel: {
      type: Function,
      required: true
    },
    showCloseButton: {
      type: Boolean,
      required: false,
      default: false
    },
    badges: {
      type: Array,
      required: true,
      default: () => []
    },
    badgeGroups: {
      type: Array,
      required: true,
      default: () => []
    },
    allowGlobal: {
      type: Boolean,
      default: false
    }
  },
  // eslint-disable-next-line
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const _badge = clones._badge
    const { save_badge } = saveHandlers

    /**
     * Uploader Modal and Handlers
     */
    const currentUploaderTab = ref('library')
    const isUploaderOpen = ref(false)
    const newFiles = ref([])
    function openIconSelector() {
      isUploaderOpen.value = true
    }
    function closeSelector() {
      isUploaderOpen.value = false
    }
    function handleTabChange(tabName) {
      currentUploaderTab.value = tabName
    }
    async function handleUploaded(data) {
      Object.assign(data, {
        envId: props.envId,
        orgId: props.orgId
      })
      const Icon = props.iconModel
      const icon = await new Icon(data).save()
      this.newFiles.push(icon)
    }
    function handleFileSelect(item) {
      save_badge({
        iconId: item._id,
        iconUrl: item.url,
        iconScale: 1,
        iconMimeType: item.mimeType,
        iconFit: 'contain'
      })
      closeSelector()
    }
    function clearIcon() {
      save_badge({
        iconId: null,
        iconUrl: null,
        iconScale: 1,
        iconMimeType: ''
      })
    }
    function alterUploadData(upload) {
      upload.envId = props.envId
    }

    const selectedBadgeGroups = computed({
      get: () => {
        const badgeGroupsById = _keyBy(props.badgeGroups, '_id')
        const selectedBadgeGroups = props.badge.badgeGroupsMeta
          .map(bg => badgeGroupsById[bg.badgeGroupId])
          .filter(i => i)
        return selectedBadgeGroups
      },
      set: val => {
        if (val && val.length) {
          const badgeGroupsMeta = val.map(bg => ({
            badgeGroupId: bg._id,
            badgeGroupName: bg.name
          }))
          save_badge({ badgeGroupsMeta })
        } else {
          save_badge({ badgeGroupsMeta: [] })
        }
      }
    })
    const filteredBadges = computed(() => {
      return props.badges.filter(cat => cat._id !== props.badge._id)
    })

    async function createBadgeGroup(name) {
      const { envId } = props
      const badge = await new models.api.BadgeGroup({ name, envId }).save()
      return badge
    }

    return {
      ...saveHandlers,
      ...clones,

      selectedBadgeGroups,
      createBadgeGroup,

      // Uploader
      isUploaderOpen,
      currentUploaderTab,
      openIconSelector,
      closeSelector,
      handleFileSelect,
      newFiles,
      clearIcon,
      alterUploadData,
      handleUploaded,
      handleTabChange
    }
  }
}
</script>

<style lang="postcss">
.edit-badge-modal {
  @apply bg-gray-300 border border-gray-400 p-3 rounded shadow-lg mb-2;
}
@screen dark {
  .edit-badge-modal {
    @apply bg-gray-800 border-gray-600;
  }
}
</style>
