import { computed } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import { unwrapRef } from './unwrap-ref'

export function findOrgUsersForUserId({ userId }) {
  const { OrgUser } = models.api
  const params = computed(() => {
    const _userId = unwrapRef(userId)
    if (_userId) {
      return {
        query: {
          userId: _userId
        }
      }
    } else {
      return null
    }
  })
  const queryWhen = computed(() => {
    const _userId = unwrapRef(userId)
    return OrgUser.findInStore({ query: { userId: _userId } }).data.length < 1
  })
  const { items: orgUsers } = useFind({
    model: models.api.OrgUser,
    params,
    queryWhen
  })

  return { orgUsers }
}

/**
 * If a user is provided, and that user is a Rovit Admin, run the query.
 */
export function findOrgUsers() {
  const { OrgUser } = models.api
  const params = computed(() => {
    return { query: {} }
  })
  const queryWhen = computed(() => {
    return true
  })
  const { items: orgUsers } = useFind({
    model: models.api.OrgUser,
    params,
    queryWhen
  })

  return { orgUsers }
}

/**
 * If a user is provided, and that user is a Rovit Admin, run the query.
 */
export function findOrgUsersForOrg({ org }) {
  const { OrgUser } = models.api
  const params = computed(() => {
    const _org = unwrapRef(org)
    return _org ? { query: { orgId: _org._id, $sort: { userName: 1 } } } : null
  })
  const { items: orgUsers } = useFind({ model: OrgUser, params })

  return { orgUsers }
}

export async function createOrgUser({ org, user, accessType }) {
  const { OrgUser } = models.api
  org = unwrapRef(org)
  user = unwrapRef(user)

  const orgUser = new OrgUser({
    orgId: org._id,
    orgName: org.name,
    userId: user._id,
    userName: user.name,
    isDefaultOrg: false,
    accessType
  }).save({ $populateParams: { name: 'org' } })

  return { orgUser }
}
