<template>
  <div class="text-right text-sm flex flex-row">
    <div class="flex-grow"></div>
    <div v-if="finishedLoading || alreadyLoaded" class="bg-gray-400 rounded px-2 text-black">
      All Scenes Loaded
    </div>
    <div v-else class="bg-green-400 rounded px-2 text-black">
      Loading Scenes
      {{ loadedCount }} /
      {{ totalCount }}
    </div>
  </div>
</template>

<script>
import { findScenesForEnv } from '@/use/scenes.js'
import { computed, ref, watch } from '@vue/composition-api'
import _get from 'lodash/get.js'

const finishedLoadingByEnvId = {}

export default {
  name: 'ScenesLoader',
  props: {
    env: {
      validator: val => typeof val === 'object',
      default: null
    }
  },
  setup(props, context) {
    const pagination = ref({
      $limit: 100,
      $skip: 0
    })
    const alreadyLoaded = computed(() => {
      return !!finishedLoadingByEnvId[props.env._id]
    })
    const queryWhen = computed(() => {
      return !alreadyLoaded.value
    })
    const { scenes, haveLoaded, latestQuery, isPending } = findScenesForEnv({
      env: props.env,
      pagination,
      queryWhen,
      populate: ''
    })
    const loadedCount = ref(0)
    const totalCount = ref(0)
    const finishedLoading = ref(false)
    // If there are more available records than what we requested, get the next set.
    // After each request has finished (!isPending), check if we need to download more records
    watch(
      () => [haveLoaded.value, isPending.value],
      ([_haveLoaded, _isPending]) => {
        if (_haveLoaded && !_isPending) {
          const total = _get(latestQuery.value, 'response.total')
          totalCount.value = total
          loadedCount.value += latestQuery.value.response.data.length
          if (total > pagination.value.$limit + pagination.value.$skip) {
            pagination.value.$skip += pagination.value.$limit
          } else {
            finishedLoading.value = true
            finishedLoadingByEnvId[props.env._id] = new Date()
          }
        }
      }
    )
    return { scenes, loadedCount, totalCount, finishedLoading, alreadyLoaded }
  }
}
</script>

<style lang="postcss"></style>
