<template>
  <Layout>
    <template #content>
      <NavbarEnvironmentLibrary :env="currentEnv" />

      <div class="px-12">
        <h1 class="content-heading-1">Linked Environments</h1>

        <p class="content-subheading">
          Manage linked environments for the
          <strong>{{ currentEnv && currentEnv.name }}</strong> environment.
        </p>
      </div>
    </template>

    <template #sidebar>
      Sidebar
    </template>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout'
import { ref } from '@vue/composition-api'
import { getEnv } from '@/use/environments'

import NavbarEnvironmentLibrary from '../components/NavbarEnvironmentLibrary/NavbarEnvironmentLibrary'

export default {
  name: 'EnvLibLinkedEnvs',
  metaInfo: {
    title: 'Env Library'
  },
  components: {
    Layout,
    NavbarEnvironmentLibrary
  },
  setup(props, context) {
    const { env: currentEnv } = getEnv(context.root.$route.params.envId)

    return { currentEnv }
  }
}
</script>

<style lang="postcss" scoped></style>
