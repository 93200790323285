<template>
  <form @submit.prevent="e => $emit('save')">
    <h1 class="sidebar-heading-1 mt-2">
      {{ $route.query.serviceId === 'new' ? 'New' : 'Edit' }} Infobox
    </h1>

    <!-- Name -->
    <label class="block">
      <span class="text-gray-700">Infobox Name</span>
      <input
        v-model="_infobox.infoboxName"
        class="form-input block w-full"
        placeholder="Name of Service, Business, or Event"
        @blur="() => save_infobox('infoboxName')"
      />
    </label>

    <!-- Scenes -->
    <!-- <SidebarInfoboxScenes :scenes="scenes" class="mt-2" /> -->

    <!-- Environments -->
    <!-- <ServiceEnvServices v-if="!envServiceId" :service="service" class="mt-2" /> -->

    <!-- <AddInfoboxToEnv :service="service" :infobox="infobox" /> -->

    <!-- Danger Zone -->
    <div class="danger-zone -m-1 mt-12 mb-4">
      <h2 class="sidebar-heading-1 flex flex-row items-center">
        The Danger Zone
        <AlertOctagonIcon class="ml-2 text-red-600" />
      </h2>

      <p class="content-subheading mb-2">These actions cannot be undone.</p>

      <!-- "Remove from Environment" Button -->
      <button
        v-if="envServiceId"
        type="button"
        class="form-button danger"
        @click="$emit('remove-from-environment')"
      >
        Remove from Environment
      </button>

      <!-- "Delete Environment" Button -->
      <div v-if="isConfirmDeleteVisible">
        <div class="flex flex-row-items-center mt-3">
          <button type="button" class="form-button danger rounded-r-none" @click="infobox.remove()">
            Confirm & Delete Infobox
          </button>

          <button
            type="button"
            class="form-button bg-gray-700 text-white rounded-l-none"
            @click="isConfirmDeleteVisible = false"
          >
            Cancel
          </button>
        </div>
      </div>

      <button
        v-else
        type="button"
        class="form-button danger mt-3"
        @click="isConfirmDeleteVisible = true"
      >
        Delete Infobox
      </button>
    </div>
  </form>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import { handleClones } from '@rovit/utils'
import { findScenesForInfobox, sceneSelection } from '@/use/scenes.js'
import { findEnvs } from '@/use/environments.js'

import { AlertOctagonIcon } from 'vue-feather-icons'
import ServiceEnvServices from '../ServiceEnvServices/ServiceEnvServices'
import SidebarInfoboxScenes from '../Infobox/SidebarInfoboxScenes'
import AddInfoboxToEnv from '../AddInfoboxToEnv/AddInfoboxToEnv.vue'

export default {
  name: 'InfoboxForm',
  components: {
    AlertOctagonIcon
    // ServiceEnvServices,
    // SidebarInfoboxScenes,
    // AddInfoboxToEnv
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    infobox: {
      type: Object,
      required: true
    },
    /**
     * If an envServiceId is passed in, several changes are made to the UI:
     *   - The "remove from environment" button shows.
     *   - The "Add to environment" UI does not show, since it's already in an environment.
     */
    envServiceId: {
      type: String,
      default: ''
    },
    envServices: {
      type: Array,
      default: () => []
    },
    scenes: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _infobox } = clones
    const isConfirmDeleteVisible = ref(false)

    return {
      ...clones,
      ...saveHandlers,
      isConfirmDeleteVisible
    }
  }
}
</script>

<style lang="postcss"></style>
