<template>
  <div class="sidebar-support">
    <h1 class="sidebar-heading-1 mt-2">Select a Template</h1>

    <p class="sidebar-body">
      This is a test
    </p>

    <p class="sidebar-subheading font-bold mt-2">This is a subheading</p>
  </div>
</template>

<script>
export default {
  name: 'InfoboxSidebarTemplates',
  components: {},
  props: {}
}
</script>

<style lang="postcss"></style>
