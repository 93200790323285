import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import { batchClient } from 'feathers-batch'
import io from 'socket.io-client'
import { iff, discard } from 'feathers-hooks-common'
import feathersVuex from 'feathers-vuex'
import paramsForServer from './utils/params-for-server'
import { storage } from './auth/auth0'

const socket = io(process.env.VUE_APP_ROVIT_API_URL, {
  transports: ['websocket']
})

let hasConnectedOnce = false
const feathersClient = feathers()
  .configure(
    socketio(socket, {
      timeout: 30000
    })
  )
  .configure(auth({ jwtStrategy: 'auth0', storage }))
  .hooks({
    before: {
      all: [
        paramsForServer('$populateParams'),
        iff(
          context => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp')
        )
      ]
    }
  })
  .configure(
    batchClient({
      batchService: 'batch',
      exclude: ['authentication']
    })
  )

socket.on('connect', async () => {
  if (hasConnectedOnce) {
    hasConnectedOnce = false
    if (feathersClient.authentication.authenticated) {
      try {
        await feathersClient.reAuthenticate()
      } catch (error) {
        console.error({ error })
      }
    }
  }
})

socket.on('disconnect', () => {
  hasConnectedOnce = true
})

export default feathersClient

// Setting up feathers-vuex
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } = feathersVuex(
  feathersClient,
  {
    serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
    idField: '_id', // Must match the id field in your database table/collection
    whitelist: ['$regex', '$options']
  }
)

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex }
