/* global window, document */
import createAuth0Client from '@auth0/auth0-spa-js'
import EventEmitter from 'events'

let instance

const defaults = {
  /** Define a default action to perform after authentication */
  onRedirectCallback() {
    window.history.replaceState({}, document.title, window.location.pathname)
  },
  redirect_uri: window.location.origin,
  domain: '',
  client_id: '',
  audience: ''
}

/** Returns the current instance of the SDK */
export const getInstance = () => instance

export class AuthManager {
  auth0Client = null
  isAuthenticated = false
  isLoading = true
  readyPromise = null
  isPopupOpen = false
  user = null
  error = null

  constructor(options) {
    options = Object.assign({}, defaults, options)

    // Mix the EventEmitter into the instnace
    for (const n in EventEmitter.prototype) {
      this[n] = EventEmitter.prototype[n]
    }

    // eslint-disable-next-line no-unused-vars
    this.readyPromise = new Promise(async (resolve, reject) => {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.client_id,
        audience: options.audience,
        redirect_uri: options.redirect_uri
      })

      var targetUrl = null
      try {
        // If the user is returning to the app after authentication..
        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback()

          targetUrl = appState.targetUrl
          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = await this.auth0Client.getUser()
        this.isLoading = false

        // Emit loaded & authenticated events to communicate with the rest of the app.
        this.emit('loaded')
        if (this.isAuthenticated) {
          this.cleanup(targetUrl)
          this.emit('authenticated')
        }
        resolve(this)
      }
    })
  }

  async cleanup(targetUrl) {
    const newUrl = window.location.href.replace(/\?.+#/, '#')
    if (!targetUrl) {
      window.history.pushState({}, '', newUrl)
    } else {
      window.location.replace(targetUrl)
    }
  }

  /** Authenticates the user using a popup window */
  async loginWithPopup(o) {
    this.popupOpen = true

    try {
      await this.auth0Client.loginWithPopup(o)
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)
    } finally {
      this.popupOpen = false
    }

    this.user = await this.auth0Client.getUser()
    this.isAuthenticated = true
  }

  /**
   * Not currently in use.
   * Handles the callback when logging in using a redirect
   */
  async handleRedirectCallback() {
    this.loading = true

    try {
      await this.auth0Client.handleRedirectCallback()
      this.user = await this.auth0Client.getUser()
      this.isAuthenticated = true
    } catch (e) {
      this.error = e
    } finally {
      this.loading = false
    }
  }

  /** Authenticates the user using the redirect method */
  loginWithRedirect(o) {
    return this.auth0Client.loginWithRedirect(o)
  }
  /** Returns all the claims present in the ID token */
  getIdTokenClaims(o) {
    return this.auth0Client.getIdTokenClaims(o)
  }
  /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
  getTokenSilently(o) {
    return this.auth0Client.getTokenSilently(o)
  }
  /** Gets the access token using a popup window */

  getTokenWithPopup(o) {
    return this.auth0Client.getTokenWithPopup(o)
  }
  /** Logs the user out and removes their session on the authorization server */
  logout(o) {
    return this.auth0Client.logout(o)
  }
}

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export function useAuth0(options) {
  if (instance) return instance

  instance = new AuthManager(options)

  return instance
}

/**
 * Storage provider for the FeathersJS Authentication Client.
 * The getItem and setItem are not needed, but have to be stubbed out to prevent errors.
 */
export const storage = {
  async getItem() {
    let token

    try {
      token = await instance.getTokenSilently()
    } catch (error) {
      token = ''
    }
    return token
  },
  setItem() {},
  removeItem() {}
}

/**
 * A Vue plugin to expose the wrapper object throughout the application.
 * Since this is a general-use Auth0 plugin, Vue is not bundled with this
 * package. The Vue instance referenced below is passed in upon calling
 * Vue.use(Auth0)
 */
export const Auth0Plugin = {
  install(Vue, options) {
    const auth0Instance = useAuth0(options)
    const pluginState = new Vue({
      data: () => ({
        isLoading: true,
        user: null
      })
    })
    // Create proxy methods from the vue pluginState to the auth auth0Instance.
    const methods = [
      'loginWithPopup',
      'handleRedirectCallback',
      'loginWithRedirect',
      'getIdTokenClaims',
      'getTokenSilently',
      'getTokenWithPopup',
      'logout'
    ]
    methods.forEach(method => {
      pluginState[method] = d => auth0Instance[method](d)
    })

    Vue.prototype.$auth0 = pluginState

    auth0Instance.on('loaded', () => {
      pluginState.isLoading = false
    })
    auth0Instance.on('authenticated', () => {
      pluginState.user = auth0Instance.user
    })
  }
}
