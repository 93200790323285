<template>
  <Layout>
    <template #content>
      <NavbarEnvironment :env="currentEnv" />

      <div class="px-12">
        <h1 class="content-heading-1">Environment Billing</h1>

        <p class="content-subheading">
          Here are billing details for this environment. It's possible to have somebody outside of
          your org to pay for this environment.
        </p>
      </div>
    </template>

    <template #sidebar>
      Environment Billing Sidebar
    </template>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout'
import { ref } from '@vue/composition-api'
import { getEnv } from '@/use/environments'

import NavbarEnvironment from '../components/NavbarEnvironment/NavbarEnvironment'

export default {
  name: 'EnvironmentBilling',
  metaInfo: {
    title: 'Environment Billing'
  },
  components: {
    Layout,
    NavbarEnvironment
  },
  setup(props, context) {
    const { env: currentEnv } = getEnv(context.root.$route.params.envId)

    return { currentEnv }
  }
}
</script>

<style lang="postcss" scoped></style>
