import { models, useFind, useGet } from 'feathers-vuex'
import { reactive, computed, watch } from '@vue/composition-api'
import { unwrapRef } from './unwrap-ref'
import _get from 'lodash/get.js'

export async function createBadge({ env }) {
  const { Badge } = models.api

  const _env = unwrapRef(env)

  const badge = await new Badge({
    name: 'New Badge',
    envId: _env._id,
    sortOrder: 0
  }).save()

  return { badge }
}

export function getBadge(id) {
  const { Badge } = models.api
  const params = computed(() => {
    return {
      $populateParams: {
        name: ''
      }
    }
  })
  const queryWhen = computed(() => {
    const existing = Badge.getFromStore(id.value)
    return id.value && !existing
  })
  const { item: badge } = useGet({
    model: Badge,
    id,
    params,
    queryWhen
  })

  return { badge }
}

// Tracks which envs have had their badges loaded.
const haveQueriedByEnvId = reactive({})

export function findBadges({ env, badgeId }) {
  const { Badge } = models.api
  const params = computed(() => {
    if (env.value && env.value._id) {
      const query = { envId: env.value && env.value._id, $limit: 5000 }
      return {
        query,
        $populateParams: {
          name: 'adminBadges'
        }
      }
    } else {
      return null
    }
  })
  const queryWhen = computed(() => {
    const envId = _get(env.value, '_id')
    if (!envId || haveQueriedByEnvId[envId]) {
      return false
    }
    return true
  })
  const { items: badges, haveBeenRequested } = useFind({ model: Badge, params, queryWhen })

  // Once the items for an environment have been requested, add them to the query tracker to prevent repeats.
  const stop = watch(
    () => haveBeenRequested.value,
    val => {
      if (haveBeenRequested.value) {
        haveQueriedByEnvId[env.value._id] = true
        stop() // manually stop the watcher
      }
    }
  )

  return { badges }
}
