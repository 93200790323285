<template>
  <div class="bg-white rounded text-black p-4">
    <div>
      <span class="text-xl font-bold ml-2">
        Delete Infobox or Scene
      </span>
    </div>
    <div class="mt-3">
      <div>
        <label class="font-bold">
          <input id="custom" v-model="deleteType" value="infobox" type="radio" name="delete-type" />
          <span class="ml-2 text-black-i">Delete Infobox</span>
        </label>
        <div class="ml-4">
          All scenes will be permanently deleted and the infobox will be removed from all
          environments
        </div>
      </div>
      <div class="mt-3">
        <label class="font-bold">
          <input id="service" v-model="deleteType" value="scene" type="radio" name="delete-type" />
          <span class="ml-2 text-black-i">Delete Scene</span>
        </label>
        <div class="ml-4 mt-1">
          <div>
            <span>
              This scene will no longer be the primary scene for this infobox. Select a new primary
              scene to show in its place.
            </span>
          </div>
          <div class="mt-2">
            <div v-for="childScene in scene.childScenes" :key="childScene._id">
              <label class="ml-1">
                <input
                  id="service"
                  v-model="primarySceneToSet"
                  :value="childScene._id"
                  type="radio"
                  name="scene-delete"
                />
                <span class="ml-3 text-black-i">{{ childScene.name }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 flex">
      <button
        class="form-button"
        :class="deleteType.length ? 'danger' : 'disabled-button'"
        :disabled="!deleteType.length"
        @click="handleDelete"
      >
        {{ deleteType.length ? 'Delete' : 'Make a selection' }}
      </button>
      <button class="form-button bg-gray-700 text-white rounded ml-2" @click="$emit('close')">
        Cancel
      </button>
    </div>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
export default {
  name: 'PromptDeleteInfobox',
  components: {},
  props: {
    scene: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const deleteType = ref('')
    const primarySceneToSet = ref(null)
    async function handleDelete() {
      if (deleteType.value === 'scene') {
        if (primarySceneToSet.value) {
          await props.scene.remove({ query: { newPrimarySceneId: primarySceneToSet.value } })
        }
      }
      //todo
      //implement delete infobox/scene
    }
    watch(
      () => deleteType.value,
      () => {
        if (deleteType.value === 'infobox') {
          primarySceneToSet.value = null
        }
      }
    )
    return {
      deleteType,
      primarySceneToSet,
      handleDelete
    }
  }
}
</script>
<style lang="postcss" scoped>
.text-black-i {
  color: black !important;
}
.disabled-button {
  @apply bg-gray-400 text-white rounded;
}
</style>
