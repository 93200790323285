<template>
  <Layout>
    <template #content>
      <NavbarAdmin />

      <div class="px-12">
        <h2 class="content-heading-1">{{ $store.getters['auth/currentOrg'].name }} Team</h2>

        <p class="content-subheading">
          These people have access to this
          <strong>{{ $store.getters['auth/currentOrg'].name }}</strong>
          organization. You can add and edit permissions, here.
        </p>

        <div class="h-32"></div>

        <h2 class="content-heading-1">Organization Access</h2>
        <p class="content-subheading">
          People in these orgs also have access to this
          <strong>{{ $store.getters['auth/currentOrg'].name }}</strong> org. You can add and edit
          permissions, here. (This will require creating a new table in the database.)
        </p>
      </div>
    </template>

    <template #sidebar>
      Sidebar
    </template>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout'
import { ref } from '@vue/composition-api'
import NavbarAdmin from '../components/NavbarAdmin/NavbarAdmin'

export default {
  name: 'OrgPeople',
  components: {
    Layout,
    NavbarAdmin
  },
  setup(props, context) {
    return {}
  }
}
</script>

<style lang="postcss" scoped></style>
