<template>
  <div class="mt-3">
    <label class="block">
      <span class="text-gray-700">Title</span>
      <input
        v-model="_item.title"
        class="form-input block w-full"
        placeholder="Enter item title"
        @blur="e => save_item('title')"
      />
    </label>

    <label class="block mt-3">
      <span class="text-gray-700">Subtitle</span>
      <input
        v-model="_item.subtitle"
        class="form-input block w-full"
        placeholder="Enter optional subtitle"
        @blur="e => save_item('subtitle')"
      />
    </label>

    <label class="block mt-3">
      <div ref="linkInfoRef">
        <span>Link</span>
        <LinkInfo
          v-model="_item.link"
          class="border border-gray-600"
          @click="() => popper.toggle()"
        />
      </div>
    </label>

    <div v-show="popper.isVisible" ref="sceneSelectorRef" class="w-full px-3 absolute z-40">
      <div class="bg-gray-400 border-gray-500 dark:bg-gray-700 border p-3 rounded text-center">
        <div class="flex justify-end">
          <button type="button" @click="popper.close">Close</button>
        </div>
        <LinkEditor
          v-if="popper.isVisible"
          v-model="_item.link"
          :org="currentOrg"
          @input="() => save_item('link')"
        />
      </div>
    </div>

    <div class="block mt-3 w-3/4">
      <span class="block mb-1">Photo</span>

      <!-- Image -->
      <ImageChangeRemove
        v-if="item.assetUrl"
        :src="thumbnail(_item.assetUrl).url"
        :fit.sync="_item.assetFit"
        @update:fit="save_item('assetFit')"
        @change-image="openAssetModal"
        @remove-image="removeAssetFromItem"
      />

      <!-- No Image -->
      <NoImage v-else button-text="Select Asset" @click="openAssetModal" />
    </div>

    <p>Location</p>
    <div class="h-64 mt-3">
      <ClusterMap
        :current="_item"
        :access-token="$store.state.mapboxToken"
        :items="[]"
        class="w-full"
        cluster
        @marker-moved="handleMarkerMoved"
        @map-click="handleMapClick"
      />
    </div>
    <Geocode
      :mapbox-token="$store.state.mapboxToken"
      direction="forward"
      placeholder="search"
      class="w-full mt-1"
      @input="setMarkerFromGeocode"
    />

    <DangerZoneDelete @remove="removeRecord" />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { handleClones } from '@rovit/utils/handle-clones.js'
import { useModal } from '@rovit/use-modal'
import { thumbnail } from '@/use/image-transforms/index'
import { usePopper } from '@rovit/popper'

import { ImageChangeRemove, NoImage } from '@rovit/image-display-sidebar'
import LinkInfo from '../LinkInfo/LinkInfo.vue'
import LinkEditor from '../LinkEditor/LinkEditor.vue'
import DangerZoneDelete from '../../components/DangerZoneDelete/DangerZoneDelete.vue'
import { ClusterMap, Geocode } from '@rovit/map'

export default {
  name: 'FrontPageItemEditor',
  components: {
    ImageChangeRemove,
    NoImage,
    LinkInfo,
    LinkEditor,
    DangerZoneDelete,
    ClusterMap,
    Geocode
  },
  props: {
    item: {
      validator: val => typeof val == 'object',
      required: true
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _item } = clones
    const { save_item } = saveHandlers

    const currentOrg = context.root.$store.getters['auth/currentOrg']

    // Asset Modal
    const assetModal = useModal('assetSelector')
    function openAssetModal() {
      assetModal.open({ applyAsset })
    }
    function applyAsset(asset) {
      return save_item({
        assetId: asset._id,
        assetUrl: asset.url
      })
    }
    function removeAssetFromItem() {
      save_item({ assetId: null, assetUrl: '' })
    }

    // Link Popper
    const linkInfoRef = ref(null)
    const sceneSelectorRef = ref(null)
    const popper = usePopper(linkInfoRef, sceneSelectorRef)

    function handleMarkerMoved({ location }) {
      save_item({ location })
    }
    function handleMapClick({ coordinates }) {
      const location = { type: 'Point', coordinates }
      save_item({ location })
    }
    function setMarkerFromGeocode(data) {
      const coordinates = data.geometry.coordinates
      handleMapClick({ coordinates })
    }

    function removeRecord() {
      props.item.remove()
      context.emit('remove')
    }

    return {
      ...clones,
      ...saveHandlers,

      currentOrg,
      thumbnail,
      removeAssetFromItem,
      removeRecord,

      // Asset Selector
      assetModal,
      openAssetModal,

      // Map Events
      handleMarkerMoved,
      handleMapClick,
      setMarkerFromGeocode,

      // Popper
      linkInfoRef,
      sceneSelectorRef,
      popper
    }
  }
}
</script>

<style lang="postcss"></style>
