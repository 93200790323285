<template>
  <div class="sidebar-support">
    <OrgForm v-if="org" :org="org"></OrgForm>

    <div v-else>
      Loading Item
    </div>
  </div>
</template>

<script>
import { models, useGet } from 'feathers-vuex'
import { computed, ref, watch } from '@vue/composition-api'
import OrgForm from '../OrgForm/OrgForm'

export default {
  name: 'UserOrgsSidebarEditorOrg',
  components: {
    OrgForm
  },
  props: {},
  setup(props, context) {
    const { Org } = models.api
    const isLoading = ref(true)

    const id = computed(() => context.root.$route.query.orgId)
    const { item: org } = useGet({ model: Org, id })

    watch(
      () => id.value,
      val => {
        if (val === 'new') {
          createNewItemAndRedirect()
        }
      },
      { immediate: true }
    )

    async function createNewItemAndRedirect() {
      const newItem = await new Org({
        name: 'New Organization'
      }).save()
      const currentQuery = context.root.$route.query
      const query = Object.assign({}, currentQuery, {
        orgId: newItem._id
      })
      context.root.$router.replace({ query })
    }

    return { isLoading, org }
  }
}
</script>

<style lang="postcss"></style>
