<template>
  <button
    type="button"
    class="w-full p-2 rounded cursor-pointer"
    :class="[isValidLink ? 'bg-gray-300 dark:bg-gray-700' : 'bg-red-300 dark:bg-red-900']"
    @click="$emit('click')"
  >
    <div v-if="link" class="text-left leading-tight">
      <div v-if="link.envName" class="py-1" :class="link.shouldSwitchEnvs ? '' : 'text-gray-500'">
        <p class="text-xs uppercase">Environment</p>
        <p>{{ link.envName }}</p>
        <div class="text-sm">
          <span v-if="!link.shouldSwitchEnvs">do not</span>
          switch environments
        </div>
      </div>

      <div v-if="link.serviceName" class="py-1">
        <p class="text-xs uppercase">Service</p>
        <p>{{ link.serviceName }}</p>
      </div>

      <div v-if="link.infoboxName" class="py-1">
        <p class="text-xs uppercase">Infobox</p>
        <p>{{ link.infoboxName }}</p>
      </div>

      <div v-if="link.sceneName" class="py-1">
        <p class="text-xs uppercase mt-2">Scene</p>
        <p>{{ link.sceneName }}</p>
      </div>
    </div>

    <div v-else>
      Select Destination
    </div>
  </button>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'LinkInfo',
  model: {
    prop: 'link'
  },
  props: {
    link: {
      validator: val => true,
      default: null
    }
  },
  setup(props, context) {
    const isValidLink = computed(() => {
      if (!props.link) {
        return true
      }
      return (
        (props.link.envId && props.link.shouldSwitchEnvs) ||
        props.link.infoboxId ||
        props.link.sceneId
      )
    })
    return { isValidLink }
  }
}
</script>

<style lang="postcss"></style>
