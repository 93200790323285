import { models, useGet, useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'
import { unwrapRef, unwrapRefs } from '@/use/unwrap-ref'

export async function createService({ name, org }) {
  const { Service } = models.api
  const _name = unwrapRef(name)
  const _org = unwrapRef(org)

  const service = await new Service({
    name: _name || 'New Service',
    orgs: [
      {
        orgId: _org._id,
        orgName: _org.name,
        accessType: 'owner',
        nameOfOwner: _org.nameOfOwner
      }
    ]
  }).save()

  return { service }
}

/**
 * Get Service by ID
 */
export function getService(id) {
  const { Service } = models.api
  const queryWhen = computed(() => {
    const service = Service.getFromStore(id)
    return !service
  })
  const { item: service } = useGet({ model: Service, id, queryWhen })
  return { service }
}

/**
 * Find Services
 */
export function findServices(options) {
  const { Service } = models.api
  const queryWhen = options.queryWhen || computed(() => true)

  const params = computed(() => {
    const { search, sort, pagination, mixin, org, populate, env } = unwrapRefs(options)
    const query = {
      $limit: 5000
    }
    if (env) {
      query['envsMeta.envId'] = env._id
    }
    if (org) {
      query['orgs.orgId'] = org._id
    }
    if (mixin) {
      Object.assign(query, mixin)
    }
    if (pagination) {
      Object.assign(query, pagination)
    }
    if (sort) {
      query.$sort = sort
    }
    if (search && search.length > 1) {
      Object.assign(query, {
        name: { $regex: search, $options: 'igm' }
      })
    }
    return { query, paginate: !!pagination, $populateParams: { name: populate } }
  })
  // See returned attributes: https://vuex.feathersjs.com/composition-api.html#returned-attributes
  const data = useFind({ model: Service, params, queryWhen })
  data.services = data.items
  return data
}

export async function addEnvToService(options) {
  const { service, env, save = true } = unwrapRefs(options)
  const alreadyExists = service.envsMeta.find(em => em.envId === env._id)
  if (alreadyExists) {
    return service
  }

  const data = { envsMeta: service.envsMeta }
  const existingEnvIds = data.envsMeta.map(em => em.envId)
  if (!existingEnvIds.includes(env._id)) {
    data.envsMeta.push({ envId: env._id, envName: env.name })
    const _service = service.clone(data)
    if (save) {
      return await _service.save({ data })
    } else {
      _service.commit()
    }
  }
  return service
}

export async function removeServiceFromEnv(options) {
  const { service, env } = unwrapRefs(options)
  const _service = service.clone()
  const envsMeta = _service.envsMeta.filter(em => {
    return em.envId !== env._id
  })
  return await _service.commit({ envsMeta }).save({ data: { envsMeta } })
}
