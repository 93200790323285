<template>
  <div class="sidebar-add-service">
    <div
      v-if="bulkServiceAddModal.isOpen"
      class="absolute overflow-y-auto z-30 top-0 left-0 right-0 bottom-0 bg-gray-800 text-white rounded m-12 p-3"
    >
      <div class="flex flex-row justify-between mt-2">
        <div>
          <h1 class="sidebar-heading-1">Services</h1>
        </div>
        <div>
          <button @click="closeBulkServiceAddModal">Close</button>
        </div>
      </div>
      <FeathersVuexPagination v-model="pagination" :latest-query="bulkAddServiceQuery">
        <template
          #default="{
            currentPage,
            pageCount,
            toStart,
            toEnd,
            toPage,
            next,
            prev,
            canNext,
            canPrev
          }"
        >
          <SidebarPagination
            :current-page="currentPage"
            :page-count="pageCount"
            :can-prev="canPrev"
            :can-next="canNext"
            @to-start="toStart"
            @to-end="toEnd"
            @to-page="toPage"
            @next="next"
            @prev="prev"
          />
        </template>
      </FeathersVuexPagination>
      <div>
        <button class="form-button primary" @click="() => handleImportServices()">
          Import Page
        </button>
      </div>
    </div>
    <h1 class="sidebar-heading-1 mt-2">Add a Service</h1>
    <p class="sidebar-subheading">
      You can create a new service or search for existing services, below.
    </p>

    <div class="flex mt-3 items-center">
      <button type="button" class="form-button primary" @click="createService">
        Create new Service
      </button>
      <div class="ml-3">
        <label
          class="px-3 bg-green-700 border border-green-600 text-white cursor-pointer rounded"
          style="padding-top: 10px; padding-bottom: 10px"
        >
          <span>Import from Spreadsheet</span>
          <input type="file" class="hidden" @change="handleFileSelect" />
        </label>
      </div>
    </div>

    <h2 class="sidebar-heading-1 mt-2">Search for Services</h2>
    <p class="sidebar-subheading">
      You can currently search services by name. In the future you'll be able to filter by category
      and location. For now, clicking a service will add it to the current environment.
    </p>

    <input
      v-model="serviceSearch"
      type="text"
      class="form-input w-full mt-2"
      placeholder="Search by Service Name"
    />

    <FeathersVuexPagination v-model="pagination" :latest-query="latestQuery">
      <template
        #default="{ currentPage, pageCount, toStart, toEnd, toPage, next, prev, canNext, canPrev }"
      >
        <SidebarPagination
          :current-page="currentPage"
          :page-count="pageCount"
          :can-prev="canPrev"
          :can-next="canNext"
          @to-start="toStart"
          @to-end="toEnd"
          @to-page="toPage"
          @next="next"
          @prev="prev"
        />
      </template>
    </FeathersVuexPagination>

    <GridSelector
      v-model="selectedService"
      :items="services"
      :label="i => i.name"
      :src="i => thumbnail(i.assetUrl).url"
      :fit="i => i.primaryAssetFit || 'contain'"
      class="mt-3 -mr-2"
      @item-click="handleServiceClick"
      @item-dblclick="handleServiceDblClick"
    >
      <template #icon="{ item }">
        <div v-if="inCurrentEnv(item)" class="absolute top-0 right-0 z-10 mr-3">
          <CheckIcon class="text-green-500" />
        </div>
      </template>
    </GridSelector>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import XLSX from 'xlsx'
import Papa from 'papaparse'
import keyBy from 'lodash/keyBy'
import { findServices } from '@/use/services.js'
import { unwrapRef } from '@/use/unwrap-ref'
import { randomString } from '@rovit/utils/random-string.js'
import { SidebarPagination } from '@rovit/sidebar-pagination'
import { getEnv } from '@/use/environments.js'
import { thumbnail } from '@/use/image-transforms/index'
import _uniq from 'lodash/uniq.js'
import _pick from 'lodash/pick'

import GridSelector from '../GridSelector/GridSelector'
import { CheckIcon } from 'vue-feather-icons'
import { useModal } from '@rovit/use-modal'

export default {
  name: 'EnvServicesSidebarAddService',
  components: {
    GridSelector,
    SidebarPagination,
    CheckIcon
  },
  setup(props, context) {
    const { Service, Environment } = models.api
    const envId = computed(() => context.root.$route.params.envId)
    const { env } = getEnv(envId)
    const currentOrg = context.root.$store.getters['auth/currentOrg']
    const importSessionId = ref(null)

    const selectedService = ref(null)

    // Find Services
    const serviceSearch = ref('')
    const pagination = ref({
      $limit: 20,
      $skip: 0
    })
    const sort = ref({ name: 1 })
    const mixin = computed(() => {
      return { 'envsMeta.envId': { $nin: [envId.value] } }
    })
    const { services, latestQuery } = findServices({
      search: serviceSearch,
      env,
      pagination,
      sort,
      mixin
    })

    function createService() {
      context.emit('create-service')
    }
    function handleServiceClick({ e, item }) {
      context.emit('add-service', item)
    }
    function handleServiceDblClick(service) {
      console.log(service)
    }

    function inCurrentEnv(service) {
      return service.envsMeta.find(em => em.envId === envId.value)
    }

    //bulk add services
    const bulkServiceAddModal = useModal('bulkServices')
    function openBulkServiceAddModal() {
      bulkServiceAddModal.open()
    }
    function closeBulkServiceAddModal() {
      bulkServiceAddModal.close()
    }

    const queryWhen = computed(() => {
      return true
    })
    const params = computed(() => {
      const query = {
        importSessionId: importSessionId.value
      }
      if (pagination.value) {
        Object.assign(query, pagination.value)
      }
      if (sort.value) {
        query.$sort = sort.value
      }

      return { query, temps: true }
    })
    const { items: bulkAddServiceList, latestQuery: bulkAddServiceQuery } = useFind({
      model: Service,
      params,
      queryWhen,
      local: true
    })

    function handleFileSelect(e) {
      const file = e.target.files[0]
      const extension = file.name.split('.').pop()

      if (extension === 'csv') {
        importFromCsv(file)
      } else if (extension === 'xls' || extension === 'xlsx') {
        importFromExcel(file)
      } else {
        console.error('unsupported file format')
      }
    }

    function importFromExcel(file) {
      // Create A File Reader HTML5
      const reader = new FileReader()

      // Ready The Event For When A File Gets Selected
      reader.onload = function (e) {
        const data = e.target.result
        const readFile = XLSX.read(data, { type: 'binary' })
        const sheetName = readFile.SheetNames[0]
        const rows = XLSX.utils.sheet_to_json(readFile.Sheets[sheetName])
        importSessionId.value = randomString(8)
        rows.forEach(row => {
          const transformedRow = transformRow(row)
          const service = new Service({ ...transformedRow })
        })
        bulkServiceAddModal.open()
      }

      // Tell JS To Start Reading The File
      reader.readAsBinaryString(file)
    }

    function importFromCsv(file) {
      Papa.parse(file, {
        header: true,
        complete: results => {
          const { data, errors } = results
          data.forEach(row => {
            const transformedRow = transformRow(row)
            const service = new Service({ ...transformedRow })
          })
          bulkServiceAddModal.open()
        }
      })
    }

    /**
     * row transform does 4 things :-
     *
     * 1. pick database related columns
     * 2. add envMeta to service
     * 3. set importSessionId for each row
     * 4. validate data
     */
    function transformRow(row) {
      const withValidColumns = _pick(row, [
        'name',
        'contacts',
        'phone',
        'email',
        'website',
        'address1',
        'city',
        'state',
        'zipcode'
      ])
      if (withValidColumns.zipcode) {
        withValidColumns.zipcode = withValidColumns.zipcode.toString()
      }
      if (withValidColumns.contacts) {
        const contacts = withValidColumns.contacts.split(',')
        withValidColumns.contacts = contacts.map(c => ({ name: c }))
      }
      const _env = unwrapRef(env)
      const withEnvAndImportSessionId = {
        ...withValidColumns,
        importSessionId: importSessionId.value,
        envsMeta: [
          {
            envId: _env._id,
            envName: _env.name
          }
        ]
      }
      return withEnvAndImportSessionId
    }

    function handleImportServices() {
      //save
    }

    return {
      selectedService,
      serviceSearch,
      services,
      createService,
      handleServiceClick,
      handleServiceDblClick,
      thumbnail,
      inCurrentEnv,

      handleFileSelect,
      bulkServiceAddModal,
      openBulkServiceAddModal,
      closeBulkServiceAddModal,
      bulkAddServiceList,
      handleImportServices,
      bulkAddServiceQuery,

      // Pagination
      latestQuery,
      pagination
    }
  }
}
</script>

<style lang="postcss"></style>
