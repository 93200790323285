<template>
  <div class="sidebar-tabs">
    <!-- Service -->
    <router-link
      tag="button"
      type="button"
      class="combo-left"
      :to="routeFor('info')"
      :class="{
        active: $route.query.serviceSection === 'info'
      }"
    >
      Info
    </router-link>

    <router-link
      tag="button"
      type="button"
      class="combo-middle"
      :to="routeFor('infoboxes')"
      :class="{
        active: $route.query.serviceSection === 'infoboxes'
      }"
    >
      Infoboxes
    </router-link>

    <router-link
      tag="button"
      type="button"
      class="combo-middle"
      :to="routeFor('location')"
      :class="{
        active: $route.query.serviceSection === 'location'
      }"
    >
      Location
    </router-link>

    <router-link
      tag="button"
      type="button"
      class="combo-middle"
      :to="routeFor('contact')"
      :class="{
        active: $route.query.serviceSection === 'contact'
      }"
    >
      Contact
    </router-link>

    <router-link
      tag="button"
      type="button"
      class="combo-middle"
      style="border-left: 0;"
      :to="routeFor('schedule')"
      :class="{
        active: $route.query.serviceSection === 'schedule'
      }"
    >
      Schedule
    </router-link>

    <router-link
      tag="button"
      type="button"
      class="combo-right"
      style="border-left: 0;"
      :to="routeFor('danger')"
      :class="{
        active: $route.query.serviceSection === 'danger'
      }"
    >
      <AlertTriangleIcon
        class="p-0.5"
        :class="[$route.query.serviceSection === 'danger' ? 'text-white' : 'text-yellow-700']"
      />
    </router-link>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { AlertTriangleIcon } from 'vue-feather-icons'

export default {
  name: 'ServiceSidebarTabs',
  component: {
    AlertTriangleIcon
  },
  setup(props, context) {
    function routeFor(serviceSection) {
      const newRoute = Object.assign({}, context.root.$route)
      const query = Object.assign({}, context.root.$route.query, { serviceSection })
      Object.assign(newRoute, { query })
      return newRoute
    }
    return { routeFor }
  }
}
</script>

<style lang="postcss"></style>
