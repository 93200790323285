<template>
  <div class="mb-6 pb-32">
    <h1 class="sidebar-heading-1 mt-2">
      {{ $route.query.serviceId === 'new' ? 'New' : 'Edit' }} Scene
    </h1>

    <SidebarModal v-model="categoryModal.isOpen" class="z-index-60">
      <template>
        <div class="edit-category">
          <div v-if="isCategoryEditorOpen">
            <CategoryEditor
              class="mt-3"
              :category="createdCategory"
              :env-id="selectedEnvironment._id"
              :org-id="currentOrg._id"
              :icon-model="models.api.Icon"
              :categories="categories"
              :show-close-button="true"
              @close="categoryModal.close"
            />

            <DangerZoneDelete @remove="() => createdCategory.remove()" />
          </div>
          <div v-else>Loading Category</div>
        </div>
      </template>
    </SidebarModal>

    <SceneFormSidebarTabs v-model="currentSceneTab" />

    <SidebarModal v-model="deleteSceneModal.isOpen" class="z-50 vertical-center">
      <template>
        <PromptDeleteInfobox
          v-if="deleteSceneModal.data.type === 'infobox'"
          :scene="scene"
          @close="deleteSceneModal.close"
        />
        <PromptDeleteScene
          v-if="deleteSceneModal.data.type === 'scene'"
          :scene="scene"
          @close="deleteSceneModal.close"
        />
        <PromptDeleteInfoboxOrScene
          v-if="deleteSceneModal.data.type === 'infobox-scene'"
          :scene="scene"
          @close="deleteSceneModal.close"
        />
      </template>
    </SidebarModal>

    <div v-if="currentSceneTab === 'info'" class="mt-3">
      <label class="block">
        <span class="text-gray-700">Name</span>
        <input
          v-model="_scene.name"
          class="form-input block w-full"
          placeholder="Name of Scenes, Business, or Event"
          @blur="
            e => {
              save_scene('name')
                .then(res => {
                  this.$toasted.global.actionSuccess('Updated name')
                })
                .catch(error => {
                  this.$toasted.global.actionError('Failed to update name')
                })
            }
          "
        />
      </label>

      <div class="mt-3">
        <div>
          <div class="flex justify-between">
            <p class="text-gray-700 dark:text-gray-300">Location</p>
            <div>
              <!-- Edit Button -->
              <button
                type="button"
                class="flex flex-row items-center p-1 focus:outline-none rounded-t"
                :class="isEditLocationEnabled ? 'bg-yellow-600 text-black' : ''"
                @click="isEditLocationEnabled = !isEditLocationEnabled"
              >
                <p v-if="isEditLocationEnabled" class="-my-1 mr-1">Done</p>
                <Edit2Icon size="1x" />
              </button>
            </div>
          </div>
          <div class="h-64">
            <ClusterMap
              :current="_scene"
              :access-token="$store.state.mapboxToken"
              :items="[]"
              class="w-full"
              cluster
              @load="handleMapLoad"
              @marker-moved="handleMarkerMoved"
              @map-click="handleMapClick"
            />
          </div>
          <div class="flex mt-3">
            <button
              v-if="!isEditLocationEnabled"
              type="button"
              class="form-button primary py-1 ml-1"
              @click="setSceneBounds"
            >
              Set Bounds
            </button>
            <button
              v-if="!isEditLocationEnabled"
              type="button"
              class="form-button primary py-1 ml-1"
              @click="resetSceneBounds"
            >
              Reset Bounds
            </button>
          </div>

          <!-- Buttons to Copy Location from Service -->
          <div v-if="service && isEditLocationEnabled" class="flex flex-row">
            <button v-if="service && !service.location" disabled class="form-button bg-gray-800">
              Service has no location
            </button>
            <button v-else-if="doLocationsMatch" disabled class="form-button bg-gray-800">
              Same as Service
            </button>
            <button
              v-else
              class="block form-button bg-rovit-green"
              @click="copyLocationFromService"
            >
              Copy location from Service
            </button>
            <button
              v-if="!doLocationsMatch"
              class="ml-2 block form-button bg-rovit-green"
              :disabled="!_scene.location"
              @click="setServiceLocationFromScene"
            >
              Set Service Location
            </button>
          </div>
        </div>
      </div>

      <div class="block mt-3 w-3/4">
        <span class="block mb-1">Default Hotspot Icon</span>

        <!-- Image -->
        <ImageChangeRemove
          v-if="scene.defaultHotspotIconUrl"
          :src="_scene.defaultHotspotIconUrl"
          fit="contain"
          @dragenter="openSelector"
          @change-image="openSelector"
          @remove-image="handleRemoveAsset"
        />

        <!-- No Image -->
        <NoImage
          v-else
          button-text="Select Default Hotspot Icon"
          :icon-component="MapPinIcon"
          @click="openSelector"
          @dragenter="openSelector"
        />
      </div>

      <!-- Feature -->
      <FeatureSelect v-model="_scene.featureType" @input="() => save_scene('featureType')" />
      <SceneFeaturePanoEditor v-if="scene.featureType === 'pano'" class="mt-2" :scene="scene" />
      <ItemFeatureAssetEditor v-if="scene.featureType === 'asset'" class="mt-2" :item="scene" />
    </div>

    <div v-if="currentSceneTab === 'service'">
      <div class="mt-3">
        <p class="text-gray-700 dark:text-gray-300">Service</p>
        <ServiceSelect :service="service" clearable @input="setService" />
      </div>

      <ServiceSidebar v-if="service" :service="service" />
    </div>

    <div v-if="currentSceneTab === 'environments'" class="mt-3">
      <!-- Environment Select -->
      <p>Featured in Environments</p>
      <EnvsMetaDisplay
        v-model="_scene.envsMeta"
        @update="() => save_scene('envsMeta')"
        @activate-env-id="handleEnvMetaSelect"
      />

      <!-- Environment Select -->
      <p class="mt-3">
        Select Environment

        <span v-if="currentEnv" class="text-sm pl-3">
          <button
            class="text-blue-700 dark:text-blue-400"
            type="button"
            @click="selectedEnvironment = currentEnv"
          >
            Select
            <span class="font-bold">{{ currentEnv.name }}</span>
          </button>
        </span>
      </p>
      <EnvironmentSelect v-model="selectedEnvironment" clearable />

      <button
        v-if="shouldShowAddToEnvButton"
        type="button"
        class="form-button bg-rovit-green w-full flex justify-center"
        @click="addToEnv"
      >
        Add Scene to {{ selectedEnvironment.name }} environment
      </button>

      <!-- Badges -->
      <p class="mt-3">Badges</p>
      <BadgesMeta
        v-if="selectedEnvironment && !shouldShowAddToEnvButton"
        v-model="_scene.badgesMeta"
        @open-badge-selector="openBadgeSelector"
      />
      <div
        v-else
        class="border border-gray-200 dark:border-gray-600 px-2 py-1 rounded cursor-not-allowed"
      >
        <span v-if="shouldShowAddToEnvButton">Add to Environment, first</span>
        <span v-else>Select an Environment, first</span>
      </div>

      <!-- Categories -->
      <div class="mt-3 block">
        <label>Categories</label>
        <CategoriesMetaSelect
          v-if="selectedEnvironment && !shouldShowAddToEnvButton"
          v-model="_scene.categoriesMeta"
          :env="selectedEnvironment"
          :category-model="models.api.Category"
          @input="handleCategoriesMetaSelect"
          @created="handleCreatedCategory"
        />
        <div
          v-else
          class="border border-gray-200 dark:border-gray-600 px-2 py-1 rounded cursor-not-allowed"
        >
          <span v-if="shouldShowAddToEnvButton">Add to Environment, first</span>
          <span v-else>Select an Environment, first</span>
        </div>
      </div>
    </div>

    <div v-if="currentSceneTab === 'permissions'" class="mt-3">
      <span class="form-label">Organization Access</span>
      <PermissionManager
        v-model="_scene.orgs"
        :org-model="models.api.Org"
        :user-model="models.api.User"
        type="orgs"
        @change="
          () => {
            save_scene('orgs')
              .then(res => {
                this.$toasted.global.actionSuccess('Updated organization access')
              })
              .catch(error => {
                this.$toasted.global.actionError('Failed to update organization access')
              })
          }
        "
      />
      <span class="text-red-400">{{ errors.orgs }}</span>

      <div class="mt-3">
        <span class="text-gray-700 dark:text-gray-400 font-medium">User Access</span>
        <PermissionManager
          v-model="_scene.users"
          :org-model="models.api.Org"
          :user-model="models.api.User"
          type="users"
          @change="
            () =>
              save_scene('users')
                .then(res => {
                  this.$toasted.global.actionSuccess('Updated user access')
                })
                .catch(error => {
                  this.$toasted.global.actionError('Failed to update user access')
                })
          "
        />
        <span class="text-red-400">{{ errors.users }}</span>
      </div>
    </div>

    <div v-if="currentSceneTab === 'danger'" class="danger-zone mt-3">
      <h2 class="sidebar-heading-1 flex flex-row items-center">
        The Danger Zone
        <AlertOctagonIcon class="ml-2 text-red-600" />
      </h2>

      <p class="content-subheading mb-2">Once you click, there's no going back.</p>

      <!-- "Delete Scene" Button -->
      <button type="button" class="form-button danger mt-3" @click="openDeleteSceneModal">
        Delete Scene
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { handleClones } from '@rovit/utils'
import { models } from 'feathers-vuex'
import { useModal } from '@rovit/use-modal'
import { createService } from '@/use/services.js'
import { createEnv, getRovitEnvFromStore } from '@/use/environments.js'
import _isEqual from 'lodash/isEqual.js'
import _get from 'lodash/get.js'
import { ClusterMap } from '@rovit/map'
import { Edit2Icon } from 'vue-feather-icons'

import { ImageChangeRemove, NoImage } from '@rovit/image-display-sidebar'
import { SidebarModal } from '@rovit/sidebar-modal'
import { AlertOctagonIcon, MapPinIcon } from 'vue-feather-icons'
import { FeatureSelect } from '@rovit/feature-select'
import SceneFeaturePanoEditor from '../SceneFeaturePano/SceneFeaturePanoEditor.vue'
import { ItemFeatureAssetEditor } from '@rovit/item-feature-asset-editor'
import { PermissionManager } from '@rovit/permission-manager'
import PromptDeleteInfobox from './../PromptDeleteInfobox/PromptDeleteInfobox'
import PromptDeleteInfoboxOrScene from './../PromptDeleteInfoboxOrScene/PromptDeleteInfoboxOrScene'
import PromptDeleteScene from './../PromptDeleteScene/PromptDeleteScene'
import SceneFormSidebarTabs from './SceneFormSidebarTabs.vue'
import BadgesMeta from '../BadgesMeta/BadgesMeta.vue'
import EnvsMetaDisplay from '../EnvsMetaDisplay/EnvsMetaDisplay.vue'
import EnvironmentSelect from './../EnvironmentSelect'
import ServiceSelect from '../ServiceSelect.vue'
import { CategoriesMetaSelect } from '@rovit/categories-meta-select'
import ServiceSidebar from '../ServiceSidebar/ServiceSidebar.vue'
import { findCategories } from '@/use/categories.js'
import CategoryEditor from '../CategoryEditor/CategoryEditor.vue'
import DangerZoneDelete from '../DangerZoneDelete/DangerZoneDelete.vue'

export default {
  name: 'SceneForm',
  components: {
    FeatureSelect,
    SceneFeaturePanoEditor,
    ItemFeatureAssetEditor,
    ImageChangeRemove,
    NoImage,
    SidebarModal,
    PermissionManager,
    PromptDeleteInfobox,
    PromptDeleteScene,
    PromptDeleteInfoboxOrScene,
    SceneFormSidebarTabs,
    BadgesMeta,
    EnvironmentSelect,
    EnvsMetaDisplay,
    CategoriesMetaSelect,
    ServiceSelect,
    ClusterMap,
    Edit2Icon,
    ServiceSidebar,
    CategoryEditor,
    DangerZoneDelete
  },
  props: {
    scene: {
      type: Object,
      required: true
    },
    /**
     * If an envSceneId is passed in, the "remove from environment" button shows.
     */
    envSceneId: {
      type: String,
      default: ''
    },
    envScenes: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props, { useExisting: true })
    const { Icon } = models.api
    const { _scene } = clones
    const { save_scene } = saveHandlers
    const vueMapbox = {}
    const isMapReady = ref(false)
    const currentOrg = context.root.$store.getters['auth/currentOrg']
    // Environments
    const selectedEnvironment = ref(null)
    const currentEnv = computed(() => {
      const { envId } = context.root.$route.params
      return envId ? models.api.Environment.getFromStore(envId) : getRovitEnvFromStore().rovit.value
    })
    const shouldShowAddToEnvButton = computed(() => {
      const selectedEnvId = _get(selectedEnvironment.value, '_id')
      return (
        selectedEnvId != null &&
        !(_scene.value.envsMeta || []).find(em => em.envId === selectedEnvId)
      )
    })
    // Adds the selectedEnvironment to envsMeta
    function addToEnv() {
      const envsMeta = _scene.value.envsMeta || []
      envsMeta.push({
        envId: selectedEnvironment.value._id,
        envName: selectedEnvironment.value.name
      })
      save_scene({ envsMeta })
        .then(res => {
          this.$toasted.global.actionSuccess('Scene added to Environment')
        })
        .catch(error => {
          this.$toasted.global.actionError('Could not add Scene to Environment')
        })
    }
    // Selects an Environment from envsMeta.
    async function handleEnvMetaSelect(envId) {
      const envFromStore = models.api.Environment.getFromStore(envId)
      if (envFromStore) {
        selectedEnvironment.value = envFromStore
      }
      const env = await models.api.Environment.get(envId)
      if (env) {
        selectedEnvironment.value = envFromStore
      }
    }

    // Doesn't use computed v-model so we have access to this.$toasted in the setter.
    const service = computed(() => models.api.Service.getFromStore(_scene.value.serviceId))
    function setService(val) {
      save_scene({ serviceId: val == null ? null : val._id })
        .then(res => {
          this.$toasted.global.actionSuccess("Scene's service updated")
        })
        .catch(error => {
          this.$toasted.global.actionError("Failed to update scene's service")
        })
    }

    // Category
    const createdCategory = ref(null)
    const { categories } = findCategories({ env: currentEnv })
    const categoryModal = useModal('categoryEditModal')

    // Location
    const doLocationsMatch = computed(() => {
      return (
        _scene.value.serviceId != null &&
        service.value.location != null &&
        _scene.value.location != null &&
        _isEqual(service.value.location, _scene.value.location)
      )
    })
    function copyLocationFromService() {
      save_scene({ location: service.value.location })
        .then(res => {
          this.$toasted.global.actionSuccess('Location updated')
        })
        .catch(error => {
          this.$toasted.global.actionError('Failed to update location')
        })
    }
    function setServiceLocationFromScene() {
      const data = { location: _scene.value.location }
      const _service = service.value
        .clone(data)
        .save({ data })
        .then(res => {
          this.$toasted.global.actionSuccess('Service location updated')
        })
        .catch(error => {
          this.$toasted.global.actionError('Failed to update service location')
        })
    }

    const sceneIconModal = useModal('iconSelector')
    const deleteSceneModal = useModal('sceneFormDeleteScene')

    /**
     * Uploader Modal and Handlers
     */
    function openSelector() {
      sceneIconModal.open({ _item: _scene.value, handler: performSceneUpdate })
    }

    function handleRemoveAsset() {
      Object.assign(_scene.value, {
        defaultHotspotIconId: null,
        defaultHotspotIconUrl: ''
      })
      save_scene(['defaultHotspotIconId', 'defaultHotspotIconUrl'])
        .then(res => {
          this.$toasted.global.actionSuccess('Removed hotspot icon')
        })
        .catch(error => {
          this.$toasted.global.actionError('Failed to remove hotspot icon')
        })
    }

    // Categories
    const isCategoryEditorOpen = ref(false)
    function handleCreatedCategory(category) {
      createdCategory.value = category
      isCategoryEditorOpen.value = true
      categoryModal.isOpen = true
    }
    function handleCategoriesMetaSelect() {
      save_scene('categoriesMeta')
        .then(() => {
          this.$toasted.global.actionSuccess('Categories Updated')
        })
        .catch(() => {
          this.$toasted.global.actionError('Categories could not be updated')
        })
    }

    function openDeleteSceneModal() {
      let type = 'scene'
      if (props.scene.isInfobox) {
        if (props.scene.childScenes.length === 0) {
          type = 'infobox'
        } else {
          type = 'infobox-scene'
        }
      }
      deleteSceneModal.open({ type })
    }

    const errors = computed(() => {
      const { errorOnCreate, errorOnPatch } = context.root.$store.state.scenes
      return (errorOnPatch && errorOnPatch.errors) || (errorOnCreate && errorOnCreate.errors) || {}
    })

    const currentSceneTab = ref('info')

    // Badge Selector Modal
    const badgeSelectorModal = useModal('badgeSelector')
    function openBadgeSelector() {
      badgeSelectorModal.open({ item: _scene, save_item: save_scene })
    }

    //Location
    const isEditLocationEnabled = ref(false)
    function handleMarkerMoved({ location }) {
      if (isEditLocationEnabled.value) {
        _scene.value.location = location
        save_scene('location')
      }
    }
    function handleMapClick({ coordinates }) {
      if (isEditLocationEnabled.value) {
        const location = { type: 'Point', coordinates }
        _scene.value.location = location
        save_scene('location')
          .then(res => {
            this.$toasted.global.actionSuccess('Scene location updated')
          })
          .catch(error => {
            this.$toasted.global.actionError('Failed to update scene location')
          })
      }
    }

    function handleMapLoad(event) {
      Object.assign(vueMapbox, event)
      isMapReady.value = true
      resetSceneBounds()
    }

    watch(
      () => _get(props.scene, 'bbox'),
      bounds => {
        if (bounds && bounds.length === 2) {
          fitToBounds(bounds)
        }
      }
    )

    function setSceneBounds() {
      if (isMapReady.value == true) {
        var bounds = vueMapbox.component.map.getBounds()
        Object.assign(_scene.value, {
          bbox: bounds.toArray()
        })
        save_scene('bbox', { autoToast: true })
      } else {
        console.log('Map is not ready')
      }
    }
    function resetSceneBounds() {
      if (props.scene.bbox && props.scene.bbox.length === 2) {
        fitToBounds(props.scene.bbox)
      }
    }
    function fitToBounds(bounds) {
      if (isMapReady.value) {
        vueMapbox.component.map.fitBounds(bounds)
      }
    }
    function performSceneUpdate(icon) {
      Object.assign(_scene.value, {
        defaultHotspotIconId: icon._id,
        defaultHotspotIconUrl: icon.url
      })
      save_scene(['defaultHotspotIconId', 'defaultHotspotIconUrl'], { autoToast: true })
    }

    return {
      models,
      ...clones,
      ...saveHandlers,
      errors,
      sceneIconModal,
      openDeleteSceneModal,
      deleteSceneModal,
      currentSceneTab,
      MapPinIcon,

      // Environments
      selectedEnvironment,
      currentEnv,
      shouldShowAddToEnvButton,
      addToEnv,
      handleEnvMetaSelect,

      // Service
      service,
      setService,
      doLocationsMatch,
      copyLocationFromService,
      setServiceLocationFromScene,

      // SidebarUploadSelector
      openSelector,
      handleRemoveAsset,

      // Badge Selector Modal
      openBadgeSelector,

      // Categories
      categories,
      createdCategory,
      isCategoryEditorOpen,
      categoryModal,
      handleCreatedCategory,
      handleCategoriesMetaSelect,

      //Location
      handleMarkerMoved,
      handleMapClick,
      isEditLocationEnabled,
      handleMapLoad,
      setSceneBounds,
      resetSceneBounds,

      // Org
      currentOrg
    }
  }
}
</script>

<style lang="postcss" scoped>
.vertical-center {
  top: 50%;
}

.z-index-60 {
  z-index: 60;
}
</style>
