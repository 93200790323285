<template>
  <div class="sidebar-support">
    EnvLibCategoriesSidebarSupport
  </div>
</template>

<script>
export default {
  name: 'EnvLibCategoriesSidebarSupport',
  props: {}
}
</script>

<style lang="postcss"></style>
