import { useFind, useGet, models } from 'feathers-vuex'
import { ref, computed } from '@vue/composition-api'
import { unwrapRef, unwrapRefs } from './unwrap-ref'
import _omit from 'lodash/omit.js'

/**
 * Create an EnvService record for the provided service.
 */
export async function createEnvService({ service, env, scene }) {
  const { EnvService } = models.api
  const _scene = unwrapRef(scene)
  const _env = unwrapRef(env)
  const _service = unwrapRef(service)

  const data = {
    envId: _env._id,
    envName: _env.name,
    serviceId: _service._id,
    serviceName: _service.name
  }
  if (_scene && _scene._id) {
    Object.assign(data, {
      sceneId: _scene._id,
      sceneName: _scene.infoboxName || _scene.name
    })
  }
  const envService = await new EnvService(data).save({
    $populateParams: {
      name: 'withService'
    }
  })
  return envService
}

/**
 * Get envService by provided id
 */
export function getEnvService(id) {
  const { EnvService } = models.api
  const params = computed(() => {
    return {
      $populateParams: {
        name: 'adminEnvService'
      }
    }
  })
  const queryWhen = computed(() => {
    const _id = unwrapRef(id)
    const envService = !EnvService.get(_id)
    return !envService || (envService && (!envService.service || !envService.environment))
  })
  const data = useGet({ model: EnvService, id, params, queryWhen })
  const { item: envService, hasLoaded: hasEnvServiceLoaded } = data

  return { envService, hasEnvServiceLoaded }
}

/**
 * Find EnvServices by Scene
 */
export function findEnvServicesByScene({ search, scene, sort }) {
  const { EnvService } = models.api

  const params = computed(() => {
    const _scene = unwrapRef(scene)

    if (!_scene) {
      return null
    }
    const $sort = unwrapRef(sort)
    const query = { sceneId: _scene._id }
    // Sort
    if ($sort) {
      query.$sort = $sort
    }
    // Search
    if (search && search.value) {
      Object.assign(query, {
        name: { $regex: search.value, $options: 'igm' }
      })
    }
    return { query, $populateParams: { name: 'withService' } }
  })
  const { items: envServices } = useFind({
    model: EnvService,
    params: params
  })

  return { envServices }
}

/**
 * Find EnvServices by Service
 */
export function findEnvServicesByService({ search, service, sort }) {
  const { EnvService } = models.api

  const params = computed(() => {
    const _service = unwrapRef(service)

    if (!_service) {
      return null
    }
    const $sort = unwrapRef(sort)
    const query = { serviceId: _service._id }
    // Sort
    if ($sort) {
      query.$sort = $sort
    }
    // Search
    if (search && search.value) {
      Object.assign(query, {
        name: { $regex: search.value, $options: 'igm' }
      })
    }
    return { query, $populateParams: { name: 'withService' } }
  })
  const { items: envServices } = useFind({
    model: EnvService,
    params: params
  })

  return { envServices }
}

/**
 * Find EnvServices by Environment
 */
export function findEnvServicesByEnv({ search, env, sort, populate = 'withService' }) {
  const { EnvService } = models.api

  const params = computed(() => {
    const _env = unwrapRef(env)
    if (!_env) {
      return null
    }
    const $sort = unwrapRef(sort)
    const query = {
      $limit: 5000
    }

    if (_env._id) {
      query.envId = _env._id
    }
    // Sort
    if ($sort) {
      query.$sort = $sort
    }
    // Search
    if (search && search.value) {
      Object.assign(query, {
        serviceName: { $regex: search.value, $options: 'igm' }
      })
    }
    return { query, $populateParams: { name: populate } }
  })
  const { items: envServices } = useFind({
    model: EnvService,
    params: params
  })

  return { envServices }
}

/**
 * Find an EnvService using the provided env and service
 */
export function findEnvService({ env, service }) {
  const { EnvService } = models.api

  const params = computed(() => {
    const _env = unwrapRef(env)
    const _service = unwrapRef(service)

    if (!_env || !_service) {
      return null
    }
    return {
      query: {
        envId: _env._id,
        serviceId: _service._id
      },
      $populateParams: { name: 'withService' }
    }
  })
  const { items: envServices } = useFind({ model: EnvService, params })

  const envService = computed(() => {
    if (envServices.value && envServices.value.length) {
      return envServices.value[0]
    } else {
      return null
    }
  })
  return { envService }
}

/**
 * Associates the provided infobox onto the envService
 */
export async function associateInfoboxWithEnvService({ infobox, envService }) {
  const _infobox = unwrapRef(infobox)
  const _envService = unwrapRef(envService)

  const updated = await _envService
    .clone({
      sceneId: _infobox._id,
      sceneName: _infobox.infoboxName
    })
    .save()

  return { envService: updated }
}

/**
 * copyEnvServiceWithPano
 *
 * Pass a { pano, sourceEnv, destEnv } and if a scene exists, an env-service record
 * will be created for the destEnv. The scene record is not copied.
 * The new env-service record is the same except for the `envId` and `envName`.
 * If there's not a matching env-service record, nothing changes.
 */
export async function copyEnvServiceWithPano(options) {
  const { pano, sourceEnv, destEnv } = unwrapRefs(options)
  const { Scene, EnvService } = models.api
  // Find the scene that's using the pano
  const sceneRes = Scene.findInStore({ query: { panoId: pano._id } }).data
  let scene = sceneRes[0]
  if (!scene) {
    return
  }
  // Find the env-service record for the scene and the sourceEnv.
  const envServiceRes = EnvService.findInStore({
    query: { sceneId: scene._id, envId: sourceEnv._id }
  }).data
  let envService = envServiceRes[0]
  if (!envService) {
    return
  }
  // Create the new env-service record
  const data = _omit(envService, ['_id'])
  Object.assign(data, { envId: destEnv._id, envName: destEnv.name })

  return await new EnvService(data).save()
}

/**
 * moveEnvServiceWithPano
 *
 * Pass a { pano, sourceEnv, destEnv } and if a scene exists, an env-service record
 * will be created for the destEnv. The scene record is not copied.
 * The existing env-service record's `envId` and `envName` is modified.
 * If there's not a matching env-service record, nothing changes.
 */
export async function moveEnvServiceWithPano(options) {
  const { pano, sourceEnv, destEnv } = unwrapRefs(options)
  const { Scene, EnvService } = models.api
  // Find the scene that's using the pano
  const sceneRes = Scene.findInStore({ query: { panoId: pano._id } }).data
  let scene = sceneRes[0]
  if (!scene) {
    return
  }
  // Find the env-service record for the scene and the sourceEnv.
  const envServiceRes = EnvService.findInStore({
    query: { sceneId: scene._id, envId: sourceEnv._id }
  }).data
  let envService = envServiceRes[0]
  if (!envService) {
    return
  }
  // Overwrite the env-service record
  Object.assign(envService, { envId: destEnv._id, envName: destEnv.name })
  return await new EnvService(envService).save()
}
