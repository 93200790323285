<template>
  <div>
    <h1 class="sidebar-heading-1 mt-2">
      {{ $route.query.orgUserId === 'new' ? 'New' : 'Edit' }} Org
    </h1>

    <label class="block">
      <span class="text-gray-700">Name</span>
      <input
        v-model="_org.name"
        class="form-input block w-full"
        placeholder="Organization Name"
        @blur="e => save_org('name')"
      />
    </label>

    <label class="block mt-2">
      <span class="text-gray-700">Owner Name</span>
      <input v-model="_org.nameOfOwner" class="form-input block w-full" placeholder="" readonly />
    </label>

    <!-- Icon -->
    <div class="block mt-3 w-3/4">
      <span class="block mb-1">Primary image</span>

      <!-- Image -->
      <ImageChangeRemove
        v-if="_org.primaryAssetUrl"
        :src="_org.primaryAssetUrl"
        hide-fit
        :fit.sync="_org.primaryAssetFit"
        @change-image="openAssetModal"
        @remove-image="clearAsset"
      />

      <!-- No Image -->
      <NoImage v-else @click="openAssetModal" />
    </div>

    <div class="mt-3">
      <span class="font-medium">Users</span>
      <div class="bg-gray-300 dark:bg-gray-800 rounded-md p-1">
        <div v-if="!orgUsers.length" class="text-center font-bold">
          No Users
        </div>
        <OrgUserRow v-for="ou in orgUsers" :key="ou._id" :org-user="ou" />
      </div>
      <PermissionBuilderUser
        :exclude-ids="orgUsers.map(ou => ou.userId)"
        :user-model="models.api.User"
        class="mt-1"
        @add="handleAddUser"
      />
    </div>

    <div class="mt-3">
      <MapboxTokenInput v-model="_org.mapboxToken" @blur="e => save_org('mapboxToken')" />
    </div>

    <div class="mt-12">
      <button type="button" @click="$emit('remove')">Delete</button>
    </div>
  </div>
</template>

<script>
import { models } from 'feathers-vuex'
import { ref, watch, computed } from '@vue/composition-api'
import { handleClones } from '@rovit/utils/handle-clones.js'
import { findOrgUsersForOrg, createOrgUser } from '@/use/org-users.js'
import { useModal } from '@rovit/use-modal'

import { ImageChangeRemove, NoImage } from '@rovit/image-display-sidebar'
import OrgUserRow from './OrgUserRow.vue'
import { PermissionBuilderUser } from '@rovit/permission-manager'
import MapboxTokenInput from './../MapboxTokenInput/MapboxTokenInput'

export default {
  name: 'OrgForm',
  components: {
    ImageChangeRemove,
    NoImage,
    OrgUserRow,
    PermissionBuilderUser,
    MapboxTokenInput
  },
  props: {
    org: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _org } = clones
    const { save_org } = saveHandlers

    const org = computed(() => props.org)
    const { orgUsers } = findOrgUsersForOrg({ org })

    /**
     * Uploader Modal and Handlers
     */
    const assetModal = useModal('assetSelector')
    function openAssetModal() {
      assetModal.open({ applyAsset })
    }
    function applyAsset(asset) {
      return save_org({
        primaryAssetId: asset._id,
        primaryAssetUrl: asset.url
      })
    }
    function clearAsset() {
      save_org({
        primaryAssetId: null,
        primaryAssetUrl: ''
      })
    }

    function handleAddUser({ user, accessType }) {
      createOrgUser({ org: props.org, user, accessType })
    }

    watch(
      () => props.org._id,
      val => assetModal.close()
    )

    return {
      models,
      ...clones,
      ...saveHandlers,
      orgUsers,
      openAssetModal,
      clearAsset,
      handleAddUser
    }
  }
}
</script>

<style lang="postcss"></style>
