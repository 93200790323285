<template>
  <div class="sidebar-add-service">
    <h1 class="sidebar-heading-1 mt-2">Add a Service</h1>
    <p class="sidebar-subheading">
      Create a new service by clicking the button, below.
    </p>

    <button type="button" class="form-button primary mt-2" @click="createService">
      Create new Service
    </button>

    <!-- <h2 class="sidebar-heading-1 mt-2">Search for Services</h2>
    <p class="sidebar-subheading">
      You can currently search services by name. In the future you'll be able to
      filter by category and location. For now, clicking a service will add it
      to the current environment.
    </p>

    <input
      v-model="serviceSearch"
      type="text"
      class="form-input w-full mt-2"
      placeholder="Search by Service Name"
    /> -->
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import keyBy from 'lodash/keyBy'

export default {
  name: 'OrgServicesSidebarAddService',
  props: {
    services: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const { Service } = models.api

    const selectedService = ref(null)
    const serviceSearch = ref('')
    const servicesParams = computed(() => {
      const query = {}
      if (serviceSearch.value) {
        Object.assign(query, {
          name: { $regex: serviceSearch.value, $options: 'igm' }
        })
      }
      return { query }
    })
    const { items: services } = useFind({
      model: Service,
      params: servicesParams
    })
    const servicesByServiceId = computed(() => keyBy(props.services, 'serviceId'))
    const filteredServices = computed(() => {
      return services.value.filter(service => {
        return !servicesByServiceId.value[service._id]
      })
    })

    function createService() {
      context.emit('create-service')
    }
    function handleServiceDblClick(service) {
      console.log(service)
    }

    return {
      selectedService,
      serviceSearch,
      filteredServices,
      createService,
      handleServiceDblClick
    }
  }
}
</script>

<style lang="postcss"></style>
