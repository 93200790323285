<template>
  <div>
    <!-- <div class="top-0 bottom-0 right-0 w-full h-full absolute z-10"> -->
    <SidebarModal v-model="isEnvironmentPublicPromptOpen" class="z-20">
      <template #default="{ close }">
        <EnvironmentPublicPrompt :env="env" @confirm="handleEnvPrivacyChange" @close="close" />
      </template>
    </SidebarModal>
    <!-- </div> -->

    <div class="mt-3">
      <div>
        <label>Visibility : </label>
        <span class="font-bold">
          {{ _env.isPublic ? 'Public' : 'Private' }}
        </span>
      </div>
      <div class="mt-2">
        <button
          class="form-button bg-gray-400 dark:bg-gray-600"
          @click="openEnvironmentPublicPrompt"
        >
          <span class="text-black dark:text-white">
            Make Environment {{ _env.isPublic ? 'Private' : 'Public' }}
          </span>
        </button>
      </div>
    </div>

    <div class="mt-3">
      <span class="form-label">Organization Access</span>
      <PermissionManager
        v-model="_env.orgs"
        :org-model="models.api.Org"
        :user-model="models.api.User"
        type="orgs"
        @change="() => save_env('orgs')"
      />
      <span class="text-red-400">{{ errors.orgs }}</span>
    </div>

    <div class="mt-3">
      <span class="text-gray-700 dark:text-gray-400 font-medium">User Access</span>
      <PermissionManager
        v-model="_env.users"
        :org-model="models.api.Org"
        :user-model="models.api.User"
        type="users"
        @change="() => save_env('users')"
      />
      <span class="text-red-400">{{ errors.users }}</span>
    </div>
  </div>
</template>

<script>
import { PermissionManager } from '@rovit/permission-manager'
import { SidebarModal } from '@rovit/sidebar-modal'
import { models, useFind } from 'feathers-vuex'
import { ref, computed, toRefs, watch } from '@vue/composition-api'
import { handleClones } from '@rovit/utils/handle-clones'

import EnvironmentPublicPrompt from './../EnvironmentPublicPrompt/EnvironmentPublicPrompt'

export default {
  name: 'EnvironmentEditorAccess',
  components: {
    PermissionManager,
    SidebarModal,
    EnvironmentPublicPrompt
  },
  props: {
    env: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props, { useExisting: true })
    const { _env } = clones
    const { save_env } = saveHandlers
    const currentOrg = context.root.$store.getters['auth/currentOrg']
    /**
     * Toggle privacy modal methods
     */
    const isEnvironmentPublicPromptOpen = ref(false)
    function openEnvironmentPublicPrompt() {
      isEnvironmentPublicPromptOpen.value = true
    }
    function closeEnvironmentPublicPrompt() {
      isEnvironmentPublicPromptOpen.value = false
    }
    function handleEnvPrivacyChange() {
      Object.assign(_env.value, {
        isPublic: !_env.value.isPublic
      })
      save_env('isPublic')
      if (isEnvironmentPublicPromptOpen.value) {
        closeEnvironmentPublicPrompt()
      }
    }
    const errors = computed(() => {
      const { errorOnCreate, errorOnPatch } = context.root.$store.state.environments
      return (errorOnPatch && errorOnPatch.errors) || (errorOnCreate && errorOnCreate.errors) || {}
    })

    return {
      models,
      isEnvironmentPublicPromptOpen,
      openEnvironmentPublicPrompt,
      closeEnvironmentPublicPrompt,
      handleEnvPrivacyChange,
      ...clones,
      ...saveHandlers,
      errors
    }
  }
}
</script>

<style lang="postcss"></style>
