var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-navbar environment-navbar mt-1.5"},[_c('UserMenu',{staticClass:"mr-1"}),_c('router-link',{staticClass:"mr-2 back-button",attrs:{"to":"/"}},[_c('div',[_c('ArrowLeftIcon')],1),_vm._v(" Back ")]),_c('router-link',{staticClass:"link mr-2",class:{ active: _vm.$route.name === 'UserOrgs' },attrs:{"to":{
      name: 'UserOrgs',
      params: {
        envId: _vm.$route.params.envId
      }
    }}},[_c('div',[_c('CloudIcon')],1),_vm._v(" Orgs ")]),_c('router-link',{staticClass:"link mr-2",class:{ active: _vm.$route.name === 'UserProfile' },attrs:{"to":{
      name: 'UserProfile',
      params: {
        envId: _vm.$route.params.envId
      }
    }}},[_c('div',[_c('UsersIcon')],1),_vm._v(" Profile ")]),_c('router-link',{staticClass:"link mr-2",class:{ active: _vm.$route.name === 'UserSettings' },attrs:{"to":{
      name: 'UserSettings',
      params: {
        envId: _vm.$route.params.envId
      }
    }}},[_c('div',[_c('SettingsIcon')],1),_vm._v(" Settings ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }