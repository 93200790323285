import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'
import shouldFilterServiceKeys from '../../utils/fetch-variable-from-env'
import { iff, alterItems } from 'feathers-hooks-common'
import _pick from 'lodash/pick.js'

class ModuleComponent extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'ModuleComponent'
  // Define default properties here
  static instanceDefaults() {
    return {
      name: '',
      componentName: '',
      createdBy: null
    }
  }
}
const servicePath = 'module-components'
const servicePlugin = makeServicePlugin({
  Model: ModuleComponent,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [
      iff(
        context => ['create', 'update', 'patch'].includes(context.method),
        iff(
          shouldFilterServiceKeys(),
          alterItems(i => _pick(i, ['_id', 'name', 'componentName', 'createdAt', 'updatedAt']))
        )
      )
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
