<template>
  <Layout>
    <template #content>
      <NavbarEnvironmentService :env="currentEnv" :service="service" />

      <div class="px-12">
        <h1 class="content-heading-1">Service Team Permissions</h1>

        <p class="content-subheading">
          Edit the permissions for infobox editing for the
          <strong>{{ service && service.name }}</strong> service in the
          <strong>{{ currentEnv && currentEnv.name }}</strong> environment.
        </p>
      </div>
    </template>

    <template #sidebar> Sidebar </template>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout'
import { ref } from '@vue/composition-api'
import { getEnv } from '@/use/environments'
import { getService } from '@/use/services'

import NavbarEnvironmentService from '../components/NavbarEnvironmentService/NavbarEnvironmentService'

export default {
  name: 'EnvServiceTeam',
  metaInfo: {
    title: 'Env Services Team'
  },
  components: {
    Layout,
    NavbarEnvironmentService
  },
  setup(props, context) {
    const { env: currentEnv } = getEnv(context.root.$route.params.envId)
    const { service } = getService(context.root.$route.params.serviceId)

    return { currentEnv, service }
  }
}
</script>

<style lang="postcss" scoped></style>
