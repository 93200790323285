<template>
  <div>
    <TmScheduleEditor :env="env" :service="service" :scene="scene" :module="module" />
  </div>
</template>

<script>
import { TmScheduleEditor } from '@rovit/tm-schedule'
export default {
  name: 'ServiceEditorSchedule',
  components: {
    TmScheduleEditor
  },
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const env = {
      validator: val => true,
      default: null
    }
    const scene = {
      validator: val => true,
      default: null
    }
    const module = {
      validator: val => true,
      default: null
    }
    return {
      env,
      scene,
      module
    }
  }
}
</script>

<style lang="postcss"></style>
