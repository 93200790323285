import { models, useFind, useGet } from 'feathers-vuex'
import { ref, computed } from '@vue/composition-api'
import { unwrapRef } from './unwrap-ref.js'

export function getAsset({ id }) {
  const { Asset } = models.api
  const queryWhen = computed(() => {
    const existing = Asset.getFromStore(id.value)
    return id.value && !existing
  })
  const { item: asset } = useGet({
    model: Asset,
    id,
    queryWhen
  })

  return { asset }
}

export function findAssetsForEnv({ env, $sort }) {
  const { Asset } = models.api

  const params = computed(() => {
    const _env = unwrapRef(env)

    if (_env && _env._id) {
      const query = {
        envId: _env._id,
        $limit: 5000
      }
      if ($sort) {
        Object.assign(query, { $sort: unwrapRef($sort) })
      }
      return {
        query,
        $populateParams: {
          name: ''
        }
      }
    } else {
      return null
    }
  })
  const queryWhen = computed(() => {
    return true
  })
  const { items: assets, haveLoaded: assetsHaveLoaded } = useFind({
    model: Asset,
    params,
    queryWhen
  })

  return { assets, assetsHaveLoaded }
}
