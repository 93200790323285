<template>
  <div class="bg-white dark:bg-gray-800 p-4 inline-flex rounded-lg flex-col mt-4">
    <div v-if="itemsHaveLoaded">
      <span>The {{ itemType }} Library is currently empty.</span>
      <div class="flex flex-row items-center">
        <button
          class="button-with-icon bg-green-600 rounded-full mt-2 justify-center mx-auto focus:outline-none focus:shadow-outline"
          @click="$emit('button-click')"
        >
          <UploadCloudIcon size="1x" class="mr-2" />
          Import or Upload {{ itemType }}
        </button>
      </div>
    </div>
    <div v-else>
      <span>Loading {{ itemType }} Library ...</span>
    </div>
  </div>
</template>

<script>
import { UploadCloudIcon } from 'vue-feather-icons'
import { UploadCare, saveUploadAsModel } from '@rovit/uploadcare'
import XProgressCircle from '@ionomy/x-progress-circle'

export default {
  name: 'EnvLibEmptyState',
  components: {
    UploadCloudIcon
  },
  props: {
    itemType: {
      type: String,
      default: 'Item'
    },
    itemsHaveLoaded: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="postcss"></style>
