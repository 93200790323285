<template>
  <div class="sidebar-support">
    <h1 class="sidebar-heading-1 mt-2">Select a Section</h1>

    <p class="sidebar-body">Click a section to add it to the current scene.</p>

    <!-- <p class="sidebar-subheading font-bold mt-2">This is a subheading</p> -->

    <div class="flex flex-row flex-wrap -mx-1">
      <ModuleTile
        v-for="{ moduleComponent, preview } in components"
        :key="moduleComponent.componentName"
        :module-component="moduleComponent"
        :preview-tile="preview"
        class="leading-tight py-2 w-1/2 p-1"
        @click="event => $emit('add', { event, moduleComponent })"
      />
    </div>
  </div>
</template>

<script>
import { models, useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'
import _keyBy from 'lodash/keyBy'

import ModuleTile from './ModuleTile.vue'
import { TmServiceInfoPreviewTile } from '@rovit/tm-service-info'
import { TmContactInfoPreviewTile } from '@rovit/tm-contact-info'
import { TmSchedulePreviewTile } from '@rovit/tm-schedule'
import { TmDirectionsPreviewTile } from '@rovit/tm-directions'
import { TmLocationInfoPreviewTile } from '@rovit/tm-location-info'
import { TmSceneTourPreviewTile } from '@rovit/tm-scene-tour'
import { TmInfoPreviewTile } from '@rovit/tm-info'
import { TmAdvertisementPreviewTile } from '@rovit/tm-advertisement'
import { TmImagePreviewTile } from '@rovit/tm-image'
import { TmGalleryPreviewTile } from '@rovit/tm-gallery'
import { TmMapShowcasePreviewTile } from '@rovit/tm-map-showcase'
import { TmRecommendedItemsPreviewTile } from '@rovit/tm-recommended-items'
import { TmGoogleStreetViewPreviewTile } from '@rovit/tm-google-street-view'
import { TmSketchFabPreviewTile } from '@rovit/tm-sketch-fab'
import { TmVideoPreviewTile } from '@rovit/tm-video'
import { TmTrailForksTrailDetailsPreviewTile } from '@rovit/tm-trail-forks-trail-details'

export default {
  name: 'InfoboxSidebarSections',
  components: {
    ModuleTile
  },
  props: {},
  setup(props, context) {
    const { ModuleComponent } = models.api

    const previewTiles = [
      TmServiceInfoPreviewTile,
      TmContactInfoPreviewTile,
      TmSchedulePreviewTile,
      TmDirectionsPreviewTile,
      TmLocationInfoPreviewTile,
      TmSceneTourPreviewTile,
      TmInfoPreviewTile,
      TmAdvertisementPreviewTile,
      TmImagePreviewTile,
      TmGalleryPreviewTile,
      TmMapShowcasePreviewTile,
      TmRecommendedItemsPreviewTile,
      TmGoogleStreetViewPreviewTile,
      TmSketchFabPreviewTile,
      TmVideoPreviewTile,
      TmTrailForksTrailDetailsPreviewTile
    ]
    const previewTilesByName = _keyBy(previewTiles, 'name')

    const { items: moduleComponents } = useFind({
      model: ModuleComponent,
      params: {
        query: {
          $sort: { componentName: 1 }
        }
      }
    })

    const components = computed(() => {
      return moduleComponents.value
        .filter(mc => !!previewTilesByName[mc.componentName + 'PreviewTile'])
        .map(moduleComponent => {
          const preview = previewTilesByName[moduleComponent.componentName + 'PreviewTile']
          return { moduleComponent, preview }
        })
    }, [])

    return { components }
  }
}
</script>

<style lang="postcss"></style>
