<template>
  <Layout>
    <template #content>
      <NavbarService :service="service" />

      <div class="px-12">
        <div v-if="service" class="flex flex-row items-center">
          <h1 class="content-heading-1 mb-0">{{ service && service.name }} Details</h1>
        </div>

        <p class="content-subheading">
          This page shows all of your
          {{ currentOrg && currentOrg.name }} organization's services.
          <span v-if="user.isRovitAdmin"> As a Rovit Admin, you can see all services. </span>
          Create as many as you would like. You only pay to publish. Click a project to quick-edit.
          Double click to open.
        </p>

        <!-- Infoboxes -->
        <div v-if="service" class="flex flex-row items-center">
          <h1 class="content-heading-1 mb-0">Infoboxes</h1>
          <button class="icon-button ml-2" @click="openAddServiceSidebar">
            <PlusIcon />
          </button>
        </div>

        <p class="content-subheading">
          This page shows all of your
          {{ currentOrg && currentOrg.name }} organization's services.
          <span v-if="user.isRovitAdmin"> As a Rovit Admin, you can see all services. </span>
          Create as many as you would like. You only pay to publish. Click a project to quick-edit.
          Double click to open.
        </p>

        <GridSelector
          v-model="selectedInfobox"
          :items="infoboxes"
          :label="i => i.name"
          :src="i => makeUrl(i)"
          :fit="i => i.assetFit || 'contain'"
          class="mt-3 -mr-2"
          @item-click="handleInfoboxClick"
          @item-dblclick="handleInfoboxDblClick"
        />

        <!-- Categories -->
        <div v-if="service" class="flex flex-row items-center">
          <h1 class="content-heading-1 mb-0">Categories</h1>
          <button class="icon-button ml-2" @click="openAddServiceSidebar">
            <PlusIcon />
          </button>
        </div>

        <p v-if="service" class="content-subheading">
          Categorize the types of services offered at
          <strong>{{ service && service.name }}</strong
          >.
        </p>

        <!-- Environments -->
        <div v-if="service" class="flex flex-row items-center">
          <h1 class="content-heading-1 mb-0">Environments</h1>
          <button class="icon-button ml-2" @click="openAddServiceSidebar">
            <PlusIcon />
          </button>
        </div>

        <p class="content-subheading">
          The
          <strong>{{ service && service.name }}</strong> service shows in these Environments.
        </p>
      </div>
    </template>

    <template #sidebar>
      <div>
        <OrgServicesSidebarTabs
          @editor-tab-click="showMostRecentEditor"
          @add-service-click="openAddServiceSidebar"
        />

        <OrgServicesSidebarSupport v-if="sidebarName === 'support'" />

        <OrgServicesSidebarEditorBlank
          v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
        />
        <OrgServicesSidebarEditorService
          v-if="sidebarName === 'editor' && sidebarEditorType === 'env-service'"
        />

        <OrgServicesSidebarAddService
          v-if="sidebarName === 'add-service'"
          :services="services"
          @create-service="createService"
          @add-service="addServiceToEnv"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { models, useFind, useGet } from 'feathers-vuex'
import { findInfoboxesForService, infoboxSelection } from '@/use/scenes'
import useSidebarTabState from '@/use/sidebar-tab-state'

import Layout from '../layouts/Layout'
import { PlusIcon } from 'vue-feather-icons'
import NavbarService from '../components/NavbarService/NavbarService'
import GridSelector from '../components/GridSelector/GridSelector'

import OrgServicesSidebarTabs from '../components/OrgServices/SidebarTabs.vue'
import OrgServicesSidebarSupport from '../components/OrgServices/SidebarSupport.vue'
import OrgServicesSidebarEditorBlank from '../components/OrgServices/SidebarEditorBlank.vue'
import OrgServicesSidebarEditorService from '../components/OrgServices/SidebarEditorService.vue'
import OrgServicesSidebarAddService from '../components/OrgServices/SidebarAddService.vue'

export default {
  name: 'OrgServiceDetails',
  components: {
    Layout,
    PlusIcon,
    NavbarService,
    GridSelector,
    OrgServicesSidebarTabs,
    OrgServicesSidebarSupport,
    OrgServicesSidebarEditorBlank,
    OrgServicesSidebarEditorService,
    OrgServicesSidebarAddService
  },
  setup(props, context) {
    const { Environment, Service, EnvService } = models.api
    const selectedService = ref(null)
    const user = context.root.$store.state.auth.user
    const currentOrg = context.root.$store.getters['auth/currentOrg']
    const filterByOrg = ref(false)

    const { item: service } = useGet({
      model: Service,
      id: context.root.$route.params.serviceId
    })

    /* Find Infobox Scenes for current service (item) */
    const { items: infoboxes } = findInfoboxesForService({
      serviceId: context.root.$route.params.serviceId
    })

    /* Infobox Selection */
    const { selectedInfobox, selectInfobox, openInfobox } = infoboxSelection()

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      const { $route } = context.root
      if ($route.query.serviceId) {
        return 'env-service'
      } else {
        return 'blank'
      }
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      watchInQuery: ['serviceId'],
      context
    })

    /**
     * Create an EnvService record for the provided service and environent.
     */
    async function addEnvToService(service, environment) {
      const envService = await new EnvService({
        envId: environment.value._id,
        envName: environment.value.name,
        serviceId: service._id,
        serviceName: service.name
      }).save({
        $populateParams: {
          name: 'service'
        }
      })
      return envService
    }
    /**
     * Show the add service sidebar, which gives the user the option
     * to create a new service or search existing services.
     */
    async function openAddServiceSidebar() {
      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'add-service'
      })
      context.root.$router.push({ query }, () => {})
    }
    /**
     * Creates a new service with the currentOrg as the initial owner.
     */
    async function createService() {
      const newService = await new Service({
        name: 'New Service',
        orgs: [
          {
            orgId: currentOrg._id,
            orgName: currentOrg.name,
            accessType: 'owner'
          }
        ]
      }).save()

      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'editor',
        serviceId: newService._id
      })
      context.root.$router.push({ query })
    }
    /**
     * Handles the currently selected environment(s). A different sidebar is shown for
     * arrays vs single objects.
     */
    function handleInfoboxClick({ item, e }) {
      if (Array.isArray(selectedService.value)) {
        if (!selectedService.value.length) return
        // TODO: handle when multiple orgs are in `selectedService` array.
      } else {
        if (!selectedService.value) return
        openEditor({ serviceId: selectedService.value._id })
      }
    }
    /**
     * When an environment is double-clicked, switch to it and open its dashboard page.
     */
    function handleInfoboxDblClick({ item, e }) {
      context.root.$router.push({
        name: 'Environment',
        params: { serviceId: item._id }
      })
    }

    function makeUrl(i) {
      return i.assetUrl ? `//images.weserv.nl/?url=${i.assetUrl}&w=660&h=330&fit=inside&il` : ''
    }

    return {
      service,
      infoboxes,
      selectedInfobox,
      showMostRecentEditor,
      sidebarName,
      sidebarEditorType,
      selectedService,
      openAddServiceSidebar,
      addServiceToEnv,
      createService,
      handleInfoboxClick,
      handleInfoboxDblClick,
      user,
      currentOrg,
      filterByOrg,
      makeUrl
    }
  }
}
</script>

<style lang="postcss" scoped></style>
