<template>
  <Layout>
    <template #content>
      <NavbarEnvironment :env="currentEnv" />

      <div class="px-12">
        <h1 class="content-heading-1">Environment Library</h1>

        <p class="content-subheading">
          Browse and build the asset libraries for the
          <strong>{{ currentEnv && currentEnv.name }}</strong> environment.
        </p>

        <div class="flex flex-row mt-3 -m-1">
          <!-- Icons -->
          <router-link
            :to="{
              name: 'EnvLibIcons',
              params: {
                envId: currentEnv && currentEnv._id
              }
            }"
            class="flex-1 mx-1"
          >
            <div class="h-32 library-button">
              <MapPinIcon class="w-10 h-10" />
            </div>
            <p class="mt-2 mb-1 text-2xl font-medium leading-none">Icons</p>
            <p class="leading-tight">Build an icon set for clear communication.</p>
          </router-link>

          <!-- Categories -->
          <router-link
            :to="{
              name: 'EnvLibCategories',
              params: {
                envId: currentEnv && currentEnv._id
              }
            }"
            class="flex-1 mx-1"
          >
            <div class="h-32 library-button">
              <ListIcon class="w-10 h-10" />
            </div>
            <p class="mt-2 mb-1 text-2xl font-medium leading-none">Categories</p>
            <p class="leading-tight">Choose categories to organize services.</p>
          </router-link>

          <!-- Badges -->
          <router-link
            :to="{
              name: 'EnvLibBadges',
              params: {
                envId: currentEnv && currentEnv._id
              }
            }"
            class="flex-1 mx-1"
          >
            <div class="h-32 library-button">
              <AwardIcon class="w-10 h-10" />
            </div>
            <p class="mt-2 mb-1 text-2xl font-medium leading-none">Badges</p>
            <p class="leading-tight">Setup badges to describe & attribute services.</p>
          </router-link>

          <!-- Photos -->
          <router-link
            :to="{
              name: 'EnvLibPhotos',
              params: {
                envId: currentEnv && currentEnv._id
              }
            }"
            class="flex-1 mx-1"
          >
            <div class="h-32 library-button">
              <ImageIcon class="w-10 h-10" />
            </div>
            <p class="mt-2 mb-1 text-2xl font-medium leading-none">Photo Library</p>
            <p class="leading-tight">Manage photos to promote services.</p>
          </router-link>

          <!-- Panos -->
          <router-link
            :to="{
              name: 'EnvLibPanos',
              params: {
                envId: currentEnv && currentEnv._id
              }
            }"
            class="flex-1 mx-1"
          >
            <div class="h-32 library-button">
              <PanoIcon class="w-10 h-10" />
            </div>
            <p class="mt-2 mb-1 text-2xl font-medium leading-none">Panos</p>
            <p class="leading-tight">Paroramic images for 360° views and tours.</p>
          </router-link>

          <!-- Linked Environments -->
          <router-link
            :to="{
              name: 'EnvLibLinkedEnvs',
              params: {
                envId: currentEnv && currentEnv._id
              }
            }"
            class="flex-1 mx-1"
          >
            <div class="h-32 library-button">
              <GlobeIcon class="w-10 h-10" />
            </div>
            <p class="mt-2 mb-1 text-2xl font-medium leading-none">Linked Envs.</p>
            <p class="leading-tight">Connect with other environments.</p>
          </router-link>
        </div>

        <hr class="pb-1 my-4 border-t border-gray-200" />

        <!-- Rovit Store -->
        <router-link
          :to="{
            name: 'EnvLibStore',
            params: {
              envId: currentEnv && currentEnv._id
            }
          }"
        >
          <p class="mt-4 text-2xl font-medium leading-none">Rovit Store</p>
          <p class="mb-1 leading-tight">Browse for resources to start your environment.</p>
          <div class="h-32 library-button">
            <ShoppingCartIcon class="w-10 h-10" />
          </div>
        </router-link>
      </div>
    </template>

    <template #sidebar> Environment Sidebar </template>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout'
import { ref } from '@vue/composition-api'
import { getEnv } from '@/use/environments'

import NavbarEnvironment from '../components/NavbarEnvironment/NavbarEnvironment'
import { PanoIcon } from '@rovit/icons'
import {
  ListIcon,
  AwardIcon,
  MapPinIcon,
  ShoppingCartIcon,
  ImageIcon,
  GlobeIcon
} from 'vue-feather-icons'

export default {
  name: 'EnvironmentLibrary',
  metaInfo: {
    title: 'Environment Library'
  },
  components: {
    Layout,
    NavbarEnvironment,
    ListIcon,
    AwardIcon,
    MapPinIcon,
    ShoppingCartIcon,
    ImageIcon,
    GlobeIcon,
    PanoIcon
  },
  setup(props, context) {
    const { env: currentEnv } = getEnv(context.root.$route.params.envId)

    return { currentEnv }
  }
}
</script>

<style lang="postcss" scoped>
.library-button {
  @apply rounded bg-gray-200 transition-colors duration-200 border border-gray-300 shadow p-3 leading-none flex flex-row items-center justify-center;
}
.library-button:hover {
  @apply bg-green-200 border-green-300;
}
@screen dark {
  .library-button {
    @apply bg-gray-800 border-gray-700;
  }
  .library-button:hover {
    @apply bg-green-700 border-green-600;
  }
}
</style>
