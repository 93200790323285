var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-tabs"},[_c('button',{staticClass:"combo-left",class:{
      active: _vm.currentTab === 'info'
    },attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('input', 'info')}}},[_vm._v(" Info ")]),_c('button',{staticClass:"combo-middle",class:{
      active: _vm.currentTab === 'service'
    },attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('input', 'service')}}},[_vm._v(" Service ")]),_c('button',{staticClass:"combo-middle",class:{
      active: _vm.currentTab === 'environments'
    },attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('input', 'environments')}}},[_vm._v(" Environments ")]),_c('button',{staticClass:"combo-middle",class:{
      active: _vm.currentTab === 'permissions'
    },staticStyle:{"border-left":"none"},attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('input', 'permissions')}}},[_vm._v(" Permissions ")]),_c('button',{staticClass:"combo-right",class:{
      active: _vm.currentTab === 'danger'
    },staticStyle:{"border-left":"none"},attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('input', 'danger')}}},[_c('AlertTriangleIcon',{staticClass:"p-0.5",class:[_vm.currentTab === 'danger' ? 'text-white' : 'text-yellow-700']})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }