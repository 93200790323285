<template>
  <Layout>
    <template #content>
      <NavbarAdmin />

      <div class="px-12">
        <div class="flex flex-row items-center">
          <h1 class="content-heading-1 mb-0">{{ currentOrg.name }} Services</h1>
          <button class="icon-button ml-2" @click="openAddServiceSidebar">
            <PlusIcon />
          </button>
        </div>

        <p class="content-subheading">
          This page shows all of your {{ currentOrg.name }} organization's services.
          <span v-if="user.isRovitAdmin">
            As a Rovit Admin, you can see all services.
          </span>
          Create as many as you would like. You only pay to publish. Click a project to quick-edit.
          Double click to open.
        </p>

        <div class="flex flex-row items-center mt-2">
          <label class="block w-full">
            <!-- <span class="text-gray-700">Search</span> -->
            <input v-model="serviceSearch" class="form-input block w-full" placeholder="Search" />
          </label>

          <!-- Grid or Table View -->
          <select v-model="viewType" class="form-select ml-2 w-1/5">
            <option value="grid">Grid</option>
            <option value="table">Table</option>
          </select>

          <!-- Filter to currentOrg -->
          <select v-if="user.isRovitAdmin" v-model="filterByOrg" class="form-select ml-2 w-1/5">
            <option :value="false">All</option>
            <option :value="currentOrg">{{ currentOrg.name }}</option>
          </select>
        </div>

        <FeathersVuexPagination v-model="pagination" :latest-query="latestQuery">
          <template
            #default="{ currentPage, pageCount, toStart, toEnd, toPage, next, prev, canNext, canPrev }"
          >
            <SidebarPagination
              :current-page="currentPage"
              :page-count="pageCount"
              :can-prev="canPrev"
              :can-next="canNext"
              @to-start="toStart"
              @to-end="toEnd"
              @to-page="toPage"
              @next="next"
              @prev="prev"
            />
          </template>
        </FeathersVuexPagination>

        <GridSelector
          v-if="viewType === 'grid'"
          v-model="selectedService"
          :items="services"
          :label="i => i.name"
          :src="i => thumbnail(i.primaryAssetUrl).url"
          :fit="i => i && i.primaryAssetFit"
          class="mt-3 -mr-2"
          @item-click="handleServiceClick"
          @item-dblclick="handleServiceDblClick"
        />

        <TableSelector v-else v-model="selectedService" :items="services">
          <template #thead>
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Orgs</th>
                <th>Location</th>
                <th class="px-1">Infoboxes</th>
              </tr>
            </thead>
          </template>

          <template #default="{ item, isSelected, select }">
            <OrgServicesTableRow
              :service="item"
              :is-selected="isSelected"
              :select="select"
              @item-click="handleServiceClick"
              @item-dblclick="handleServiceDblClick"
            />
          </template>
        </TableSelector>

        <table>
          <tbody></tbody>
        </table>
      </div>
    </template>

    <template #sidebar>
      <div>
        <OrgServicesSidebarTabs
          @editor-tab-click="showMostRecentEditor"
          @add-service-click="openAddServiceSidebar"
        />

        <OrgServicesSidebarSupport v-if="sidebarName === 'support'" />

        <OrgServicesSidebarEditorBlank
          v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
        />
        <OrgServicesSidebarEditorService
          v-if="sidebarName === 'editor' && sidebarEditorType === 'env-service'"
        />

        <OrgServicesSidebarAddService
          v-if="sidebarName === 'add-service'"
          :services="services"
          @create-service="handleCreateService"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import useSidebarTabState from '@/use/sidebar-tab-state'
import { createService, findServices } from '@/use/services'
import { createEnvService } from '@/use/env-services.js'
import { thumbnail } from '@/use/image-transforms/index'
import { SidebarPagination } from '@rovit/sidebar-pagination'

import Layout from '../../layouts/Layout'
import { PlusIcon } from 'vue-feather-icons'
import NavbarAdmin from '../NavbarAdmin/NavbarAdmin.vue'
import GridSelector from '../GridSelector/GridSelector.vue'
import TableSelector from '../TableSelector/TableSelector.vue'
import OrgServicesTableRow from './OrgServicesTableRow.vue'

import OrgServicesSidebarTabs from '../OrgServices/SidebarTabs.vue'
import OrgServicesSidebarSupport from '../OrgServices/SidebarSupport.vue'
import OrgServicesSidebarEditorBlank from '../OrgServices/SidebarEditorBlank.vue'
import OrgServicesSidebarEditorService from '../OrgServices/SidebarEditorService.vue'
import OrgServicesSidebarAddService from '../OrgServices/SidebarAddService.vue'

export default {
  name: 'OrgServices',
  metaInfo: {
    title: 'Services'
  },
  components: {
    Layout,
    PlusIcon,
    NavbarAdmin,
    GridSelector,
    TableSelector,
    SidebarPagination,
    OrgServicesTableRow,
    OrgServicesSidebarTabs,
    OrgServicesSidebarSupport,
    OrgServicesSidebarEditorBlank,
    OrgServicesSidebarEditorService,
    OrgServicesSidebarAddService
  },
  setup(props, context) {
    const { Environment, Service, EnvService } = models.api
    const selectedService = ref(null)
    const user = context.root.$store.state.auth.user
    const currentOrg = context.root.$store.getters['auth/currentOrg']
    const viewType = ref('table')

    /* Find Services */
    const serviceSearch = ref('')
    const pagination = ref({
      $limit: 24,
      $skip: 0
    })
    const sort = { name: 1 }
    const filterByOrg = ref(false)
    const populate = ref('adminOrgServices')
    const { services, latestQuery } = findServices({
      search: serviceSearch,
      sort,
      org: filterByOrg,
      populate,
      pagination
    })

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      return context.root.$route.query.serviceId ? 'env-service' : 'blank'
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      watchInQuery: ['serviceId'],
      context
    })

    /**
     * Create an EnvService record for the provided service and environent.
     */
    async function handleAddServiceToEnv(service, env) {
      createEnvService({ service, env })
    }

    /**
     * Creates a new service with the currentOrg as the initial owner.
     */
    async function handleCreateService() {
      const { service } = await createService({ org: currentOrg })
      openServiceInSidebar(service)
    }

    /**
     * Open the provided service in the sidebar
     */
    function openServiceInSidebar(service) {
      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'editor',
        serviceId: service._id
      })
      context.root.$router.push({ query }, () => {})
    }

    /**
     * Show the add service sidebar, which gives the user the option
     * to create a new service or search existing services.
     */
    async function openAddServiceSidebar() {
      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'add-service'
      })
      context.root.$router.push({ query }, () => {})
    }

    /**
     * Handles the currently selected environment(s). A different sidebar is shown for
     * arrays vs single objects.
     */
    function handleServiceClick({ item, e }) {
      if (Array.isArray(selectedService.value)) {
        if (!selectedService.value.length) {
          return
        }

        // TODO: handle when multiple orgs are in `selectedService` array.
      } else {
        if (!selectedService.value) {
          // TODO WORKAROUND FOR OrgServicesTableRow
          // selectedService v-model is not being updated from TableSelector.vue
          // using item to open in sidebar
          if (item) {
            openServiceInSidebar(item)
          }
          return
        }
        openServiceInSidebar(selectedService.value)
      }
    }
    /**
     * When an environment is double-clicked, switch to it and open its dashboard page.
     */
    function handleServiceDblClick({ item, e }) {
      openService(item)
    }

    function openService(service) {
      const route = {
        name: 'OrgServiceInfoboxes',
        params: { serviceId: service._id }
      }
      context.root.$router.push(route, () => {})
    }

    return {
      serviceSearch,
      services,
      showMostRecentEditor,
      sidebarName,
      sidebarEditorType,
      selectedService,
      openAddServiceSidebar,
      handleAddServiceToEnv,
      handleCreateService,
      handleServiceClick,
      handleServiceDblClick,
      user,
      viewType,
      currentOrg,
      filterByOrg,

      // Pagination
      latestQuery,
      pagination
    }
  }
}
</script>

<style lang="postcss" scoped></style>
