<template>
  <div class="sidebar-add-service">
    <h1 class="sidebar-heading-1 mt-2">Start from Scratch</h1>
    <p class="sidebar-subheading">
      Get started on a fresh, clean infobox.
    </p>

    <button type="button" class="form-button primary mt-2" @click="createBlankInfobox">
      Create Empty Infobox
    </button>

    <h1 class="sidebar-heading-1 mt-4">Start from a Template</h1>
    <p class="sidebar-subheading">
      Coming Later
    </p>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import keyBy from 'lodash/keyBy'
import GridSelector from '../GridSelector/GridSelector'

export default {
  name: 'OrgServiceSidebarAddInfobox',
  components: {
    // GridSelector
  },
  props: {
    services: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const { Service } = models.api

    function createBlankInfobox() {
      context.emit('create-blank-infobox')
    }

    return {
      createBlankInfobox
    }
  }
}
</script>

<style lang="postcss"></style>
