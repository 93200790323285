var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-tabs"},[_c('router-link',{staticClass:"combo-left",class:{
      active: _vm.$route.query.serviceSection === 'info'
    },attrs:{"tag":"button","type":"button","to":_vm.routeFor('info')}},[_vm._v(" Info ")]),_c('router-link',{staticClass:"combo-middle",class:{
      active: _vm.$route.query.serviceSection === 'infoboxes'
    },attrs:{"tag":"button","type":"button","to":_vm.routeFor('infoboxes')}},[_vm._v(" Infoboxes ")]),_c('router-link',{staticClass:"combo-middle",class:{
      active: _vm.$route.query.serviceSection === 'location'
    },attrs:{"tag":"button","type":"button","to":_vm.routeFor('location')}},[_vm._v(" Location ")]),_c('router-link',{staticClass:"combo-middle",class:{
      active: _vm.$route.query.serviceSection === 'contact'
    },attrs:{"tag":"button","type":"button","to":_vm.routeFor('contact')}},[_vm._v(" Contact ")]),_c('router-link',{staticClass:"combo-middle",class:{
      active: _vm.$route.query.serviceSection === 'schedule'
    },staticStyle:{"border-left":"0"},attrs:{"tag":"button","type":"button","to":_vm.routeFor('schedule')}},[_vm._v(" Schedule ")]),_c('router-link',{staticClass:"combo-right",class:{
      active: _vm.$route.query.serviceSection === 'danger'
    },staticStyle:{"border-left":"0"},attrs:{"tag":"button","type":"button","to":_vm.routeFor('danger')}},[_c('AlertTriangleIcon',{staticClass:"p-0.5",class:[_vm.$route.query.serviceSection === 'danger' ? 'text-white' : 'text-yellow-700']})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }