// src/store/store.js
import Vue from 'vue'
import Vuex from 'vuex'
import { FeathersVuex } from '../feathers-client'
import auth from './store.auth'

Vue.config.devtools = true
Vue.use(Vuex)
Vue.use(FeathersVuex)

const requireModule = require.context('./services', false, /.js$/)
const servicePlugins = requireModule.keys().map(modulePath => requireModule(modulePath).default)

export default new Vuex.Store({
  state: {
    uploadcarePublicKey: 'f6ddda56c94aa9fd782f',
    mapboxToken: process.env.VUE_APP_MAPBOX_PUBLIC_TOKEN,
    stripeKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    uploadUrlEndpoint: process.env.VUE_APP_ROVIT_API_URL + '/upload-urls'
  },
  mutations: {},
  actions: {},
  plugins: [...servicePlugins, auth]
})
