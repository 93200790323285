<template>
  <div class="sidebar-tabs">
    <!-- Support -->
    <router-link
      :to="{ query: { sidebar: 'support' } }"
      :class="{
        active: $route.query.sidebar === 'support' || !$route.query.sidebar
      }"
    >
      <HelpCircleIcon />
    </router-link>

    <!-- Service -->
    <button
      type="button"
      class="combo-left"
      :class="{
        active: $route.query.sidebar === 'editor'
      }"
      @click="$emit('editor-tab-click')"
    >
      Service
    </button>

    <!-- Add Service -->
    <button
      type="button"
      class="combo-right icon"
      :class="{
        active: $route.query.sidebar === 'add-service'
      }"
      @click="$emit('add-service-click')"
    >
      <PlusIcon />
    </button>
  </div>
</template>

<script>
import { PlusIcon, HelpCircleIcon } from 'vue-feather-icons'

export default {
  name: 'EnvServicesSidebarTabs',
  components: {
    PlusIcon,
    HelpCircleIcon
  },
  props: {}
}
</script>

<style lang="postcss"></style>
