<template>
  <div>
    <h2 class="text-xl font-bold">Create / Add to Scene</h2>
    <div class="px-3 pt-2 pb-3 rounded border-gray-300 dark:border-gray-600 border">
      <div>
        <label>Service</label>
        <ServiceSelect v-model="service" />
      </div>

      <!-- Select Infobox -->
      <div v-if="service" class="mt-2">
        <label>Infobox</label>
        <div class="flex flex-row items-center">
          <XSelect
            v-model="infobox"
            :items="infoboxes"
            :label="i => makeLabel(i)"
            placeholder="Select Infobox"
            class="flex-grow"
            :create="handleCreateInfobox"
          />
          <button
            type="button"
            class="form-button ml-1 py-1"
            :class="[infobox ? (infobox.panoId ? 'bg-red-600' : 'primary') : 'bg-gray-500']"
            :disabled="!infobox"
            @click="() => applyPanoTo(infobox)"
          >
            {{ infobox && infobox.panoId ? 'Replace Pano' : 'Add Pano' }}
          </button>
        </div>
      </div>

      <!-- Select a scene -->
      <div v-if="service && infobox" class="mt-2">
        <label>Child Scene</label>
        <div class="flex flex-row items-center">
          <XSelect
            v-model="scene"
            :items="scenesForInfobox"
            :label="i => makeLabel(i)"
            :create="handleCreateScene"
            placeholder="Select Child Scene"
            class="flex-grow"
          />
          <button
            type="button"
            class="form-button primary ml-1 py-1"
            :class="[scene ? (scene.panoId ? 'bg-red-600' : 'primary') : 'bg-gray-500']"
            :disabled="!scene"
            @click="() => applyPanoTo(scene)"
          >
            {{ scene && scene.panoId ? 'Replace Pano' : 'Add Pano' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import {
  findInfoboxesForService,
  findScenesForInfobox,
  createInfobox,
  createSceneForInfobox
} from '@/use/scenes.js'
import { addEnvToService } from '@/use/services.js'
import { addEnvToScene } from '@/use/scenes.js'
import _keyBy from 'lodash/keyBy.js'

import ServiceSelect from '../ServiceSelect.vue'
import { XSelect } from '@rovit/x-select'

export default {
  name: 'CreateSceneFromPano',
  components: {
    ServiceSelect,
    XSelect
  },
  props: {
    pano: {
      type: Object,
      required: true
    },
    env: {
      type: Object,
      required: true
    },
    /**
     * all scenes in the current environment
     */
    scenes: {
      type: Array,
      required: true
    }
  },
  setup(props, context) {
    // Service
    const service = ref(null)

    // Infoboxes
    const { infoboxes } = findInfoboxesForService({ service })
    const infobox = ref(null)

    // Scenes
    const scene = ref(null)
    const {
      scenes: scenesForInfobox,
      haveScenesBeenRequested,
      haveScenesLoaded
    } = findScenesForInfobox({ infobox })
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    const scenesById = computed(() => _keyBy(props.scenes, '_id'))
    function makeLabel(scene) {
      let label = `${scene.infoboxName || scene.name}`
      // Mark if the scene already has a pano.
      if (scene.panoId) {
        label += ' 🌅'
      }
      // Mark if scene is not in the current environment.
      if (!scenesById[scene._id]) {
        label += ' ⚠️'
      }

      return label
    }

    watch(
      () => service.value,
      () => (infobox.value = null),
      { immediate: true }
    )
    watch(
      () => infobox.value,
      () => (scene.value = null),
      { immediate: true }
    )

    /**
     * Creates an infobox then adds the service/infobox combo to the environment
     * by updating the service's envsMeta.
     */
    async function handleCreateInfobox(name) {
      const { infobox } = await createInfobox({
        name,
        service: service.value,
        envsMeta: [{ envId: props.env._id, envName: props.env.name }],
        org: currentOrg
      })
      const { envService } = await addEnvToService({ service, env: props.env })
      return infobox
    }

    async function handleCreateScene(name) {
      const { scene } = await createSceneForInfobox({
        name,
        infobox: infobox.value,
        envsMeta: [{ envId: props.env._id, envName: props.env.name }]
      })
      return scene
    }

    async function applyPanoTo(scene) {
      const data = {
        panoId: props.pano._id,
        panoName: props.pano.name,
        panoUrl: props.pano.url
      }
      // If the current env is not in envsMeta, add it.
      await addEnvToScene({ scene, env: props.env, save: false })
      scene.clone(data).save({ data })
    }

    return {
      service,
      makeLabel,

      // Infoboxes
      infoboxes,
      infobox,
      handleCreateInfobox,

      // Scenes
      scenesForInfobox,
      scene,
      handleCreateScene,

      applyPanoTo
    }
  }
}
</script>

<style lang="postcss"></style>
