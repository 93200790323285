<template>
  <table>
    <slot name="thead" />

    <XDataSelector v-model="selectedProxy" tag="tbody" :items="items" :multiple="multiple">
      <template #default="props">
        <slot name="default" v-bind="props" />
      </template>

      <template #no-items>
        <slot name="no-items">
          GridSelector #no-items
        </slot>
      </template>
    </XDataSelector>

    <slot name="tfoot" />
  </table>
</template>

<script>
import { XDataSelector } from '@rovit/x-data-selector'
import ProjectTile from '../ProjectTile/ProjectTile'
import { ref, watch, computed } from '@vue/composition-api'
import get from 'lodash/get'

export default {
  name: 'TableSelector',
  components: {
    XDataSelector
  },
  props: {
    value: {
      type: [Array, Object],
      default: () => null
    },
    items: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const selected = ref(null)

    const selectedProxy = computed({
      get: () => props.value,
      set: val => context.emit('input', val)
    })

    function handleItemClick({ e, item, select }) {
      select()
      setTimeout(() => context.emit('item-click', { e, item }), 0)
    }

    return { selectedProxy, handleItemClick }
  }
}
</script>

<style lang="postcss">
.project-tile-wrapper {
  @apply w-full pr-2 mb-2;
}
@screen sm {
  .project-tile-wrapper {
    @apply min-w-1/2 max-w-1/2;
  }
}
@screen md {
  .project-tile-wrapper {
    @apply min-w-1/2 max-w-1/2;
  }
}
@screen lg {
  .project-tile-wrapper {
    @apply min-w-1/3 max-w-1/3;
  }
}
@screen xl {
  .project-tile-wrapper {
    @apply min-w-1/4 max-w-1/4;
  }
}
@media (min-width: 1600px) {
  .project-tile-wrapper {
    @apply min-w-1/5 max-w-1/5;
  }
}
@media (min-width: 1800px) {
  .project-tile-wrapper {
    @apply min-w-1/6 max-w-1/6;
  }
}
</style>
