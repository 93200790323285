<template>
  <div class="admin-navbar">
    <UserMenu class="mr-1" />

    <router-link to="/" class="link mr-2.5" :class="{ active: $route.name === 'OrgHome' }">
      <div>
        <HomeIcon />
      </div>
      Home
    </router-link>

    <router-link
      :to="{ name: 'OrgEnvironments' }"
      class="link"
      :class="{ active: $route.name === 'OrgEnvironments' }"
    >
      <div>
        <GlobeIcon />
      </div>
      Environs
    </router-link>

    <div class="text-green-900 mt-4">
      <ChevronRightIcon />
    </div>

    <!-- Environment Name -->
    <router-link
      :to="{
        name: 'EnvironmentServices',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link active-crumb mr-2"
      style="max-width: 92px;"
      :class="{ active: $route.name === 'OrgProjects' }"
    >
      <div class="flex flex-row justify-center bg-green-200">
        <EditIcon />
      </div>

      <VClamp autoresize :max-lines="2" class="leading-none mt-1" tag="p">
        {{ env && env.name }}
      </VClamp>
    </router-link>

    <!-- Divider -->
    <div class="navbar-divider mr-2"></div>

    <!-- Environment Services -->
    <router-link
      :to="{
        name: 'EnvironmentServices',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page mr-1.5"
      :class="{ active: $route.name === 'EnvironmentServices' }"
    >
      <div style="max-width: 52px; margin: 0 auto;">
        <BriefcaseIcon />
      </div>
      Services
    </router-link>

    <!-- Environment Library -->
    <router-link
      :to="{
        name: 'EnvironmentLibrary',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvironmentLibrary' }"
    >
      <div>
        <BookIcon />
      </div>
      Library
    </router-link>

    <!-- Environment Details -->
    <router-link
      :to="{
        name: 'EnvironmentFrontPage',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvironmentFrontPage' }"
    >
      <div class="flex flex-row justify-center">
        <FileTextIcon />
      </div>
      <p class="leading-none mt-1">Front <br />Page</p>
    </router-link>

    <router-link
      :to="{
        name: 'EnvironmentAnalytics',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvironmentAnalytics' }"
    >
      <div>
        <BarChart2Icon />
      </div>
      Analytics
    </router-link>

    <!-- Team -->
    <router-link
      :to="{
        name: 'EnvironmentTeam',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvironmentTeam' }"
    >
      <div>
        <UsersIcon />
      </div>
      Team
    </router-link>

    <!-- Billing -->
    <router-link
      :to="{
        name: 'EnvironmentBilling',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvironmentBilling' }"
    >
      <div>
        <CreditCardIcon />
      </div>
      Billing
    </router-link>
  </div>
</template>

<script>
import UserMenu from '../UserMenu/UserMenu'
import VClamp from 'vue-clamp'
import {
  HomeIcon,
  BriefcaseIcon,
  BookIcon,
  InfoIcon,
  EditIcon,
  GlobeIcon,
  ChevronRightIcon,
  FileTextIcon,
  BarChart2Icon,
  UsersIcon,
  CreditCardIcon
} from 'vue-feather-icons'

export default {
  name: 'NavbarEnvironment',
  components: {
    UserMenu,
    HomeIcon,
    BriefcaseIcon,
    GlobeIcon,
    BookIcon,
    EditIcon,
    ChevronRightIcon,
    FileTextIcon,
    BarChart2Icon,
    UsersIcon,
    CreditCardIcon,
    VClamp
  },
  props: {
    env: {
      type: Object,
      default: () => null
    }
  }
}
</script>

<style lang="postcss"></style>
