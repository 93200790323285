<template>
  <div>
    <XPopper placement="bottom-start" offset="8, 2">
      <template v-slot:reference="{ toggle }">
        <div ref="reference" class="flex flex-row items-center" @click.stop="toggle">
          <UserAvatar :picture="auth0User.picture" class="cursor-pointer hover:opacity-75" />
          <ChevronDownIcon class="w-4 h-4 cursor-pointer" />
        </div>
      </template>

      <template v-slot:popper="{ toggle }">
        <div
          ref="popper"
          v-click-outside="toggle"
          class="bg-gray-200 rounded text-black z-50 overflow-hidden select-none shadow border border-gray-400"
        >
          <p class="px-3 pt-2 font-bold block">
            {{ auth0User.given_name }} {{ auth0User.family_name }}
          </p>
          <p class="text-sm px-3 -mt-1 block">
            {{ auth0User.email }}
          </p>
          <p class="text-xs px-3 mt-1 block select-text">UserID: {{ user._id }}</p>

          <div class="border-b border-gray-500 mt-1" />

          <p class="text-left px-3 py-1.5 w-full border-t border-gray-300 pr-6 font-bold block">
            {{ $store.getters['auth/currentOrg'].name }}
          </p>

          <router-link to="/orgs" class="user-menu-link short text-blue-600">
            Switch Orgs
          </router-link>

          <div class="border-b border-gray-500" />

          <router-link to="/profile" class="user-menu-link short text-blue-600">
            Profile
          </router-link>

          <div class="border-b border-gray-500" />

          <!-- Logout -->
          <button type="button" class="user-menu-link" @click="logout">
            Logout
          </button>
        </div>
      </template>
    </XPopper>
  </div>
</template>

<script>
import { UserAvatar } from '@rovit/user-avatar'
import { XPopper } from '@ionomy/x-popper'
import { ChevronDownIcon } from 'vue-feather-icons'
import useAuth from '@/use/auth'
import { ref } from '@vue/composition-api'

export default {
  name: 'UserMenu',
  components: {
    UserAvatar,
    XPopper,
    ChevronDownIcon
  },
  props: {},
  // eslint-disable-next-line
  setup(props, context) {
    const user = context.root.$store.state.auth.user
    const auth0User = context.root.$store.state.auth.auth0User
    const { logout } = useAuth()

    function switchOrg(org) {
      console.log('implement org switching')
    }

    return { user, auth0User, logout, switchOrg }
  }
}
</script>

<style lang="postcss" scoped>
.user-menu-link {
  @apply text-left px-3 py-2.5 w-full border-t border-gray-300 pr-6;
}
.user-menu-link.short {
  @apply py-1.5;
}
a.user-menu-link {
  @apply block;
}
.user-menu-link:hover {
  @apply bg-gray-400;
}
</style>
