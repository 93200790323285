<template>
  <div class="edit-item">
    <FrontPageItemEditor
      v-if="frontPageItem"
      class="mt-3"
      :item="frontPageItem"
      @remove="handleRemove"
    />
    <div v-else>
      Loading Front Page Item
    </div>
  </div>
</template>

<script>
import { models } from 'feathers-vuex'
import { computed } from '@vue/composition-api'
import { getFrontPageItem } from '@/use/front-page-items.js'

import FrontPageItemEditor from './FrontPageItemEditor.vue'

export default {
  name: 'EnvFrontPageSidebarEditItem',
  components: {
    FrontPageItemEditor
  },
  setup(props, context) {
    // Current Badge
    const frontPageItemId = computed(() => context.root.$route.query.frontPageItemId)

    const { frontPageItem } = getFrontPageItem(frontPageItemId)
    const envId = context.root.$route.params.envId
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    function handleRemove() {}

    return {
      frontPageItem,
      models,
      envId,
      currentOrg,
      handleRemove
    }
  }
}
</script>

<style lang="postcss"></style>
