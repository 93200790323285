<template>
  <div class="sidebar-support">
    EnvFrontPageSidebarSupport
  </div>
</template>

<script>
export default {
  name: 'EnvFrontPageSidebarSupport',
  props: {}
}
</script>

<style lang="postcss"></style>
