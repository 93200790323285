<template>
  <div class="sidebar-tabs">
    <!-- Support -->
    <router-link
      :to="{ query: { sidebar: 'support' } }"
      :class="{
        active: $route.query.sidebar === 'support' || !$route.query.sidebar
      }"
    >
      <HelpCircleIcon />
    </router-link>

    <!-- Scene -->
    <button
      type="button"
      class="combo-left"
      :class="{
        active: $route.query.sidebar === 'editor'
      }"
      @click="$emit('editor-tab-click')"
    >
      Scene
    </button>

    <!-- Add Scene -->
    <button
      type="button"
      class="combo-right icon"
      :class="{
        active: $route.query.sidebar === 'add-scene'
      }"
      @click="$emit('add-scene-click')"
    >
      <PlusIcon />
    </button>
  </div>
</template>

<script>
import { PlusIcon, HelpCircleIcon } from 'vue-feather-icons'

export default {
  name: 'EnvScenesSidebarTabs',
  components: {
    PlusIcon,
    HelpCircleIcon
  },
  props: {}
}
</script>

<style lang="postcss"></style>
