<template>
  <div>
    <input
      class="form-input block w-full"
      :value="value"
      placeholder="Mapbox token"
      @change="handleChange"
      @blur="handleBlur"
    />
    <div class="text-sm mt-1">
      Be sure to enable <span style="font-family: monospace;">{{ getOrigin() }}</span>
      for this key in
      <a href="https://account.mapbox.com/" target="_blank" rel="noopener noreferrer">
        <u>your Mapbox account</u>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MapboxTokenInput',
  components: {},
  props: {
    value: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  setup(props, context) {
    function handleChange(event) {
      context.emit('input', event.target.value)
    }

    function handleBlur(event) {
      context.emit('blur', event)
    }

    function getOrigin() {
      return window.location.origin
    }

    return {
      handleChange,
      handleBlur,
      getOrigin
    }
  }
}
</script>
