<template>
  <button
    type="button"
    class="leading-none"
    style="focus:outline-none focus:shadow-outline"
    @click="event => $emit('click', event)"
  >
    <div class="text-center cursor-pointer">
      <component
        :is="previewTile"
        v-if="previewTile"
        class="w-full rounded border border-gray-400"
      />
      <AspectRatio v-else ratio="220:144">
        <div
          class="bg-white text-gray-700 text-xl w-full h-full rounded text-black flex flex-row items-center justify-center"
        >
          {{ name }}
        </div>
      </AspectRatio>

      <p class="font-bold mt-1 ml-1.5">
        {{ name }}
      </p>
    </div>
  </button>
</template>

<script>
import { pickBy } from 'lodash'
import { PlusSquareIcon } from 'vue-feather-icons'
import { AspectRatio } from '@rovit/aspect-ratio'
import { computed } from '@vue/composition-api'
import { capitalCase } from 'change-case'

export default {
  name: 'Name',
  components: {
    PlusSquareIcon,
    AspectRatio
  },
  props: {
    moduleComponent: {
      type: Object,
      required: true
    },
    /**
     * If the previewTile component is not provided, then we need to show a
     * generic preview in its place. This allows us to use new components without
     * immediately having their ____PreviewTile.vue component completed.
     */
    previewTile: {
      default: null,
      validator: () => true
    }
  },
  setup(props, context) {
    const name = computed(() => {
      const name = props.moduleComponent.name || props.moduleComponent.componentName
      return capitalCase(name.replace('Tm', ''))
    })
    return { name }
  }
}
</script>

<style lang="postcss"></style>
