import { isRef } from '@vue/composition-api'

export function unwrapRef(obj) {
  return isRef(obj) ? obj.value : obj
}

export function unwrapRefs(obj) {
  return Object.keys(obj).reduce((props, key) => {
    props[key] = unwrapRef(obj[key])
    return props
  }, {})
}
