<template>
  <form @submit.prevent="e => {}">
    <h1 class="sidebar-heading-1 mt-2">Edit Environment</h1>

    <EnvironmentSidebarTabs />

    <EnvironmentEditorInfo v-if="$route.query.environmentSection === 'info'" :env="env" />
    <EnvironmentEditorAccess v-if="$route.query.environmentSection === 'access'" :env="env" />
    <EnvironmentEditorDanger v-if="$route.query.environmentSection === 'danger'" :remove="remove" />
  </form>
</template>

<script>
import EnvironmentSidebarTabs from './EnvironmentSidebarTabs.vue'
import EnvironmentEditorInfo from './EnvironmentEditorInfo.vue'
import EnvironmentEditorAccess from './EnvironmentEditorAccess.vue'
import EnvironmentEditorDanger from './EnvironmentEditorDanger.vue'
import { watch, ref } from '@vue/composition-api'

export default {
  name: 'EnvironmentSidebar',
  components: {
    EnvironmentSidebarTabs,
    EnvironmentEditorInfo,
    EnvironmentEditorAccess,
    EnvironmentEditorDanger
  },
  props: {
    env: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const environmentSection = ref('info')
    watch(
      () => context.root.$route.query.environmentSection,
      section => {
        if (!section) {
          const newRoute = Object.assign({}, context.root.$route)
          if (!environmentSection.value) {
            newRoute.query = Object.assign({}, newRoute.query, { environmentSection: 'info' })
          } else {
            newRoute.query = Object.assign({}, newRoute.query, {
              environmentSection: environmentSection.value
            })
          }
          context.root.$router.push(newRoute)
        } else {
          environmentSection.value = section
        }
      },
      { immediate: true }
    )
    function remove() {
      props.env.remove()
      const query = {}
      context.root.$router.push({ query })
    }
    return { remove }
  }
}
</script>

<style lang="postcss"></style>
