import Vue from 'vue'
import * as vueFeatherIcons from 'vue-feather-icons'
import * as customIcons from '@rovit/icons'

Object.keys(vueFeatherIcons).forEach(name => {
  Vue.component(name, vueFeatherIcons[name])
})

Object.keys(customIcons).forEach(name => {
  Vue.component(name, customIcons[name])
})
