<template>
  <div class="sidebar-tabs">
    <!-- Support -->
    <router-link
      tag="button"
      type="button"
      :to="routeFor('support')"
      class="mr-1"
      :class="{
        active: $route.query.sidebar === 'support' || !$route.query.sidebar
      }"
    >
      <HelpCircleIcon />
    </router-link>

    <!-- Infobox -->
    <router-link
      tag="button"
      type="button"
      :to="routeFor('infobox')"
      class="combo-left"
      :class="{
        active: $route.query.sidebar === 'infobox'
      }"
    >
      Infobox
    </router-link>

    <!-- Scene -->
    <router-link
      tag="button"
      type="button"
      :to="routeFor('scene')"
      class="combo-middle"
      :class="{
        active: $route.query.sidebar === 'scene'
      }"
    >
      Scene
    </router-link>

    <!-- Add Scene -->
    <router-link
      tag="button"
      type="button"
      class="combo-right icon mr-1"
      :to="routeFor('add-scene')"
      :class="{
        active: $route.query.sidebar === 'add-scene'
      }"
    >
      <PlusIcon />
    </router-link>

    <!-- Section -->
    <router-link
      tag="button"
      type="button"
      class="combo-left"
      :to="routeFor('section')"
      :class="{
        active: $route.query.sidebar === 'section'
      }"
    >
      Section
    </router-link>

    <!-- Add Section -->
    <router-link
      tag="button"
      type="button"
      class="combo-right icon"
      :to="routeFor('sections')"
      :class="{
        active: $route.query.sidebar === 'sections'
      }"
    >
      <PlusIcon />
    </router-link>
  </div>
</template>

<script>
import { PlusIcon, HelpCircleIcon } from 'vue-feather-icons'

export default {
  name: 'InfoboxSidebarTabs',
  components: {
    PlusIcon,
    HelpCircleIcon
  },
  props: {},
  setup(props, context) {
    function routeFor(sidebar) {
      const newRoute = Object.assign({}, context.root.$route)
      const query = Object.assign({}, context.root.$route.query, { sidebar })
      Object.assign(newRoute, { query })
      return newRoute
    }

    return { routeFor }
  }
}
</script>

<style lang="postcss"></style>
