<template>
  <div class="mb-24">
    <!-- Sidebar Modal with Asset Selector -->
    <SidebarModal v-model="isUploaderOpen" class="z-50">
      <template #default>
        <SidebarUploadSelector
          :model="models.api.Asset"
          :new-files="newFiles"
          :current-tab="currentUploaderTab"
          :endpoint="$store.state.uploadUrlEndpoint"
          qid="assetSelector"
          resource-name="Asset"
          @select="handleFileSelect"
          @tab-change="handleTabChange"
          @uploaded="handleUploaded"
          @close="closeSelector"
        />
      </template>
    </SidebarModal>

    <label class="block">
      <span class="text-gray-700">Name</span>
      <input
        v-model="_service.name"
        class="form-input block w-full"
        placeholder="Name of Service, Business, or Event"
        @blur="() => save_service('name')"
      />
    </label>

    <!-- Primary Asset -->
    <div class="block mt-3 w-3/4">
      <span class="block mb-1">Primary Image</span>

      <!-- Image -->
      <ImageChangeRemove
        v-if="service.primaryAssetUrl"
        :src="_service.primaryAssetUrl"
        :fit.sync="_service.primaryAssetFit"
        @update:fit="() => save_service('primaryAssetFit')"
        @dragenter="openSelector"
        @change-image="openSelector"
        @remove-image="handleRemoveAsset"
      />

      <!-- No Image -->
      <NoImage v-else @click="openSelector" @dragenter="openSelector" />

      <label class="block mt-3">Badges</label>
    </div>

    <BadgesMeta v-model="_service.badgesMeta" @open-badge-selector="openBadgeSelector" />

    <div class="mt-3 block">
      <label>Categories</label>
      <CategoriesMetaSelect
        v-model="_service.categoriesMeta"
        :env="currentEnv"
        :category-model="models.api.Category"
        @input="handleCategoriesMetaSelect"
        @created="handleCreatedCategory"
      />
    </div>
  </div>
</template>

<script>
import { models } from 'feathers-vuex'
import { handleClones } from '@rovit/utils'
import { ref, computed } from '@vue/composition-api'
import { useModal } from '@rovit/use-modal'
import { getEnv, getRovitEnvFromStore } from '@/use/environments.js'

import { SidebarUploadSelector } from '@rovit/sidebar-upload-selector'
import { SidebarModal } from '@rovit/sidebar-modal'
import { ImageChangeRemove, NoImage } from '@rovit/image-display-sidebar'
import BadgesMeta from '../BadgesMeta/BadgesMeta.vue'
import { CategoriesMetaSelect } from '@rovit/categories-meta-select'

export default {
  name: 'ServiceEditorInfo',
  components: {
    SidebarUploadSelector,
    SidebarModal,
    ImageChangeRemove,
    NoImage,
    BadgesMeta,
    CategoriesMetaSelect
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    envService: {
      validator: val => true,
      default: null
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _service } = clones
    const { save_service } = saveHandlers
    const currentOrg = context.root.$store.getters['auth/currentOrg']
    const createdCategory = ref(null)

    /**
     * Primary Asset Selection
     */
    const currentUploaderTab = ref('library')
    const isUploaderOpen = ref(false)
    const newFiles = ref([])
    function openSelector() {
      isUploaderOpen.value = true
    }
    function closeSelector() {
      isUploaderOpen.value = false
    }
    function handleTabChange(tabName) {
      currentUploaderTab.value = tabName
    }
    async function handleUploaded(data) {
      Object.assign(data, {
        orgId: currentOrg._id
      })
      const asset = await new models.api.Asset(data).save()
      newFiles.value.push(asset)
    }
    function handleFileSelect(item) {
      Object.assign(_service.value, {
        primaryAssetId: item._id,
        primaryAssetUrl: item.url
      })
      save_service(['primaryAssetId', 'primaryAssetUrl'])
      closeSelector()
    }
    function handleRemoveAsset() {
      Object.assign(_service.value, {
        primaryAssetId: null,
        primaryAssetUrl: ''
      })
      save_service(['primaryAssetId', 'primaryAssetUrl'])
    }

    // Badge Selector Modal
    const badgeSelectorModal = useModal('badgeSelector')
    function openBadgeSelector() {
      badgeSelectorModal.open({ item: _service, save_item: save_service })
    }

    // Current Env (needed for categories)
    const { rovit } = getRovitEnvFromStore()
    const envId = computed(() => context.root.$route.params.envId)
    const { env } = getEnv(envId)
    const currentEnv = computed(() => (env.value ? env.value : rovit))

    // Categories
    const isCategoryEditorOpen = ref(false)
    function handleCreatedCategory(category) {
      createdCategory.value = category
      isCategoryEditorOpen.value = true
    }
    function handleCategoriesMetaSelect() {
      save_service('categoriesMeta')
        .then(() => {
          this.$toasted.global.actionSuccess('Categories Updated')
        })
        .catch(() => {
          this.$toasted.global.actionError('Categories could not be updated')
        })
    }

    return {
      models,
      ...clones,
      ...saveHandlers,

      // SidebarUploadSelector
      currentUploaderTab,
      isUploaderOpen,
      newFiles,
      openSelector,
      closeSelector,
      handleTabChange,
      handleUploaded,
      handleFileSelect,
      handleRemoveAsset,

      // Badge Selector Modal
      openBadgeSelector,

      // Current Env (Needed for categories)
      currentEnv,

      // Categories
      isCategoryEditorOpen,
      handleCreatedCategory,
      handleCategoriesMetaSelect
    }
  }
}
</script>

<style lang="postcss"></style>
