<template>
  <div class="flex flex-row items-center px-1">
    <p class="flex-grow pl-1">{{ orgUser.userName }}</p>

    <div v-if="isConfirmVisible">
      <button
        type="button"
        class="p-2 bg-red-700 border border-transparent rounded-l text-white"
        @click="orgUser.remove()"
      >
        Remove User
      </button>
      <button
        type="button"
        class="bg-gray-400 dark:text-black p-2 rounded-r"
        @click="isConfirmVisible = false"
      >
        Cancel
      </button>
    </div>

    <div v-else class="flex flex-row items-center">
      <!-- AccessType -->
      <PermissionSelect
        v-model="_orgUser.accessType"
        class="bg-transparent border-transparent rounded-none"
        @input="updateAccessType"
      />
      <button type="button" class="p-0.5 text-red-700" @click="isConfirmVisible = true">
        <Trash2Icon />
      </button>
    </div>
  </div>
</template>

<script>
import { handleClones } from '@rovit/utils/handle-clones.js'
import { Trash2Icon } from 'vue-feather-icons'
import { ref } from '@vue/composition-api'

import { PermissionSelect } from '@rovit/permission-manager'

export default {
  name: 'OrgUserRow',
  components: {
    Trash2Icon,
    PermissionSelect
  },
  props: {
    orgUser: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { clones, saveHandlers } = handleClones(props)
    const { _orgUser } = clones
    const { save_orgUser } = saveHandlers
    const isConfirmVisible = ref(false)

    function updateAccessType(accessType) {
      save_orgUser({ accessType })
    }

    return { ...clones, ...saveHandlers, isConfirmVisible, updateAccessType }
  }
}
</script>

<style lang="postcss"></style>
