import { models, useFind, useGet } from 'feathers-vuex'
import { ref, computed } from '@vue/composition-api'
import { unwrapRef } from './unwrap-ref'
import _get from 'lodash/get.js'

export async function createCategory({ env }) {
  const { Category } = models.api

  const _env = unwrapRef(env)

  const category = await new Category({
    name: 'New Category',
    envId: _env._id,
    sortOrder: 0,
    parentCategoryId: null,
    parentCategoryName: ''
  }).save()

  return { category }
}

export function getCategory(id) {
  const { Category } = models.api
  const params = computed(() => {
    return {
      $populateParams: {
        name: ''
      }
    }
  })
  const queryWhen = computed(() => {
    const existing = Category.getFromStore(id.value)
    return id.value && !existing
  })
  const { item: category } = useGet({
    model: Category,
    id,
    params,
    queryWhen
  })

  return { category }
}

export function findCategories({ env, categoryId }) {
  const { Category } = models.api
  const params = computed(() => {
    const envId = _get(env.value, '_id')
    if (envId) {
      const query = { envId, $limit: 5000, $sort: { sortOrder: 1 } }
      return {
        query,
        $populateParams: {
          name: 'adminCategories'
        }
      }
    } else {
      return null
    }
  })
  const queryWhen = computed(() => {
    return true
  })
  const { items: categories } = useFind({ model: Category, params, queryWhen })

  return { categories }
}
