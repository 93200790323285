<template>
  <div class="sidebar-support">
    <div v-if="service">
      <ServiceSidebar :service="service" />
    </div>

    <div v-else>Loading Service</div>
  </div>
</template>

<script>
import ServiceSidebar from '../ServiceSidebar/ServiceSidebar.vue'

export default {
  name: 'InfoboxSidebarService',
  components: {
    ServiceSidebar
  },
  props: {
    service: {
      validator: val => true,
      default: null
    },
    env: {
      validator: val => true,
      default: null
    }
  },
  setup(props, context) {
    return {}
  }
}
</script>

<style lang="postcss"></style>
