import feathersClient, { models, makeServicePlugin, BaseModel } from '../../feathers-client'
import { makeModel } from '@rovit/models/Category.js'
import shouldFilterServiceKeys from '../../utils/fetch-variable-from-env'
import { iff, discard, alterItems } from 'feathers-hooks-common'
import _pick from 'lodash/pick.js'

const servicePath = 'categories'
const servicePlugin = makeServicePlugin({
  Model: makeModel({ BaseModel, models }),
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [
      iff(
        context => ['create', 'update', 'patch'].includes(context.method),
        discard('childCategories'),
        iff(
          shouldFilterServiceKeys(),
          alterItems(i =>
            _pick(i, [
              '_id',
              'name',
              'sortOrder',

              'parentCategoryId',
              'parentCategoryName',

              // Feature Type Attributes
              'featureType',

              'assetId',
              'assetUrl',
              'assetFit',

              'defaultHotspotIconUrl',
              'defaultHotspotIconId',

              'panoId',
              'panoName',
              'panoUrl',
              'panoTargetPercentX',
              'panoTargetPercentY',

              'hotspots',

              // Icon Attributes
              'iconId',
              'iconUrl',
              'iconMimeType',
              'iconFit',

              'iconAdaptiveFill',
              'iconAdaptiveStroke',
              'iconColor',
              'bgColor',

              'envId',
              'createdBy',
              'createdAt',
              'updatedAt'
            ])
          )
        )
      )
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
