<template>
  <div>
    <button
      ref="infoboxBrowserButton"
      type="button"
      class="text-blue-600 dark:text-blue-300 text-sm leading-none"
      @click="popper.toggle()"
    >
      Change Infobox
    </button>

    <div
      v-show="popper.isVisible"
      ref="infoboxBrowserPopper"
      v-click-outside="() => popper.close()"
      class="bg-white dark:bg-gray-900 absolute z-10 w-11/12 p-3 border-2 border-blue-400 dark:border-blue-700 rounded shadow"
    >
      <h3 class="text-2xl font-semibold leading-tight mt-0.5">Select an Infobox</h3>
      <InfoboxBrowser
        v-if="popper.isVisible"
        :service="service"
        :current-env="currentEnv"
        :current-infobox="currentInfobox"
        @select-infobox="handleSelectInfobox"
      />
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { usePopper } from '@rovit/popper'

import InfoboxBrowser from '../InfoboxBrowser/InfoboxBrowser.vue'

export default {
  name: 'InfoboxBrowserPopper',
  components: {
    InfoboxBrowser
  },
  props: {
    service: {
      validator: val => typeof val === 'object',
      required: true
    },
    currentEnv: {
      validator: val => typeof val === 'object',
      required: true
    },
    currentInfobox: {
      validator: val => typeof val === 'object',
      required: true
    }
  },
  setup(props, context) {
    // Infobox Browser Popper
    const infoboxBrowserButton = ref(null)
    const infoboxBrowserPopper = ref(null)
    const popper = usePopper(infoboxBrowserButton, infoboxBrowserPopper, {
      placement: 'bottom-start'
    })

    function handleSelectInfobox(data) {
      context.emit('select-infobox', data)
      setTimeout(() => {
        popper.close()
      }, 500)
    }

    return {
      // Popper
      infoboxBrowserButton,
      infoboxBrowserPopper,
      popper,
      handleSelectInfobox
    }
  }
}
</script>

<style lang="postcss"></style>
