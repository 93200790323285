<template>
  <div>
    <p class="sidebar-heading-1">Scenes</p>

    <p class="sidebar-subheading">
      This infobox contains the following scenes.
    </p>

    <div class="permission-manager bg-white rounded border border-gray-400">
      <div v-if="!scenes.length" class="py-2 text-left pl-3 rounded cursor-not-allowed">
        None
      </div>

      <div v-if="scenes.length">
        <SidebarSceneRow v-for="scene in scenes" :key="scene._id" :scene="scene" />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarSceneRow from './SidebarSceneRow.vue'

export default {
  name: 'SidebarInfoboxScenes',
  components: {
    SidebarSceneRow
  },
  props: {
    scenes: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="postcss">
@screen dark {
  .permission-manager,
  .permission-creator {
    @apply bg-gray-800 text-gray-400 border-gray-700;
  }
}
</style>
