<template>
  <Layout>
    <template #content>
      <NavbarEnvironment :env="currentEnv" />

      <div class="px-12">
        <h1 class="content-heading-1">Environment Services</h1>

        <p class="content-subheading">
          These services are in the
          <strong class="font-bold">{{ currentEnv && currentEnv.name }}</strong>
          environment. Click a service to edit it. You can also add new or existing services.
        </p>

        <button
          class="bg-green-700 text-white px-4 py-2 rounded my-2"
          @click="openAddServiceSidebar"
        >
          Add Service
        </button>

        <div class="flex flex-row items-center mt-2">
          <label class="block">
            <!-- <span class="text-gray-700">Search</span> -->
            <input v-model="serviceSearch" class="form-input block w-full" placeholder="Search" />
          </label>

          <FeathersVuexPagination v-model="servicesPagination" :latest-query="latestServicesQuery">
            <template
              #default="{
                currentPage,
                pageCount,
                toStart,
                toEnd,
                toPage,
                next,
                prev,
                canNext,
                canPrev
              }"
              debugger
            >
              <SidebarPagination
                :current-page="currentPage"
                :page-count="pageCount"
                :can-prev="canPrev"
                :can-next="canNext"
                class="ml-2"
                style="margin-top: 0"
                @to-start="toStart"
                @to-end="toEnd"
                @to-page="toPage"
                @next="next"
                @prev="prev"
              />
            </template>
          </FeathersVuexPagination>

          <!-- Grid or Table View -->
          <select v-model="viewType" class="form-select ml-2 w-1/5">
            <option value="grid">Grid</option>
            <option value="table">Table</option>
          </select>
        </div>

        <GridSelector
          v-if="viewType === 'grid'"
          v-model="selectedService"
          :items="services"
          :label="i => i.name"
          :src="i => thumbnail(i.primaryAssetUrl).url"
          :fit="i => i => i.primaryAssetFit"
          class="mt-3 -mr-2"
          @item-click="handleServiceClick"
          @item-dblclick="handleServiceDblClick"
        />
        <TableSelector v-else v-model="selectedService" :items="services">
          <template #thead>
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Orgs</th>
                <th>Location</th>
                <th class="px-1">Infoboxes</th>
              </tr>
            </thead>
          </template>

          <template #default="{ item, isSelected, select }">
            <ServicesTableRow
              :service="item"
              :is-selected="isSelected"
              :select="select"
              @item-click="handleServiceClick"
              @item-dblclick="handleServiceDblClick"
            />
          </template>
        </TableSelector>
      </div>
    </template>

    <template #sidebar>
      <div>
        <EnvServicesSidebarTabs
          @editor-tab-click="showMostRecentEditor"
          @add-service-click="openAddServiceSidebar"
        />

        <EnvServicesSidebarSupport v-if="sidebarName === 'support'" />

        <EnvServicesSidebarEditorBlank
          v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
        />
        <EnvServicesSidebarEditorEnvService
          v-if="sidebarName === 'editor' && sidebarEditorType === 'env-service'"
        />

        <EnvServicesSidebarAddService
          v-if="sidebarName === 'add-service'"
          @create-service="handleCreateService"
          @add-service="handleAddService"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import { getEnv } from '@/use/environments'
import { createEnvService, findEnvServicesByEnv } from '@/use/env-services'
import { createService, findServices, addEnvToService } from '@/use/services'
import { findInfoboxForEnv } from '@/use/scenes'
import useSidebarTabState from '@/use/sidebar-tab-state'
import { thumbnail } from '@/use/image-transforms/index'

import Layout from '../../layouts/Layout.vue'
import NavbarEnvironment from '../NavbarEnvironment/NavbarEnvironment.vue'
import GridSelector from '../GridSelector/GridSelector.vue'
import TableSelector from '../TableSelector/TableSelector.vue'
import ServicesTableRow from './ServicesTableRow.vue'
import { SidebarPagination } from '@rovit/sidebar-pagination'

import EnvServicesSidebarTabs from './SidebarTabs.vue'
import EnvServicesSidebarSupport from './SidebarSupport.vue'
import EnvServicesSidebarEditorBlank from './SidebarEditorBlank.vue'
import EnvServicesSidebarEditorEnvService from './SidebarEditorEnvService.vue'
import EnvServicesSidebarAddService from './SidebarAddService.vue'

export default {
  name: 'EnvironmentServices',
  metaInfo: {
    title: 'Environment Services'
  },
  components: {
    Layout,
    NavbarEnvironment,
    GridSelector,
    TableSelector,
    ServicesTableRow,
    SidebarPagination,
    EnvServicesSidebarTabs,
    EnvServicesSidebarSupport,
    EnvServicesSidebarEditorBlank,
    EnvServicesSidebarEditorEnvService,
    EnvServicesSidebarAddService
  },
  setup(props, context) {
    const { Environment, Service, EnvService } = models.api
    const { env: currentEnv } = getEnv(context.root.$route.params.envId)
    const currentOrg = context.root.$store.getters['auth/currentOrg']
    const viewType = ref('table')
    const selectedService = ref(null)

    // Services
    const serviceSearch = ref('')
    const serviceSort = ref({ name: 1 })
    const servicesPagination = ref({
      $limit: 100,
      $skip: 0
    })
    const { services, latestQuery: latestServicesQuery } = findServices({
      env: currentEnv,
      sort: serviceSort,
      search: serviceSearch,
      pagination: servicesPagination
    })

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      return context.root.$route.query.serviceId ? 'env-service' : 'blank'
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      watchInQuery: ['serviceId'],
      context
    })

    /**
     * Show the add service sidebar, which gives the user the option
     * to create a new service or serviceSearch existing services.
     */
    async function openAddServiceSidebar() {
      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'add-service'
      })
      context.root.$router.push({ query }, () => {})
    }
    /**
     * Creates a new service before calling addEnvToService
     */
    async function handleCreateService() {
      const { service } = await createService({ org: currentOrg })
      await addEnvToService({ service, env: currentEnv })
      selectedService.value = service
      openServiceInSidebar(service)
    }

    /**
     * Open service in Sidebar
     */
    function openServiceInSidebar(service) {
      const serviceSection = context.root.$route.query.serviceSection
      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'editor',
        serviceId: service._id,
        serviceSection: serviceSection ? serviceSection : 'info'
      })
      context.root.$router.push({ query }, () => {})
    }

    /**
     * Handles the currently selected environment(s). A different sidebar is shown for
     * arrays vs single objects.
     */
    function handleServiceClick({ item, e }) {
      if (Array.isArray(selectedService.value)) {
        if (!selectedService.value.length) return
        // TODO: handle when multiple orgs are in `selectedService` array.
      } else {
        if (!selectedService.value) return
        openServiceInSidebar(selectedService.value)
      }
    }
    /**
     * When a service is double-clicked, open the infobox for the current environment
     */
    async function handleServiceDblClick({ item: service, e }) {
      const { scene } = await findInfoboxForEnv({ service, env: currentEnv })
      const infoboxId = scene ? scene._id : null
      context.root.$router.push(
        {
          name: 'EnvServiceInfoboxEditor',
          params: {
            envId: context.root.$route.params.envId,
            serviceId: service._id,
            infoboxId
          }
        },
        () => {}
      )
    }
    function openInfobox(scene) {
      debugger
      context.root.$router.push(
        {
          name: 'EnvServiceInfoboxEditor',
          params: {
            envId: context.root.$route.params.envId,
            serviceId: scene.serviceId,
            infoboxId: scene._id
          }
        },
        () => {}
      )
    }

    function makeUrl(i) {
      return i.primaryAssetUrl
        ? `//images.weserv.nl/?url=${i.primaryAssetUrl}&w=660&h=330&fit=inside&il`
        : ''
    }

    function handleAddService(service) {
      addEnvToService({ service, env: currentEnv })
    }

    return {
      currentEnv,

      // Services
      serviceSearch,
      services,
      servicesPagination,
      latestServicesQuery,

      showMostRecentEditor,
      sidebarName,
      sidebarEditorType,
      selectedService,
      openAddServiceSidebar,
      handleCreateService,
      handleServiceClick,
      handleServiceDblClick,
      thumbnail,
      viewType,
      handleAddService
    }
  }
}
</script>

<style lang="postcss" scoped></style>
