<template>
  <Layout>
    <template #content>
      <NavbarAdmin />

      <div class="px-12">
        <h1 class="content-heading-1">{{ $store.getters['auth/currentOrg'].name }} Billing</h1>

        <p class="content-subheading">
          This page shows a summary of all billings for this entire Org.
        </p>
        <div class="stripe-card-wrapper">
          <StripeCard :stripe-instance="stripeInstance" @save-card-click="handleSaveCard" />
        </div>
      </div>
    </template>

    <template #sidebar>
      Billing Sidebar
    </template>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout'
import { ref } from '@vue/composition-api'
import { StripeCard } from '@rovit/stripe-card'
import NavbarAdmin from '../components/NavbarAdmin/NavbarAdmin'
import { models } from 'feathers-vuex'

export default {
  name: 'Billing',
  metaInfo: {
    title: 'Billing'
  },
  components: {
    Layout,
    NavbarAdmin,
    StripeCard
  },
  setup(props, context) {
    const { stripeKey } = context.root.$store.state
    const { StripeSetupIntent, StripeAttachPaymentMethod } = models.api
    const stripeInstance = Stripe(stripeKey)
    const user = context.root.$store.state.auth.user

    async function handleSaveCard(cardElement) {
      const setupIntentService = await new StripeSetupIntent().save()
      const { client_secret: clientSecret } = setupIntentService
      const setupResult = await stripeInstance.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            email: user.name
          }
        }
      })
      if (setupResult.error) {
        //todo handle error state.
        // show an error toast/message
      } else {
        const { setupIntent } = setupResult
        const { payment_method: paymentMethod } = setupIntent
        const newStripeCard = await new StripeAttachPaymentMethod({
          id: paymentMethod,
          paymentToAttach: true
        }).save()
        console.log(newStripeCard)
      }
    }
    return {
      stripeInstance,
      handleSaveCard
    }
  }
}
</script>

<style lang="postcss" scoped></style>
