<template>
  <SceneSelector
    v-model="linkProxy"
    :env-model="models.api.Environment"
    :env-service-model="models.api.EnvService"
    :service-model="models.api.Service"
    :scene-model="models.api.Scene"
    :create-env="handleCreateEnv"
    :create-service="handleCreateService"
    :create-env-service="handleCreateEnvService"
    :create-scene="handleCreateScene"
  />
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { models } from 'feathers-vuex'
import { createService } from '@/use/services.js'
import { createEnv } from '@/use/environments.js'

import { SceneSelector } from '@rovit/scene-selector'

export default {
  name: 'LinkEditor',
  components: {
    SceneSelector
  },
  model: {
    prop: 'link'
  },
  props: {
    link: {
      validator: val => typeof val === 'object',
      required: true
    },
    org: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const linkProxy = computed({
      get: () => props.link,
      set: val => context.emit('input', val)
    })
    async function handleCreateEnv({ name }) {
      const { env } = await createEnv({ name, org: props.org }).save()
      return env
    }
    async function handleCreateService({ name }) {
      const { service } = await createService({ name, org: props.org })
      return service
    }
    async function handleCreateEnvService(linkData) {
      const { envId, envName, serviceId, serviceName } = linkData
      const data = {
        envId,
        envName,
        serviceId,
        serviceName,
        sceneId: linkData.infoboxId,
        sceneName: linkData.infoboxName
      }
      const envService = await new models.api.EnvService(data).save()
      return envService
    }
    async function handleCreateScene(data) {
      const { name, infoboxName, parentSceneId, isInfobox, serviceId } = data
      const scene = await new models.api.Scene({
        name,
        infoboxName,
        parentSceneId,
        isInfobox,
        serviceId
      }).save()
      return scene
    }

    return {
      models,
      linkProxy,
      // Hotspot Link
      handleCreateEnv,
      handleCreateService,
      handleCreateEnvService,
      handleCreateScene
    }
  }
}
</script>

<style lang="postcss" scoped>
.vertical-center {
  top: 50%;
}
</style>
