<template>
  <tr v-if="service">
    <!-- Image -->
    <td class="p-0.75 rounded-l" :class="[isSelected ? 'selected' : '']">
      <ProjectTile
        :label="false"
        :src="thumbnail(service.primaryAssetUrl).url"
        :fit="service.primaryAssetFit"
        class="w-24"
        @click="e => handleServiceClick({ e, item: service })"
        @dblclick="e => handleDblServiceClick({ e, item: service })"
      />
    </td>

    <!-- Name -->
    <td
      class="align-middle pl-1 cursor-pointer"
      :class="[isSelected ? 'selected' : '']"
      @click="e => handleServiceClick({ e, item: service })"
      @dblclick="e => handleDblServiceClick({ e, item: service })"
    >
      <div class="flex">
        {{ service.name }}
        <AlertTriangleIcon v-if="needsServiceAlert()" class="ml-1" />
      </div>
    </td>

    <!-- Orgs -->
    <td
      class="align-middle pl-2 cursor-pointer"
      :class="[isSelected ? 'selected' : '']"
      @click="e => handleServiceClick({ e, item: service })"
      @dblclick="e => handleDblServiceClick({ e, item: service })"
    >
      <p v-for="org in service.orgs" :key="org.orgId">{{ org.orgName }}</p>
    </td>

    <!-- Coordinates -->
    <td
      class="align-middle cursor-pointer"
      :class="[isSelected ? 'selected' : '']"
      @click="e => handleServiceClick({ e, item: service })"
      @dblclick="e => handleDblServiceClick({ e, item: service })"
    >
      <CheckIcon v-if="hasLocation" class="mx-auto" />
    </td>

    <!-- Infoboxes -->
    <td
      class="align-middle rounded-r px-1 text-center cursor-pointer"
      :class="[isSelected ? 'selected' : '']"
      @click="e => handleServiceClick({ item: service, e })"
    >
      {{ infoboxCount ? infoboxCount : '' }}
    </td>
  </tr>
</template>

<script>
import { handleClones } from '@rovit/utils/handle-clones.js'
import { thumbnail } from '@/use/image-transforms/index'
import _get from 'lodash/get.js'
import { hasValidCoordinates } from '@rovit/map'

import ProjectTile from '../ProjectTile/ProjectTile'
import { CheckIcon, AlertTriangleIcon } from 'vue-feather-icons'
import { computed } from '@vue/composition-api'

export default {
  name: 'ServicesTableRow',
  components: {
    ProjectTile,
    CheckIcon,
    AlertTriangleIcon
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    /**
     * For the select method when nesting in XDataSelector
     */
    select: {
      type: Function,
      default: () => {}
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _service } = clones
    const { save_service } = saveHandlers
    const infoboxes = computed(() => {
      const scenes = _get(props.service, 'scenes')
      return scenes ? scenes.filter(sc => sc.isInfobox) : []
    })
    const infoboxCount = computed(() => infoboxes.value.length)
    const location = _get(props.service, 'location')
    const hasLocation = location ? (location.coordinates.length > 0 ? true : false) : false

    function handleServiceClick(data) {
      props.select()
      setTimeout(() => {
        context.emit('item-click', data)
      }, 0)
    }
    function handleDblServiceClick(data) {
      setTimeout(() => {
        context.emit('item-dblclick', data)
      }, 0)
    }

    function needsServiceAlert() {
      const service = props.service
      return (
        !service.primaryAssetUrl.length ||
        !service.categoriesMeta.length ||
        !hasValidCoordinates(service.location)
      )
    }

    return {
      ...clones,
      ...saveHandlers,
      infoboxCount,
      hasLocation,
      needsServiceAlert,
      handleServiceClick,
      handleDblServiceClick,
      thumbnail
    }
  }
}
</script>

<style lang="postcss" scoped>
td.selected {
  @apply bg-gray-500;
}
@screen dark {
  td.selected {
    @apply bg-gray-800;
  }
}
</style>
