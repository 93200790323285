<template>
  <Layout>
    <template #content>
      <SettingsNavbar />

      <div class="px-12">
        <h1 class="content-heading-1">Settings</h1>

        <p class="content-subheading">
          Edit Settings
        </p>
      </div>
    </template>

    <template #sidebar>
      <div>
        <UserOrgsSidebarTabs @editor-tab-click="showMostRecentEditor" />

        <UserOrgsSidebarSupport v-if="sidebarName === 'support'" />

        <UserOrgsSidebarEditorBlank
          v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
        />
        <UserOrgsSidebarEditorOrg
          v-if="sidebarName === 'editor' && sidebarEditorType === 'org-user'"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout'
import { ref, computed } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import useSidebarTabState from '@/use/sidebar-tab-state'

import GridSelector from '../components/GridSelector/GridSelector.vue'
import SettingsNavbar from '../components/SettingsNavbar/SettingsNavbar.vue'

import UserOrgsSidebarTabs from '../components/UserOrgs/SidebarTabs.vue'
import UserOrgsSidebarSupport from '../components/UserOrgs/SidebarSupport.vue'
import UserOrgsSidebarEditorBlank from '../components/UserOrgs/SidebarEditorBlank.vue'
import UserOrgsSidebarEditorOrg from '../components/UserOrgs/SidebarEditorOrg.vue'

export default {
  name: 'UserSettings',
  metaInfo: {
    title: 'User Settings'
  },
  components: {
    Layout,
    SettingsNavbar,
    UserOrgsSidebarTabs,
    UserOrgsSidebarSupport,
    UserOrgsSidebarEditorBlank,
    UserOrgsSidebarEditorOrg
  },
  setup(props, context) {
    const { OrgUser } = models.api

    const selected = ref(null)

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      const { $route } = context.root
      if ($route.query.orgUser) {
        return 'org-user'
      } else {
        return 'blank'
      }
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      context
    })

    return {
      selected,
      sidebarName,
      sidebarEditorType,
      showMostRecentEditor
    }
  }
}
</script>

<style lang="postcss" scoped></style>
