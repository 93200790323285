var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('NavbarEnvironmentLibrary',{attrs:{"env":_vm.env}}),_c('div',{staticClass:"px-12"},[_c('div',{staticClass:"flex flex-row items-center"},[_c('h1',{staticClass:"content-heading-1 mr-3"},[_vm._v("Panos")]),_c('button',{staticClass:"button-with-icon bg-green-600 rounded-full ml-2",on:{"click":_vm.openBulkUploadSidebar}},[_c('UploadCloudIcon',{staticClass:"mr-2",attrs:{"size":"1x"}}),_vm._v(" Upload ")],1)]),_c('p',{staticClass:"content-subheading"},[_vm._v(" Manage panos for the "),_c('strong',[_vm._v(_vm._s(_vm.env && _vm.env.name))]),_vm._v(" environment. ")]),(_vm.panos.length)?_c('div',{staticClass:"relative text-right",staticStyle:{"bottom":"24px"}},[_c('button',{staticClass:"text-green-700 dark:text-green-500",attrs:{"type":"button"},on:{"click":function($event){_vm.isEditEnabled = !_vm.isEditEnabled}}},[(_vm.isEditEnabled)?_c('span',[_vm._v("Done")]):_c('span',[_vm._v("Edit")])]),_c('hr',{staticClass:"border-gray-300 dark:border-gray-800"})]):_vm._e(),_c('FeathersVuexPagination',{attrs:{"latest-query":_vm.latestPanosQuery},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var currentPage = ref.currentPage;
          var pageCount = ref.pageCount;
          var toStart = ref.toStart;
          var toEnd = ref.toEnd;
          var toPage = ref.toPage;
          var next = ref.next;
          var prev = ref.prev;
          var canNext = ref.canNext;
          var canPrev = ref.canPrev;
return [_c('SidebarPagination',{attrs:{"current-page":currentPage,"page-count":pageCount,"can-prev":canPrev,"can-next":canNext},on:{"to-start":toStart,"to-end":toEnd,"to-page":toPage,"next":next,"prev":prev}})]}}]),model:{value:(_vm.panosPagination),callback:function ($$v) {_vm.panosPagination=$$v},expression:"panosPagination"}}),(_vm.panos.length)?_c('GridSelector',{staticClass:"mt-3 -mr-2",attrs:{"items":_vm.panos,"label":function (i) { return _vm.makePanoLabel(i); },"src":function (i) { return _vm.thumbnail(i.url).url; },"fit":function (i) { return 'contain'; },"multiple":_vm.isEditEnabled},on:{"input":_vm.handleInput},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):(_vm.arePanosLoading)?_c('div',[_vm._v("Loading Panos")]):_c('div',[_c('EnvLibEmptyState',{attrs:{"item-type":"Panos","items-have-loaded":_vm.panosHaveLoaded},on:{"button-click":_vm.openBulkUploadSidebar}})],1)],1)]},proxy:true},{key:"sidebar",fn:function(){return [_c('EnvLibPanosSidebarTabs',{on:{"editor-tab-click":_vm.showMostRecentEditor,"upload-tab-click":_vm.openBulkUploadSidebar}}),(_vm.sidebarName === 'support')?_c('EnvLibPanosSidebarSupport'):_vm._e(),(_vm.sidebarName === 'editor' && _vm.sidebarEditorType === 'blank')?_c('EnvLibPanosSidebarEditorBlank'):_vm._e(),(_vm.sidebarName === 'editor' && _vm.sidebarEditorType === 'pano' && _vm.currentPano)?_c('EnvLibPanosSidebarEditorPano',{attrs:{"pano":_vm.currentPano,"panos-with-scenes":_vm.panosWithScenes,"panos-without-scenes":_vm.panosWithoutScenes,"env":_vm.env,"scenes":_vm.scenes}}):_vm._e(),(_vm.sidebarName === 'bulk-edit' && _vm.selected && _vm.selected.length)?_c('EnvLibPanosSidebarBulkEdit',{attrs:{"items":_vm.selected,"env-id":_vm.env && _vm.env._id}}):_vm._e(),(_vm.sidebarName === 'upload')?_c('EnvLibPanosSidebarBulkUpload',{staticClass:"mt-2",attrs:{"env-id":_vm.env && _vm.env._id},on:{"pano-click":_vm.openPanoInSidebar}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }