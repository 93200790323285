import { computed, watch } from '@vue/composition-api'
import { models, useFind, useGet } from 'feathers-vuex'
import { unwrapRef, unwrapRefs } from './unwrap-ref'

import _get from 'lodash/get.js'

export async function createFrontPageItem({ env }) {
  const { FrontPageItem } = models.api

  const _env = unwrapRef(env)

  const frontPageItem = await new FrontPageItem({
    envId: _env._id,
    envName: _env.name,
    title: '',
    subtitle: '',
    sortOrder: 999,
    assetFit: 'cover'
  }).save()

  return { frontPageItem }
}

export function getFrontPageItem(id) {
  const { FrontPageItem } = models.api
  const params = computed(() => {
    return {
      $populateParams: {
        name: ''
      }
    }
  })
  const queryWhen = computed(() => {
    const existing = FrontPageItem.getFromStore(id.value)
    return id.value && !existing
  })
  const { item: frontPageItem } = useGet({
    model: FrontPageItem,
    id,
    params,
    queryWhen
  })

  return { frontPageItem }
}

const byEnvId = {}
export function findFrontPageItems({ env }) {
  const { FrontPageItem } = models.api
  const params = computed(() => {
    const envId = _get(env.value, '_id')
    const query = {
      envId,
      $sort: { sortOrder: 1 },
      $limit: 2000
    }
    return { query, paginate: false }
  })
  const queryWhen = computed(() => {
    const envId = _get(env.value, '_id')
    if (!envId || byEnvId[envId]) {
      return false
    }
    return true
  })
  const { items: frontPageItems, haveBeenRequested } = useFind({
    model: FrontPageItem,
    params,
    queryWhen
  })

  // Once the items for an environment have been requested, add them to the query tracker to prevent repeats.
  const stop = watch(
    () => haveBeenRequested.value,
    val => {
      if (haveBeenRequested.value) {
        const envId = _get(env.value, '_id')
        byEnvId[envId] = new Date()
        stop() // manually stop the watcher
      }
    }
  )

  return { frontPageItems, haveBeenRequested }
}
