import { models, useFind, useGet } from 'feathers-vuex'
import { ref, computed, watch } from '@vue/composition-api'
import { unwrapRef, unwrapRefs } from './unwrap-ref.js'

/**
 *  getPano
 */
export function getPano({ id }) {
  const { Pano } = models.api
  const params = computed(() => {
    return {
      $populateParams: {
        name: ''
      }
    }
  })
  const queryWhen = computed(() => {
    const existing = Pano.getFromStore(id.value)
    return id.value && !existing
  })
  const { item: pano } = useGet({
    model: Pano,
    id,
    params,
    queryWhen
  })

  return { pano }
}

/**
 * findPanos
 */
const queriedByEnvId = {}
export function findPanosForEnv(options) {
  const { Pano } = models.api

  const params = computed(() => {
    const { env, qid, sort, pagination } = unwrapRefs(options)
    if (!env) {
      return null
    }
    const query = { envId: env._id }
    if (pagination) {
      Object.assign(query, pagination)
    }
    if (sort) {
      Object.assign(query, { $sort: sort })
    }
    return { query, $populateParams: { name: '' } }
  })
  const queryWhen = computed(() => {
    const { env, pagination } = unwrapRefs(options)
    if (pagination) {
      return true
      // return (
      //   (env && !queriedByEnvId[env._id]) ||
      //   !queriedByEnvId[env._id][`${pagination.$limit}::${pagination.$skip}`] ||
      //   new Date() - queriedByEnvId[env._id][`${pagination.$limit}::${pagination.$skip}`] >
      //     1000 * 60 * 1
      // )
    } else {
      return env && !queriedByEnvId[env._id]
    }
  })
  const data = useFind({ model: Pano, params, queryWhen })
  const {
    items: panos,
    isPending,
    haveBeenRequested,
    haveLoaded: panosHaveLoaded,
    find: refreshPanos,
    latestQuery
  } = data

  watch(
    () => haveBeenRequested.value,
    val => {
      if (val) {
        const { env, pagination } = unwrapRefs(options)
        if (pagination) {
          queriedByEnvId[env._id] = queriedByEnvId[env._id] || {}
          queriedByEnvId[env._id][`${pagination.$limit}::${pagination.$skip}`] = new Date()
        } else {
          queriedByEnvId[env._id] = new Date()
        }
      }
    }
  )

  return { panos, refreshPanos, panosParams: params, panosHaveLoaded, latestQuery, isPending }
}

/**
 * createPano
 */
export async function createPano({ name, envId }) {
  const { Pano } = models.api

  const pano = await new Pano({
    name: name || 'New Pano',
    envId
  }).save()

  return pano
}
