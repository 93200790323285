<template>
  <div class="sidebar-support">
    <PanoSidebar
      v-if="pano"
      :pano="pano"
      :panos-with-scenes="panosWithScenes"
      :panos-without-scenes="panosWithoutScenes"
      :env="env"
      :scenes="scenes"
    ></PanoSidebar>

    <div v-else>Loading Service</div>
  </div>
</template>

<script>
import PanoSidebar from './PanoSidebar.vue'

export default {
  name: 'EnvLibPanosSidebarEditorPano',
  components: {
    PanoSidebar
  },
  props: {
    pano: {
      validator: () => true,
      default: null
    },
    panosWithScenes: {
      type: Array,
      required: true
    },
    panosWithoutScenes: {
      type: Array,
      required: true
    },
    env: {
      validator: val => typeof val === 'object',
      default: null
    },
    scenes: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>

<style lang="postcss"></style>
