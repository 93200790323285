<template>
  <Layout>
    <template #content>
      <NavbarEnvironmentLibrary :env="env" />

      <div class="px-12">
        <div class="flex flex-row items-center">
          <h1 class="content-heading-1 mr-3">Panos</h1>

          <button
            class="button-with-icon bg-green-600 rounded-full ml-2"
            @click="openBulkUploadSidebar"
          >
            <UploadCloudIcon size="1x" class="mr-2" />
            Upload
          </button>
        </div>

        <p class="content-subheading">
          Manage panos for the
          <strong>{{ env && env.name }}</strong> environment.
        </p>

        <div v-if="panos.length" class="relative text-right" style="bottom: 24px">
          <button
            type="button"
            class="text-green-700 dark:text-green-500"
            @click="isEditEnabled = !isEditEnabled"
          >
            <span v-if="isEditEnabled">Done</span>
            <span v-else>Edit</span>
          </button>
          <hr class="border-gray-300 dark:border-gray-800" />
        </div>

        <FeathersVuexPagination v-model="panosPagination" :latest-query="latestPanosQuery">
          <template
            #default="{
              currentPage,
              pageCount,
              toStart,
              toEnd,
              toPage,
              next,
              prev,
              canNext,
              canPrev
            }"
            debugger
          >
            <SidebarPagination
              :current-page="currentPage"
              :page-count="pageCount"
              :can-prev="canPrev"
              :can-next="canNext"
              @to-start="toStart"
              @to-end="toEnd"
              @to-page="toPage"
              @next="next"
              @prev="prev"
            />
          </template>
        </FeathersVuexPagination>

        <GridSelector
          v-if="panos.length"
          v-model="selected"
          :items="panos"
          :label="i => makePanoLabel(i)"
          :src="i => thumbnail(i.url).url"
          :fit="i => 'contain'"
          :multiple="isEditEnabled"
          class="mt-3 -mr-2"
          @input="handleInput"
        />

        <div v-else-if="arePanosLoading">Loading Panos</div>

        <div v-else>
          <EnvLibEmptyState
            item-type="Panos"
            :items-have-loaded="panosHaveLoaded"
            @button-click="openBulkUploadSidebar"
          />
        </div>
      </div>
    </template>

    <template #sidebar>
      <EnvLibPanosSidebarTabs
        @editor-tab-click="showMostRecentEditor"
        @upload-tab-click="openBulkUploadSidebar"
      />

      <EnvLibPanosSidebarSupport v-if="sidebarName === 'support'" />

      <EnvLibPanosSidebarEditorBlank
        v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
      />
      <EnvLibPanosSidebarEditorPano
        v-if="sidebarName === 'editor' && sidebarEditorType === 'pano' && currentPano"
        :pano="currentPano"
        :panos-with-scenes="panosWithScenes"
        :panos-without-scenes="panosWithoutScenes"
        :env="env"
        :scenes="scenes"
      />
      <EnvLibPanosSidebarBulkEdit
        v-if="sidebarName === 'bulk-edit' && selected && selected.length"
        :items="selected"
        :env-id="env && env._id"
      />

      <EnvLibPanosSidebarBulkUpload
        v-if="sidebarName === 'upload'"
        class="mt-2"
        :env-id="env && env._id"
        @pano-click="openPanoInSidebar"
      />
    </template>
  </Layout>
</template>

<script>
import { models } from 'feathers-vuex'
import Layout from '../../layouts/Layout.vue'
import { ref, computed } from '@vue/composition-api'
import useSidebarTabState from '@/use/sidebar-tab-state.js'
import { getEnv } from '@/use/environments'
import { findPanosForEnv, getPano } from '@/use/panos.js'
import { findScenesForPanoIds, findScenesForEnv } from '@/use/scenes.js'
import { thumbnail } from '@/use/image-transforms/index'
import _keyBy from 'lodash/keyBy.js'
import _get from 'lodash/get.js'

import NavbarEnvironmentLibrary from '../NavbarEnvironmentLibrary/NavbarEnvironmentLibrary'
import EnvLibEmptyState from '../EnvLibEmptyState/EnvLibEmptyState.vue'
import { UploadCloudIcon } from 'vue-feather-icons'
import GridSelector from '../GridSelector/GridSelector.vue'
import { SidebarPagination } from '@rovit/sidebar-pagination'

import EnvLibPanosSidebarTabs from './SidebarTabs.vue'
import EnvLibPanosSidebarSupport from './SidebarSupport.vue'
import EnvLibPanosSidebarEditorBlank from './SidebarEditorBlank.vue'
import EnvLibPanosSidebarEditorPano from './SidebarEditorPano.vue'
import EnvLibPanosSidebarBulkUpload from './SidebarBulkUpload.vue'
import EnvLibPanosSidebarBulkEdit from './SidebarBulkEdit.vue'

export default {
  name: 'EnvLibPanos',
  metaInfo: {
    title: 'Env Library Panos'
  },
  components: {
    Layout,
    NavbarEnvironmentLibrary,
    EnvLibEmptyState,
    UploadCloudIcon,
    GridSelector,
    SidebarPagination,
    EnvLibPanosSidebarTabs,
    EnvLibPanosSidebarSupport,
    EnvLibPanosSidebarEditorBlank,
    EnvLibPanosSidebarEditorPano,
    EnvLibPanosSidebarBulkUpload,
    EnvLibPanosSidebarBulkEdit
  },
  setup(props, context) {
    const { env } = getEnv(context.root.$route.params.envId)
    const isEditEnabled = ref(false)

    const sort = ref({ originalFileName: 1, name: 1 })
    const panosPagination = ref({
      $limit: 100,
      $skip: 0
    })
    const {
      panos,
      panosHaveLoaded,
      latestQuery: latestPanosQuery,
      isPending: arePanosLoading
    } = findPanosForEnv({
      env,
      sort,
      pagination: panosPagination
    })
    const allPanosFromStore = computed(() => {
      return models.api.Pano.findInStore({
        query: {
          envId: env.value._id
        }
      }).data
    })
    const panoIds = computed(() => allPanosFromStore.value.map(p => p._id))
    const panosById = computed(() => _keyBy(allPanosFromStore.value, '_id'))

    // Pull in scenes so we know if a pano is used (in the map its dot turns blue when used in a scene)
    const scenesPagination = {
      $limit: 5000,
      $skip: 0
    }
    const { scenes } = findScenesForEnv({
      env,
      populate: '',
      panoId: { $ne: null },
      pagination: scenesPagination
    })

    const scenesWithPanos = computed(() => scenes.value.filter(s => s.panoId))
    const scenesByPanoId = computed(() => _keyBy(scenesWithPanos.value, 'panoId'))
    const panosWithScenes = computed(() => {
      return allPanosFromStore.value.filter(p => !!scenesByPanoId.value[p._id])
    })
    const panosWithoutScenes = computed(() => {
      return allPanosFromStore.value.filter(p => !scenesByPanoId.value[p._id])
    })

    // Current Pano
    const panoId = computed(() => context.root.$route.query.panoId)
    const { pano: currentPano } = getPano({ id: panoId })

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      return context.root.$route.query.panoId ? 'pano' : 'blank'
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      watchInQuery: ['panoId'],
      context
    })

    function openPanoInSidebar(pano) {
      if (!pano) return
      const query = { panoId: pano._id, sidebar: 'editor' }
      context.root.$router.push({ query }, () => {})
    }

    function openBulkUploadSidebar() {
      const query = { sidebar: 'upload' }
      context.root.$router.push({ query }, () => {})
    }
    function openBulkEditSidebar() {
      const query = { sidebar: 'bulk-edit' }
      context.root.$router.push({ query }, () => {})
    }

    function coordsAreValid(item) {
      const coords = _get(item, 'location.coordinates')
      return Array.isArray(coords) && coords[0] != null && coords[1] != null
    }

    function makePanoLabel(pano) {
      let label = pano.name || pano.originalFileName
      if (!coordsAreValid(pano)) {
        label = `❌ ${label}`
      }
      return label
    }

    function save({ event, clone, prop, data }) {
      clone.commit().save({ data })
    }

    const selected = ref(null)
    function handleInput(val) {
      if (!val) {
        return
      }
      if (Array.isArray(val)) {
        if (val.length > 1) {
          openBulkEditSidebar(val)
        } else {
          openPanoInSidebar(val[0])
        }
      } else {
        openPanoInSidebar(val)
      }
    }

    return {
      env,
      // Panos
      panosPagination,
      latestPanosQuery,
      arePanosLoading,
      panos,
      panosHaveLoaded,
      currentPano,

      isEditEnabled,
      makePanoLabel,
      thumbnail,
      scenes,
      scenesWithPanos,
      scenesByPanoId,
      panosWithScenes,
      panosWithoutScenes,

      // GridSelector
      selected,
      handleInput,

      // Sidebar
      sidebarName,
      sidebarEditorType,
      showMostRecentEditor,
      openPanoInSidebar,
      openBulkUploadSidebar,
      save
    }
  }
}
</script>

<style lang="postcss" scoped></style>
fsave
