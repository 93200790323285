<template>
  <div>
    <!-- Map -->
    <label class="block mt-1">
      <span class="text-gray-700">Location</span>

      <div class="h-48 rounded-lg overflow-hidden relative">
        <MapOverlay />
        <MapScriptLoader>
          <MapWithPoints
            :current-feature="_service"
            current-editable
            :zoom="13"
            :access-token="$store.state.mapboxToken"
            class="w-full"
            :features="envServices"
            @update:current-feature="moveMarker"
            @click="moveMarker"
            @feature-click="moveMarker"
            @marker-click="moveMarker"
            @marker-move="moveMarker"
          />
        </MapScriptLoader>
      </div>
      <div>
        <GpsCoordinateInput
          v-model="_service.location"
          @save="() => updateLocation(_service.location.coordinates)"
        />
      </div>

      <Geocode
        :mapbox-token="$store.state.mapboxToken"
        direction="forward"
        placeholder="search"
        class="w-full mt-1"
        @input="setMarkerFromGeocode"
      />
    </label>
  </div>
</template>

<script>
import { MapScriptLoader, MapWithPoints, MapOverlay, Geocode } from '@rovit/map'
import { GpsCoordinateInput } from '@rovit/gps-coordinate-input'
import { handleClones } from '@rovit/utils/handle-clones'

export default {
  name: 'ServiceEditorLocation',
  components: {
    MapScriptLoader,
    MapWithPoints,
    MapOverlay,
    GpsCoordinateInput,
    Geocode
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    envServices: {
      type: Array,
      required: true
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props, { useExisting: true })
    const { _service } = clones
    const { save_service } = saveHandlers

    function updateLocation(coords) {
      Object.assign(_service.value, {
        location: {
          ..._service.value.location,
          coordinates: coords
        }
      })
      save_service(['location'])
        .then(() => {
          this.$toasted.global.actionSuccess('Location Updated')
        })
        .catch(() => {
          this.$toasted.global.actionError('Location could not be updated')
        })
    }

    function moveMarker({ marker, event, feature }) {
      let coords = []
      if (!marker) {
        coords = [event.lngLat.lng, event.lngLat.lat]
      } else {
        coords = [marker.getLngLat().lng, marker.getLngLat().lat]
      }
      updateLocation.call(this, coords)
    }

    function setMarkerFromGeocode(data) {
      const coords = data.geometry.coordinates
      updateLocation.call(this, coords)
    }

    return {
      updateLocation,
      moveMarker,
      setMarkerFromGeocode,
      ...clones
    }
  }
}
</script>

<style lang="postcss"></style>
