<template>
  <div class="sidebar-support">
    <ServiceSidebar
      v-if="service"
      :service="service"
      @remove-from-environment="() => removeFromEnvironment({ envId: $route.params.envId })"
    />

    <div v-else>Loading Service</div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { models } from 'feathers-vuex'
import { getService, removeServiceFromEnv } from '@/use/services.js'

import ServiceSidebar from '../ServiceSidebar/ServiceSidebar'

export default {
  name: 'EnvServicesSidebarEditorEnvService',
  components: {
    ServiceSidebar
  },
  setup(props, context) {
    const id = computed(() => context.root.$route.query.serviceId)
    const { service } = getService(id)

    /**
     * Remove the service from the environment (delete the envService)
     */
    async function removeFromEnvironment({ envId }) {
      const env = await models.api.Environment.get(envId)
      removeServiceFromEnv({ service, env })
    }

    return { id, service, removeFromEnvironment }
  }
}
</script>

<style lang="postcss"></style>
