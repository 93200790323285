<template>
  <div class="w-full mb-1" @click="e => $emit('click', e)">
    <div
      class="flex flex-row items-center border border-gray-400 dark:border-gray-700 rounded p-2 w-full"
      :class="[isCurrent ? 'border-green-500' : '']"
      @click="() => $emit('open', category)"
    >
      <button
        v-if="childCategories.length"
        type="button"
        @click.stop="areChildrenVisible = !areChildrenVisible"
      >
        <ChevronDownIcon v-if="areChildrenVisible" />
        <ChevronUpIcon v-else />
      </button>

      <div class="flex flex-row flex-grow items-center">
        <CategoryTile :category="category" hide-label class="mr-2" />
        <div>
          <p>{{ category.name }}</p>
          <!-- <p class="leading-none text-green-500">6 Infoboxes, 2 Sponsored</p> -->
        </div>
      </div>

      <!-- <button type="button">
      <XIcon />
    </button> -->
    </div>

    <div v-if="childCategories.length && areChildrenVisible" class="ml-5 mt-1">
      <draggable
        v-model="childCategories"
        :animation="250"
        @start="drag = true"
        @end="drag = false"
      >
        <transition-group
          tag="div"
          type="transition"
          :name="!drag ? 'flip-list' : null"
          class="mt-3 flex flex-row flex-wrap -mx-1"
        >
          <CategoryEditorTile
            v-for="cat in childCategories"
            :key="cat._id"
            :category="cat"
            :current-category="currentCategory"
            :class="[
              currentCategory && currentCategory._id === category._id ? 'border-green-500' : ''
            ]"
            :categories="categories"
            @open="category => $emit('open', category)"
          />
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import _sortBy from 'lodash/sortBy.js'

import { ChevronDownIcon, ChevronUpIcon } from 'vue-feather-icons'
import { CategoryTile } from '@rovit/category-tile'
import draggable from 'vuedraggable'

export default {
  name: 'CategoryEditorTile',
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    CategoryTile,
    draggable
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    // eslint-disable-next-line vue/require-default-prop
    currentCategory: {
      type: Object
    },
    categories: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup(props, context) {
    const areChildrenVisible = ref(false)
    const isCurrent = computed(
      () => props.currentCategory && props.currentCategory._id === props.category._id
    )
    const childCategories = computed({
      get: () => {
        const children = props.categories.filter(cat => cat.parentCategoryId === props.category._id)
        return _sortBy(children, 'sortOrder')
      },
      set: val => {
        val.forEach((category, index) => {
          category.clone({ sortOrder: index }).commit().save()
        })
      }
    })
    const drag = ref(false)
    return { areChildrenVisible, isCurrent, childCategories, drag }
  }
}
</script>

<style lang="postcss"></style>
