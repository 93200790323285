<template>
  <div class="admin-layout">
    <div class="content flex-grow relative">
      <!-- FeatureHotspots Modal -->
      <ContentModal v-model="featureHotspotsModal.isOpen" class="z-20 overflow-y-auto">
        <template #default="{ close }">
          <div class="flex flex-col items-center justify-center h-full">
            <div class="bg-white text-black rounded pb-3 px-3 dark:bg-gray-700">
              <div class="flex flex-row pointer-events-none">
                <div class="flex-grow"></div>
                <button
                  type="button"
                  class="pointer-events-auto mt-0.4 py-1 mt-0.5 px-1.5 text-black dark:text-gray-300"
                  @click="close"
                >
                  Close
                </button>
              </div>

              <ItemFeatureAsset
                v-if="featureHotspotsModal.isOpen && featureHotspotsModal.data.item"
                :item="featureHotspotsModal.data.item"
              />
            </div>
          </div>
        </template>
      </ContentModal>

      <slot name="content" />
    </div>

    <!-- Asset Selector Modal -->
    <div class="sidebar">
      <SidebarModal v-model="assetModal.isOpen" class="z-20 overflow-y-auto">
        <template #default="{ close }">
          <SidebarUploadSelector
            :model="models.api.Asset"
            :new-files="newFilesAsset"
            :current-tab="currentUploaderTabAsset"
            :endpoint="$store.state.uploadUrlEndpoint"
            qid="assetSelector"
            resource-name="Asset"
            @select="handleFileSelectAsset"
            @tab-change="val => (currentUploaderTabAsset = val)"
            @uploaded="handleUploadedAsset"
            @close="close"
          />
        </template>
      </SidebarModal>

      <SidebarModal v-model="iconModal.isOpen" class="z-20 overflow-y-auto">
        <template #default="{ close }">
          <SidebarUploadSelector
            :model="models.api.Icon"
            :new-files="newFilesIcon"
            :current-tab="currentUploaderTabIcon"
            :endpoint="$store.state.uploadUrlEndpoint"
            qid="iconSelector"
            resource-name="Icon"
            @select="handleFileSelectIcon"
            @tab-change="val => (currentUploaderTabIcon = val)"
            @uploaded="handleUploadedIcon"
            @close="close"
          />
        </template>
      </SidebarModal>

      <!-- Pano Selector Modal -->
      <SidebarModal v-model="panoModal.isOpen" class="z-20 overflow-y-auto">
        <template #default="{ close }">
          <SidebarUploadSelector
            :model="models.api.Pano"
            :new-files="newFilesPano"
            :current-tab="currentUploaderTabPano"
            :endpoint="$store.state.uploadUrlEndpoint"
            qid="panoSelector"
            resource-name="Pano"
            @select="handleFileSelectPano"
            @tab-change="val => (currentUploaderTabPano = val)"
            @uploaded="handleUploadedPano"
            @close="close"
          />
        </template>
      </SidebarModal>

      <!-- Badge Selector Modal -->
      <SidebarModal v-model="badgeSelectorModal.isOpen" class="z-20 overflow-y-auto">
        <template #default="{ close }">
          <div
            v-if="badgeSelectorModal.isOpen"
            class="bg-gray-300 dark:bg-gray-700 border border-gray-500 dark:border-gray-800 rounded h-full mr-1 p-2"
          >
            <!-- Close Button -->
            <div class="flex flex-row items-center">
              <h2 class="flex-grow"></h2>
              <button type="button" class="form-button text-black dark:text-white" @click="close">
                Close
              </button>
            </div>

            <BadgeSelector
              v-model="badgeSelectorModal.data.item.value.badgesMeta"
              @input="saveBadgesMeta"
            />
          </div>
        </template>
      </SidebarModal>

      <slot name="sidebar" />
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { handleClones } from '@rovit/utils/handle-clones.js'
import { models, useGet } from 'feathers-vuex'
import { useModal } from '@rovit/use-modal'
import { updateHotspot } from '@/use/scenes.js'

import { SidebarModal } from '@rovit/sidebar-modal'
import ContentModal from '../components/ContentModal.vue'
import { SidebarUploadSelector } from '@rovit/sidebar-upload-selector'
import BadgeSelector from '../components/BadgeSelector/BadgeSelector.vue'
import ItemFeatureAsset from '../components/ItemFeatureAsset/ItemFeatureAsset.vue'

export default {
  name: 'Layout',
  components: {
    SidebarModal,
    SidebarUploadSelector,
    BadgeSelector,
    ContentModal,
    ItemFeatureAsset
  },
  setup(props, context) {
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    /**
     * Asset Selector
     */
    const assetModal = useModal('assetSelector')
    const currentUploaderTabAsset = ref('library')
    const newFilesAsset = ref([])
    async function handleUploadedAsset(data) {
      Object.assign(data, {
        envId: props.envId,
        orgId: currentOrg._id
      })
      const item = await new models.api.Asset(data)
        .save()
        .then(result => {
          this.$toasted.global.actionSuccess('Image Saved')
          return result
        })
        .catch(() => {
          this.$toasted.global.actionError('Could not save image')
        })
      newFilesAsset.value.push(item)
    }
    function handleFileSelectAsset(asset) {
      assetModal.close()
      return assetModal.data
        .applyAsset(asset)
        .then(result => {
          this.$toasted.global.actionSuccess('Image Updated')
          return result
        })
        .catch(() => {
          this.$toasted.global.actionError('Image could not be updated')
        })
    }

    /**
     * Icon Selector
     */
    const iconModal = useModal('iconSelector')
    const currentUploaderTabIcon = ref('library')
    const newFilesIcon = ref([])
    async function handleUploadedIcon(data) {
      Object.assign(data, {
        envId: props.envId,
        orgId: currentOrg._id
      })
      const item = await new models.api.Icon(data)
        .save()
        .then(result => {
          this.$toasted.global.actionSuccess('Icon Saved')
          return result
        })
        .catch(() => {
          this.$toasted.global.actionError('Could not save icon')
        })
      newFilesIcon.value.push(item)
    }

    function handleFileSelectIcon(icon) {
      const { _item, hotspotIndex, handler } = iconModal.data

      if (hotspotIndex) {
        updateHotspot({ _item, hotspotIndex, icon })
          .then(result => {
            this.$toasted.global.actionSuccess('Icon Updated')
            return result
          })
          .catch(() => {
            this.$toasted.global.actionError('Icon could not be updated')
          })
      }

      /**
       * Here _item could be of any type: icon, category or scene
       * handler will update _item with provided icon details
       */
      if (handler) {
        handler(icon)
      }

      iconModal.close()
    }

    /**
     * Pano Selector
     */
    const panoModal = useModal('panoSelector')
    const currentUploaderTabPano = ref('library')
    const newFilesPano = ref([])
    async function handleUploadedPano(data) {
      Object.assign(data, {
        envId: props.envId,
        orgId: currentOrg._id
      })
      const item = await new models.api.Pano(data)
        .save()
        .then(result => {
          this.$toasted.global.actionSuccess('Pano Saved')
          return result
        })
        .catch(() => {
          this.$toasted.global.actionError('Could not save pano')
        })
      newFilesPano.value.push(item)
    }
    function handleFileSelectPano(item) {
      const { scene } = panoModal.data
      const data = {
        panoId: item._id,
        panoUrl: item.url
      }
      scene
        .clone(data)
        .save({ data })
        .then(result => {
          this.$toasted.global.actionSuccess('Pano Updated')
          return result
        })
        .catch(() => {
          this.$toasted.global.actionError('Pano could not be updated')
        })
      panoModal.close()
    }

    /**
     * Badge Selector
     */
    const badgeSelectorModal = useModal('badgeSelector')
    function saveBadgesMeta(badgesMeta) {
      return badgeSelectorModal.data.save_item({ badgesMeta })
    }

    /**
     * FeatureHotspots Editor
     */
    const featureHotspotsModal = useModal('featureHotspotsModal')
    function openFeatureHotspotsModal() {
      featureHotspotsModal.open()
    }

    return {
      models,

      // Asset SidebarUploadSelector
      assetModal,
      currentUploaderTabAsset,
      newFilesAsset,
      handleUploadedAsset,
      handleFileSelectAsset,

      // Icon SidebarUploadSelector
      iconModal,
      currentUploaderTabIcon,
      newFilesIcon,
      handleUploadedIcon,
      handleFileSelectIcon,

      // Pano SidebarUploadSelector
      panoModal,
      currentUploaderTabPano,
      newFilesPano,
      handleUploadedPano,
      handleFileSelectPano,

      // Badge Selector Modal
      badgeSelectorModal,
      saveBadgesMeta,

      // FeatureHotspots Modal
      featureHotspotsModal,
      openFeatureHotspotsModal
    }
  }
}
</script>

<style lang="postcss"></style>
