import { models, useFind, useGet } from 'feathers-vuex'
import { ref, computed } from '@vue/composition-api'
import { unwrapRef } from './unwrap-ref.js'
import _get from 'lodash/get.js'

export function getIcon(id) {
  const { Icon } = models.api
  const params = computed(() => {
    return {
      $populateParams: {
        name: ''
      }
    }
  })
  const queryWhen = computed(() => {
    const existing = Icon.getFromStore(id.value)
    return id.value && !existing
  })
  const { item: icon } = useGet({
    model: Icon,
    id,
    params,
    queryWhen
  })

  return { icon }
}

export function findIcons({ env, iconId }) {
  const { Icon } = models.api
  const params = computed(() => {
    const _env = unwrapRef(env)
    if (_env && _env._id) {
      const query = {
        envId: _env._id,
        $limit: 5000
      }
      return {
        query,
        $populateParams: {
          name: ''
        }
      }
    } else {
      return null
    }
  })
  const queryWhen = computed(() => {
    return true
  })
  const { items: icons, haveLoaded: iconsHaveLoaded } = useFind({ model: Icon, params, queryWhen })

  return { icons, iconsHaveLoaded }
}

export async function createIcon({ name, envId }) {
  const { Icon } = models.api

  const icon = await new Icon({
    name: name || 'New Icon',
    envId
  }).save()

  return icon
}
