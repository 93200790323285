<template>
  <div class="p-3">
    <div class="flex flex-row" :class="[envService.sceneName ? 'items-center' : 'items-start']">
      <!-- Icon -->
      <div class="h-10 w-10 rounded bg-green-500"></div>

      <!-- Description -->
      <div class="leading-none ml-1.5 flex-grow">
        <p class="font-semibold">{{ envService.envName }}</p>
        <p v-if="envService.sceneName">{{ envService.sceneName }}</p>
        <div v-else class="mt-2">
          <p class="flex flex-row items-center">
            <AlertTriangleIcon size="1x" class="text-orange-600 mr-1" />
            No Infobox Selected
          </p>
          <div class="flex flex-row items-center mt-0.5">
            <ServiceInfoboxSelect v-model="selectedScene" :service="service" class="flex-grow" />
            <button type="button" class="form-button primary" @click="handleUpdateEnvService">
              Set Infobox
            </button>
          </div>
        </div>
      </div>

      <!-- Action -->
      <div></div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { handleClones } from '@rovit/utils/handle-clones.js'

import ServiceInfoboxSelect from '../ServiceInfoboxSelect.vue'
import { AlertTriangleIcon } from 'vue-feather-icons'

export default {
  name: 'ServiceEnvServiceRow',
  components: {
    ServiceInfoboxSelect,
    AlertTriangleIcon
  },
  props: {
    envService: {
      type: Object,
      required: true
    },
    service: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { save_envService } = saveHandlers

    const selectedScene = ref(null)

    function handleUpdateEnvService() {
      if (!selectedScene.value) {
        return
      }
      save_envService({
        sceneId: selectedScene.value._id,
        sceneName: selectedScene.value.infoboxName
      })
    }

    return { selectedScene, handleUpdateEnvService }
  }
}
</script>

<style lang="postcss"></style>
