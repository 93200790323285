<template>
  <div class="bg-white rounded p-4">
    <div class="text-black">
      <span class="text-xl font-bold">
        Delete Infobox
      </span>
    </div>
    <div class="text-black">
      <span>
        The infobox will be removed from any environments in which it currently appears
      </span>
    </div>
    <div class="mt-3 flex">
      <button class="form-button danger" @click="deleteScene">
        Delete Scene
      </button>
      <button class="form-button bg-gray-700 text-white rounded ml-2" @click="$emit('close')">
        Cancel
      </button>
    </div>
  </div>
</template>
<script>
import { handleClones } from '@rovit/utils'

export default {
  name: 'PromptDeleteInfobox',
  components: {},
  props: {
    scene: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props, { useExisting: true })
    async function deleteScene() {
      context.emit('close')
      const { _scene } = clones
      const removedScene = await props.scene.remove()
      //go back to root infoboxes page
      const { params } = context.root.$route
      let newRoute = ''
      let newParams = null
      let query = {}
      /**
       * user can either come from environments page
       * or from services page.
       * check if params has envId, which means the user
       * came to this page from environments.
       *
       * take them to respective root page
       */
      if (!!params.envId) {
        newRoute = 'EnvServiceInfoboxEditor'
        newParams = Object.assign({}, params)
      } else {
        newRoute = 'OrgServiceInfoboxes'
        newParams = { serviceId: removedScene.serviceId }
      }
      context.root.$router.replace({
        name: newRoute,
        params: newParams,
        query
      })
    }
    return {
      deleteScene
    }
  }
}
</script>
