import feathersClient, { models, makeServicePlugin, BaseModel } from '../../feathers-client'
import shouldFilterServiceKeys from '../../utils/fetch-variable-from-env'
import { iff, discard, alterItems } from 'feathers-hooks-common'
import _pick from 'lodash/pick.js'

class OrgLibraryPano extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'OrgLibraryPano'
  static instanceDefaults() {
    return {
      panoId: '',
      panoName: '',

      orgId: '',
      orgName: '',
      envs: [],

      createdBy: null,
      createdAt: null,
      updatedAt: null
    }
  }
  static setupInstance(data, { models }) {
    if (data.pano) {
      data.pano = new models.api.Pano(data.pano)
    }
    if (data.org) {
      data.org = new models.api.Org(data.org)
    }
    return data
  }
}
const servicePath = 'org-library-panos'
const servicePlugin = makeServicePlugin({
  Model: OrgLibraryPano,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [
      // iff(
      //   context => ['create', 'update', 'patch'].includes(context.method),
      //   discard('childCategories')
      // )
      iff(
        context => ['create', 'update', 'patch'].includes(context.method),
        iff(
          shouldFilterServiceKeys(),
          alterItems(i =>
            _pick(i, [
              '_id',
              'panoId',
              'panoName',
              'orgId',
              'orgName',
              'envs',
              'createdBy',
              'createdAt',
              'updatedAt'
            ])
          )
        )
      )
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
