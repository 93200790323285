export function toDataUrl(img) {
  return new Promise((resolve, reject) => {
    const timeout = 1000
    let hasSaved = false
    img.crossOrigin = 'anonymous'

    function saveDataUrl() {
      const canvas = document.createElement('CANVAS')
      const ctx = canvas.getContext('2d')

      canvas.height = img.naturalHeight
      canvas.width = img.naturalWidth

      ctx.drawImage(img, 0, 0)
      const dataUrl = canvas.toDataURL('image/png')

      resolve(dataUrl)
    }

    img.onload = () => {
      hasSaved = true
      saveDataUrl()
    }

    setTimeout(() => {
      if (!hasSaved) {
        saveDataUrl()
      }
    }, 500)
  })
}
