<template>
  <div class="block bg-gray-400 black:bg-gray-800 rounded px-2 py-3">
    <div v-if="!scene.hotspots.length">
      No Hotspots
    </div>

    <AddHotspotButton v-if="scene.hotspots.length > 4" class="mb-2" @click="handleAddHotspot" />

    <ul>
      <li
        v-for="(hs, index) in scene.hotspots"
        :key="index"
        class="flex flex-row items-center py-1 px-1 hover:bg-gray-300 dark:hover:bg-gray-900 rounded-md cursor-pointer transition duration-150"
        @click="() => routeToHotspot(index)"
      >
        <!-- Icon Thumbnail -->
        <div v-if="hs.iconUrl" class="w-16 p-0.25 h-16 rounded-sm">
          <img :src="hs.iconUrl" alt="" class="object-contain object-center h-full w-full" />
        </div>

        <button
          v-else
          class="flex flex-row items-center justify-center bg-gray-300 dark:bg-red-700 rounded p-2 font-medium"
        >
          <AlertTriangleIcon class="text-white mr-2" size="1x" />
          Icon
        </button>

        <!-- Text -->
        <div v-if="hs.link" class="pl-2 leading-tight flex-grow">
          <!-- Scene Name -->
          <p v-if="hs.targetSceneId" class="font-bold">
            {{ hs.targetSceneName }}
          </p>
          <p v-else class="font-bold flex flex-row items-center">
            No target scene
          </p>

          <!-- Env Name -->
          <p>{{ (hs.link && hs.link.envName) || 'no env' }}</p>
        </div>

        <div v-else class="pl-2 flex-grow">
          <button
            class="flex flex-row items-center justify-center bg-gray-300 dark:bg-gray-700 rounded pl-10 pr-12 py-2 font-medium"
          >
            <AlertTriangleIcon class="text-red-600 mr-2" size="1x" />
            Add Link
          </button>
        </div>

        <!-- ChevronRightIcon -->
        <button v-if="hs.link" type="button">
          <ChevronRightIcon />
        </button>
      </li>
    </ul>

    <AddHotspotButton class="mt-2" @click="handleAddHotspot" />
  </div>
</template>

<script>
import { addHotspotToItem } from '@/use/scenes.js'
import { handleClones } from '@rovit/utils/handle-clones.js'

import { AlertTriangleIcon, ChevronRightIcon, PlusIcon } from 'vue-feather-icons'
import AddHotspotButton from './AddHotspotButton.vue'

export default {
  name: 'SceneHotspotsList',
  components: {
    AlertTriangleIcon,
    ChevronRightIcon,
    AddHotspotButton
  },
  props: {
    scene: {
      type: Object,
      required: true
    },
    hotspotIndex: {
      validator: val => ['number', 'string', 'undefined'].includes(typeof val),
      default: null
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _scene } = clones
    const { save_scene } = saveHandlers

    function routeToHotspot(index) {
      const newRoute = Object.assign({}, context.root.$route)
      newRoute.query = Object.assign({}, newRoute.query, {
        sidebar: 'scene',
        hotspotIndex: index,
        panoTab: 'hotspot'
      })
      context.root.$router.push(newRoute)
    }

    async function handleAddHotspot() {
      await addHotspotToItem({ _item: _scene, save_scene })
      routeToHotspot(_scene.value.hotspots.length - 1)
    }

    return {
      routeToHotspot,
      handleAddHotspot
    }
  }
}
</script>

<style lang="postcss"></style>
