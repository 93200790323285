<template>
  <div>
    <label>Featured in Scene</label>
    <div
      class="px-3 p-2 bg-gray-300 dark:bg-gray-800 rounded leading-tight flex flex-row items-center"
    >
      <div class="flex-grow">
        <p class="text-xl font-bold">{{ scene.infoboxName || scene.name }}</p>
        <p v-if="scene.parentSceneId" class="text-sm">
          Child of
          <span class="font-medium">{{ parentScene.infoboxName || parentScene.name }}</span>
        </p>
      </div>
      <button type="button" class="form-button primary" @click="() => $emit('open-scene', scene)">
        Open <ExternalLinkIcon class="ml-2" size="1x" />
      </button>
    </div>
  </div>
</template>

<script>
import { ExternalLinkIcon } from 'vue-feather-icons'

export default {
  name: 'PanoFeaturedInScene',
  components: {
    ExternalLinkIcon
  },
  props: {
    scene: {
      validator: () => true,
      required: true
    },
    parentScene: {
      validator: () => true,
      default: null
    }
  }
}
</script>

<style lang="postcss"></style>
