<template>
  <div>
    <p v-if="scenes.length" class="content-subheading">
      These are the scenes in the
      <strong>{{ infobox && infobox.infoboxName }}</strong> infobox. Select a scene to edit it.
    </p>

    <GridSelector
      v-model="sceneProxy"
      :items="scenes"
      :label="i => (i.isInfobox ? i.infoboxName : i.name)"
      :src="i => src(i)"
      :fit="i => i.assetFit || 'cover'"
      :wrap="false"
      class="-mr-2 overflow-x-scroll"
      :class="[scenes.length ? 'mt-3' : '-mt-3']"
      @item-click="data => $emit('scene-click', data)"
    >
      <template #no-items>
        <div class="p-3">No Scenes</div>
      </template>

      <template #list-end>
        <div class="project-tile-wrapper relative">
          <router-link
            tag="button"
            type="button"
            class="w-full"
            :to="routeFor('add-scene')"
            :class="{
              active: $route.query.sidebar === 'add-scene'
            }"
          >
            <AspectRatio
              ratio="2:1"
              class="overflow-hidden rounded w-full bg-gray-400 dark:bg-gray-800 cursor-pointer border-2 dark:border-gray-700 rounded flex flex-row items-center justify-center"
            >
              <PlusIcon />
            </AspectRatio>
            <p class="text-center">Add Scene</p>
          </router-link>
        </div>
      </template>
    </GridSelector>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { thumbnail } from '@/use/image-transforms/index'

import GridSelector from '../GridSelector/GridSelector.vue'
import { AspectRatio } from '@rovit/aspect-ratio'
import { PlusIcon } from 'vue-feather-icons'

export default {
  name: 'InfoboxScenes',
  components: {
    GridSelector,
    AspectRatio,
    PlusIcon
  },
  props: {
    scenes: {
      type: Array,
      required: true
    },
    infobox: {
      validator: val => typeof val === 'object',
      required: true
    },
    service: {
      validator: val => typeof val === 'object',
      required: true
    },
    selectedScene: {
      validator: val => typeof val === 'object',
      required: true
    }
  },
  setup(props, context) {
    const sceneProxy = computed({
      get: () => props.selectedScene,
      set: val => context.emit('update:selectedScene', val)
    })

    const src = function (scene) {
      if (!scene) {
        return null
      }
      const url =
        scene.featureType === 'asset'
          ? scene.assetUrl
          : scene.featureType === 'pano'
          ? scene.panoUrl
          : ''
      return thumbnail(url).url
    }

    function routeFor(sidebar) {
      const newRoute = Object.assign({}, context.root.$route)
      const query = Object.assign({}, context.root.$route.query, { sidebar })
      Object.assign(newRoute, { query })
      return newRoute
    }
    return { thumbnail, sceneProxy, routeFor, src }
  }
}
</script>

<style lang="postcss"></style>
