<template>
  <div class="py-3">
    <h2 class="text-3xl font-semibold mb-2">{{ items.length }} Icons Selected</h2>

    <MoveCopyItems :items="items" :env-id="envId" class="mb-3" />

    <div v-for="item in items" :key="item._id" class="flex flex-row items-center mb-1">
      <ProjectTile :src="thumbnail(item.url).url" fit="contain" class="w-1/3" />

      <div class="w-2/3 h-full pl-2 font-medium">
        <FeathersVuexInputWrapper class="h-full" :item="item" prop="name">
          <template #default="{ current, prop, createClone, handler }">
            <input
              v-model="current[prop]"
              type="text"
              class="form-input block w-full"
              :placeholder="item.originalFileName"
              @focus="createClone"
              @blur="e => handler(e, save)"
            />
          </template>
        </FeathersVuexInputWrapper>
      </div>
    </div>
  </div>
</template>

<script>
import { thumbnail } from '@/use/image-transforms/index'

import ProjectTile from '../ProjectTile/ProjectTile'
import MoveCopyItems from '../MoveCopyItems.vue'

export default {
  name: 'SidebarBulkEdit',
  components: {
    ProjectTile,
    MoveCopyItems
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    envId: {
      type: String,
      default: null
    }
  },
  setup(props, context) {
    // Save Pano Name
    function save({ event, clone, prop, data }) {
      clone.commit().save({ data })
    }

    return {
      save,
      thumbnail
    }
  }
}
</script>

<style lang="postcss"></style>
