import { models, useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'
import { unwrapRefs } from './unwrap-ref.js'

export function getChartByName(options) {
  const { Chart } = models.api
  const params = computed(() => {
    const { name, startDate, endDate } = unwrapRefs(options)

    if (name && startDate && endDate) {
      const query = {
        name,
        startDate,
        endDate
      }
      return {
        query,
        paginate: true
      }
    } else {
      return null
    }
  })

  const queryWhen = computed(() => {
    return params || false
  })

  const { items } = useFind({
    model: Chart,
    params,
    queryWhen
  })

  const chartOptions = computed(() => {
    if (items.value && items.value.length) {
      return items.value[0].chartOptions
    }
    return {}
  })

  return { chartOptions }
}
