import Vue from 'vue'
import { computed } from '@vue/composition-api'
import { models } from 'feathers-vuex'

Vue.use({
  install(Vue) {
    const { Environment } = models.api

    Vue.prototype.$rovit = {
      env: computed(() => {
        const params = {
          query: { slug: 'rovit' },
          $populateParams: { name: 'app' }
        }
        const rovit = Environment.findInStore(params).data[0] || null
        return rovit
      })
    }
  }
})
