<template>
  <div>
    <TmContactInfoEditor
      :env="env"
      :service="service"
      :scene="scene"
      :module="module"
      :mapbox-token="$store.state.mapboxToken"
    />
  </div>
</template>

<script>
import { TmContactInfoEditor } from '@rovit/tm-contact-info'
export default {
  name: 'ServiceEditorContact',
  components: {
    TmContactInfoEditor
  },
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const env = {
      validator: val => true,
      default: null
    }
    const scene = {
      validator: val => true,
      default: null
    }
    const module = {
      validator: val => true,
      default: null
    }
    return {
      env,
      scene,
      module
    }
  }
}
</script>

<style lang="postcss"></style>
