<template>
  <XSelect
    v-model="envProxy"
    :items="environments"
    :label="i => i.name"
    placeholder="Select Environment"
    :create="handleCreateEnvironment"
    :clearable="clearable"
  />
</template>

<script>
import { computed } from '@vue/composition-api'
import { findEnvs, createEnv } from '@/use/environments.js'

import { XSelect } from '@rovit/x-select'

export default {
  name: 'EnvironmentSelect',
  components: {
    XSelect
  },
  model: {
    prop: 'environment'
  },
  props: {
    environment: {
      required: true,
      default: null,
      validator: () => true
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    const envProxy = computed({
      get: () => props.environment,
      set: val => context.emit('input', val)
    })
    const sort = { name: 1 }
    const queryWhen = computed(() => {
      return context.root.$store.state.environments.ids.length < 30
    })
    const { environments } = findEnvs({ sort, queryWhen })

    async function handleCreateEnvironment(name) {
      const { environment } = await createEnv({ name, org: currentOrg })
      return environment
    }

    return { envProxy, environments, handleCreateEnvironment }
  }
}
</script>

<style lang="postcss"></style>
