<template>
  <Layout>
    <template #content>
      <NavbarEnvironment :env="currentEnv" />

      <div class="px-12">
        <h1 class="content-heading-1">Environment Front Page</h1>

        <p class="content-subheading">
          These items appear on the front page of the
          <strong>{{ currentEnv && currentEnv.name }}</strong> environment. Drag and drop to
          reorder.
        </p>

        <div class="mt-4">
          <button
            type="button"
            class="bg-gray-300 dark:bg-gray-700 rounded px-4 py-1.5"
            @click="handleCreateItem"
          >
            Add Item
          </button>
        </div>

        <draggable
          v-model="frontPageItemsProxy"
          :animation="250"
          @start="drag = true"
          @end="drag = false"
        >
          <transition-group
            tag="div"
            type="transition"
            :name="!drag ? 'flip-list' : null"
            class="mt-3 flex flex-row flex-wrap -mx-1"
          >
            <div
              v-for="item in frontPageItemsProxy"
              :key="item._id"
              class="mx-1"
              @click="() => openItemInSidebar(item)"
            >
              <SceneTile
                :src="thumbnail(item.assetUrl, { fit: item.assetFit }).url"
                :fit="item.assetFit"
                :title="item.title || ''"
                :subtitle="item.subtitle || ''"
                :rating="false"
                :category="false"
              />
            </div>
          </transition-group>
        </draggable>
      </div>
    </template>

    <template #sidebar>
      <div>
        <EnvFrontPageSidebarTabs @editor-tab-click="showMostRecentEditor" />

        <EnvFrontPageSidebarSupport v-if="sidebarName === 'support'" />

        <EnvFrontPageSidebarEditorBlank
          v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
        />

        <EnvFrontPageSidebarEditItem
          v-if="sidebarName === 'editor' && sidebarEditorType === 'front-page-item'"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from '../../layouts/Layout'
import { ref, computed } from '@vue/composition-api'
import { getEnv } from '@/use/environments'
import { thumbnail } from '@/use/image-transforms/index'
import {
  getFrontPageItem,
  findFrontPageItems,
  createFrontPageItem
} from '@/use/front-page-items.js'
import useSidebarTabState from '@/use/sidebar-tab-state.js'

import NavbarEnvironment from '../NavbarEnvironment/NavbarEnvironment'
import { SceneTile } from '@rovit/scene-tiles'
import draggable from 'vuedraggable'

import EnvFrontPageSidebarTabs from './SidebarTabs.vue'
import EnvFrontPageSidebarSupport from './SidebarSupport.vue'
import EnvFrontPageSidebarEditorBlank from './SidebarEditorBlank.vue'
import EnvFrontPageSidebarEditItem from './SidebarEditItem.vue'

export default {
  name: 'EnvironmentFrontPage',
  metaInfo: {
    title: 'Environment Details'
  },
  components: {
    Layout,
    NavbarEnvironment,
    SceneTile,
    draggable,
    EnvFrontPageSidebarTabs,
    EnvFrontPageSidebarSupport,
    EnvFrontPageSidebarEditorBlank,
    EnvFrontPageSidebarEditItem
  },
  setup(props, context) {
    const { env: currentEnv } = getEnv(context.root.$route.params.envId)

    const { frontPageItems } = findFrontPageItems({ env: currentEnv })

    // Drag and Drop
    const frontPageItemsProxy = computed({
      get: () => frontPageItems.value,
      set: items => {
        items.forEach((i, index) => {
          i.clone({ sortOrder: index }).commit().save()
        })
      }
    })
    const drag = ref(false)

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      return context.root.$route.query.frontPageItemId ? 'front-page-item' : 'blank'
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      watchInQuery: ['frontPageItemId'],
      context
    })

    function openItemInSidebar(frontPageItem) {
      if (!frontPageItem) return
      const query = { frontPageItemId: frontPageItem._id, sidebar: 'editor' }
      context.root.$router.push({ query }, () => {})
    }

    function handleInput(val) {
      if (!val) {
        return
      }
      if (Array.isArray(val)) {
        if (val.length > 1) {
          // openBulkEditSidebar(val)
        } else {
          openItemInSidebar(val[0])
        }
      } else {
        openItemInSidebar(val)
      }
    }

    /**
     * Create a new frontPageItem then open it in the sidebar
     */
    async function handleCreateItem() {
      const { frontPageItem } = await createFrontPageItem({ env: currentEnv })

      openItemInSidebar(frontPageItem)
    }

    return {
      currentEnv,
      frontPageItems,
      thumbnail,

      // Drag and Drop
      frontPageItemsProxy,
      drag,

      // Sidebar
      showMostRecentEditor,
      sidebarName,
      sidebarEditorType,

      // Handlers
      handleCreateItem,
      openItemInSidebar
    }
  }
}
</script>

<style lang="postcss" scoped>
.flip-list-move {
  transition: transform 250ms;
}
</style>
