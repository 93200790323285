<template>
  <div @click="$emit('open-badge-selector')">
    <button
      v-if="selectedBadgesProxy.length === 0"
      type="button"
      class="bg-gray-400 dark:bg-gray-800 px-2 py-2 rounded text-center block w-3/4"
    >
      Select Badges
    </button>

    <button
      v-if="selectedBadgesProxy.length"
      type="button"
      class="flex flex-row items-center flex-wrap mt-1"
    >
      <BadgeTile
        v-for="badge in selectedBadgesProxy"
        :key="badge._id"
        :badge="badge"
        class="mr-2"
      />
    </button>
  </div>
</template>

<script>
import { computed, toRefs } from '@vue/composition-api'
import { findBadges } from '@/use/badges.js'
import _keyBy from 'lodash/keyBy.js'
import _get from 'lodash/get.js'
import { rovit } from '@/use/environments.js'

import BadgeTile from '../BadgeTile/BadgeTile.vue'

export default {
  name: 'BadgesMeta',
  components: {
    BadgeTile
  },
  model: {
    prop: 'badgesMeta'
  },
  props: {
    badgesMeta: {
      type: Array,
      default: () => []
    },
    env: {
      validator: val => typeof val === 'object',
      default: null
    }
  },
  setup(props, context) {
    const { env } = toRefs(props)
    const isRovitEnv = computed(() => {
      return env.value && rovit.value && env.value === rovit.value
    })
    const envIds = computed(() => {
      const envIds = []
      const envId = _get(env.value, '_id')
      const rovitEnvId = _get(rovit.value, '_id')
      if (envId != null) {
        envIds.push(envId)
      }
      if (rovitEnvId != null && !isRovitEnv.value) {
        envIds.push(rovitEnvId)
      }
      return envIds
    })

    const { badges: badgesForEnv } = findBadges({ env })
    const { badges: badgesForRovit } = findBadges({ env: rovit })
    const globalRovitBadges = computed(() => {
      return badgesForRovit.value.filter(badge => badge.isGlobal)
    })
    const allBadges = computed(() => badgesForEnv.value.concat(globalRovitBadges.value))
    const allBadgesById = computed(() => {
      return _keyBy(allBadges.value, '_id')
    })

    const selectedBadgesProxy = computed({
      // convert badgesMeta to badges
      get: () => {
        return props.badgesMeta
          .filter(bm => {
            return envIds.value.includes(bm.envId)
          })
          .map(bm => {
            return allBadgesById.value[bm.badgeId]
          })
          .filter(i => i)
      },
      set: val => {
        // Convert back to badgesMeta
        const newMeta = val.map(badge => {
          return {
            badgeId: badge._id,
            badgeName: badge.name,
            envId: badge.envId
          }
        })
        const allMeta = badgeMetaForOtherEnvs.value.concat(newMeta)
        context.emit('input', allMeta)
      }
    })

    return { selectedBadgesProxy }
  }
}
</script>

<style lang="postcss"></style>
