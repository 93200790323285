import Vue from 'vue'
import { Auth0Plugin } from './auth0'

const audience = 'https://api-v2.rovit.com'
const domain = process.env.VUE_APP_ROVIT_AUTH_DOMAIN
const client_id = process.env.VUE_APP_ROVIT_AUTH_CLIENT_ID

Vue.use(Auth0Plugin, {
  audience,
  domain,
  client_id
})
