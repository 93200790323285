<template>
  <Layout>
    <template #content>
      <NavbarAdmin />

      <div class="px-12">
        <div class="flex flex-row items-center">
          <h1 class="mb-0 content-heading-1">{{ currentOrg.name }} Environments</h1>
          <button class="ml-2 icon-button" @click="handleCreateEnv">
            <PlusIcon />
          </button>
        </div>

        <p class="content-subheading">
          This page shows all of your Org's environments.
          <span v-if="user.isRovitAdmin">As a Rovit Admin, you can see all environments.</span>
          Create as many as you would like. You only pay to publish. Click a project to quick-edit.
          Double click to open.
        </p>

        <div class="flex flex-row mt-2 items-center">
          <label class="w-full block">
            <!-- <span class="text-gray-700">Search</span> -->
            <input v-model="envSearch" class="w-full form-input block" placeholder="Search" />
          </label>

          <select v-if="user.isRovitAdmin" v-model="filterByOrg" class="ml-2 w-1/5 form-select">
            <option :value="false">All</option>
            <option :value="true">{{ currentOrg.name }}</option>
          </select>
        </div>

        <GridSelector
          v-model="selectedEnv"
          :items="environments"
          :label="i => i.name"
          :src="i => makeUrl(i)"
          :fit="i => i.primaryAssetFit || 'contain'"
          class="mt-3 -mr-2"
          @item-click="handleEnvClick"
          @item-dblclick="handleEnvDblClick"
        />
      </div>
    </template>

    <template #sidebar>
      <div>
        <OrgProjectsSidebarTabs @editor-tab-click="showMostRecentEditor" />

        <OrgProjectsSidebarSupport v-if="sidebarName === 'support'" />

        <OrgProjectsSidebarEditorBlank
          v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
        />
        <OrgProjectsSidebarEditorEnvironment
          v-if="sidebarName === 'editor' && sidebarEditorType === 'environment'"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import useSidebarTabState from '@/use/sidebar-tab-state'
import { models, useFind } from 'feathers-vuex'
import { createEnv, findEnvs } from '@/use/environments'

import Layout from '../layouts/Layout'
import { PlusIcon } from 'vue-feather-icons'
import NavbarAdmin from '../components/NavbarAdmin/NavbarAdmin'

import GridSelector from '../components/GridSelector/GridSelector'
import OrgProjectsSidebarTabs from '../components/OrgProjects/SidebarTabs'
import OrgProjectsSidebarSupport from '../components/OrgProjects/SidebarSupport'
import OrgProjectsSidebarEditorBlank from '../components/OrgProjects/SidebarEditorBlank'
import OrgProjectsSidebarEditorEnvironment from '../components/OrgProjects/SidebarEditorEnvironment'

export default {
  name: 'OrgEnvironments',
  components: {
    PlusIcon,
    Layout,
    NavbarAdmin,
    GridSelector,
    OrgProjectsSidebarTabs,
    OrgProjectsSidebarSupport,
    OrgProjectsSidebarEditorBlank,
    OrgProjectsSidebarEditorEnvironment
  },
  setup(props, context) {
    const { Environment } = models.api
    const user = context.root.$store.state.auth.user
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    const selectedEnv = ref(null)

    /* Load Environments */
    const envSearch = ref('')
    const filterByOrg = ref(false)
    const { environments } = findEnvs({
      org: currentOrg,
      filterByOrg,
      search: envSearch
    })

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      return context.root.$route.query.envId ? 'environment' : 'blank'
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      watchInQuery: ['envId'],
      context
    })

    /**
     * Create an env and open it in the sidebar
     */
    async function handleCreateEnv() {
      const env = await createEnv({ org: currentOrg })
      openEnvInSidebar(env)
    }

    /**
     * Open the provided env in the sidebar.
     */
    function openEnvInSidebar(env) {
      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'editor',
        envId: env._id
      })
      context.root.$router.push({ query }, () => false)
    }

    /**
     * Handles the currently selected environment(s). A different sidebar is shown for
     * arrays vs single objects.
     */
    function handleEnvClick({ item, e }) {
      if (Array.isArray(selectedEnv.value)) {
        if (!selectedEnv.value.length) return
        // TODO: handle when multiple orgs are in `selectedEnv` array.
      } else {
        if (!selectedEnv.value) return
        openEditor({ envId: selectedEnv.value._id })
      }
    }
    /**
     * When an environment is double-clicked, switch to it and open its dashboard page.
     */
    function handleEnvDblClick({ item, e }) {
      context.root.$router.push(
        {
          name: 'EnvironmentServices',
          params: { envId: item._id }
        },
        () => false
      )
    }

    function makeUrl(i) {
      return i.primaryAssetUrl
        ? `//images.weserv.nl/?url=${i.primaryAssetUrl}&w=660&h=330&fit=inside&il`
        : ''
    }

    return {
      environments,
      envSearch,
      showMostRecentEditor,
      sidebarName,
      sidebarEditorType,
      selectedEnv,
      handleCreateEnv,
      handleEnvClick,
      handleEnvDblClick,
      user,
      currentOrg,
      filterByOrg,
      makeUrl
    }
  }
}
</script>

<style lang="postcss" scoped></style>
