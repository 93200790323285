<template>
  <div>
    <p class="sidebar-heading-1">Environments</p>

    <p class="sidebar-subheading">
      This {{ service ? 'service' : 'infobox' }} appears in the following environments.
    </p>

    <div class="permission-manager bg-white rounded border border-gray-400">
      <div v-if="!envServices.length" class="py-2 text-left pl-3 rounded cursor-not-allowed">
        None
      </div>

      <div v-if="envServices.length">
        <ServiceEnvServiceRow
          v-for="envService in envServices"
          :key="envService._id"
          :env-service="envService"
          :service="service"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { watch, computed, ref } from '@vue/composition-api'
import { findEnvServicesByService, findEnvServicesByScene } from '@/use/env-services.js'

import ServiceEnvServiceRow from './ServiceEnvServiceRow.vue'

export default {
  name: 'ServiceEnvServices',
  components: {
    ServiceEnvServiceRow
  },
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const service = computed(() => props.service)
    const scene = computed(() => props.scene)
    const { envServices } = props.service
      ? findEnvServicesByService({ service })
      : findEnvServicesByScene({ scene })

    /**
     * Adds the provided permission to the envServices array and emits the array
     * in the `input` event for the v-model.  It emits the input event first, to
     * update the value in the parent.  Then it emits the change event, which is
     * used to auto-save the record.
     */
    function add({ org, user, accessType }) {
      const envServices = props.envServices.slice()

      if (org) {
        envServices.push({
          orgId: org._id,
          orgName: org.name,
          accessType
        })
      } else if (user) {
        envServices.push({
          userId: user._id,
          userName: user.name,
          accessType
        })
      }

      context.emit('input', envServices)
      context.emit('change', envServices)
    }

    /**
     * Update the permission at the index with the accessType. It emits the input
     * event first, to update the value in the parent.  Then it emits the change
     * event, which is used to auto-save the record.
     */
    function update(index, accessType) {
      const envServices = props.envServices.slice()
      envServices[index].accessType = accessType
      context.emit('input', envServices)
      context.emit('change', envServices)
    }

    /**
     * Remove an item from the envServices. It emits the input event first, to
     * update the value in the parent.  Then it emits the change event, which is
     * used to auto-save the record.
     */
    function remove(index) {
      const envServices = props.envServices.slice()
      envServices.splice(index, 1)
      context.emit('input', envServices)
      context.emit('change', envServices)
    }

    return {
      envServices,
      add,
      update,
      remove
    }
  }
}
</script>

<style lang="postcss">
.permission-row {
  /* @apply rounded; */
}
@screen dark {
  .permission-manager,
  .permission-creator {
    @apply bg-gray-800 text-gray-400 border-gray-700;
  }
  .select-label {
    @apply text-gray-300;
  }
  .permission-row {
    @apply border-gray-700;
  }
  .permission-row:hover {
    @apply bg-gray-900 border-gray-700;
  }
  .x-select-button,
  .x-select-button > div {
    @apply bg-gray-800 text-gray-400 border-gray-700;
  }
}
</style>
