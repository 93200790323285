var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('NavbarEnvironment',{attrs:{"env":_vm.currentEnv}}),_c('div',{staticClass:"px-12"},[_c('h1',{staticClass:"content-heading-1"},[_vm._v("Environment Services")]),_c('p',{staticClass:"content-subheading"},[_vm._v(" These services are in the "),_c('strong',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.currentEnv && _vm.currentEnv.name))]),_vm._v(" environment. Click a service to edit it. You can also add new or existing services. ")]),_c('button',{staticClass:"bg-green-700 text-white px-4 py-2 rounded my-2",on:{"click":_vm.openAddServiceSidebar}},[_vm._v(" Add Service ")]),_c('div',{staticClass:"flex flex-row items-center mt-2"},[_c('label',{staticClass:"block"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.serviceSearch),expression:"serviceSearch"}],staticClass:"form-input block w-full",attrs:{"placeholder":"Search"},domProps:{"value":(_vm.serviceSearch)},on:{"input":function($event){if($event.target.composing){ return; }_vm.serviceSearch=$event.target.value}}})]),_c('FeathersVuexPagination',{attrs:{"latest-query":_vm.latestServicesQuery},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var currentPage = ref.currentPage;
            var pageCount = ref.pageCount;
            var toStart = ref.toStart;
            var toEnd = ref.toEnd;
            var toPage = ref.toPage;
            var next = ref.next;
            var prev = ref.prev;
            var canNext = ref.canNext;
            var canPrev = ref.canPrev;
return [_c('SidebarPagination',{staticClass:"ml-2",staticStyle:{"margin-top":"0"},attrs:{"current-page":currentPage,"page-count":pageCount,"can-prev":canPrev,"can-next":canNext},on:{"to-start":toStart,"to-end":toEnd,"to-page":toPage,"next":next,"prev":prev}})]}}]),model:{value:(_vm.servicesPagination),callback:function ($$v) {_vm.servicesPagination=$$v},expression:"servicesPagination"}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.viewType),expression:"viewType"}],staticClass:"form-select ml-2 w-1/5",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.viewType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"grid"}},[_vm._v("Grid")]),_c('option',{attrs:{"value":"table"}},[_vm._v("Table")])])],1),(_vm.viewType === 'grid')?_c('GridSelector',{staticClass:"mt-3 -mr-2",attrs:{"items":_vm.services,"label":function (i) { return i.name; },"src":function (i) { return _vm.thumbnail(i.primaryAssetUrl).url; },"fit":function (i) { return function (i) { return i.primaryAssetFit; }; }},on:{"item-click":_vm.handleServiceClick,"item-dblclick":_vm.handleServiceDblClick},model:{value:(_vm.selectedService),callback:function ($$v) {_vm.selectedService=$$v},expression:"selectedService"}}):_c('TableSelector',{attrs:{"items":_vm.services},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Image")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Orgs")]),_c('th',[_vm._v("Location")]),_c('th',{staticClass:"px-1"},[_vm._v("Infoboxes")])])])]},proxy:true},{key:"default",fn:function(ref){
            var item = ref.item;
            var isSelected = ref.isSelected;
            var select = ref.select;
return [_c('ServicesTableRow',{attrs:{"service":item,"is-selected":isSelected,"select":select},on:{"item-click":_vm.handleServiceClick,"item-dblclick":_vm.handleServiceDblClick}})]}}]),model:{value:(_vm.selectedService),callback:function ($$v) {_vm.selectedService=$$v},expression:"selectedService"}})],1)]},proxy:true},{key:"sidebar",fn:function(){return [_c('div',[_c('EnvServicesSidebarTabs',{on:{"editor-tab-click":_vm.showMostRecentEditor,"add-service-click":_vm.openAddServiceSidebar}}),(_vm.sidebarName === 'support')?_c('EnvServicesSidebarSupport'):_vm._e(),(_vm.sidebarName === 'editor' && _vm.sidebarEditorType === 'blank')?_c('EnvServicesSidebarEditorBlank'):_vm._e(),(_vm.sidebarName === 'editor' && _vm.sidebarEditorType === 'env-service')?_c('EnvServicesSidebarEditorEnvService'):_vm._e(),(_vm.sidebarName === 'add-service')?_c('EnvServicesSidebarAddService',{on:{"create-service":_vm.handleCreateService,"add-service":_vm.handleAddService}}):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }