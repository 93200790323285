import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'
import shouldFilterServiceKeys from '../../utils/fetch-variable-from-env'
import { iff, discard, alterItems } from 'feathers-hooks-common'
import _pick from 'lodash/pick.js'

class Org extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Org'
  // Define default properties here
  static instanceDefaults() {
    return {
      name: '',
      primaryAssetFit: 'cover'
    }
  }
  static setupInstance(data, { models }) {
    if (data.orgUsers) {
      data.orgUsers = data.orgUsers.map(ou => new models.api.OrgUser(ou))
    }
    return data
  }
}
const servicePath = 'orgs'
const servicePlugin = makeServicePlugin({
  Model: Org,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [
      iff(
        context => ['create', 'update', 'patch'].includes(context.method),
        discard('orgUsers'),
        iff(
          shouldFilterServiceKeys(),
          alterItems(i =>
            _pick(i, [
              '_id',
              'name',
              'primaryAssetId',
              'primaryAssetUrl',
              'primaryAssetFit',
              'mapboxToken',
              'isPersonalOrgOf',
              'nameOfOwner',
              'createdBy',
              'createdAt',
              'updatedAt'
            ])
          )
        )
      )
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
