<template>
  <div>
    <label class="font-medium">Move/Copy to Environment</label>
    <div class="flex flex-row items-center">
      <XSelect
        v-model="selectedEnv"
        :search.sync="envSearch"
        :items="environments"
        :label="i => i.name"
        placeholder="Select Environment"
        class="w-full"
      />
      <button
        type="button"
        class="form-button primary py-1 ml-1"
        :disabled="isDisabled"
        @click="moveToEnv"
      >
        Move
      </button>
      <!-- <button
        type="button"
        class="form-button primary py-1 ml-1"
        :disabled="isDisabled"
        @click="copyToEnv"
      >
        Copy
      </button> -->
    </div>
    <div class="leading-tight py-1">
      No validations are done before moving. Make sure you're not using the item in this
      environment.
    </div>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { findEnvs } from '@/use/environments.js'
import _omit from 'lodash/omit.js'

import { XSelect } from '@rovit/x-select'

export default {
  name: 'MoveCopyItems',
  components: {
    XSelect
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    envId: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    /* Load Environments */
    const { envId: currentEnvId } = props
    const selectedEnv = ref(null)
    const isDisabled = ref(true)
    const envSearch = ref('')
    const { environments } = findEnvs({ search: envSearch })

    /* If selectedEnv is currentEnv then disable move and copy buttons */
    watch(
      () => selectedEnv.value,
      value => {
        if (!value) {
          isDisabled.value = true
        }
        if (value._id === currentEnvId) {
          isDisabled.value = true
        } else {
          isDisabled.value = false
        }
      }
    )

    // Move Item to another Environment
    function moveToEnv() {
      const ids = []
      if (!selectedEnv.value) {
        return
      }
      props.items.forEach(item => {
        if (item.envId === selectedEnv.value._id) {
          return
        }
        item.clone({ envId: selectedEnv.value._id }).save()
        ids.push(item._id)
      })
      context.emit('moved', ids, selectedEnv.value)
    }

    // Move Item to another Environment
    function copyToEnv() {
      const ids = []
      if (!selectedEnv.value) {
        return
      }
      props.items.forEach(item => {
        if (item.envId === selectedEnv.value._id) {
          return
        }
        const clone = item.clone({ envId: selectedEnv.value._id })
        const newData = Object.assign({}, _omit(clone, ['_id']))
        new item.constructor(newData).save()
        ids.push(item._id)
      })
      context.emit('copied', ids, selectedEnv.value)
    }
    return {
      selectedEnv,
      envSearch,
      environments,
      moveToEnv,
      copyToEnv,
      isDisabled
    }
  }
}
</script>

<style lang="postcss"></style>
