import feathersClient, { makeServicePlugin, models, BaseModel } from '../../feathers-client'
import { makeModel } from '@rovit/models/Service'
import shouldFilterServiceKeys from '../../utils/fetch-variable-from-env'
import { iff, alterItems } from 'feathers-hooks-common'
import _pick from 'lodash/pick.js'

const servicePath = 'services'
const servicePlugin = makeServicePlugin({
  Model: makeModel({ BaseModel, models }),
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [
      iff(
        context => ['create', 'update', 'patch'].includes(context.method),
        iff(
          shouldFilterServiceKeys(),
          alterItems(i =>
            _pick(i, [
              '_id',
              'name',
              'shortDescription',
              'description',
              'location',

              'envsMeta',
              'categoriesMeta',
              'badgesMeta',

              'logoAssetId',
              'logoAssetUrl',
              'logoAssetFit',

              'primaryAssetId',
              'primaryAssetUrl',
              'primaryAssetFit',

              'phone',
              'email',
              'website',
              'address1',
              'address2',
              'city',
              'state',
              'zipcode',
              'countryCode',

              'users',
              'orgs',
              'scheduleConfig',

              'createdBy',
              'createdAt',
              'updatedAt'
            ])
          )
        )
      )
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
