import { models, useFind, useGet } from 'feathers-vuex'
import { computed, reactive, watch } from '@vue/composition-api'
import { unwrapRef } from './unwrap-ref'
import _get from 'lodash/get.js'

export async function createBadgeGroup({ env }) {
  const { BadgeGroup } = models.api

  const _env = unwrapRef(env)

  const badgeGroup = await new BadgeGroup({
    name: 'New Badge Group',
    envId: _env._id,
    sortOrder: 0
  }).save()

  return { badgeGroup }
}

export function getBadgeGroup(id) {
  const { BadgeGroup } = models.api
  const params = computed(() => {
    return {
      $populateParams: {
        name: ''
      }
    }
  })
  const queryWhen = computed(() => {
    const existing = BadgeGroup.getFromStore(id.value)
    return id.value && !existing
  })
  const { item: badgeGroup } = useGet({
    model: BadgeGroup,
    id,
    params,
    queryWhen
  })

  return { badgeGroup }
}

// Tracks which envs have had their badges loaded.
const haveQueriedByEnvId = reactive({})

export function findBadgeGroups({ env }) {
  const { BadgeGroup } = models.api
  const params = computed(() => {
    const envId = _get(env.value, '_id')
    if (!envId) {
      return null
    }
    const query = { envId }
    return { query, $populateParams: { name: 'admin' } }
  })
  const queryWhen = computed(() => {
    const envId = _get(env.value, '_id')
    if (!envId || haveQueriedByEnvId[envId]) {
      return false
    }
    return true
  })
  const { items: badgeGroups, haveBeenRequested } = useFind({
    model: BadgeGroup,
    params,
    queryWhen
  })

  // Once the items for an environment have been requested, add them to the query tracker to prevent repeats.
  const stop = watch(
    () => haveBeenRequested.value,
    val => {
      if (haveBeenRequested.value) {
        haveQueriedByEnvId[env.value._id] = true
        stop() // manually stop the watcher
      }
    }
  )

  return { badgeGroups }
}
