<template>
  <div class="group">
    <div
      class="bg-blue-900 rounded-t px-3 py-1 font-medium group-hover:bg-blue-800 cursor-pointer"
      @click="openSceneSidebar"
    >
      Video Feature
    </div>
    <div class="border border-blue-900 group-hover:border-blue-800 rounded-b p-3">
      Video goes here
    </div>
  </div>
</template>

<script>
import _isEqual from 'lodash/isEqual.js'

export default {
  name: 'SceneFeatureVideo',
  setup(props, context) {
    /**
     * Open the scene editor sidebar.
     */
    function openSceneSidebar() {
      const currentQuery = context.root.$route.query
      const query = Object.assign({}, currentQuery, { sidebar: 'scene' })
      if (!_isEqual(currentQuery, query)) {
        context.root.$router.push({ query })
      }
    }

    return { openSceneSidebar }
  }
}
</script>

<style lang="postcss"></style>
