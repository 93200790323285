import Vue from 'vue'
import VueRouter from 'vue-router'
import OrgHome from '../views/OrgHome.vue'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    name: 'OrgHome',
    path: '/',
    component: OrgHome
  },
  {
    name: 'UserOrgs',
    path: '/orgs',
    component: () => import(/* webpackChunkName: "user-orgs" */ '../views/UserOrgs.vue')
  },
  {
    name: 'UserProfile',
    path: '/profile',
    component: () => import(/* webpackChunkName: "user-profile" */ '../views/UserProfile.vue')
  },
  {
    name: 'UserSettings',
    path: '/settings',
    component: () => import(/* webpackChunkName: "user-settings" */ '../views/UserSettings.vue')
  },
  {
    name: 'OrgEnvironments',
    path: '/environments',
    component: () =>
      import(/* webpackChunkName: "org-environments" */ '../views/OrgEnvironments.vue')
  },
  {
    name: 'OrgServices',
    path: '/services',
    component: () =>
      import(/* webpackChunkName: "org-services" */ '../components/OrgServices/OrgServices.vue')
  },
  {
    name: 'OrgServiceInfoboxes',
    path: '/services/:serviceId/infoboxes',
    component: () =>
      import(/* webpackChunkName: "org-services" */ '../views/OrgServiceInfoboxes.vue')
  },
  {
    name: 'OrgServiceDetails',
    path: '/services/:serviceId/details',
    component: () =>
      import(/* webpackChunkName: "org-service-details" */ '../views/OrgServiceDetails.vue')
  },
  {
    name: 'OrgServiceAnalytics',
    path: '/services/:serviceId/analytics',
    component: () =>
      import(/* webpackChunkName: "org-service-analytics" */ '../views/OrgServiceAnalytics.vue')
  },
  {
    name: 'OrgServiceTeam',
    path: '/services/:serviceId/team',
    component: () =>
      import(/* webpackChunkName: "org-service-team" */ '../views/OrgServiceTeam.vue')
  },
  {
    name: 'OrgServiceBilling',
    path: '/services/:serviceId/billing',
    component: () =>
      import(/* webpackChunkName: "org-service-billing" */ '../views/OrgServiceBilling.vue')
  },
  {
    name: 'InfoboxEditor',
    path: '/services/:serviceId/infoboxes/:infoboxId',
    component: () =>
      import(
        /* webpackChunkName: "infobox-editor" */ '../components/InfoboxEditor/InfoboxEditor.vue'
      )
  },
  {
    name: 'InfoboxPublish',
    path: '/services/:serviceId/infoboxes/:infoboxId/publish',
    component: () => import(/* webpackChunkName: "infobox-publish" */ '../views/InfoboxPublish.vue')
  },
  {
    name: 'InfoboxAnalytics',
    path: '/services/:serviceId/infoboxes/:infoboxId/analytics',
    component: () =>
      import(/* webpackChunkName: "infobox-analytics" */ '../views/InfoboxAnalytics.vue')
  },
  {
    name: 'InfoboxBilling',
    path: '/services/:serviceId/infoboxes/:infoboxId/billing',
    component: () => import(/* webpackChunkName: "infobox-billing" */ '../views/InfoboxBilling.vue')
  },
  {
    name: 'InfoboxTeam',
    path: '/services/:serviceId/infoboxes/:infoboxId/team',
    component: () => import(/* webpackChunkName: "infobox-team" */ '../views/InfoboxTeam.vue')
  },
  {
    name: 'OrgLibrary',
    path: '/library',
    component: () => import(/* webpackChunkName: "org-library" */ '../views/OrgLibrary.vue')
  },
  {
    name: 'OrgPeople',
    path: '/team',
    component: () => import(/* webpackChunkName: "org-team" */ '../views/OrgPeople.vue')
  },
  {
    name: 'OrgBilling',
    path: '/billing',
    component: () => import(/* webpackChunkName: "org-billing" */ '../views/OrgBilling.vue')
  },
  {
    name: 'EnvironmentServices',
    path: '/environments/:envId/services',
    component: () =>
      import(
        /* webpackChunkName: "env-services" */ '../components/EnvironmentServices/EnvironmentServices.vue'
      )
  },
  {
    name: 'EnvironmentFrontPage',
    path: '/environments/:envId/front-page',
    component: () =>
      import(
        /* webpackChunkName: "env-front-page" */ '../components/EnvironmentFrontPage/EnvironmentFrontPage.vue'
      )
  },
  {
    name: 'EnvironmentLibrary',
    path: '/environments/:envId/library-summary',
    component: () => import(/* webpackChunkName: "env-library" */ '../views/EnvironmentLibrary.vue')
  },
  {
    name: 'EnvironmentAnalytics',
    path: '/environments/:envId/analytics',
    component: () =>
      import(/* webpackChunkName: "env-analytics" */ '../views/EnvironmentAnalytics.vue')
  },
  {
    name: 'EnvironmentTeam',
    path: '/environments/:envId/team',
    component: () => import(/* webpackChunkName: "env-team" */ '../views/EnvironmentTeam.vue')
  },
  {
    name: 'EnvironmentBilling',
    path: '/environments/:envId/billing',
    component: () => import(/* webpackChunkName: "env-billing" */ '../views/EnvironmentBilling.vue')
  },

  /**
   * Environment Service Routes
   */
  {
    name: 'EnvServiceInfoboxEditor',
    path: '/environments/:envId/services/:serviceId/infobox/:infoboxId?',
    component: () =>
      import(
        /* webpackChunkName: "infobox-editor" */ '../components/InfoboxEditor/InfoboxEditor.vue'
      )
  },
  {
    name: 'EnvServicePublish',
    path: '/environments/:envId/services/:serviceId/publish',
    component: () =>
      import(/* webpackChunkName: "env-service-publish" */ '../views/EnvServicePublish.vue')
  },
  {
    name: 'EnvServiceAnalytics',
    path: '/environments/:envId/services/:serviceId/analytics',
    component: () =>
      import(/* webpackChunkName: "env-service-analytics" */ '../views/EnvServiceAnalytics.vue')
  },
  {
    name: 'EnvServiceTeam',
    path: '/environments/:envId/services/:serviceId/team',
    component: () =>
      import(/* webpackChunkName: "env-service-team" */ '../views/EnvServiceTeam.vue')
  },
  {
    name: 'EnvServiceBilling',
    path: '/environments/:envId/services/:serviceId/billing',
    component: () =>
      import(/* webpackChunkName: "env-service-billing" */ '../views/EnvServiceBilling.vue')
  },

  /**
   * Environment Library Routes
   */
  {
    name: 'EnvLibStore',
    path: '/environments/:envId/library/store',
    component: () => import(/* webpackChunkName: "env-lib-store" */ '../views/EnvLibStore.vue')
  },
  {
    name: 'EnvLibCategories',
    path: '/environments/:envId/library/categories',
    component: () =>
      import(
        /* webpackChunkName: "env-lib-categories" */ '../components/EnvLibCategories/EnvLibCategories.vue'
      )
  },
  {
    name: 'EnvLibBadges',
    path: '/environments/:envId/library/badges',
    component: () =>
      import(/* webpackChunkName: "env-lib-badges" */ '../components/EnvLibBadges/EnvLibBadges.vue')
  },
  {
    name: 'EnvLibIcons',
    path: '/environments/:envId/library/icons',
    component: () =>
      import(/* webpackChunkName: "env-lib-icons" */ '../components/EnvLibIcons/EnvLibIcons.vue')
  },
  {
    name: 'EnvLibPhotos',
    path: '/environments/:envId/library/photos',
    component: () =>
      import(/* webpackChunkName: "env-lib-photos" */ '../components/EnvLibPhotos/EnvLibPhotos.vue')
  },
  {
    name: 'EnvLibPanos',
    path: '/environments/:envId/library/panos',
    component: () =>
      import(/* webpackChunkName: "env-lib-photos" */ '../components/EnvLibPanos/EnvLibPanos.vue')
  },
  {
    name: 'EnvLibLinkedEnvs',
    path: '/environments/:envId/library/linked-envs',
    component: () =>
      import(/* webpackChunkName: "env-lib-linked-envs" */ '../views/EnvLibLinkedEnvs.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
