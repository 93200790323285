<template>
  <div class="sidebar-editor-infobox">
    <InfoboxForm v-if="infobox" :infobox="infobox" :scenes="scenes" />

    <div v-else>
      Loading Infobox
    </div>
  </div>
</template>

<script>
import InfoboxForm from '../InfoboxForm/InfoboxForm'

export default {
  name: 'InfoboxSidebarInfobox',
  components: {
    InfoboxForm
  },
  props: {
    infobox: {
      validator: val => true,
      default: null
    },
    scenes: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="postcss"></style>
