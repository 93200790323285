<template>
  <Layout>
    <template #content>
      <NavbarEnvironmentLibrary :env="currentEnv" />

      <div class="px-12" @dragenter="openBulkUploadSidebar">
        <div class="flex flex-row items-center">
          <h1 class="content-heading-1 mr-3">Icons</h1>

          <button
            class="button-with-icon bg-green-600 rounded-full ml-2"
            @click="openBulkUploadSidebar"
          >
            <UploadCloudIcon size="1x" class="mr-2" />
            Upload
          </button>
        </div>

        <p class="content-subheading">
          Manage icons for the
          <strong>{{ currentEnv && currentEnv.name }}</strong> environment.
        </p>

        <div v-if="icons.length" class="relative text-right" style="bottom: 24px;">
          <button
            type="button"
            class="text-green-700 dark:text-green-500"
            @click="isEditEnabled = !isEditEnabled"
          >
            <span v-if="isEditEnabled">Done</span>
            <span v-else>Edit</span>
          </button>
          <hr class="border-gray-300 dark:border-gray-800" />
        </div>

        <GridSelector
          v-if="icons.length"
          v-model="selected"
          :items="icons"
          :label="i => i.name || i.originalFileName"
          :src="makeSrc"
          :fit="i => 'contain'"
          :multiple="isEditEnabled"
          class="mt-3 -mr-2"
          @input="handleInput"
        />

        <div v-else>
          <EnvLibEmptyState
            item-type="Icons"
            :items-have-loaded="iconsHaveLoaded"
            @button-click="openBulkUploadSidebar"
          />
        </div>
      </div>
    </template>

    <template #sidebar>
      <EnvLibIconsSidebarTabs
        @editor-tab-click="showMostRecentEditor"
        @upload-tab-click="openBulkUploadSidebar"
      />

      <EnvLibIconsSidebarSupport v-if="sidebarName === 'support'" />

      <EnvLibIconsSidebarEditorBlank
        v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
      />
      <EnvLibIconsSidebarEditIcon
        v-if="sidebarName === 'editor' && sidebarEditorType === 'icon' && currentIcon"
        :icon="currentIcon"
        :env-id="currentEnv && currentEnv._id"
      />
      <EnvLibIconsSidebarBulkEdit
        v-if="sidebarName === 'bulk-edit' && selected && selected.length"
        :items="selected"
        :env-id="currentEnv && currentEnv._id"
      />

      <EnvLibIconsSidebarBulkUpload
        v-if="sidebarName === 'upload'"
        class="mt-2"
        :env-id="currentEnv && currentEnv._id"
        @icon-click="openItemEditSidebar"
      />
    </template>
  </Layout>
</template>

<script>
import Layout from '../../layouts/Layout'
import { computed, ref, onUpdated, watch } from '@vue/composition-api'
import { models, FeathersVuexInputWrapper } from 'feathers-vuex'
import useSidebarTabState from '@/use/sidebar-tab-state.js'
import { getEnv } from '@/use/environments.js'
import { findIcons, getIcon } from '@/use/icons.js'
import { thumbnail } from '@/use/image-transforms/index'

import NavbarEnvironmentLibrary from '../NavbarEnvironmentLibrary/NavbarEnvironmentLibrary'
import EnvLibEmptyState from '../EnvLibEmptyState/EnvLibEmptyState'
import { UploadCloudIcon } from 'vue-feather-icons'
import GridSelector from '../GridSelector/GridSelector.vue'

import EnvLibIconsSidebarTabs from './SidebarTabs'
import EnvLibIconsSidebarSupport from './SidebarSupport'
import EnvLibIconsSidebarEditorBlank from './SidebarEditorBlank'
import EnvLibIconsSidebarEditIcon from './SidebarEditIcon'
import EnvLibIconsSidebarBulkUpload from './SidebarBulkUpload.vue'
import EnvLibIconsSidebarBulkEdit from './SidebarBulkEdit.vue'

export default {
  name: 'EnvLibIcons',
  metaInfo: {
    title: 'Env Library Icons'
  },
  components: {
    Layout,
    NavbarEnvironmentLibrary,
    UploadCloudIcon,
    EnvLibIconsSidebarTabs,
    EnvLibIconsSidebarSupport,
    EnvLibIconsSidebarEditorBlank,
    EnvLibIconsSidebarEditIcon,
    EnvLibIconsSidebarBulkUpload,
    EnvLibIconsSidebarBulkEdit,
    EnvLibEmptyState,
    GridSelector
  },
  setup(props, context) {
    const { env: currentEnv } = getEnv(context.root.$route.params.envId)

    // Icons
    const { icons, iconsHaveLoaded } = findIcons({ env: currentEnv })

    const isEditEnabled = ref(false)

    // Current Icon
    const iconId = computed(() => context.root.$route.query.iconId)
    const { icon: currentIcon } = getIcon(iconId)

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      return context.root.$route.query.iconId ? 'icon' : 'blank'
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      watchInQuery: ['iconId'],
      context
    })

    function openItemEditSidebar(icon) {
      if (!icon) return
      const query = { iconId: icon._id, sidebar: 'editor' }
      context.root.$router.push({ query }, () => {})
    }

    function openBulkUploadSidebar() {
      const query = { sidebar: 'upload' }
      context.root.$router.push({ query }, () => {})
    }
    function openBulkEditSidebar() {
      const query = { sidebar: 'bulk-edit' }
      context.root.$router.push({ query }, () => {})
    }

    function save({ event, clone, prop, data }) {
      clone.commit().save({ data })
    }

    function makeSrc(i) {
      return i.originalFileName.includes('svg') ? i.url : thumbnail(i.url).url
    }

    const selected = ref(null)
    function handleInput(val) {
      if (!val) {
        return
      }
      if (Array.isArray(val)) {
        if (val.length > 1) {
          openBulkEditSidebar(val)
        } else {
          openItemEditSidebar(val[0])
        }
      } else {
        openItemEditSidebar(val)
      }
    }

    return {
      currentEnv,
      icons,
      iconsHaveLoaded,
      currentIcon,
      thumbnail,

      // GridSelector
      selected,
      handleInput,

      makeSrc,

      // Sidebar
      sidebarName,
      sidebarEditorType,
      showMostRecentEditor,
      openItemEditSidebar,
      openBulkUploadSidebar,
      isEditEnabled,
      save
    }
  }
}
</script>

<style lang="postcss" scoped></style>
