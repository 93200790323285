<template>
  <div>
    <GridSelector
      v-if="badges.length"
      v-model="selectedProxy"
      :items="badges"
      :label="i => i.name || i.name"
      :src="i => thumbnail(i.iconUrl).url"
      :fit="i => 'contain'"
      :multiple="isEditEnabled"
      class="mt-3 -mr-2"
      @input="val => $emit('input', val)"
    >
      <template #item="{ item, select, isSelected, handleItemClick}">
        <BadgeTile
          :badge="item"
          :is-selected="isSelected"
          @click="e => handleItemClick({ e, item, select })"
        />
      </template>
    </GridSelector>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { thumbnail } from '@/use/image-transforms/index'

import GridSelector from '../GridSelector/GridSelector.vue'
import BadgeTile from '../BadgeTile/BadgeTile.vue'

export default {
  name: 'BadgeGrid',
  components: {
    GridSelector,
    BadgeTile
  },
  model: {
    prop: 'selected'
  },
  props: {
    badges: {
      type: Array,
      default: () => []
    },
    selected: {
      validator: val => typeof val == 'object',
      default: null
    }
  },
  setup(props, context) {
    const isEditEnabled = ref(false)

    const selectedProxy = computed({
      get: () => props.selected,
      set: val => context.emit('value', val)
    })

    return { isEditEnabled, selectedProxy, thumbnail }
  }
}
</script>

<style lang="postcss"></style>
