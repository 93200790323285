<template>
  <div class="sidebar-support">
    <h1 class="sidebar-heading-1 mt-2">About Infoboxes</h1>

    <p class="sidebar-body">
      Each service will have a scene published in this environment. Once you bring a service into an
      environment, you can then begin creating scenes for that service.
    </p>

    <h1 class="sidebar-heading-1 mt-2">Services in Environments</h1>

    <p class="sidebar-body">
      Adding a service to an environment eventually allows the service to show on the map when a
      user opens the environment. You can add a service to this environment by clicking the "Add
      Service" button in the content area, or the
      <PlusIcon class="inline w-5 h-5 relative" style="bottom: 2px;" /> button at the top of this
      sidebar.
    </p>
  </div>
</template>

<script>
import { PlusIcon } from 'vue-feather-icons'

export default {
  name: 'OrgServiceSidebarSupport',
  components: {
    PlusIcon
  },
  props: {}
}
</script>

<style lang="postcss"></style>
