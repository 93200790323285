import Vue from 'vue'
import LogRocket from 'logrocket'
import { Notifier } from '@airbrake/browser'
import './plugins/composition-api'
import './plugins/social-sharing'
import './plugins/feather-icons'
import './plugins/vue-youtube'
import router from './router'
import './auth/plugin'
import App from './App.vue'
import store from './store/store'
import './plugins/rovit.js'
import 'swiper/swiper-bundle.css'

// Import all plugins
const requireModule = require.context('./plugins', false, /.js$/)
requireModule.keys().map(modulePath => {
  if (!modulePath.includes('composition')) {
    requireModule(modulePath).default
  }
})

//init logrocket & airbrake
if (process.env.NODE_ENV !== 'development') {
  LogRocket.init(process.env.VUE_APP_LOGROCKET_KEY)
  const _ = new Notifier({
    projectId: process.env.VUE_APP_AIRBRAKE_ID,
    projectKey: process.env.VUE_APP_AIRBRAKE_KEY,
    environment: process.env.NODE_ENV
  })
}

Vue.config.devtools = true
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
