<template>
  <div>
    <div v-if="currentEnv" class="h-48 mt-3 rounded-lg overflow-hidden relative mb-3">
      <ClusterMap
        :current="currentItem"
        :access-token="$store.state.mapboxToken"
        class="w-full"
        cluster
        :items="features"
        @feature-click="handleFeatureClick"
      />
    </div>
    <div v-if="selectedScene" class="my-4">
      <TargetScene :scene="selectedScene" />
    </div>
  </div>
</template>

<script>
import { findEnvServicesByEnv } from '@/use/env-services'
import { findScenesForEnv } from '@/use/scenes'
import TargetScene from './../TargetScene/TargetScene.vue'
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import { getEnv } from '@/use/environments'
import { ClusterMap } from '@rovit/map'

export default {
  name: 'SceneMap',
  components: {
    ClusterMap,
    TargetScene
  },
  props: {
    scene: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const envServiceSearch = ref('')
    const envServiceSort = { serviceName: 1 }
    const { env: currentEnv } = getEnv(context.root.$route.params.envId)
    const selectedScene = ref(null)
    const currentItem = ref(null)

    const { scenes } = findScenesForEnv({ env: currentEnv })
    const features = computed(() => {
      const items = (scenes.value || []).map((item, i) => {
        return {
          _id: item._id,
          name: 'name' + i,
          location: item.location
        }
      })
      return items
    })

    function handleFeatureClick({ component, coordinates, feature, layerId, map, mapboxEvent }) {
      const scene = scenes.value.find(s => s._id === feature._id)
      selectedScene.value = scene
    }
    return {
      currentItem,
      features,
      handleFeatureClick,
      currentEnv,
      selectedScene
    }
  }
}
</script>
