<template>
  <div class="danger-zone -m-1 mt-12 mb-4">
    <h2 class="sidebar-heading-1 flex flex-row items-center">
      The Danger Zone
      <AlertOctagonIcon class="ml-2 text-red-600" />
    </h2>

    <p class="content-subheading mb-2">Once you click, there's no going back.</p>

    <!-- "Remove from Environment" Button -->
    <button
      v-if="$route.params.envId"
      type="button"
      class="form-button danger"
      @click="$emit('remove-from-environment')"
    >
      Remove from Environment
    </button>

    <!-- "Delete Service" Button -->
    <div v-if="isConfirmDeleteVisible">
      <div class="flex flex-row-items-center mt-3">
        <button type="button" class="form-button danger rounded-r-none" @click="service.remove()">
          Confirm & Delete Service
        </button>

        <button
          type="button"
          class="form-button bg-gray-700 text-white rounded-l-none"
          @click="isConfirmDeleteVisible = false"
        >
          Cancel
        </button>
      </div>
    </div>

    <button
      v-else
      type="button"
      class="form-button danger mt-3"
      @click="isConfirmDeleteVisible = true"
    >
      Delete Service
    </button>
  </div>
</template>

<script>
import { AlertOctagonIcon } from 'vue-feather-icons'
import { ref } from '@vue/composition-api'

export default {
  name: 'ServiceEditorDanger',
  components: {
    AlertOctagonIcon
  },
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const isConfirmDeleteVisible = ref(false)
    return { isConfirmDeleteVisible }
  }
}
</script>

<style lang="postcss"></style>
