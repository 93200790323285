<template>
  <div class="edit-badge">
    <BadgeEditor
      v-if="badge"
      class="mt-3"
      :badge="badge"
      :env-id="envId"
      :org-id="currentOrg._id"
      :icon-model="models.api.Icon"
      :badges="badges"
      :badge-groups="badgeGroups"
      :allow-global="isAdmin && isRovitEnv"
    />
    <div v-else>
      Loading Badge
    </div>
  </div>
</template>

<script>
import { models } from 'feathers-vuex'
import { computed } from '@vue/composition-api'
import { getBadge } from '@/use/badges.js'

import BadgeEditor from './BadgeEditor.vue'

export default {
  name: 'EnvLibBadgesSidebarEditBadge',
  components: {
    BadgeEditor
  },
  props: {
    badges: {
      type: Array,
      required: true,
      default: () => []
    },
    badgeGroups: {
      type: Array,
      required: true,
      default: () => []
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isRovitEnv: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    // Current Badge
    const badgeId = computed(() => context.root.$route.query.badgeId)

    const { badge } = getBadge(badgeId)
    const envId = context.root.$route.params.envId
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    return {
      badge,
      models,
      envId,
      currentOrg
    }
  }
}
</script>

<style lang="postcss"></style>
