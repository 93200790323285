<template>
  <Layout>
    <template #content>
      <NavbarEnvironmentLibrary :env="currentEnv" />

      <div class="px-12">
        <div>
          <h1 class="content-heading-1">Custom Badges</h1>

          <p class="content-subheading">
            Custom badges for the
            <strong>{{ currentEnv && currentEnv.name }}</strong> environment.
          </p>

          <div class="mt-4">
            <button
              type="button"
              class="bg-gray-300 dark:bg-gray-700 rounded px-4 py-1.5"
              @click="handleCreateBadge"
            >
              Add Badge
            </button>
          </div>

          <BadgeGrid v-model="selected" :badges="customBadges" @input="handleInput" />
        </div>

        <div class="mt-10">
          <h1 class="content-heading-1">Rovit Badges</h1>
          <p class="content-subheading">Badges for all of Rovit.</p>

          <BadgeGrid v-model="selected" :badges="rovitGlobalBadges" @input="handleInput" />
        </div>
      </div>
    </template>

    <template #sidebar>
      <div>
        <EnvLibBadgesSidebarTabs @editor-tab-click="showMostRecentEditor" />

        <EnvLibBadgesSidebarSupport v-if="sidebarName === 'support'" />

        <EnvLibBadgesSidebarEditorBlank
          v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
        />

        <EnvLibBadgesSidebarEditBadge
          v-if="sidebarName === 'editor' && sidebarEditorType === 'badge'"
          :badges="badges"
          :badge-groups="badgeGroups"
          :is-admin="user.isRovitAdmin"
          :is-rovit-env="isRovitEnv"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from '../../layouts/Layout'
import { ref, computed } from '@vue/composition-api'
import { models } from 'feathers-vuex'
import { rovit, getEnv } from '@/use/environments.js'
import useSidebarTabState from '@/use/sidebar-tab-state.js'
import { createBadge, getBadge, findBadges } from '@/use/badges.js'
import { findBadgeGroups } from '@/use/badge-groups.js'
import _get from 'lodash/get.js'

import NavbarEnvironmentLibrary from '../NavbarEnvironmentLibrary/NavbarEnvironmentLibrary'
import BadgeGrid from './BadgeGrid.vue'

import EnvLibBadgesSidebarTabs from './SidebarTabs.vue'
import EnvLibBadgesSidebarSupport from './SidebarSupport.vue'
import EnvLibBadgesSidebarEditorBlank from './SidebarEditorBlank.vue'
import EnvLibBadgesSidebarEditBadge from './SidebarEditBadge.vue'

export default {
  name: 'EnvLibBadges',
  metaInfo: {
    title: 'Env Library Badges'
  },
  components: {
    Layout,
    NavbarEnvironmentLibrary,
    BadgeGrid,
    EnvLibBadgesSidebarTabs,
    EnvLibBadgesSidebarSupport,
    EnvLibBadgesSidebarEditorBlank,
    EnvLibBadgesSidebarEditBadge
  },
  setup(props, context) {
    const { env: currentEnv } = getEnv(context.root.$route.params.envId)
    const user = context.root.$store.state.auth.user
    const isRovitEnv = computed(() => {
      const currentEnvId = _get(currentEnv.value, '_id')
      const rovitEnvId = _get(rovit.value, '_id')
      return rovitEnvId != null && currentEnvId === rovitEnvId
    })

    const { badges } = findBadges({ env: currentEnv })
    const { badges: rovitBadges } = findBadges({ env: rovit })
    const { badgeGroups } = findBadgeGroups({ env: currentEnv })

    const customBadges = computed(() => {
      return badges.value.filter(badge => !badge.isGlobal)
    })
    const rovitGlobalBadges = computed(() => {
      return rovitBadges.value.filter(rb => rb.isGlobal)
    })

    const selected = ref(null)

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      return context.root.$route.query.badgeId ? 'badge' : 'blank'
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      watchInQuery: ['badgeId'],
      context
    })

    function openItemEditSidebar(badge) {
      if (!badge) return
      const query = { badgeId: badge._id, sidebar: 'editor' }
      context.root.$router.push({ query }, () => {})
    }

    function handleInput(val) {
      if (!val) {
        return
      }
      if (Array.isArray(val)) {
        if (val.length > 1) {
          // openBulkEditSidebar(val)
        } else {
          openItemEditSidebar(val[0])
        }
      } else {
        openItemEditSidebar(val)
      }
    }

    /**
     * Create a new badge then open it in the sidebar
     */
    async function handleCreateBadge() {
      const { badge } = await createBadge({ env: currentEnv })

      openBadgeInSidebar(badge)
    }

    function openBadgeInSidebar(badge) {
      const query = { badgeId: badge._id, sidebar: 'editor' }
      context.root.$router.push({ query }, () => {})
    }

    return {
      currentEnv,
      user,
      isRovitEnv,
      badges,
      badgeGroups,
      customBadges,
      rovitGlobalBadges,
      selected,

      // Sidebar
      showMostRecentEditor,
      sidebarName,
      sidebarEditorType,

      // Handlers
      handleInput,
      openItemEditSidebar,
      handleCreateBadge,
      openBadgeInSidebar
    }
  }
}
</script>

<style lang="postcss" scoped></style>
