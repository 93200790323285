<template>
  <div class="infobox-browser">
    <div
      v-if="!currentInfobox"
      class="bg-blue-100 dark:bg-blue-800 border border-blue-200 dark:border-blue-700 rounded p-3"
    >
      <p class="text-2xl">No infobox for this environment.</p>
      <p>
        The <strong>{{ service && service.name }}</strong>
        service does not have an infobox in the
        <strong>{{ currentEnv && currentEnv.name }}</strong>
        environment.
      </p>
    </div>

    <div class="flex flex-row flex-wrap">
      <div class="w-1/4">
        <h3 class="font-semibold leading-none mb-1">Start Fresh</h3>

        <!-- New Infobox Button -->
        <button
          type="button"
          class="create-infobox-button transition-colors duration-200 border border-gray-300 dark:border-gray-700 p-2 rounded hover:border-gray-500 w-full"
          @click="handleCreateInfobox"
        >
          <AspectRatio
            ratio="200:102"
            class="overflow-hidden create-infobox-icon flex flex-row items-center justify-center rounded transition-colors duration-200 w-full bg-gray-400 dark:bg-gray-800 cursor-pointer border-2 dark:border-gray-700 rounded"
          >
            <PlusIcon />
          </AspectRatio>
          <p class="leading-none mt-1">Create Infobox</p>
        </button>
      </div>

      <div class="w-3/4">
        <h3 class="font-semibold leading-none mb-1 ml-2">
          <span v-if="infoboxes.length">Select an Existing Infobox</span>
          <span v-else>No Existing Infoboxes</span>
        </h3>

        <hr class="border-gray-300 dark:border-gray-700 mx-2" />

        <!-- Existing Infoboxes -->
        <div class="flex flex-row flex-wrap p-1">
          <div v-for="infobox in infoboxes" :key="infobox._id" class="w-1/3 p-1">
            <ProjectTile
              :src="infobox.assetUrl"
              fit="infobox.assetFit"
              class="w-full"
              :label="infobox.infoboxName"
              @click="() => selectInfobox(infobox)"
            >
              <template #menu>
                <!-- <div>test</div> -->
              </template>
            </ProjectTile>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'
import { createInfobox, findInfoboxesForService } from '@/use/scenes.js'

import { PlusIcon } from 'vue-feather-icons'
import ProjectTile from '../ProjectTile/ProjectTile.vue'
import { AspectRatio } from '@rovit/aspect-ratio'

export default {
  name: 'InfoboxBrowser',
  components: {
    PlusIcon,
    ProjectTile,
    AspectRatio
  },
  props: {
    service: {
      validator: val => typeof val === 'object',
      required: true
    },
    currentEnv: {
      validator: val => typeof val === 'object',
      required: true
    },
    currentInfobox: {
      validator: val => typeof val === 'object',
      required: true
    }
  },
  setup(props, context) {
    const org = context.root.$store.getters['auth/currentOrg']
    const { service } = toRefs(props)
    const { infoboxes } = findInfoboxesForService({ service })

    function selectInfobox(infobox) {
      context.emit('select-infobox', infobox)
    }
    async function handleCreateInfobox() {
      const { infobox } = await createInfobox({ service, org })
      selectInfobox(infobox)
    }

    return { infoboxes, selectInfobox, handleCreateInfobox }
  }
}
</script>

<style lang="postcss" scoped>
.create-infobox-button:hover .create-infobox-icon {
  @apply bg-gray-400;
}
@screen dark {
  .create-infobox-button:hover .create-infobox-icon {
    @apply bg-gray-700;
  }
}
</style>
