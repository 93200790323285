<template>
  <Layout>
    <template #content>
      <SettingsNavbar />

      <div class="px-12">
        <h1 class="content-heading-1">My Organizations</h1>

        <p class="content-subheading">
          Everything in Rovit belongs to an Organization. When you first logged in, you
          automatically got your own default "Personal" organization. You can add and manage your
          organizations on this page.
        </p>

        <button class="bg-green-700 text-white px-4 py-2 rounded mt-2" @click="startNewItem">
          Create a New Organization
        </button>

        <GridSelector
          v-model="selected"
          :items="orgUsers"
          :label="i => i.orgName"
          :src="i => thumbnail(i.org.primaryAssetUrl).url"
          :fit="i => i.org.primaryAssetFit || 'contain'"
          class="mt-3 -mr-2"
          @item-click="handleOrgUserClick"
          @item-dblclick="handleOrgUserDblClick"
        />
      </div>

      <div v-if="user.isRovitAdmin" class="px-12">
        <h1 class="content-heading-1">All Organizations</h1>

        <button class="bg-green-700 text-white px-4 py-2 rounded mt-2" @click="startNewItem">
          Create a New Organization
        </button>

        <GridSelector
          v-model="selected"
          :items="allOrgs"
          :label="i => getLabel(i)"
          :src="i => thumbnail(i.primaryAssetUrl).url"
          :fit="i => i.primaryAssetFit || 'contain'"
          class="mt-3 -mr-2"
          @item-click="handleOrgUserClick"
          @item-dblclick="handleOrgDblClick"
        />
      </div>
    </template>

    <template #sidebar>
      <div>
        <UserOrgsSidebarTabs @editor-tab-click="showMostRecentEditor" />

        <UserOrgsSidebarSupport v-if="sidebarName === 'support'" />

        <UserOrgsSidebarEditorBlank
          v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
        />
        <UserOrgsSidebarEditorOrg v-if="sidebarName === 'editor' && sidebarEditorType === 'org'" />
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout'
import { ref, computed, watch } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import useSidebarTabState from '@/use/sidebar-tab-state'
import { useCurrentUser } from '@/use/users'
import { findOrgUsersForUserId } from '@/use/org-users'
import { findOrgs } from '@/use/orgs'
import { thumbnail } from '@/use/image-transforms/index'

import GridSelector from '../components/GridSelector/GridSelector.vue'
import SettingsNavbar from '../components/SettingsNavbar/SettingsNavbar.vue'

import UserOrgsSidebarTabs from '../components/UserOrgs/SidebarTabs.vue'
import UserOrgsSidebarSupport from '../components/UserOrgs/SidebarSupport.vue'
import UserOrgsSidebarEditorBlank from '../components/UserOrgs/SidebarEditorBlank.vue'
import UserOrgsSidebarEditorOrg from '../components/UserOrgs/SidebarEditorOrg.vue'

export default {
  name: 'UserOrgs',
  metaInfo: {
    title: 'User Orgs'
  },
  components: {
    Layout,
    SettingsNavbar,
    UserOrgsSidebarTabs,
    UserOrgsSidebarSupport,
    UserOrgsSidebarEditorBlank,
    UserOrgsSidebarEditorOrg,
    GridSelector
  },
  setup(props, context) {
    const { userId, user } = useCurrentUser(context)
    const { orgUsers } = findOrgUsersForUserId({ userId })
    const selected = ref(null)

    const { orgs: allOrgs } = findOrgs()

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      const { $route } = context.root
      if ($route.query.orgId) {
        return 'org'
      } else {
        return 'blank'
      }
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      watchInQuery: ['orgId'],
      context
    })

    /**
     * When the "Create New" button is clicked, redirect to the sidebar editor with
     * the orgId set to 'new'. The SidebarEditor component handles the logic to create
     * the new record and replace orgId: 'new' with the new orgId.
     */
    function startNewItem() {
      context.root.$router.push({ query: { sidebar: 'editor', orgId: 'new' } })
    }
    /**
     * Handles the currently selected item(s). A different sidebar is shown for
     * arrays vs single objects.
     */
    function handleOrgUserClick({ item, e }) {
      if (Array.isArray(selected.value)) {
        if (!selected.value.length) return
        // TODO: handle when multiple orgs are in `selected` array.
      } else {
        if (!selected.value) return
        openEditor({ orgId: selected.value.orgId || selected.value._id })
      }
    }
    /**
     * When an orgUser is double-clicked, switch to it and open its Home page.
     */
    function handleOrgUserDblClick({ item, e }) {
      context.root.$store.commit('auth/setCurrentOrgId', item.orgId)
      context.root.$router.push({ name: 'OrgHome' }, () => {})
    }
    /**
     * When an org is double-clicked, switch to it and open its Home page.
     */
    function handleOrgDblClick({ item, e }) {
      context.root.$store.commit('auth/setCurrentOrgId', item._id)
      context.root.$router.push({ name: 'OrgHome' }, () => {})
    }

    function getLabel(org) {
      let label = org.name
      if (org.isPersonalOrgOf) {
        label += ` - ${org.nameOfOwner}`
      }
      return label
    }

    return {
      selected,
      user,
      orgUsers,
      allOrgs,
      startNewItem,
      sidebarName,
      sidebarEditorType,
      showMostRecentEditor,
      handleOrgUserClick,
      handleOrgUserDblClick,
      handleOrgDblClick,
      getLabel,
      thumbnail
    }
  }
}
</script>

<style lang="postcss" scoped></style>
