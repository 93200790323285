<template>
  <div class="sidebar-add-scene">
    <h1 class="sidebar-heading-1 mt-2">Add a Scene</h1>
    <p class="sidebar-subheading">
      You can create a new scene or search for existing scenes, below.
    </p>

    <button type="button" class="form-button primary mt-2" @click="createScene">
      Create new Scene
    </button>

    <h2 class="sidebar-heading-1 mt-2">Search for Scenes</h2>
    <p class="sidebar-subheading">
      You can currently search scenes by name. In the future you'll be able to filter by category
      and location. For now, clicking a scene will add it to the current environment.
    </p>

    <input
      v-model="sceneSearch"
      type="text"
      class="form-input w-full mt-2"
      placeholder="Search by Scene Name"
    />

    <GridSelector
      v-model="selectedScene"
      :items="filteredScenes"
      :label="i => i.name"
      :src="i => makeUrl(i)"
      :fit="i => i.assetFit || 'contain'"
      class="mt-3 -mr-2"
      @item-click="handleSceneClick"
      @item-dblclick="handleSceneDblClick"
    />
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import keyBy from 'lodash/keyBy'
import GridSelector from '../GridSelector/GridSelector'

export default {
  name: 'EnvScenesSidebarAddScene',
  components: {
    GridSelector
  },
  props: {
    envScenes: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const { Scene } = models.api

    const selectedScene = ref(null)
    const sceneSearch = ref('')
    const scenesParams = computed(() => {
      const query = {}
      if (sceneSearch.value) {
        Object.assign(query, {
          name: { $regex: sceneSearch.value, $options: 'igm' }
        })
      }
      return { query }
    })
    const { items: scenes } = useFind({
      model: Scene,
      params: scenesParams
    })
    const envScenesBySceneId = computed(() => keyBy(props.envScenes, 'sceneId'))
    const filteredScenes = computed(() => {
      return scenes.value.filter(scene => {
        return !envScenesBySceneId.value[scene._id]
      })
    })

    function createScene() {
      context.emit('create-scene')
    }
    function handleSceneClick({ e, item }) {
      context.emit('add-scene', item)
    }
    function handleSceneDblClick(scene) {
      console.log(scene)
    }

    function makeUrl(i) {
      return i.assetUrl ? `//images.weserv.nl/?url=${i.assetUrl}&w=660&h=330&fit=inside&il` : ''
    }

    return {
      selectedScene,
      sceneSearch,
      filteredScenes,
      createScene,
      handleSceneClick,
      handleSceneDblClick,
      makeUrl
    }
  }
}
</script>

<style lang="postcss"></style>
