<template>
  <div class="badge-selector mx-1">
    <h2 class="font-bold text-xl mt-2">Currently Selected Badges</h2>
    <BadgesMeta :badges-meta="badgesMeta" :env="env" />

    <h2 class="font-bold text-xl mt-4">Select Badges</h2>
    <!-- Badge Group Selector -->
    <label class="block">
      <XSelect
        v-model="selectedGroup"
        :items="badgeGroups"
        :label="bg => bg.name"
        placeholder="Filter by Badge Group"
      />
    </label>

    <input v-model="badgeSearch" type="text" class="form-input w-full mt-1" placeholder="Search" />

    <div class="mt-3">
      <XDataSelector
        v-model="selectedBadgesProxy"
        :items="badges"
        multiple
        class="flex flex-row items-start flex-wrap pt-1"
      >
        <template v-slot="{ item, isSelected, select }">
          <button type="button" class="block w-1/4" @click="select">
            <BadgeTile :badge="item" :is-selected="isSelected" class="py-1" />
            <!-- <CheckIcon v-if="isSelected" /> -->
          </button>
        </template>
      </XDataSelector>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs } from '@vue/composition-api'
import _keyBy from 'lodash/keyBy.js'
import { findBadges } from '@/use/badges.js'
import { findBadgeGroups } from '@/use/badge-groups.js'
import { rovit } from '@/use/environments.js'
import _sortBy from 'lodash/sortBy.js'
import _get from 'lodash/get.js'

import { XSelect } from '@rovit/x-select'
import { XDataSelector } from '@rovit/x-data-selector'
import { CheckIcon } from 'vue-feather-icons'
import BadgeTile from '../BadgeTile/BadgeTile.vue'
import BadgesMeta from '../BadgesMeta/BadgesMeta.vue'

export default {
  name: 'BadgeSelector',
  components: {
    XSelect,
    XDataSelector,
    // CheckIcon,
    BadgeTile,
    BadgesMeta
  },
  model: {
    prop: 'badgesMeta'
  },
  props: {
    badgesMeta: {
      validator: () => true,
      default: () => []
    },
    env: {
      validator: val => typeof val === 'object',
      default: null
    }
  },
  setup(props, context) {
    const { env } = toRefs(props)
    const shouldHideRovitBadges = ref(false)
    const isRovitEnv = computed(() => {
      return env.value && rovit.value && env.value === rovit.value
    })
    const envIds = computed(() => {
      const envIds = []
      const envId = _get(env.value, '_id')
      const rovitEnvId = _get(rovit.value, '_id')
      if (envId != null) {
        envIds.push(envId)
      }
      if (rovitEnvId != null && !isRovitEnv.value) {
        envIds.push(rovitEnvId)
      }
      return envIds
    })

    // Badge Groups
    const { badgeGroups: badgeGroupsForEnv } = findBadgeGroups({ env })
    const { badgeGroups: badgeGroupsForRovit } = findBadgeGroups({ env: rovit })
    const badgeGroups = computed(() => {
      // If envs are the same, just return rovit badges.
      if (isRovitEnv.value) {
        return badgeGroupsForRovit.value
        // If rovit badges are hidden, just show
      } else if (shouldHideRovitBadges.value) {
        return badgeGroupsForEnv.value
      } else {
        const allBadgeGroups = badgeGroupsForEnv.value.concat(badgeGroupsForRovit.value)
        return _sortBy(allBadgeGroups, 'name')
      }
    })
    // Selected Groups
    const selectedGroup = ref(null)
    const badgeSearch = ref('')

    // Badges
    const { badges: badgesForEnv } = findBadges({ env })
    const { badges: badgesForRovit } = findBadges({ env: rovit })
    const globalRovitBadges = computed(() => {
      return badgesForRovit.value.filter(badge => badge.isGlobal)
    })
    const allBadges = computed(() => badgesForEnv.value.concat(globalRovitBadges.value))
    const badges = computed(() => {
      const groupId = _get(selectedGroup.value, '_id')
      // First we figure out which set of badges we're going to use.
      let badges = []
      if (isRovitEnv.value) {
        badges = globalRovitBadges.value
      } else if (shouldHideRovitBadges.value) {
        badges = badgesForEnv.value
      } else {
        badges = allBadges.value
      }
      // Now we figure out how to filter the list.
      if (groupId) {
        badges = badges.filter(badge => {
          return badge.badgeGroupsMeta.map(bg => bg.badgeGroupId).includes(groupId)
        })
      }
      const badgesMatchingSearch = badges.filter(badge =>
        badge.name.toLowerCase().includes(badgeSearch.value.toLowerCase())
      )
      return badgesMatchingSearch
    })

    const badgeMetaForOtherEnvs = computed(() => {
      return props.badgesMeta.filter(bm => {
        return !envIds.value.includes(bm.envId)
      })
    })
    const allBadgesById = computed(() => {
      return _keyBy(allBadges.value, '_id')
    })
    const selectedBadgesProxy = computed({
      // convert badgesMeta to badges
      get: () => {
        return props.badgesMeta
          .filter(bm => {
            return envIds.value.includes(bm.envId)
          })
          .map(bm => {
            return allBadgesById.value[bm.badgeId]
          })
      },
      set: val => {
        // Convert back to badgesMeta
        const newMeta = val.map(badge => {
          return {
            badgeId: badge._id,
            badgeName: badge.name,
            envId: badge.envId
          }
        })
        const allMeta = badgeMetaForOtherEnvs.value.concat(newMeta)
        context.emit('input', allMeta)
      }
    })

    return {
      shouldHideRovitBadges,
      isRovitEnv,
      badgeGroups,
      selectedGroup,
      badgeSearch,
      allBadges,
      badges,
      selectedBadgesProxy
    }
  }
}
</script>

<style lang="postcss"></style>
