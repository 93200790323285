var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.env)?_c('div',{staticClass:"admin-navbar"},[_c('UserMenu',{staticClass:"mr-1"}),_c('router-link',{staticClass:"link mr-2.5",class:{ active: _vm.$route.name === 'OrgHome' },attrs:{"to":"/"}},[_c('div',[_c('HomeIcon')],1),_vm._v(" Home ")]),_c('router-link',{staticClass:"link",class:{ active: _vm.$route.name === 'OrgEnvironments' },attrs:{"to":{ name: 'OrgEnvironments' }}},[_c('div',[_c('BriefcaseIcon')],1),_vm._v(" Environs ")]),_c('div',{staticClass:"text-green-900 mt-4"},[_c('ChevronRightIcon')],1),_c('router-link',{staticClass:"link active-crumb",class:{ active: _vm.$route.name === 'OrgProjects' },staticStyle:{"max-width":"92px"},attrs:{"to":{
      name: 'EnvironmentServices',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',{staticClass:"flex flex-row justify-center bg-green-200"},[_c('EditIcon')],1),_c('VClamp',{staticClass:"leading-none mt-1",attrs:{"autoresize":"","max-lines":2,"tag":"p"}},[_vm._v(" "+_vm._s(_vm.env && _vm.env.name)+" ")])],1),_c('div',{staticClass:"text-green-900 mt-4"},[_c('ChevronRightIcon')],1),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvironmentLibrary' },attrs:{"to":{
      name: 'EnvironmentLibrary',
      params: {
        envId: _vm.env && _vm.env._id
      }
    }}},[_c('div',{staticStyle:{"max-width":"52px","margin":"0 auto"}},[_c('BookIcon')],1),_vm._v(" Library ")]),_c('div',{staticClass:"navbar-divider mr-2"}),_c('router-link',{staticClass:"link page mr-1.5",class:{ active: _vm.$route.name === 'EnvLibStore' },attrs:{"to":{
      name: 'EnvLibStore',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',[_c('ShoppingCartIcon')],1),_vm._v(" Store ")]),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvLibIcons' },attrs:{"to":{
      name: 'EnvLibIcons',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',[_c('MapPinIcon')],1),_vm._v(" Icons ")]),_c('router-link',{staticClass:"link page",class:{ active: _vm.$route.name === 'EnvLibCategories' },attrs:{"to":{
      name: 'EnvLibCategories',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',{staticClass:"mx-1.5",staticStyle:{"max-width":"52px"}},[_c('ListIcon')],1),_c('p',{staticClass:"leading-none mt-1"},[_vm._v(" Categories ")])]),_c('router-link',{staticClass:"link page mr-0.5",class:{ active: _vm.$route.name === 'EnvLibBadges' },attrs:{"to":{
      name: 'EnvLibBadges',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',{staticClass:"mx-1.5",staticStyle:{"max-width":"52px"}},[_c('AwardIcon')],1),_c('p',{staticClass:"leading-none mt-1"},[_vm._v(" Badges ")])]),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvLibPhotos' },attrs:{"to":{
      name: 'EnvLibPhotos',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',[_c('ImageIcon')],1),_vm._v(" Photos ")]),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvLibPanos' },attrs:{"to":{
      name: 'EnvLibPanos',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',[_c('PanoIcon')],1),_vm._v(" Panos ")]),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvLibLinkedEnvs' },attrs:{"to":{
      name: 'EnvLibLinkedEnvs',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',[_c('GlobeIcon')],1),_c('p',{staticClass:"leading-none mt-1"},[_vm._v(" Linked "),_c('br'),_vm._v(" Environs ")])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }