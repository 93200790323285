<template>
  <div class="group">
    <div
      class="bg-purple-600 rounded-t px-3 py-1 font-medium group-hover:bg-purple-700"
      @click="openPanoSidebar"
    >
      Pano Feature
    </div>
    <div class="border border-purple-600 group-hover:border-purple-700 rounded-b">
      <FeatureHotspots
        v-if="scene.panoUrl"
        :item="scene"
        :src="() => largeImage(scene.panoUrl).url"
        :current-hotspot-index="currentHotspotIndex"
        target-percent-x-attr-name="panoTargetPercentX"
        target-percent-y-attr-name="panoTargetPercentY"
        @image-click="openPanoSidebar"
        @hotspot-click="handleHotspotClick"
        @hotspot-update="handleHotspotUpdate"
      />

      <button
        v-else
        type="button"
        class="bg-gray-800 h-24 rounded-b w-full flex flex-col items-center justify-center"
        @click="openPanoSelector"
      >
        <PanoIcon />
        Select Pano
      </button>
    </div>
  </div>
</template>

<script>
import { handleClones } from '@rovit/utils'
import { ref, computed } from '@vue/composition-api'
import { useModal } from '@rovit/use-modal'
import _isEqual from 'lodash/isEqual.js'
import { largeImage } from '@/use/image-transforms/index'

import FeatureHotspots from '../FeatureHotspots/FeatureHotspots.vue'
import { PanoIcon } from '@rovit/icons'
import { AspectRatio } from '@rovit/aspect-ratio'

export default {
  name: 'SceneFeaturePano',
  components: {
    FeatureHotspots,
    PanoIcon
  },
  props: {
    scene: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const currentHotspotIndex = computed(() => {
      return parseInt(context.root.$route.query.hotspotIndex)
    })

    function handleHotspotClick({ hotspot, offset, event, index }) {
      routeToHotspot(index)
    }
    function handleHotspotUpdate({ hotspot, index }) {
      routeToHotspot(index)
    }
    function routeToHotspot(index) {
      const newRoute = Object.assign({}, context.root.$route)
      newRoute.query = Object.assign({}, newRoute.query, {
        sidebar: 'scene',
        hotspotIndex: index,
        panoTab: 'hotspot'
      })
      context.root.$router.push(newRoute, () => {})
    }

    /**
     * Open the pano selector in the sidebar.
     */
    function openPanoSidebar() {
      const currentQuery = context.root.$route.query
      const query = Object.assign({}, currentQuery, { sidebar: 'scene', panoTab: 'pano' })
      if (!_isEqual(currentQuery, query)) {
        context.root.$router.push({ query })
      }
    }

    const scenePanoModal = useModal('panoSelector')
    function openPanoSelector() {
      openPanoSidebar()
      scenePanoModal.open({ scene: props.scene })
    }

    return {
      currentHotspotIndex,
      largeImage,

      // Handlers
      handleHotspotClick,
      handleHotspotUpdate,
      openPanoSelector,
      openPanoSidebar
    }
  }
}
</script>

<style lang="postcss"></style>
