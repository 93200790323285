var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-tabs"},[_c('router-link',{staticClass:"mr-1",class:{
      active: _vm.$route.query.sidebar === 'support' || !_vm.$route.query.sidebar
    },attrs:{"tag":"button","type":"button","to":_vm.routeFor('support')}},[_c('HelpCircleIcon')],1),_c('router-link',{staticClass:"combo-left",class:{
      active: _vm.$route.query.sidebar === 'infobox'
    },attrs:{"tag":"button","type":"button","to":_vm.routeFor('infobox')}},[_vm._v(" Infobox ")]),_c('router-link',{staticClass:"combo-middle",class:{
      active: _vm.$route.query.sidebar === 'scene'
    },attrs:{"tag":"button","type":"button","to":_vm.routeFor('scene')}},[_vm._v(" Scene ")]),_c('router-link',{staticClass:"combo-right icon mr-1",class:{
      active: _vm.$route.query.sidebar === 'add-scene'
    },attrs:{"tag":"button","type":"button","to":_vm.routeFor('add-scene')}},[_c('PlusIcon')],1),_c('router-link',{staticClass:"combo-left",class:{
      active: _vm.$route.query.sidebar === 'section'
    },attrs:{"tag":"button","type":"button","to":_vm.routeFor('section')}},[_vm._v(" Section ")]),_c('router-link',{staticClass:"combo-right icon",class:{
      active: _vm.$route.query.sidebar === 'sections'
    },attrs:{"tag":"button","type":"button","to":_vm.routeFor('sections')}},[_c('PlusIcon')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }