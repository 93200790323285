<template>
  <div class="bg-gray-300 dark:bg-gray-800 p-2 rounded overflow-hidden">
    <div>
      <img :src="thumbnail(targetPano.url).url" alt="" class="w-full rounded" />
    </div>
    <div class="mt-2 flex flex-row items-center">
      <div class="flex-grow">
        <p>{{ targetPano.name || targetPano.originalFileName }}</p>
        <p v-if="targetScene">
          Scene:
          <button
            type="button"
            class="text-blue-800 dark:text-blue-300 inline-flex flex-row items-center"
            @click="$emit('open-target-scene', targetScene)"
          >
            {{ targetScene.name }}
            <ExternalLinkIcon class="ml-2" size="1x" />
          </button>
        </p>
        <p v-else class="text-gray-500">Not in a scene</p>
      </div>
      <button type="button" class="form-button primary" @click="$emit('open-target-pano')">
        Open Pano
      </button>
    </div>

    <div>
      Hotspots
      <div class="bg-gray-200 dark:bg-gray-700 rounded">
        <div v-if="targetScene && currentScene">
          <button
            type="button"
            class="form-button py-1 w-full text-black dark:text-white"
            @click="linkCurrentToTarget"
          >
            <ArrowRightIcon class="h-5 relative" style="right: 6px;" />
            Link Current to Target
            <CheckIcon
              v-if="currentLinkToTarget"
              class="text-green-600 dark:text-green-300 ml-1.5 h-5"
            />
          </button>
          <button
            type="button"
            class="form-button py-1 w-full text-black dark:text-white"
            @click="linkTargetToCurrent"
          >
            <ArrowLeftIcon class="h-5 relative" style="right: 6px;" />
            Link Target to Current
            <CheckIcon
              v-if="targetLinkToCurrent"
              class="text-green-600 dark:text-green-300 ml-1.5 h-5"
            />
          </button>
        </div>

        <!-- Reasons why we aren't showing link buttons. -->
        <div v-else class="p-2">
          <div v-if="targetScene && !currentScene" class="flex flex-row items-center">
            <AlertTriangleIcon size="1x" class="text-red-600" />
            <p class="ml-1">Current pano requires a scene</p>
          </div>
          <div v-else-if="currentScene && !targetScene" class="flex flex-row items-center">
            <AlertTriangleIcon size="1x" class="text-red-600" />
            <p class="ml-1">Target pano requires a scene</p>
          </div>
          <div v-else class="flex flex-row items-center">
            <AlertTriangleIcon size="1x" class="text-red-600" />
            <p class="ml-1">Both panos require scenes</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { models } from 'feathers-vuex'
import { computed } from '@vue/composition-api'
import { addHotspotToItem, getInfoboxScenePair, makeLink } from '@/use/scenes.js'
import { handleClones } from '@rovit/utils/handle-clones.js'
import { thumbnail } from '@/use/image-transforms/index'

import { ExternalLinkIcon, CheckIcon, ArrowLeftIcon, ArrowRightIcon } from 'vue-feather-icons'

export default {
  name: 'TargetPano',
  components: {
    ExternalLinkIcon,
    CheckIcon,
    ArrowLeftIcon,
    ArrowRightIcon
  },
  props: {
    targetPano: {
      type: Object,
      required: true
    },
    targetScene: {
      type: Object,
      default: null,
      validator: () => true
    },
    currentScene: {
      type: Object,
      default: null,
      validator: () => true
    },
    envId: {
      type: String,
      default: null
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props, {
      watchProps: ['currentScene', 'targetScene', 'targetPano']
    })
    const { _targetPano, _targetScene, _currentScene } = clones
    const { save_targetPano, save_targetScene, save_currentScene } = saveHandlers

    const currentLinkToTarget = computed(() => {
      if (!props.currentScene || !props.targetScene) {
        return null
      }
      const foundLink = props.currentScene.hotspots.find(hs => {
        const targetScene = props.targetScene
        const link = hs && hs.link
        if (targetScene && link) {
          return link.isInfobox
            ? link.infoboxId === targetScene._id
            : link.sceneId === targetScene._id
        }
      })
      return foundLink || null
    })
    const targetLinkToCurrent = computed(() => {
      if (!props.currentScene || !props.targetScene) {
        return null
      }
      const foundLink = props.targetScene.hotspots.find(hs => {
        const currentScene = props.currentScene
        const link = hs && hs.link
        if (currentScene && link) {
          return link.isInfobox
            ? link.infoboxId === currentScene._id
            : link.sceneId === currentScene._id
        }
      })
      return foundLink || null
    })

    /**
     * Create a hotspot in the currentScene that goes to the targetScene
     */
    async function linkCurrentToTarget() {
      try {
        if (currentLinkToTarget.value) {
          throw new Error('Link already present')
          return
        }
        const env = models.api.Environment.getFromStore(props.envId)
        const { infobox, scene } = await getInfoboxScenePair(props.targetScene)
        const { link } = await makeLink({ env, infobox, scene })
        await addHotspotToItem({ _item: _currentScene, save_item: save_currentScene, link })
        this.$toasted.global.actionSuccess()
      } catch (error) {
        this.$toasted.global.actionError(error)
      }
    }
    /**
     * Create a hotspot in the targetScene that goes to the currentScene
     */
    async function linkTargetToCurrent() {
      try {
        if (targetLinkToCurrent.value) {
          throw new Error('Link already present')
          return
        }
        const env = models.api.Environment.getFromStore(props.envId)
        const { infobox, scene } = await getInfoboxScenePair(props.currentScene)
        const { link } = await makeLink({ env, infobox, scene })
        await addHotspotToItem({ _item: _targetScene, save_item: save_targetScene, link })
        this.$toasted.global.actionSuccess()
      } catch (error) {
        this.$toasted.global.actionError(error)
      }
    }
    return {
      ...clones,
      ...saveHandlers,
      thumbnail,
      currentLinkToTarget,
      targetLinkToCurrent,
      linkCurrentToTarget,
      linkTargetToCurrent
    }
  }
}
</script>

<style lang="postcss"></style>
