<template>
  <div class="p-3">
    <div class="flex flex-row items-center cursor-pointer" @click="openSceneInSidebar">
      <!-- Icon -->
      <img :src="thumbnail(scene.assetUrl).url" alt="" class="w-16" />

      <!-- Description -->
      <div class="leading-none ml-1.5">
        <p class="font-semibold">{{ scene.name }}</p>
      </div>

      <!-- Action -->
      <div></div>
    </div>
  </div>
</template>
<script>
import { thumbnail } from '@/use/image-transforms/index'

export default {
  name: 'SidebarSceneRow',
  props: {
    scene: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const { scene } = props
    function openSceneInSidebar() {
      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'scene',
        sceneId: scene._id
      })
      context.root.$router.push({ query }, () => {})
    }

    return {
      thumbnail,
      openSceneInSidebar
    }
  }
}
</script>

<style lang="postcss"></style>
