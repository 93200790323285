<template>
  <div class="sidebar-support">
    <EnvironmentSidebar v-if="env" :env="env"></EnvironmentSidebar>

    <div v-else>
      Loading Environment
    </div>
  </div>
</template>

<script>
import { models, useGet } from 'feathers-vuex'
import { computed } from '@vue/composition-api'
import EnvironmentSidebar from './EnvironmentSidebar'

export default {
  name: 'OrgProjectsSidebarEditorEnvironment',
  components: {
    EnvironmentSidebar
  },
  props: {},
  setup(props, context) {
    const { Environment } = models.api

    const id = computed(() => context.root.$route.query.envId)
    const { item: env } = useGet({ model: Environment, id })

    return { env }
  }
}
</script>

<style lang="postcss"></style>
