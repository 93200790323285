var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-navbar"},[_c('UserMenu',{staticClass:"mr-1"}),_c('router-link',{staticClass:"link mr-2.5",class:{ active: _vm.$route.name === 'OrgHome' },attrs:{"to":"/"}},[_c('div',[_c('HomeIcon')],1),_vm._v(" Home ")]),_c('router-link',{staticClass:"link",class:{ active: _vm.$route.name === 'OrgEnvironments' },attrs:{"to":{ name: 'OrgEnvironments' }}},[_c('div',[_c('GlobeIcon')],1),_vm._v(" Environs ")]),_c('div',{staticClass:"text-green-900 mt-4"},[_c('ChevronRightIcon')],1),_c('router-link',{staticClass:"link active-crumb mr-2",class:{ active: _vm.$route.name === 'OrgProjects' },staticStyle:{"max-width":"92px"},attrs:{"to":{
      name: 'EnvironmentServices',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',{staticClass:"flex flex-row justify-center bg-green-200"},[_c('EditIcon')],1),_c('VClamp',{staticClass:"leading-none mt-1",attrs:{"autoresize":"","max-lines":2,"tag":"p"}},[_vm._v(" "+_vm._s(_vm.env && _vm.env.name)+" ")])],1),_c('div',{staticClass:"navbar-divider mr-2"}),_c('router-link',{staticClass:"link page mr-1.5",class:{ active: _vm.$route.name === 'EnvironmentServices' },attrs:{"to":{
      name: 'EnvironmentServices',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',{staticStyle:{"max-width":"52px","margin":"0 auto"}},[_c('BriefcaseIcon')],1),_vm._v(" Services ")]),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvironmentLibrary' },attrs:{"to":{
      name: 'EnvironmentLibrary',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',[_c('BookIcon')],1),_vm._v(" Library ")]),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvironmentFrontPage' },attrs:{"to":{
      name: 'EnvironmentFrontPage',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',{staticClass:"flex flex-row justify-center"},[_c('FileTextIcon')],1),_c('p',{staticClass:"leading-none mt-1"},[_vm._v("Front "),_c('br'),_vm._v("Page")])]),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvironmentAnalytics' },attrs:{"to":{
      name: 'EnvironmentAnalytics',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',[_c('BarChart2Icon')],1),_vm._v(" Analytics ")]),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvironmentTeam' },attrs:{"to":{
      name: 'EnvironmentTeam',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',[_c('UsersIcon')],1),_vm._v(" Team ")]),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvironmentBilling' },attrs:{"to":{
      name: 'EnvironmentBilling',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',[_c('CreditCardIcon')],1),_vm._v(" Billing ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }