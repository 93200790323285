import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'
import shouldFilterServiceKeys from '../../utils/fetch-variable-from-env'
import { iff, alterItems } from 'feathers-hooks-common'
import _pick from 'lodash/pick.js'

class Environment extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Environment'
  // Define default properties here
  static instanceDefaults() {
    return {
      name: '',
      slug: '',
      notes: '',
      mapboxToken: '',
      startingSceneId: null,
      isPublic: false,
      primaryAssetId: null,
      primaryAssetUrl: '',
      primaryAssetFit: 'cover',
      users: [],
      orgs: [],
      createdBy: null
    }
  }
  static setupInstance(data) {
    if (data.createdAt) {
      data.createdAt = new Date(data.createdAt)
    }
    if (data.updatedAt) {
      data.updatedAt = new Date(data.updatedAt)
    }
    return data
  }
}
const servicePath = 'environments'
const servicePlugin = makeServicePlugin({
  Model: Environment,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [
      iff(
        context => ['create', 'update', 'patch'].includes(context.method),
        iff(
          shouldFilterServiceKeys(),
          alterItems(i =>
            _pick(i, [
              '_id',
              'name',
              'slug',
              'notes',
              'startingSceneId',

              'mapboxToken',
              'bbox',

              'primaryAssetId',
              'primaryAssetUrl',
              'primaryAssetFit',

              'isPublic',
              'users',
              'orgs',

              'createdBy',
              'createdAt',
              'updatedAt'
            ])
          )
        )
      )
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [
      // Update bbox to be an array if it's null
      context => {
        if (context.data.hasOwnProperty('bbox') && context.data.bbox == null) {
          context.data.bbox = []
        }
      }
    ],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
