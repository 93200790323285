<template>
  <Layout>
    <template #content>
      <NavbarEnvironmentLibrary :env="currentEnv" />

      <div class="px-12">
        <div class="flex flex-row items-center">
          <h1 class="content-heading-1 mr-3">Photos</h1>

          <button
            class="button-with-icon bg-green-600 rounded-full ml-2"
            @click="openBulkUploadSidebar"
          >
            <UploadCloudIcon size="1x" class="mr-2" />
            Upload
          </button>
        </div>

        <p class="content-subheading">
          Manage photos for the
          <strong>{{ currentEnv && currentEnv.name }}</strong> environment.
        </p>

        <div v-if="assets.length" class="relative text-right" style="bottom: 24px;">
          <button
            type="button"
            class="text-green-700 dark:text-green-500"
            @click="isEditEnabled = !isEditEnabled"
          >
            <span v-if="isEditEnabled">Done</span>
            <span v-else>Edit</span>
          </button>
          <hr class="border-gray-300 dark:border-gray-800" />
        </div>

        <GridSelector
          v-if="assets.length"
          v-model="selected"
          :items="assets"
          :label="i => i.name || i.originalFileName"
          :src="i => thumbnail(i.url).url"
          :fit="i => 'contain'"
          :multiple="isEditEnabled"
          class="mt-3 -mr-2"
          @input="handleInput"
        />

        <div v-else>
          <EnvLibEmptyState
            item-type="Photos"
            :items-have-loaded="assetsHaveLoaded"
            @button-click="openBulkUploadSidebar"
          />
        </div>
      </div>
    </template>

    <template #sidebar>
      <EnvLibPhotosSidebarTabs
        @editor-tab-click="showMostRecentEditor"
        @upload-tab-click="openBulkUploadSidebar"
      />

      <EnvLibPhotosSidebarSupport v-if="sidebarName === 'support'" />

      <EnvLibPhotosSidebarEditorBlank
        v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
      />
      <EnvLibPhotosSidebarEditPhoto
        v-if="sidebarName === 'editor' && sidebarEditorType === 'asset' && currentAsset"
        :asset="currentAsset"
        :env-id="currentEnv && currentEnv._id"
      />
      <EnvLibPhotosSidebarBulkEdit
        v-if="sidebarName === 'bulk-edit' && selected && selected.length"
        :items="selected"
        :env-id="currentEnv && currentEnv._id"
      />

      <EnvLibPhotosSidebarBulkUpload
        v-if="sidebarName === 'upload'"
        class="mt-2"
        :env-id="currentEnv && currentEnv._id"
        @asset-click="openAssetInSidebar"
      />
    </template>
  </Layout>
</template>

<script>
import Layout from '../../layouts/Layout'
import { ref, computed } from '@vue/composition-api'
import useSidebarTabState from '@/use/sidebar-tab-state.js'
import { getEnv } from '@/use/environments.js'
import { findAssetsForEnv, getAsset } from '@/use/assets.js'
import { thumbnail } from '@/use/image-transforms/index'

import NavbarEnvironmentLibrary from '../NavbarEnvironmentLibrary/NavbarEnvironmentLibrary'
import EnvLibEmptyState from '../EnvLibEmptyState/EnvLibEmptyState.vue'
import { UploadCloudIcon } from 'vue-feather-icons'
import GridSelector from '../GridSelector/GridSelector.vue'

import EnvLibPhotosSidebarTabs from './SidebarTabs.vue'
import EnvLibPhotosSidebarSupport from './SidebarSupport.vue'
import EnvLibPhotosSidebarEditorBlank from './SidebarEditorBlank.vue'
import EnvLibPhotosSidebarEditPhoto from './SidebarEditPhoto.vue'
import EnvLibPhotosSidebarBulkUpload from './SidebarBulkUpload.vue'
import EnvLibPhotosSidebarBulkEdit from './SidebarBulkEdit.vue'

export default {
  name: 'EnvLibPhotos',
  metaInfo: {
    title: 'Env Library Photos'
  },
  components: {
    Layout,
    NavbarEnvironmentLibrary,
    EnvLibEmptyState,
    UploadCloudIcon,
    EnvLibPhotosSidebarTabs,
    EnvLibPhotosSidebarSupport,
    EnvLibPhotosSidebarEditorBlank,
    EnvLibPhotosSidebarEditPhoto,
    EnvLibPhotosSidebarBulkUpload,
    EnvLibPhotosSidebarBulkEdit,
    GridSelector
  },
  setup(props, context) {
    const { env: currentEnv } = getEnv(context.root.$route.params.envId)
    const isEditEnabled = ref(false)

    const $sort = computed(() => {
      return { createdAt: -1 }
    })
    const { assets, assetsHaveLoaded } = findAssetsForEnv({ env: currentEnv, $sort })

    // Current Asset
    const assetId = computed(() => context.root.$route.query.assetId)
    const { asset: currentAsset } = getAsset({ id: assetId })

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      return context.root.$route.query.assetId ? 'asset' : 'blank'
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      watchInQuery: ['assetId'],
      context
    })

    function openAssetInSidebar(asset) {
      if (!asset) return
      const query = { assetId: asset._id, sidebar: 'editor' }
      context.root.$router.push({ query }, () => {})
    }

    function openBulkUploadSidebar() {
      const query = { sidebar: 'upload' }
      context.root.$router.push({ query }, () => {})
    }
    function openBulkEditSidebar() {
      const query = { sidebar: 'bulk-edit' }
      context.root.$router.push({ query }, () => {})
    }

    function save({ event, clone, prop, data }) {
      clone.commit().save({ data })
    }

    const selected = ref(null)
    function handleInput(val) {
      if (!val) {
        return
      }
      if (Array.isArray(val)) {
        if (val.length > 1) {
          openBulkEditSidebar(val)
        } else {
          openAssetInSidebar(val[0])
        }
      } else {
        openAssetInSidebar(val)
      }
    }

    return {
      currentEnv,
      assets,
      assetsHaveLoaded,
      currentAsset,
      isEditEnabled,
      thumbnail,

      // GridSelector
      selected,
      handleInput,

      // Sidebar
      sidebarName,
      sidebarEditorType,
      showMostRecentEditor,
      openAssetInSidebar,
      openBulkUploadSidebar,
      save
    }
  }
}
</script>

<style lang="postcss" scoped></style>
