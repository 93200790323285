<template>
  <div
    class="flex flex-col items-center justify-center rounded"
    :class="[isSelected ? 'border border-green-500' : '']"
    @click="$emit('click')"
  >
    <div
      class="flex items-center justify-center h-16 w-16 p-1"
      :class="[
        badge.bgColor || 'bg-white dark:bg-black',
        !badge.containerShape ? 'rounded-full' : '',
        badge.containerShape === 'square' ? 'rounded-md' : '',
        badge.containerShape === 'circle' ? 'rounded-full' : ''
      ]"
    >
      <Icon
        :src="badge.iconUrl"
        :icon-class="badge.iconColor"
        :adaptive-fill="badge.iconAdaptiveFill"
        :adaptive-stroke="badge.iconAdaptiveStroke"
        :is-svg="badge.iconMimeType.includes('svg')"
        class="object-contain object-center w-full"
      />
      <!-- <img v-else :src="badge.iconUrl" class="object-contain object-center h-full" /> -->
    </div>

    <p class="text-center">{{ badge.name }}</p>
  </div>
</template>

<script>
import { Icon } from '@rovit/icons'

export default {
  name: 'BadgeTile',
  components: {
    Icon
  },
  props: {
    badge: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="postcss"></style>
