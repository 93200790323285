<template>
  <div class="sidebar-support">
    <div v-if="scene">
      <SceneForm :scene="scene" />
      <!-- <SceneMap :scene="scene" /> -->
    </div>

    <div v-else>Loading Scene</div>
  </div>
</template>

<script>
import SceneForm from '../SceneForm/SceneForm.vue'
import SceneMap from './../SceneMap/SceneMap.vue'

export default {
  name: 'InfoboxSidebarScene',
  components: {
    SceneForm
    // SceneMap
  },
  props: {
    scene: {
      validator: val => true,
      default: null
    },
    envId: {
      validator: val => true,
      default: null
    }
  },
  setup(props, context) {
    return {}
  }
}
</script>

<style lang="postcss"></style>
