import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'
import { models } from 'feathers-vuex'
import shouldFilterServiceKeys from '../../utils/fetch-variable-from-env'
import { iff, alterItems } from 'feathers-hooks-common'
import _pick from 'lodash/pick.js'

class User extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'User'
  // Define default properties here
  static instanceDefaults() {
    return {
      user_id: '',
      auth0: null
    }
  }
  static setupInstance(data, { models }) {
    if (data.orgUsers) {
      data.orgUsers = data.orgUsers.map(ou => new models.api.OrgUser(ou))
    }
    return data
  }
}
const servicePath = 'users'
const servicePlugin = makeServicePlugin({
  Model: User,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [
      iff(
        context => ['create', 'update', 'patch'].includes(context.method),
        iff(
          shouldFilterServiceKeys(),
          alterItems(i =>
            _pick(i, [
              '_id',
              'auth0UserId',

              'name',
              'nickname',
              'picture',

              'isRovitAdmin',

              'stripeCustomerId',

              'createdAt',
              'updatedAt'
            ])
          )
        )
      )
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
