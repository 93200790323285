<template>
  <Layout>
    <template #content>
      <NavbarAdmin />

      <div class="px-12">
        <h1 class="content-heading-1">{{ $store.getters['auth/currentOrg'].name }} Library</h1>

        <p class="content-subheading">
          Manage your org's Rovit library. Upload or purchase content to enrich your projects.
        </p>
      </div>
    </template>

    <template #sidebar>
      Library Sidebar
    </template>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout'
import { ref } from '@vue/composition-api'
import NavbarAdmin from '../components/NavbarAdmin/NavbarAdmin'

export default {
  name: 'Library',
  components: {
    Layout,
    NavbarAdmin
  },
  setup(props, context) {
    return {}
  }
}
</script>

<style lang="postcss" scoped></style>
