import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class StripeSetupIntent extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'StripeSetupIntent'
  static instanceDefaults() {
    return {}
  }
  static setupInstance(data, { models }) {
    if (data.org) {
      data.org = new models.api.Org(data.org)
    }
    return data
  }
}
const servicePath = 'stripe-setup-intent'
const servicePlugin = makeServicePlugin({
  Model: StripeSetupIntent,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
