import { getInstance } from '../auth/auth0'
import feathersClient from '../feathers-client'
import { models } from 'feathers-vuex'

// called when the store is initialized
export default function authPlugin(store) {
  const auth0 = getInstance()

  store.registerModule('auth', {
    namespaced: true,
    state: {
      isLoading: true,
      isAuthenticated: false,
      accessToken: null,
      payload: null,
      user: null,
      auth0User: null,
      currentOrgId: null,
      error: null
    },
    getters: {
      currentOrg: state => models.api.Org.getFromStore(state.currentOrgId)
    },
    mutations: {
      setApiAuthData(state, response) {
        Object.assign(state, {
          user: new models.api.User(response.user),
          payload: response.authentication.payload,
          accessToken: response.accessToken
        })
      },
      setAuth0User(state, auth0User) {
        state.auth0User = auth0User
      },
      setAuthenticated(state) {
        state.isAuthenticated = true
        state.isLoading = false
      },
      setLoaded(state) {
        state.isLoading = false
      },
      /**
       * Call this mutation with the orgId to change orgs in the UI.
       * This is called from store.auth.js immediately after authenticating the FeathersJS API.
       */
      setCurrentOrgId(state, orgId) {
        state.currentOrgId = orgId
      },
      setError(state) {
        state.error = true
      }
    },
    actions: {
      async authenticate({ commit, dispatch }) {
        let feathersApiResponse

        try {
          feathersApiResponse = await feathersClient.authenticate()

          commit('setApiAuthData', feathersApiResponse)
          dispatch('setInitialOrg', feathersApiResponse.user)
        } catch (error) {
          console.log('error during Feathers API Authentication', error)
          store.commit('auth/setError')
        }

        return feathersApiResponse
      },
      /**
       * Sets the initial org from either the current-org-id key in localStorage OR
       * the orgId from the orgUser record where isDefaultOrg === true. For this to work,
       * `user.orgUsers` must exist, and each orgUser record must have `orgUser.org` on it.
       */
      setInitialOrg({ commit }, user) {
        const storedOrgId = window.localStorage.getItem('current-org-id')
        const orgUser =
          user.orgUsers.find(ou => ou.orgId === storedOrgId) ||
          user.orgUsers.find(ou => ou.isDefaultOrg)

        window.localStorage.setItem('current-org-id', orgUser.orgId)
        commit('setCurrentOrgId', orgUser.orgId)
      }
    }
  })
  auth0.on('loaded', async () => {
    if (auth0.user) {
      await store.dispatch('auth/authenticate')
      store.commit('auth/setAuth0User', auth0.user)
      store.commit('auth/setAuthenticated')
      store.commit('auth/setLoaded')
    } else {
      store.commit('auth/setLoaded')
    }
  })
}
