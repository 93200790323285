<template>
  <div class="sidebar-support">
    <FeathersVuexFormWrapper v-if="item && item.scene" :item="item.scene" watch>
      <template #default="{ clone, save, reset, remove }">
        <SceneForm
          :item="clone"
          :env-scene-id="id"
          @save="save"
          @reset="reset"
          @remove="e => removeFromEnvironment(remove)"
          @remove-from-environment="removeFromEnvironment"
        ></SceneForm>
      </template>
    </FeathersVuexFormWrapper>

    <div v-else>Loading Service</div>
  </div>
</template>

<script>
import { models, useGet } from 'feathers-vuex'
import { computed } from '@vue/composition-api'
import SceneForm from '../SceneForm/SceneForm'

export default {
  name: 'EnvScenesSidebarEditorEnvScene',
  components: {
    SceneForm
  },
  props: {
    envScenes: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const { EnvScene } = models.api

    const id = computed(() => context.root.$route.query.envSceneId)
    const queryWhen = computed(() => {
      const envScene = EnvScene.getFromStore(id.value)
      return !envScene || !envScene.scene
    })
    const params = computed(() => {
      return { $populateParams: { name: 'scene' } }
    })
    const { item } = useGet({ model: EnvScene, id, queryWhen, params })

    /**
     * Remove the scene from the environment (delete the envScene)
     */
    function removeFromEnvironment(callback) {
      item.value.remove()
      if (callback) {
        callback()
      }
    }

    return { id, item, removeFromEnvironment }
  }
}
</script>

<style lang="postcss"></style>
