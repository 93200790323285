<template>
  <form @submit.prevent="">
    <SidebarModal v-model="isUploaderOpen" class="z-10">
      <template #default="{ close }">
        <SidebarUploadSelector
          :model="models.api.Asset"
          :new-files="newFiles"
          :current-tab="currentUploaderTab"
          :endpoint="$store.state.uploadUrlEndpoint"
          qid="assetSelector"
          resource-name="Asset"
          @select="handleFileSelect"
          @tab-change="handleTabChange"
          @uploaded="handleUploaded"
          @close="close"
        />
      </template>
    </SidebarModal>

    <label class="block">
      <span class="form-label text-gray-700">Name</span>
      <input
        v-model="_env.name"
        class="form-input block w-full"
        placeholder="My Environment"
        @blur="() => save_env('name')"
      />
      <span class="text-red-400">{{ errors.name }}</span>
    </label>

    <div class="block mt-3">
      <span class="block mb-1">Cover Image</span>

      <!-- Image -->
      <div class="max-w-3/4">
        <ImageChangeRemove
          v-if="_env.primaryAssetUrl"
          :src="_env.primaryAssetUrl"
          :fit.sync="_env.primaryAssetFit"
          @update:fit="() => save_env('primaryAssetFit')"
          @dragenter="openSelector"
          @change-image="openSelector"
          @remove-image="handleRemoveCover"
        />
        <!-- No Image -->
        <NoImage
          v-else
          button-text="Select Image"
          @click="openSelector"
          @dragenter="openSelector"
        />
      </div>
    </div>

    <label class="block mt-3">
      <p>Environment Bounds</p>
      <div class="h-64 mt-3">
        <ClusterMap
          :access-token="$store.state.mapboxToken"
          :items="[]"
          class="w-full"
          cluster
          @load="handleMapLoad"
        />
      </div>
    </label>

    <div class="flex mt-3">
      <button type="button" class="form-button primary py-1 ml-1" @click="setEnvBounds">
        Set Bounds
      </button>
      <button type="button" class="form-button primary py-1 ml-1" @click="resetEnvBounds">
        Reset Bounds
      </button>
    </div>
    <div class="mt-3">
      <MapboxTokenInput v-model="_env.mapboxToken" @blur="handleBlur" />
    </div>
  </form>
</template>

<script>
import { models, useFind } from 'feathers-vuex'
import { ref, computed, toRefs, watch } from '@vue/composition-api'
import { PermissionManager } from '@rovit/permission-manager'
import { SidebarModal } from '@rovit/sidebar-modal'
import { ImageChangeRemove, NoImage } from '@rovit/image-display-sidebar'
import { SidebarUploadSelector } from '@rovit/sidebar-upload-selector'
import { AlertOctagonIcon } from 'vue-feather-icons'
import { handleClones } from '@rovit/utils/handle-clones'
import { MglMap } from 'vue-mapbox'

import MapboxTokenInput from './../MapboxTokenInput/MapboxTokenInput'
import { ClusterMap } from '@rovit/map'
import _get from 'lodash/get.js'

export default {
  name: 'EnvironmentEditorInfo',
  components: {
    SidebarModal,
    ImageChangeRemove,
    NoImage,
    SidebarUploadSelector,
    MapboxTokenInput,
    ClusterMap
  },
  props: {
    env: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props, { useExisting: true })
    const { _env } = clones
    const { save_env } = saveHandlers
    const currentOrg = context.root.$store.getters['auth/currentOrg']
    const vueMapbox = {}
    const isMapReady = ref(false)

    function handleBlur() {
      console.log({ _env: _env.value })
      save_env('mapboxToken')
    }

    context.root.$store.commit('environments/clearError', 'create')
    context.root.$store.commit('environments/clearError', 'patch')

    const errors = computed(() => {
      const { errorOnCreate, errorOnPatch } = context.root.$store.state.environments
      return (errorOnPatch && errorOnPatch.errors) || (errorOnCreate && errorOnCreate.errors) || {}
    })

    /**
     * Adds the org to the environment's `orgs` array.
     */
    function addOrgToEnv({ org, permission }) {
      _env.value.orgs.push({
        orgId: org._id,
        orgName: org.name,
        permission
      })
      save_env('orgs')
    }
    /**
     * Adds the user to the environment's `users` array.
     */
    function addUserToEnv({ user, permission }) {
      _env.value.users.push({
        userId: user._id,
        userName:
          (user && user.name) ||
          user.email ||
          (user.firstName && user.lastName && `${user.firstName} ${user.lastName}`),
        permission
      })
      save_env('users')
    }

    /**
     * Sidebar upload selector methods
     */
    const currentUploaderTab = ref('library')
    const isUploaderOpen = ref(false)
    const newFiles = ref([])
    function openSelector() {
      isUploaderOpen.value = true
    }
    function closeSelector() {
      isUploaderOpen.value = false
    }
    function handleTabChange(tabName) {
      currentUploaderTab.value = tabName
    }

    async function handleUploaded(data) {
      Object.assign(data, {
        envId: props.env._id,
        orgId: currentOrg._id
      })
      const asset = await new models.api.Asset(data).save()
      newFiles.value.push(asset)
    }

    async function handleFileSelect(item) {
      Object.assign(_env.value, {
        primaryAssetId: item._id,
        primaryAssetUrl: item.url
      })
      save_env(['primaryAssetId', 'primaryAssetUrl'])
      closeSelector()
    }

    function handleRemoveCover() {
      Object.assign(_env.value, {
        primaryAssetId: null,
        primaryAssetUrl: ''
      })
      save_env(['primaryAssetId', 'primaryAssetUrl'])
    }

    function handleMapLoad(event) {
      Object.assign(vueMapbox, event)
      isMapReady.value = true
      resetEnvBounds()
    }

    watch(
      () => _get(props.env, 'bbox'),
      bounds => {
        if (bounds && bounds.length === 2) {
          fitToBounds(bounds)
        }
      }
    )

    function setEnvBounds() {
      if (isMapReady.value == true) {
        var bounds = vueMapbox.component.map.getBounds()
        Object.assign(_env.value, {
          bbox: bounds.toArray()
        })
        save_env('bbox', { autoToast: true })
      } else {
        console.log('Map is not ready')
      }
    }

    function resetEnvBounds() {
      if (props.env.bbox && props.env.bbox.length === 2) {
        fitToBounds(props.env.bbox)
      }
    }

    function fitToBounds(bounds) {
      if (isMapReady.value) {
        vueMapbox.component.map.fitBounds(bounds)
      }
    }

    return {
      models,
      ...clones,
      ...saveHandlers,
      addOrgToEnv,
      addUserToEnv,
      errors,

      // SidebarUploadSelector
      currentUploaderTab,
      isUploaderOpen,
      newFiles,
      openSelector,
      closeSelector,
      handleTabChange,
      handleUploaded,
      handleFileSelect,
      handleRemoveCover,
      handleBlur,
      handleMapLoad,
      setEnvBounds,
      resetEnvBounds
    }
  }
}
</script>

<style lang="postcss"></style>
