<template>
  <div class="sidebar-support">
    <InfoboxForm v-if="scene" :service="service" :infobox="scene" />

    <div v-else>
      Loading Infobox
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { getScene } from '@/use/scenes.js'

import InfoboxForm from '../InfoboxForm/InfoboxForm'

export default {
  name: 'OrgServiceSidebarEditorInfobox',
  components: {
    InfoboxForm
  },
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const id = computed(() => context.root.$route.query.infoboxId)
    const { scene } = getScene({ id })

    return { id, scene }
  }
}
</script>

<style lang="postcss"></style>
