<template>
  <button type="button" class="feature-option" @click="e => $emit('click', e)">
    <div class="feature-tile" :class="current.bg">
      <component :is="current.icon" class="" />
      <p class="pl-2">{{ current.label }}</p>
    </div>
  </button>
</template>

<script>
import { PlayIcon, ImageIcon, MapIcon } from 'vue-feather-icons'
import { PanoIcon } from '@rovit/icons'
import { computed } from '@vue/composition-api'

export default {
  name: 'FeatureTile',
  props: {
    type: {
      validator: val => ['pano', 'map', 'asset', 'video'].includes(val),
      required: true
    }
  },
  setup(props, context) {
    const icons = {
      pano: PanoIcon,
      map: MapIcon,
      asset: ImageIcon,
      video: PlayIcon
    }
    const labels = {
      pano: 'Pano',
      map: 'Map',
      asset: 'Image',
      video: 'Video'
    }
    const bgColors = {
      pano: 'bg-purple-600 hover:bg-purple-700',
      map: 'bg-orange-600 hover:bg-orange-700',
      asset: 'bg-blue-600 hover:bg-blue-700',
      video: 'bg-blue-900 hover:bg-blue-800'
    }
    const current = computed(() => {
      return {
        icon: icons[props.type],
        label: labels[props.type],
        bg: bgColors[props.type]
      }
    })

    return { current }
  }
}
</script>

<style lang="postcss" scoped>
.feature-option {
  @apply text-center mx-2;
}
.feature-tile {
  @apply px-3 py-2 rounded flex flex-row items-center justify-center text-white font-semibold;
}
</style>
