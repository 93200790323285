<template>
  <div class="admin-navbar">
    <UserMenu class="mr-1" />

    <router-link to="/" class="link mr-2.5" :class="{ active: $route.name === 'OrgHome' }">
      <div>
        <HomeIcon />
      </div>
      Home
    </router-link>

    <router-link
      to="/services"
      class="link mr-2.5"
      :class="{ active: $route.name === 'OrgServices' }"
    >
      <div>
        <BriefcaseIcon />
      </div>
      Services
    </router-link>

    <router-link
      :to="{ name: 'OrgEnvironments' }"
      class="link mr-2.5"
      :class="{ active: $route.name === 'OrgEnvironments' }"
    >
      <div>
        <GlobeIcon />
      </div>
      Environs
    </router-link>

    <router-link
      to="/library"
      class="link mr-2.5"
      :class="{ active: $route.name === 'OrgLibrary' }"
    >
      <div>
        <BookIcon />
      </div>
      Library
    </router-link>

    <router-link to="/team" class="link mr-2.5" :class="{ active: $route.name === 'OrgTeam' }">
      <div>
        <UsersIcon />
      </div>
      Team
    </router-link>

    <router-link to="/billing" class="link mr-2.5" :class="{ active: $route.name === 'billing' }">
      <div>
        <CreditCardIcon />
      </div>
      Billing
    </router-link>
  </div>
</template>

<script>
import UserMenu from '../UserMenu/UserMenu'
import {
  HomeIcon,
  BriefcaseIcon,
  GlobeIcon,
  BookIcon,
  UsersIcon,
  CreditCardIcon
} from 'vue-feather-icons'

export default {
  name: 'NavbarAdmin',
  components: {
    UserMenu,
    HomeIcon,
    BriefcaseIcon,
    GlobeIcon,
    BookIcon,
    UsersIcon,
    CreditCardIcon
  }
}
</script>

<style lang="postcss"></style>
