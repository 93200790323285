<template>
  <div class="sidebar-add-service">
    <h1 class="sidebar-heading-1 mt-2">Add a Scene</h1>
    <p class="sidebar-subheading">
      You can create a new service or search for existing services, below.
    </p>

    <button type="button" class="form-button primary mt-2" @click="handleAdd">
      Add Scene to Infobox
    </button>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import keyBy from 'lodash/keyBy'

export default {
  name: 'InfoboxSidebarAddScene',
  components: {},
  props: {},
  setup(props, context) {
    function handleAdd() {
      context.emit('add-scene')
    }

    return {
      handleAdd
    }
  }
}
</script>

<style lang="postcss"></style>
