<template>
  <div class="sidebar-edit-pano mb-64">
    <div v-if="pano">
      <!-- Sidebar Modal with Pano Selector -->
      <SidebarModal v-model="isUploaderOpen" class="z-10">
        <template #default="{ close }">
          <SidebarUploadSelector
            :model="models.api.Pano"
            :new-files="newFiles"
            :current-tab="currentUploaderTab"
            :endpoint="$store.state.uploadUrlEndpoint"
            qid="panoSelector"
            resource-name="Pano"
            @select="handleFileSelect"
            @tab-change="handleTabChange"
            @uploaded="handleUploaded"
            @close="close"
          />
        </template>
      </SidebarModal>

      <!-- Popper for Hotspot Icon Editor -->
      <div ref="popperEl" class="absolute z-10 w-3/4">
        <div
          v-show="isPopperVisible"
          class="rounded bg-white dark:bg-gray-900 dark:text-white p-3 w-full relative border border-gray-500"
        >
          <div data-popper-arrow></div>
          <div class="flex justify-end">
            <button type="button" @click="isPopperVisible = false">Close</button>
          </div>
          <ItemHotspotEditor
            v-if="scene"
            :item="scene"
            :hotspot-index="hotspotIndex"
            class="mt-1 w-full"
          />
        </div>
      </div>

      <label class="block">
        <span class="text-gray-700">Name</span>
        <input
          v-model="_pano.name"
          type="text"
          class="form-input block w-full"
          :placeholder="pano.originalFileName"
          @blur="e => save_pano('name')"
        />
      </label>

      <div class="block mt-3 w-full">
        <span class="block mb-1">Pano</span>

        <div v-if="scene && scene.panoUrl">
          <FeatureHotspots
            :item="scene"
            :src="() => largeImage(scene.panoUrl).url"
            target-percent-x-attr-name="panoTargetPercentX"
            target-percent-y-attr-name="panoTargetPercentY"
            :current-hotspot-index="hotspotIndex || 0"
            @image-click="handlePanoClick"
            @hotspot-click="handleHotspotClick"
            @hotspot-update="handleHotspotUpdate"
          />
          <!-- <div class="flex flex-row">
            <div class="flex-grow"></div>
            <button type="button" @click="openSelector">Change Image</button>
          </div> -->
        </div>

        <!-- Image -->
        <ImageChangeRemove
          v-else-if="pano.url"
          :src="thumbnail(_pano.url).url"
          fit="contain"
          height="204px"
          hide-fit
          @change-image="openSelector"
          @remove-image="handleRemovePano"
        />

        <!-- No Image -->
        <NoImage v-else button-text="Select Pano" @click="openSelector" />
      </div>

      <!-- Featured In Scene -->
      <PanoFeaturedInScene
        v-if="scene"
        :scene="scene"
        :parent-scene="parentScene"
        class="mt-4"
        @open-scene="openScene"
      />

      <!-- Create Infobox / Scene -->
      <CreateSceneFromPano v-else :pano="pano" :env="env" class="mt-2" :scenes="scenes" />

      <ScenesLoader :env="env" class="mt-3" />

      <!-- PanoMap -->
      <PanoMap
        :pano="pano"
        :panos-with-scenes="panosWithScenes"
        :panos-without-scenes="panosWithoutScenes"
        :env="env"
        @target-pano="setTargetPano"
      />

      <!-- TargetPano -->
      <div class="mt-2">
        <label>Target Pano</label>
        <TargetPano
          v-if="targetPano"
          :target-pano="targetPano"
          :target-scene="targetScene"
          :current-scene="scene"
          :scenes="scenes"
          :env-id="env && env._id"
          @open-target-pano="openTargetPano"
          @open-target-scene="openScene"
        />
        <p v-else class="bg-gray-300 dark:bg-gray-800 p-2 rounded">
          Select a pano point in the map, above.
        </p>
      </div>

      <!-- Move/Copy -->
      <!-- <MoveCopyItems
        :items="[pano]"
        :env-id="env && env._id"
        class="mt-4"
        @moved="moveHandler"
        @copied="copyHandler"
      /> -->
    </div>

    <div v-else>Loading Pano</div>
  </div>
</template>

<script>
import { models, useGet } from 'feathers-vuex'
import { ref, computed, watch, onUnmounted } from '@vue/composition-api'
import { handleClones } from '@rovit/utils/handle-clones.js'
import { thumbnail, largeImage } from '@/use/image-transforms/index'
import fastCopy from 'fast-copy'
import _omit from 'lodash/omit.js'
import _get from 'lodash/get.js'
import { createPopper } from '@popperjs/core'
import { copyEnvServiceWithPano, moveEnvServiceWithPano } from '../../use/env-services'

import { SidebarModal } from '@rovit/sidebar-modal'
import { ImageChangeRemove, NoImage } from '@rovit/image-display-sidebar'
import { SidebarUploadSelector } from '@rovit/sidebar-upload-selector'
import CreateSceneFromPano from './CreateSceneFromPano.vue'
import PanoFeaturedInScene from './PanoFeaturedInScene.vue'
import ScenesLoader from './ScenesLoader.vue'
import MoveCopyItems from '../MoveCopyItems.vue'
import PanoMap from './PanoMap.vue'
import TargetPano from './TargetPano.vue'
import FeatureHotspots from '../FeatureHotspots/FeatureHotspots.vue'
import ItemHotspotEditor from '../ItemHotspotEditor/ItemHotspotEditor.vue'

export default {
  name: 'PanoEditorInfo',
  components: {
    ImageChangeRemove,
    NoImage,
    SidebarModal,
    SidebarUploadSelector,
    CreateSceneFromPano,
    PanoFeaturedInScene,
    ScenesLoader,
    // MoveCopyItems,
    PanoMap,
    TargetPano,
    FeatureHotspots,
    ItemHotspotEditor
  },
  props: {
    pano: {
      validator: () => true,
      default: null
    },
    panosWithScenes: {
      type: Array,
      required: true
    },
    panosWithoutScenes: {
      type: Array,
      required: true
    },
    env: {
      validator: val => typeof val === 'object',
      default: null
    },
    scenes: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _pano } = clones
    const { save_pano } = saveHandlers
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    const panoId = computed(() => props.pano._id)
    // Is the current pano in any scene inside this environment, including child scenes.
    const scene = computed(() => {
      return props.scenes.find(s => s.panoId === panoId.value)
    })

    // Parent Scene
    const parentSceneId = computed(() => (scene.value ? scene.value.parentSceneId : null))
    const { item: parentScene } = useGet({
      id: parentSceneId,
      model: models.api.Scene,
      local: true
    })

    /**
     * Primary Pano Selection
     */
    const currentUploaderTab = ref('library')
    const isUploaderOpen = ref(false)
    const newFiles = ref([])
    function openSelector() {
      isUploaderOpen.value = true
    }
    function closeSelector() {
      isUploaderOpen.value = false
    }
    function handleTabChange(tabName) {
      currentUploaderTab.value = tabName
    }
    /**
     * Uploading works like normal, but as soon as you click a file, the most recently-uploaded
     * pano will replace the current (see the handleFileSelect handler).
     */
    async function handleUploaded(data) {
      const uploadData = await prepareUploadData(data)
      Object.assign(uploadData, {
        envId: props.env._id,
        orgId: currentOrg._id
      })
      const pano = await new models.api.Pano(uploadData).save()
      newFiles.value.push(pano)
    }
    /**
     * This is different than most handleFileSelect functions. Since we're overwriting the original
     * pano in the database, it creates a copy of the original as a new record and then overwrites
     * the data for the original record with the new pano's data.
     */
    async function handleFileSelect(item) {
      // Save a copy of the old pano.
      const oldData = _omit(props.pano, ['_id'])
      oldData.envId = props.env._id
      oldData.name += '[old pano]'
      await new models.api.Pano(oldData).save()

      // Overwrite the current record with the selection
      const newData = _omit(item, ['_id'])
      newData.envId = props.env._id
      save_pano(newData)

      closeSelector()
    }
    function handleRemovePano() {
      Object.assign(_pano.value, {
        primaryPanoId: null,
        primaryPanoUrl: ''
      })
      save_pano(['primaryPanoId', 'primaryPanoUrl'])
    }

    function openScene(item) {
      // normalize argument or scene closure into a computed value.
      const sceneToOpen = computed(() => (item && item._id ? item : scene.value))
      const data = {
        name: 'EnvServiceInfoboxEditor',
        params: {
          envId: props.env._id,
          serviceId: sceneToOpen.value.serviceId
        }
      }
      // If current scene is a child of another infobox, include the sceneId
      if (sceneToOpen.value.parentSceneId) {
        data.params.sceneId = sceneToOpen.value._id
      }
      let route = this.$router.resolve(data)
      window.open(route.href, '_blank')
    }

    const targetPano = ref(null)
    function setTargetPano(pano) {
      targetPano.value = pano
    }
    // Is the targetPano in any scene inside this environment, including child scenes.
    const targetScene = computed(() => {
      return props.scenes
        .filter(s => s.panoId)
        .find(s => s.panoId != null && s.panoId === (targetPano.value && targetPano.value._id))
    })

    /**
     * Open the targetPano, and set the current pano as the target, so it refers back to
     * where we just came from.
     */
    const cachedCurrentPano = ref(null)
    const cachedTargetPano = ref(null)
    function openTargetPano() {
      // cache the current state for when we come back to this pano.
      cachedCurrentPano.value = props.pano
      cachedTargetPano.value = targetPano.value
      // Update the route
      const data = {
        name: 'EnvLibPanos',
        params: { envId: props.env._id },
        query: {
          panoId: targetPano.value._id,
          sidebar: 'editor'
        }
      }
      context.root.$router.push(data)
      // Set the cachedCurrentPano as the new targetPano.
      targetPano.value = cachedCurrentPano.value
    }

    /**
     * Popper
     */
    let popper = null
    const isPopperVisible = ref(false)
    const popperEl = ref(null)
    const hotspotIndex = ref(null)
    onUnmounted(() => {
      popper && popper.destroy()
    })

    watch(
      () => _get(props.pano, '_id') || null,
      panoId => {
        isPopperVisible.value = false
      }
    )

    function handleHotspotClick({ hotspot, offset, event, index }) {
      hotspotIndex.value = index
      isPopperVisible.value = true
      popper = createPopper(event.target, popperEl.value, {
        placement: 'bottom'
      })
    }
    function handlePanoClick() {}
    function handleHotspotUpdate() {}
    function moveHandler(ids, destEnv) {
      ids.forEach(panoId => {
        const options = {
          pano: {
            _id: panoId
          },
          sourceEnv: env,
          destEnv: destEnv
        }
        moveEnvServiceWithPano(options)
      })
    }
    function copyHandler(ids, destEnv) {
      ids.forEach(async panoId => {
        const options = {
          pano: {
            _id: panoId
          },
          sourceEnv: env,
          destEnv: destEnv
        }
        copyEnvServiceWithPano(options)
      })
    }

    return {
      models,
      ...clones,
      ...saveHandlers,
      thumbnail,
      largeImage,
      scene,
      parentScene,
      openScene,

      // Popper
      isPopperVisible,
      popperEl,
      hotspotIndex,

      // PanoHotpots Handlers
      handlePanoClick,
      handleHotspotClick,
      handleHotspotUpdate,

      // Target Pano
      targetPano,
      targetScene,
      setTargetPano,
      openTargetPano,

      // SidebarUploadSelector
      currentUploaderTab,
      isUploaderOpen,
      newFiles,
      openSelector,
      closeSelector,
      handleTabChange,
      handleUploaded,
      handleFileSelect,
      handleRemovePano,

      copyHandler,
      moveHandler
    }
  }
}
</script>

<style lang="postcss"></style>
