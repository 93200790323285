<template>
  <div class="sidebar-tabs">
    <button
      type="button"
      class="combo-left"
      :class="{
        active: currentTab === 'info'
      }"
      @click="$emit('input', 'info')"
    >
      Info
    </button>

    <button
      type="button"
      class="combo-middle"
      :class="{
        active: currentTab === 'service'
      }"
      @click="$emit('input', 'service')"
    >
      Service
    </button>

    <button
      type="button"
      class="combo-middle"
      :class="{
        active: currentTab === 'environments'
      }"
      @click="$emit('input', 'environments')"
    >
      Environments
    </button>

    <button
      type="button"
      class="combo-middle"
      style="border-left: none"
      :class="{
        active: currentTab === 'permissions'
      }"
      @click="$emit('input', 'permissions')"
    >
      Permissions
    </button>

    <button
      type="button"
      class="combo-right"
      style="border-left: none"
      :class="{
        active: currentTab === 'danger'
      }"
      @click="$emit('input', 'danger')"
    >
      <AlertTriangleIcon
        class="p-0.5"
        :class="[currentTab === 'danger' ? 'text-white' : 'text-yellow-700']"
      />
    </button>
  </div>
</template>

<script>
import { AlertTriangleIcon } from 'vue-feather-icons'

export default {
  name: 'SceneFormSidebarTabs',
  components: {
    AlertTriangleIcon
  },
  model: {
    prop: 'currentTab'
  },
  props: {
    currentTab: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    return {}
  }
}
</script>

<style lang="postcss"></style>
