<template>
  <div class="sidebar-edit-asset">
    <div v-if="asset">
      <!-- Sidebar Modal with Asset Selector -->
      <SidebarModal v-model="isUploaderOpen" class="z-10">
        <template #default="{ close }">
          <SidebarUploadSelector
            :model="models.api.Asset"
            :new-files="newFiles"
            :current-tab="currentUploaderTab"
            :endpoint="$store.state.uploadUrlEndpoint"
            qid="assetSelector"
            resource-name="Asset"
            @select="handleFileSelect"
            @tab-change="handleTabChange"
            @uploaded="handleUploaded"
            @close="close"
          />
        </template>
      </SidebarModal>

      <h1 class="sidebar-heading-1 mt-2">Edit Photo</h1>

      <label class="block">
        <span class="text-gray-700">Name</span>
        <input
          v-model="_asset.name"
          type="text"
          class="form-input block w-full"
          :placeholder="asset.originalFileName"
          @blur="e => save_asset('name')"
        />
      </label>

      <div class="block mt-3 w-3/4">
        <span class="block mb-1">Photo</span>

        <!-- Image -->
        <ImageChangeRemove
          v-if="asset.url"
          :src="_asset.url"
          fit="contain"
          hide-fit
          @change-image="openSelector"
          @remove-image="handleRemoveAsset"
        />

        <!-- No Image -->
        <NoImage v-else button-text="Select Asset" @click="openSelector" />
      </div>

      <DangerZoneDelete @remove="remove" />
    </div>

    <div v-else>
      Loading Photo
    </div>
  </div>
</template>

<script>
import { models } from 'feathers-vuex'
import { ref, computed } from '@vue/composition-api'
import { handleClones } from '@rovit/utils/handle-clones.js'
import fastCopy from 'fast-copy'
import _omit from 'lodash/omit'

import { SidebarModal } from '@rovit/sidebar-modal'
import { ImageChangeRemove, NoImage } from '@rovit/image-display-sidebar'
import { SidebarUploadSelector } from '@rovit/sidebar-upload-selector'
import DangerZoneDelete from '../../components/DangerZoneDelete/DangerZoneDelete.vue'

export default {
  name: 'EnvLibPhotosSidebarEditPhoto',
  components: {
    ImageChangeRemove,
    NoImage,
    SidebarModal,
    SidebarUploadSelector,
    DangerZoneDelete
  },
  props: {
    asset: {
      validator: () => true,
      default: null
    },
    envId: {
      type: String,
      default: null
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _asset } = clones
    const { save_asset } = saveHandlers
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    function remove() {
      props.asset.remove()
      const query = {}
      context.root.$router.push({ query })
    }

    /**
     * Primary Asset Selection
     */
    const currentUploaderTab = ref('library')
    const isUploaderOpen = ref(false)
    const newFiles = ref([])
    function openSelector() {
      isUploaderOpen.value = true
    }
    function closeSelector() {
      isUploaderOpen.value = false
    }
    function handleTabChange(tabName) {
      currentUploaderTab.value = tabName
    }
    /**
     * Uploading works like normal, but as soon as you click a file, the most recently-uploaded
     * asset will replace the current (see the handleFileSelect handler).
     */
    async function handleUploaded(data) {
      const uploadData = await prepareUploadData(data)
      Object.assign(uploadData, {
        envId: props.envId,
        orgId: currentOrg._id
      })
      const asset = await new models.api.Asset(uploadData).save()
      newFiles.value.push(asset)
    }
    /**
     * This is different than most handleFileSelect functions. Since we're overwriting the original
     * asset in the database, it creates a copy of the original as a new record and then overwrites
     * the data for the original record with the new asset's data.
     */
    async function handleFileSelect(item) {
      // Save a copy of the old asset.
      const oldData = _omit(props.asset, ['_id'])
      oldData.envId = props.envId
      oldData.name += '[old asset]'
      await new models.api.Asset(oldData).save()

      // Overwrite the current record with the selection
      const newData = _omit(item, ['_id'])
      newData.envId = props.envId
      save_asset(newData)

      closeSelector()
    }
    function handleRemoveAsset() {
      Object.assign(_asset.value, {
        primaryAssetId: null,
        primaryAssetUrl: ''
      })
      save_asset(['primaryAssetId', 'primaryAssetUrl'])
    }

    return {
      models,
      ...clones,
      ...saveHandlers,
      remove,

      // SidebarUploadSelector
      currentUploaderTab,
      isUploaderOpen,
      newFiles,
      openSelector,
      closeSelector,
      handleTabChange,
      handleUploaded,
      handleFileSelect,
      handleRemoveAsset
    }
  }
}
</script>

<style lang="postcss"></style>
