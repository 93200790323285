<template>
  <div class="sidebar-support">
    <h1 class="sidebar-heading-1 mt-2">
      Edit Service
    </h1>

    <p class="content-subheading">
      Select a service from the left, or use
      <button class="bg-gray-400 px-1 rounded">
        <PlusIcon class="inline w-4 h-4 relative" style="bottom: 2px;" />
      </button>
      , above.
    </p>
  </div>
</template>

<script>
import { PlusIcon } from 'vue-feather-icons'

export default {
  name: 'OrgServicesSidebarEditorBlank',
  components: {
    PlusIcon
  },
  props: {}
}
</script>

<style lang="postcss"></style>
