<template>
  <Layout>
    <template #content>
      <NavbarAdmin />

      <div class="px-12">
        <h1 class="content-heading-1">{{ $store.getters['auth/currentOrg'].name }} Org</h1>
        <router-link to="/orgs" class="text-blue-600 relative" style="top: -0.7em;"
          >Switch Orgs</router-link
        >

        <p class="content-subheading">
          See an overview of your entire org. Charts, Stats, Numbers, all of it!
        </p>
      </div>
    </template>

    <template #sidebar>
      Dashboard Sidebar
    </template>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout'
import { ref, computed } from '@vue/composition-api'
import NavbarAdmin from '../components/NavbarAdmin/NavbarAdmin'

export default {
  name: 'Dashboard',
  components: {
    Layout,
    NavbarAdmin
  },
  setup(props, context) {}
}
</script>

<style lang="postcss" scoped></style>
