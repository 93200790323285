var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-navbar"},[_c('UserMenu',{staticClass:"mr-1"}),_c('router-link',{staticClass:"link mr-2.5",class:{ active: _vm.$route.name === 'OrgHome' },attrs:{"to":"/"}},[_c('div',[_c('HomeIcon')],1),_vm._v(" Home ")]),_c('router-link',{staticClass:"link",class:{ active: _vm.$route.name === 'OrgServices' },attrs:{"to":"/services"}},[_c('div',[_c('BriefcaseIcon')],1),_vm._v(" Services ")]),_c('div',{staticClass:"text-green-900 mt-4"},[_c('ChevronRightIcon')],1),_c('router-link',{staticClass:"link active-crumb",class:{ active: _vm.$route.name === 'OrgProjects' },staticStyle:{"max-width":"92px"},attrs:{"to":{
      name: 'OrgServiceInfoboxes',
      params: {
        serviceId: (_vm.service && _vm.service._id) || ''
      }
    }}},[_c('div',{staticClass:"flex flex-row justify-center bg-green-200"},[_c('BriefcaseIcon')],1),_c('VClamp',{staticClass:"leading-none mt-1",attrs:{"autoresize":"","max-lines":2,"tag":"p"}},[_vm._v(" "+_vm._s(_vm.service && _vm.service.name)+" ")])],1),_c('div',{staticClass:"text-green-900 mt-4"},[_c('ChevronRightIcon')],1),_c('router-link',{staticClass:"link",class:{ active: _vm.$route.name === 'OrgServiceInfoboxes' },attrs:{"to":{
      name: 'OrgServiceInfoboxes',
      params: {
        serviceId: (_vm.service && _vm.service._id) || ''
      }
    }}},[_c('div',{staticStyle:{"max-width":"52px","margin":"0 auto"}},[_c('InfoIcon')],1),_vm._v(" Infoboxes ")]),_c('div',{staticClass:"text-green-900 mt-4"},[_c('ChevronRightIcon')],1),_c('router-link',{staticClass:"link active-crumb mr-2",class:{ active: _vm.$route.name === 'OrgServiceInfoboxes' },staticStyle:{"max-width":"92px"},attrs:{"to":{
      name: 'InfoboxEditor',
      params: {
        serviceId: _vm.service._id,
        infoboxId: _vm.infobox._id
      }
    }}},[_c('div',{staticClass:"flex flex-row justify-center bg-green-200"},[_c('EditIcon')],1),_c('VClamp',{staticClass:"leading-none mt-1",attrs:{"autoresize":"","max-lines":2,"tag":"p"}},[_vm._v(" "+_vm._s(_vm.infobox && _vm.infobox.infoboxName)+" ")])],1),_c('div',{staticClass:"navbar-divider mr-2"}),_c('router-link',{staticClass:"link mr-2 page",class:{ active: _vm.$route.name === 'OrgServiceDetails' },attrs:{"to":{
      name: 'InfoboxEditor',
      params: {
        serviceId: _vm.service._id,
        infoboxId: _vm.infobox._id
      }
    }}},[_c('div',[_c('GridIcon')],1),_vm._v(" Scenes ")]),_c('router-link',{staticClass:"link mr-2 page",class:{ active: _vm.$route.name === 'InfoboxPublish' },attrs:{"to":{
      name: 'InfoboxPublish',
      params: {
        serviceId: _vm.service._id,
        infoboxId: _vm.infobox._id
      }
    }}},[_c('div',[_c('ShareIcon')],1),_c('p',{staticClass:"leading-none mt-1"},[_vm._v("Publish "),_c('br'),_vm._v("& Share")])]),_c('router-link',{staticClass:"link mr-2 page",class:{ active: _vm.$route.name === 'InfoboxAnalytics' },attrs:{"to":{
      name: 'InfoboxAnalytics',
      params: {
        serviceId: _vm.service._id,
        infoboxId: _vm.infobox._id
      }
    }}},[_c('div',[_c('BarChart2Icon')],1),_vm._v(" Analytics ")]),_c('router-link',{staticClass:"link mr-2 page",class:{ active: _vm.$route.name === 'InfoboxTeam' },attrs:{"to":{
      name: 'InfoboxTeam',
      params: {
        serviceId: _vm.service._id,
        infoboxId: _vm.infobox._id
      }
    }}},[_c('div',[_c('UsersIcon')],1),_vm._v(" Team ")]),_c('router-link',{staticClass:"link mr-2 page",class:{ active: _vm.$route.name === 'InfoboxBilling' },attrs:{"to":{
      name: 'InfoboxBilling',
      params: {
        serviceId: _vm.service._id,
        infoboxId: _vm.infobox._id
      }
    }}},[_c('div',[_c('CreditCardIcon')],1),_vm._v(" Billing ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }