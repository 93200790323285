<template>
  <Layout>
    <template #content>
      <NavbarEnvironment :env="currentEnv" />

      <div class="px-12">
        <h1 class="content-heading-1">Environment Team</h1>

        <p class="content-subheading">
          There are three types of interactions you can have with people on this page.
        </p>

        <ol class="list-decimal list-inside mt-2 mx-3">
          <li>
            View and add
            <strong class="font-medium">members of your team</strong>. You can even add people
            outside your org so they can view or edit environment content.
          </li>
          <li class="mt-2">
            <strong class="font-medium">Your subscribers</strong> also show up here. They get
            updates about your environment, and you can even communicate with them directly through
            the in-app chat.
          </li>
          <li class="mt-2">
            Finally, you can manage all of the people who currently host their services inside of
            this environment. You'll also see how much they pay, if anything, to host their content.
            If you belong to an agency, you'll also see pricing information for infoboxes and other
            content which you have sold. Feel free to chat with them, too!
          </li>
        </ol>
      </div>
    </template>

    <template #sidebar>
      Environment People Sidebar
    </template>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout'
import { ref } from '@vue/composition-api'
import { getEnv } from '@/use/environments'

import NavbarEnvironment from '../components/NavbarEnvironment/NavbarEnvironment'

export default {
  name: 'EnvironmentTeam',
  metaInfo: {
    title: 'Environment Team'
  },
  components: {
    Layout,
    NavbarEnvironment
  },
  setup(props, context) {
    const { env: currentEnv } = getEnv(context.root.$route.params.envId)

    return { currentEnv }
  }
}
</script>

<style lang="postcss" scoped></style>
