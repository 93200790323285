<template>
  <form @submit.prevent="e => {}">
    <h1 class="sidebar-heading-1 mt-2">
      {{ $route.query.serviceId === 'new' ? 'New' : 'Edit' }} Service
    </h1>

    <ServiceSidebarTabs />

    <ServiceEditorInfo v-if="$route.query.serviceSection === 'info'" :service="service" />
    <ServiceEditorInfoboxes
      v-if="$route.query.serviceSection === 'infoboxes'"
      :service="service"
      :env-services="envServices"
    />
    <ServiceEditorLocation
      v-if="$route.query.serviceSection === 'location'"
      :service="service"
      :env-services="envServices"
    />
    <ServiceEditorContact v-if="$route.query.serviceSection === 'contact'" :service="service" />
    <ServiceEditorSchedule v-if="$route.query.serviceSection === 'schedule'" :service="service" />

    <ServiceEditorDanger
      v-if="$route.query.serviceSection === 'danger'"
      :service="service"
      @remove-from-environment="$emit('remove-from-environment')"
    />
  </form>
</template>

<script>
import ServiceSidebarTabs from './ServiceSidebarTabs.vue'
import ServiceEditorInfo from './ServiceEditorInfo.vue'
import ServiceEditorInfoboxes from './ServiceEditorInfoboxes.vue'
import ServiceEditorLocation from './ServiceEditorLocation.vue'
import ServiceEditorDanger from './ServiceEditorDanger.vue'
import ServiceEditorContact from './ServiceEditorContact.vue'
import ServiceEditorSchedule from './ServiceEditorSchedule.vue'
import { watch } from '@vue/composition-api'

export default {
  name: 'ServiceSidebar',
  components: {
    ServiceSidebarTabs,
    ServiceEditorInfo,
    ServiceEditorInfoboxes,
    ServiceEditorLocation,
    ServiceEditorDanger,
    ServiceEditorContact,
    ServiceEditorSchedule
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    envServices: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    watch(
      () => context.root.$route.query.serviceSection,
      section => {
        if (!section) {
          const newRoute = Object.assign({}, context.root.$route)
          newRoute.query = Object.assign({}, newRoute.query, { serviceSection: 'info' })
          context.root.$router.push(newRoute)
        }
      },
      { immediate: true }
    )
    return {}
  }
}
</script>

<style lang="postcss"></style>
