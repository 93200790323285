<template>
  <div
    class="text-black dark:text-white bg-gray-400 dark:bg-gray-800 px-3 pt-1 border border-gray-500 dark:border-gray-700 rounded"
  >
    <div class="pt-2 text-lg font-medium flex items-center justify-center">
      Add a Background Feature
    </div>

    <div class="flex flex-row items-center justify-center py-3 flex-wrap">
      <FeatureTile type="pano" @click="() => setFeatureType('pano')" />
      <FeatureTile type="map" @click="() => setFeatureType('map')" />
      <FeatureTile type="asset" @click="() => setFeatureType('asset')" />
      <FeatureTile type="video" @click="() => setFeatureType('video')" />
    </div>
    <!-- <div
      class="h-3 bg-gray-500 rounded-t-lg border-l border-t border-r border-gray-500 dark:border-gray-600"
    ></div> -->
  </div>
</template>

<script>
import FeatureTile from './FeatureTile.vue'
import { handleClones } from '@rovit/utils'

export default {
  name: 'SceneNoBackgroundFeature',
  components: {
    FeatureTile
  },
  props: {
    scene: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props, { useExisting: true })
    const { _scene } = clones
    const { save_scene } = saveHandlers

    function setFeatureType(type) {
      _scene.value.featureType = type
      save_scene('featureType')
    }
    return { setFeatureType }
  }
}
</script>

<style lang="postcss" scoped></style>
