<template>
  <div class="danger-zone -m-1 mt-12 mb-4">
    <h2 class="sidebar-heading-1 flex flex-row items-center">
      The Danger Zone
      <AlertOctagonIcon class="ml-2 text-red-600" />
    </h2>

    <!-- "Delete Environment" Button -->
    <XPopper placement="right">
      <template #reference="{ toggle }">
        <button ref="reference" type="button" class="form-button danger mt-1" @click.stop="toggle">
          {{ buttonText }}
        </button>
      </template>

      <template #popper="{ toggle }">
        <div
          ref="popper"
          v-click-outside="toggle"
          class="bg-white bg-gray-800 p-3 rounded text-black text-center z-50 flex flex-row items center"
        >
          <button
            type="button"
            class="form-button bg-gray-300 hover:bg-gray-500 dark:bg-gray-500 dark:hover:bg-gray-700 mr-2"
            @click="toggle"
          >
            Cancel
          </button>
          <button type="button" class="form-button danger" @click="$emit('remove')">
            Confirm Delete
          </button>
        </div>
      </template>
    </XPopper>
  </div>
</template>

<script>
import { XPopper } from '@ionomy/x-popper'
import { AlertOctagonIcon } from 'vue-feather-icons'

export default {
  name: 'Name',
  components: {
    XPopper,
    AlertOctagonIcon
  },
  props: {
    buttonText: {
      type: String,
      default: 'Delete Record'
    }
  }
}
</script>

<style lang="postcss"></style>
