<template>
  <div>
    <label>Target Scene</label>
    <div class="bg-gray-300 dark:bg-gray-800 p-2 rounded overflow-hidden">
      <div v-if="scene.assetUrl">
        <img :src="thumbnail(scene.assetUrl).url" alt="" class="w-full rounded" />
      </div>
      <div
        v-else
        style="width: 392px; height: 150px;"
        class="rounded flex items-center justify-center bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-400"
      >
        <div>No Image Set</div>
      </div>

      <div class="flex justify-between items-center mt-2">
        <div>
          <div>{{ scene.infoboxName || scene.name }}</div>
          <div>Service: {{ scene.service.name }}</div>
        </div>
        <button class="form-button primary" @click="handleOpenScene">
          Open Scene
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { thumbnail } from '@/use/image-transforms/index'

export default {
  name: 'TargetScene',
  components: {},
  props: {
    scene: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    function handleOpenScene() {
      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'scene',
        sceneId: props.scene._id
      })
      const params = Object.assign({}, context.root.$route.params, {
        serviceId: props.scene.serviceId
      })
      context.root.$router.push({ params, query })
    }
    return {
      thumbnail,
      handleOpenScene
    }
  }
}
</script>
