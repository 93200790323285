<template>
  <Layout>
    <template #content>
      <NavbarEnvironmentLibrary :env="currentEnv" />

      <div class="px-12">
        <h1 class="content-heading-1">Categories</h1>

        <p class="content-subheading">
          Manage categories for the
          <strong>{{ currentEnv && currentEnv.name }}</strong> environment. Drag and drop to sort.
        </p>

        <div v-if="!isRovitEnv" class="mt-4 text-xs uppercase">Branding Tile</div>
        <div v-else class="mt-4"></div>
        <div>
          <draggable
            v-model="parentCategoriesProxy"
            :animation="250"
            @start="drag = true"
            @end="drag = false"
          >
            <transition-group
              tag="div"
              type="transition"
              :name="!drag ? 'flip-list' : null"
              class="mt-3 flex flex-row flex-wrap -mx-1"
            >
              <CategoryEditorTile
                v-for="category in parentCategoriesProxy"
                :key="category._id"
                :category="category"
                :current-category="currentCategory"
                :categories="categories"
                @open="cat => openCategoryInSidebar(cat)"
              />
            </transition-group>
          </draggable>

          <button
            type="button"
            class="bg-gray-300 dark:bg-gray-700 rounded px-4 py-1.5"
            @click="handleCreateCategory"
          >
            Add Category
          </button>
        </div>
      </div>
    </template>

    <template #sidebar>
      <div>
        <EnvLibCategoriesSidebarTabs @editor-tab-click="showMostRecentEditor" />

        <EnvLibCategoriesSidebarSupport v-if="sidebarName === 'support'" />

        <EnvLibCategoriesSidebarEditorBlank
          v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
        />

        <EnvLibCategoriesSidebarEditCategory
          v-if="sidebarName === 'editor' && sidebarEditorType === 'category'"
          :categories="categories"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from '../../layouts/Layout'
import { ref, computed } from '@vue/composition-api'
import { getEnv, rovit } from '@/use/environments.js'
import { models } from 'feathers-vuex'
import useSidebarTabState from '@/use/sidebar-tab-state.js'
import { createCategory, getCategory, findCategories } from '@/use/categories.js'
import _get from 'lodash/get.js'

import NavbarEnvironmentLibrary from '../NavbarEnvironmentLibrary/NavbarEnvironmentLibrary'
import CategoryEditorTile from '../CategoryEditorTile/CategoryEditorTile'
import draggable from 'vuedraggable'

import EnvLibCategoriesSidebarTabs from '../EnvLibCategories/SidebarTabs.vue'
import EnvLibCategoriesSidebarSupport from '../EnvLibCategories/SidebarSupport.vue'
import EnvLibCategoriesSidebarEditorBlank from '../EnvLibCategories/SidebarEditorBlank.vue'
import EnvLibCategoriesSidebarEditCategory from '../EnvLibCategories/SidebarEditCategory.vue'

export default {
  name: 'EnvLibCategories',
  metaInfo: {
    title: 'Env Library Categories'
  },
  components: {
    Layout,
    NavbarEnvironmentLibrary,
    CategoryEditorTile,
    draggable,
    EnvLibCategoriesSidebarTabs,
    EnvLibCategoriesSidebarSupport,
    EnvLibCategoriesSidebarEditorBlank,
    EnvLibCategoriesSidebarEditCategory
  },
  setup(props, context) {
    const { env: currentEnv } = getEnv(context.root.$route.params.envId)

    // Categories
    const { categories } = findCategories({ env: currentEnv })
    const parentCategoriesProxy = computed({
      get: () => categories.value.filter(cat => !cat.parentCategoryId),
      set: val => {
        val.forEach((category, index) => {
          category
            .clone({ sortOrder: index })
            .commit()
            .save({ data: { sortOrder: index } })
        })
      }
    })
    const drag = ref(false)

    // Current Category
    const categoryId = computed(() => context.root.$route.query.categoryId)
    const { category: currentCategory } = getCategory(categoryId)

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      return context.root.$route.query.categoryId ? 'category' : 'blank'
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      watchInQuery: ['categoryId'],
      context
    })

    /**
     * Create a new category then open it in the sidebar
     */
    async function handleCreateCategory() {
      const { category } = await createCategory({ env: currentEnv })

      openCategoryInSidebar(category)
    }

    function openCategoryInSidebar(category) {
      const query = { categoryId: category._id, sidebar: 'editor' }
      context.root.$router.push({ query }, () => {})
    }

    const isRovitEnv = computed(() => {
      const currentEnvId = _get(currentEnv.value, '_id')
      const rovitEnvId = _get(rovit.value, '_id')
      return rovitEnvId != null && currentEnvId === rovitEnvId
    })

    return {
      isRovitEnv,
      currentEnv,
      categories,
      parentCategoriesProxy,
      drag,
      currentCategory,
      showMostRecentEditor,
      sidebarName,
      sidebarEditorType,
      handleCreateCategory,
      openCategoryInSidebar
    }
  }
}
</script>

<style lang="postcss" scoped></style>
