<template>
  <div class="bg-white rounded text-black p-4">
    <div>
      <span class="text-xl font-bold">
        Make Environment {{ env.isPublic ? 'Private' : 'Public' }}
      </span>
    </div>
    <div class="mt-2">
      <p>You are about to make the {{ env.name }} {{ env.isPublic ? 'private' : 'public' }}.</p>
    </div>
    <div class="flex flex-row items-center mt-2">
      <button
        class="form-button bg-green-600 rounded-ful justify-center mr-2"
        @click="$emit('confirm')"
      >
        Confirm
      </button>
      <button class="form-button bg-gray-300 dark:bg-gray-500" @click="$emit('close')">
        Close
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EnvironmentPublicPrompt',
  props: {
    env: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {}
}
</script>
