<template>
  <div class="sidebar-support">
    EnvLibIconsSidebarSupport
  </div>
</template>

<script>
export default {
  name: 'EnvLibPhotosSidebarSupport',
  props: {}
}
</script>

<style lang="postcss"></style>
