<template>
  <XSelect
    v-model="envProxy"
    :items="infoboxes"
    :label="i => i.infoboxName || i.name"
    placeholder="Select Infobox"
    :create="handleCreateInfobox"
  />
</template>

<script>
import { computed } from '@vue/composition-api'
import { findInfoboxesForService, createInfobox } from '@/use/scenes.js'

import { XSelect } from '@rovit/x-select'

export default {
  name: 'InfoboxSelect',
  components: {
    XSelect
  },
  model: {
    prop: 'scene'
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    scene: {
      required: true,
      default: null,
      validator: () => true
    }
  },
  setup(props, context) {
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    const envProxy = computed({
      get: () => props.scene,
      set: val => context.emit('input', val)
    })
    const sort = { name: 1 }
    const queryWhen = computed(() => {
      return context.root.$store.state.scenes.ids.length < 1
    })
    const { infoboxes } = findInfoboxesForService({ service: props.service, queryWhen })

    async function handleCreateInfobox(name) {
      const { scene } = await createInfobox({ name, org: currentOrg })
      return scene
    }

    return { envProxy, infoboxes, handleCreateInfobox }
  }
}
</script>

<style lang="postcss"></style>
