<template>
  <div>
    <!-- Primary Asset -->
    <div v-if="hotspot">
      <div class="block bg-gray-400 dark:bg-gray-800 rounded p-3">
        <span class="block mb-1">Hotspot Icon</span>

        <!-- Image -->
        <ImageChangeRemove
          v-if="hotspot.iconUrl"
          :src="hotspot.iconUrl"
          fit="contain"
          hide-fit
          @change-image="openIconSelector"
          @remove-image="clearIcon"
        />

        <!-- No Image -->
        <NoImage
          v-else
          button-text="Select Hotspot Icon"
          :icon-component="MapPinIcon"
          @click="openIconSelector"
        />

        <div>
          <div class="flex flex-col mt-3">
            <span class="mr-1">Icon Size:</span>
            <input
              v-model.number="hotspot.iconScale"
              type="range"
              min="0.1"
              max="10"
              step="0.01"
              class="rounded-slider flex-grow"
              @change="handleHotspotIconResize"
            />
          </div>
        </div>

        <div class="flex flex-col mt-3">
          <span class="mr-1">Link to:</span>
          <LinkInfo v-model="hotspot.link" @click="handleLinkInfoClick" />

          <!-- Popper for LinkEditor -->
          <div ref="popperEl" class="absolute z-10 w-11/12">
            <div data-popper-arrow></div>
            <div
              v-show="isPopperVisible"
              class="rounded bg-white dark:bg-gray-900 dark:text-white p-3 w-full relative border border-gray-500"
            >
              <div class="flex justify-end">
                <button type="button" @click="isPopperVisible = false">Close</button>
              </div>
              <LinkEditor
                v-if="hotspot && isPopperVisible"
                v-model="hotspot.link"
                :org="currentOrg"
                @input="() => saveHotspotsHandler()"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-col mt-3">
          Hotspot Nudge:
          <div class="flex flex-col items-center">
            <div class="flex flex-row w-1/3">
              <div class="flex flex-col w-1/3"></div>
              <button
                type="button"
                class="flex flex-col items-center w-1/3"
                @click="() => nudgeHandler('up')"
              >
                <ArrowUpIcon size="1.5x" class="cursor-pointer"> </ArrowUpIcon>
              </button>
              <div class="flex flex-col w-1/3"></div>
            </div>
            <div class="flex flex-row w-1/3">
              <button
                type="button"
                class="flex flex-col items-center w-1/3"
                @click="() => nudgeHandler('left')"
              >
                <ArrowLeftIcon size="1.5x" class="cursor-pointer"> </ArrowLeftIcon>
              </button>
              <button
                type="button"
                class="flex flex-col items-center w-1/3"
                @click="() => nudgeHandler('down')"
              >
                <ArrowDownIcon size="1.5x" class="cursor-pointer"> </ArrowDownIcon>
              </button>
              <button
                type="button"
                class="flex flex-col items-center w-1/3"
                @click="() => nudgeHandler('right')"
              >
                <ArrowRightIcon size="1.5x" class="cursor-pointer"> </ArrowRightIcon>
              </button>
            </div>
          </div>
        </div>

        <div class="flex flex-col mt-3">
          <button
            type="button"
            class="form-button rounded danger justify-center w-full"
            @click="handleDeleteClick"
          >
            <div class="text-center">Delete Hotspot</div>
          </button>
          <!-- Popper for Delete confirmation -->
          <div ref="deletePopperEl" class="absolute z-10 w-11/12">
            <div data-popper-arrow></div>
            <div
              v-show="isDeletePopperVisible"
              class="rounded bg-white dark:bg-gray-900 dark:text-white p-3 w-full relative border border-gray-500"
            >
              <div class="bg-white rounded p-4">
                <div class="text-black">
                  <span class="text-xl font-bold">
                    Delete this Hotspot?
                  </span>
                </div>
                <div class="text-black">
                  <span> It will be permanently deleted. </span>
                </div>
                <div class="mt-3 flex">
                  <button class="form-button danger" @click="deleteHotspot">
                    Delete Hotspot
                  </button>
                  <button
                    class="form-button bg-gray-700 text-white rounded ml-2"
                    @click="isDeletePopperVisible = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="mt-1 p-3 bg-gray-400 black:bg-gray-800 rounded-lg text-center">
      Select a hotspot to edit it, here.
    </div>
  </div>
</template>

<script>
import { handleClones } from '@rovit/utils/handle-clones.js'
import { ref, computed, onUnmounted } from '@vue/composition-api'
import { useModal } from '@rovit/use-modal'
import { createPopper } from '@popperjs/core'
import _debounce from 'lodash/debounce.js'
import _cloneDeep from 'lodash/cloneDeep.js'

import { ImageChangeRemove, NoImage } from '@rovit/image-display-sidebar'
import {
  MapPinIcon,
  ArrowLeftIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowRightIcon
} from 'vue-feather-icons'
import LinkInfo from '../LinkInfo/LinkInfo.vue'
import LinkEditor from '../LinkEditor/LinkEditor.vue'

export default {
  name: 'SceneHotspotIconEditor',
  components: {
    ImageChangeRemove,
    NoImage,
    LinkInfo,
    LinkEditor,
    ArrowLeftIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    ArrowRightIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    hotspotIndex: {
      validator: val => ['number', 'string', 'undefined'].includes(typeof val),
      default: null
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props, { useExisting: true })
    const { _item } = clones
    const { save_item } = saveHandlers
    const iconModal = useModal('iconSelector')
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    const hotspot = computed(() => {
      return _item.value.hotspots[props.hotspotIndex]
    })

    function openIconSelector() {
      iconModal.open({ _item, hotspotIndex: props.hotspotIndex })
    }
    function clearIcon() {
      console.log(hotspot.value)
      Object.assign(hotspot.value, {
        iconId: null,
        iconUrl: '',
        iconHeight: 0,
        iconWidth: 0
      })
      saveHotspotsHandler.call(this)
    }

    function save_item_d() {
      return _debounce(save_item, 200)
    }

    function handleHotspotIconResize(value) {
      saveHotspotsHandler = saveHotspotsHandler.bind(this)
      saveHotspotsHandler()
    }

    /**
     * Popper
     */
    let popper = null
    let deletePopper = null
    const isPopperVisible = ref(false)
    const isDeletePopperVisible = ref(false)
    const popperEl = ref(null)
    const deletePopperEl = ref(null)
    onUnmounted(() => {
      popper && popper.destroy()
      deletePopper && deletePopper.destroy()
    })

    function handleLinkInfoClick() {
      isPopperVisible.value = true
      popper = createPopper(event.target, popperEl.value, {
        placement: 'top'
      })
    }
    function handleDeleteClick() {
      isDeletePopperVisible.value = true
      deletePopper = createPopper(event.target, deletePopperEl.value, {
        placement: 'top'
      })
    }
    function deleteHotspot() {
      const updatedHotspots = _cloneDeep(_item.value.hotspots)
      updatedHotspots.splice(props.hotspotIndex, 1)
      _item.value.hotspots = updatedHotspots
      save_item('hotspots')
        .then(res => {
          this.$toasted.global.actionSuccess('Hotspot deleted')
        })
        .catch(error => {
          this.$toasted.global.actionError('Failed to delete hotspot')
        })
      isDeletePopperVisible.value = false
    }

    function saveHotspotsHandler() {
      save_item('hotspots')
        .then(res => {
          this.$toasted.global.actionSuccess('Updated hotspot')
        })
        .catch(error => {
          this.$toasted.global.actionError('Failed to update hotspot')
        })
    }

    function nudgeHandler(direction) {
      switch (direction) {
        case 'up':
          hotspot.value.percentY -= 0.5
          if (hotspot.value.percentY < 0) {
            hotspot.value.percentY = 0
          }
          break
        case 'down':
          hotspot.value.percentY += 0.5
          if (hotspot.value.percentY > 100) {
            hotspot.value.percentY = 100
          }
          break
        case 'left':
          hotspot.value.percentX -= 0.5
          if (hotspot.value.percentX < 0) {
            hotspot.value.percentX = 0
          }
          break
        case 'right':
          hotspot.value.percentX += 0.5
          if (hotspot.value.percentX > 100) {
            hotspot.value.percentX = 100
          }
          break
        default:
          return
      }
      // TODO debounce
      saveHotspotsHandler = saveHotspotsHandler.bind(this)
      saveHotspotsHandler()
    }
    return {
      ...clones,
      ...saveHandlers,
      hotspot,
      openIconSelector,
      clearIcon,
      iconModal,
      handleHotspotIconResize,
      handleLinkInfoClick,
      MapPinIcon,

      // Popper
      isPopperVisible,
      popperEl,
      currentOrg,

      // Delete Popper
      isDeletePopperVisible,
      deletePopperEl,
      handleDeleteClick,
      deleteHotspot,

      nudgeHandler,
      saveHotspotsHandler
    }
  }
}
</script>

<style lang="postcss">
.rounded-slider {
  @apply bg-gray-700 block;
  -webkit-appearance: none;
  height: 15px;
  border-radius: 5px;
  outline: none;
  opacity: 0.99;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.rounded-slider::-webkit-slider-thumb {
  @apply bg-green-700 rounded-full;
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.rounded-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}
</style>
