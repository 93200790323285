<template>
  <div>
    <div class="sidebar-tabs">
      <button
        type="button"
        class="combo-left"
        :class="{
          active: currentTab === 'pano'
        }"
        @click="openPanoTab('pano')"
      >
        Pano
      </button>

      <button
        type="button"
        class="combo-middle"
        style="border-left: none;"
        :class="{
          active: currentTab === 'hotspots'
        }"
        @click="openPanoTab('hotspots')"
      >
        Hotspots
      </button>

      <button
        type="button"
        class="combo-right"
        style="border-left: none;"
        :class="{
          active: currentTab === 'hotspot'
        }"
        @click="openPanoTab('hotspot')"
      >
        Hotspot
      </button>
    </div>

    <div v-if="currentTab === 'pano'">
      <div class="block mt-3 w-full">
        <span class="block mb-1">Pano</span>

        <!-- Image -->
        <ImageChangeRemove
          v-if="scene.panoUrl"
          :src="thumbnail(_scene.panoUrl).url"
          fit="contain"
          height="204px"
          hide-fit
          @change-image="openSelector"
          @remove-image="handleRemovePano"
        />

        <!-- No Image -->
        <NoImage v-else button-text="Select Pano" @click="openSelector" />
      </div>
    </div>

    <SceneHotspotsList v-if="currentTab === 'hotspots'" :scene="scene" class="mt-1" />

    <ItemHotspotEditor
      v-if="currentTab === 'hotspot'"
      :item="scene"
      :hotspot-index="hotspotIndex"
      class="mt-1 w-3/4"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useModal } from '@rovit/use-modal'
import { handleClones } from '@rovit/utils/handle-clones.js'
import _isEqual from 'lodash/isEqual.js'
import { thumbnail } from '@/use/image-transforms/index'

import ItemHotspotEditor from '../ItemHotspotEditor/ItemHotspotEditor.vue'
import SceneHotspotsList from './SceneHotspotsList.vue'
import { ImageChangeRemove, NoImage } from '@rovit/image-display-sidebar'

export default {
  name: 'SceneFeaturePanoEditor',
  components: {
    ItemHotspotEditor,
    SceneHotspotsList,
    ImageChangeRemove,
    NoImage
  },
  props: {
    scene: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _scene } = clones
    const { save_scene } = saveHandlers

    const scenePanoModal = useModal('panoSelector')
    const currentTab = computed(() => context.root.$route.query.panoTab || 'pano')
    const hotspotIndex = computed(() => {
      return context.root.$route.query.hotspotIndex
    })
    const currentHotspot = computed(() => {
      return props.scene.hotspots[hotspotIndex.value] || null
    })

    function openSelector() {
      scenePanoModal.open({ scene: props.scene })
    }

    function handleRemovePano() {
      save_scene({ panoId: null, panoUrl: '' })
    }

    // watch(
    //   () => context.root.$route.query.hotspotIndex,
    //   index => {
    //     if (typeof parseInt(index) === 'number') {
    //       currentTab.value = 'hotspot'
    //     }
    //   }
    // )

    function openPanoTab(panoTab) {
      const currentQuery = context.root.$route.query
      const query = Object.assign({}, currentQuery, { panoTab })
      if (!_isEqual(currentQuery, query)) {
        context.root.$router.push({ query })
      }
    }

    return {
      ...clones,
      ...saveHandlers,
      currentTab,
      hotspotIndex,
      currentHotspot,
      openSelector,
      handleRemovePano,
      scenePanoModal,
      openPanoTab,
      thumbnail
    }
  }
}
</script>

<style lang="postcss"></style>
