var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-navbar"},[_c('UserMenu',{staticClass:"mr-1"}),_c('router-link',{staticClass:"link mr-2.5",class:{ active: _vm.$route.name === 'OrgHome' },attrs:{"to":"/"}},[_c('div',[_c('HomeIcon')],1),_vm._v(" Home ")]),_c('router-link',{staticClass:"link",class:{ active: _vm.$route.name === 'OrgEnvironments' },attrs:{"to":{ name: 'OrgEnvironments' }}},[_c('div',[_c('GlobeIcon')],1),_vm._v(" Environs ")]),_c('div',{staticClass:"text-green-900 mt-4"},[_c('ChevronRightIcon')],1),_c('router-link',{staticClass:"link active-crumb",class:{ active: _vm.$route.name === 'OrgProjects' },staticStyle:{"max-width":"92px"},attrs:{"to":{
      name: 'EnvironmentServices',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',{staticClass:"flex flex-row justify-center bg-green-200"},[_c('EditIcon')],1),_c('VClamp',{staticClass:"leading-none mt-1",attrs:{"autoresize":"","max-lines":2,"tag":"p"}},[_vm._v(" "+_vm._s(_vm.env && _vm.env.name)+" ")])],1),_c('div',{staticClass:"text-green-900 mt-4"},[_c('ChevronRightIcon')],1),_c('router-link',{staticClass:"link page no-highlight",attrs:{"to":{
      name: 'EnvironmentServices',
      params: {
        envId: (_vm.env && _vm.env._id) || ''
      }
    }}},[_c('div',{staticStyle:{"max-width":"52px","margin":"0 auto"}},[_c('BriefcaseIcon')],1),_vm._v(" Services ")]),_c('div',{staticClass:"text-green-900 mt-4"},[_c('ChevronRightIcon')],1),_c('router-link',{staticClass:"link page mr-2 active",staticStyle:{"max-width":"92px"},attrs:{"to":{
      name: 'EnvServiceInfoboxEditor',
      params: {
        envId: (_vm.env && _vm.env._id) || '',
        serviceId: (_vm.service && _vm.service._id) || ''
      }
    }}},[_c('div',{staticClass:"flex flex-row justify-center bg-green-200"},[_c('EditIcon')],1),_c('VClamp',{staticClass:"leading-none mt-1",attrs:{"autoresize":"","max-lines":2,"tag":"p"}},[_vm._v(" "+_vm._s(_vm.service && _vm.service.name)+" ")])],1),_c('div',{staticClass:"navbar-divider mr-2"}),_c('router-link',{staticClass:"link page mr-1.5",class:{ active: _vm.$route.name === 'EnvServiceInfoboxEditor' },attrs:{"to":{
      name: 'EnvServiceInfoboxEditor',
      params: {
        envId: (_vm.env && _vm.env._id) || '',
        serviceId: (_vm.service && _vm.service._id) || ''
      }
    }}},[_c('div',[_c('InfoIcon')],1),_vm._v(" Infobox ")]),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvServicePublish' },attrs:{"to":{
      name: 'EnvServicePublish',
      params: {
        envId: (_vm.env && _vm.env._id) || '',
        serviceId: (_vm.service && _vm.service._id) || ''
      }
    }}},[_c('div',[_c('BookIcon')],1),_c('p',{staticClass:"leading-none mt-1"},[_vm._v(" Publish "),_c('br'),_vm._v(" & Share ")])]),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvServiceAnalytics' },attrs:{"to":{
      name: 'EnvServiceAnalytics',
      params: {
        envId: (_vm.env && _vm.env._id) || '',
        serviceId: (_vm.service && _vm.service._id) || ''
      }
    }}},[_c('div',[_c('BarChart2Icon')],1),_vm._v(" Analytics ")]),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvServiceTeam' },attrs:{"to":{
      name: 'EnvServiceTeam',
      params: {
        envId: (_vm.env && _vm.env._id) || '',
        serviceId: (_vm.service && _vm.service._id) || ''
      }
    }}},[_c('div',[_c('UsersIcon')],1),_vm._v(" Team ")]),_c('router-link',{staticClass:"link page mr-2",class:{ active: _vm.$route.name === 'EnvServiceBilling' },attrs:{"to":{
      name: 'EnvServiceBilling',
      params: {
        envId: (_vm.env && _vm.env._id) || '',
        serviceId: (_vm.service && _vm.service._id) || ''
      }
    }}},[_c('div',[_c('CreditCardIcon')],1),_vm._v(" Billing ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }