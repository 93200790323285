<template>
  <div
    v-if="isVisible"
    class="transition transform fixed top-0 bottom-0 left-0 z-10 bg-black bg-opacity-50"
    style="padding: 0.45em 0.85em 0.85em; overflow-x: auto; right: 440px;"
    :class="[
      `duration-${duration}`,
      isTransitioned ? 'scale-100 opacity-100' : `scale-${scale} opacity-0`
    ]"
    @click.self="close"
  >
    <slot name="default" v-bind="{ close }" />
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from '@vue/composition-api'
export default {
  name: 'ContentModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    scale: {
      type: Number,
      default: 75
    },
    duration: {
      type: Number,
      default: 200
    }
  },
  setup(props, context) {
    const isVisible = computed(() => props.value)
    const isTransitioned = ref(false)
    function open() {
      isTransitioned.value = true
    }

    /**
     * 1. Remove the `isTransitioned` style to start the transition.
     * 2. Wait `duration` ms
     * 3. Hide the modal
     */
    function close() {
      isTransitioned.value = false
      setTimeout(() => {
        context.emit('input', false)
      }, props.duration)
    }

    watch(
      () => props.value,
      () => {
        setTimeout(() => {
          props.value && open()
        })
      },
      { immediate: true }
    )

    return { isTransitioned, isVisible, close }
  }
}
</script>

<style lang="postcss">
.sidebar-modal-closed {
  @apply transition-transform duration-150 transform scale-90;
}
.sidebar-modal-open {
  @apply scale-100;
}
</style>
