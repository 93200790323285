<template>
  <div class="bg-white rounded p-4">
    <div class="text-black">
      <span class="text-xl font-bold">
        Delete Scene from Infobox?
      </span>
    </div>
    <div class="text-black">
      <span> The "{{ scene.name }}" scene will be deleted permanently. </span>
    </div>
    <div class="mt-3 flex">
      <button class="form-button danger" @click="deleteScene">
        Delete Scene
      </button>
      <button class="form-button bg-gray-700 text-white rounded ml-2" @click="$emit('close')">
        Cancel
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PromptDeleteInfobox',
  components: {},
  props: {
    scene: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    async function deleteScene() {
      context.emit('close')
      const removedScene = await props.scene.remove()
      const newRoute = Object.assign({}, context.root.$route)
      context.root.$router.push({
        name: newRoute.name,
        params: newRoute.params,
        query: {}
      })
    }
    return {
      deleteScene
    }
  }
}
</script>
