<template>
  <div class="project-tile" @click="e => $emit('click', e)" @dblclick="e => $emit('dblclick', e)">
    <AspectRatio
      ratio="2:1"
      class="overflow-hidden rounded w-full bg-gray-400 dark:bg-gray-800 cursor-pointer border-2 rounded"
      :class="[selected ? 'border-green-500' : 'border-none']"
    >
      <img
        v-if="src && !img404"
        :src="src"
        alt=""
        class="h-full object-cover w-full"
        @error="img404 = true"
      />
      <div
        v-else
        class="flex flex-col text-center w-full font-semibold text-gray-600 justify-center items-center h-full"
      >
        <ImageIcon class="self-center" />
        <p>No image available</p>
      </div>
    </AspectRatio>
    <div
      v-if="label !== false"
      class="font-semibold text-center cursor-pointer truncate flex flex-row items-center"
    >
      <p class="flex-grow">{{ label }}</p>

      <slot name="menu"></slot>
    </div>
  </div>
</template>

<script>
import { AspectRatio } from '@rovit/aspect-ratio'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'ProjectTile',
  components: {
    AspectRatio
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    label: {
      default: '',
      validator: val => ['string', 'boolean'].includes(typeof val)
    },
    fit: {
      type: String,
      default: 'cover'
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const fitClass = computed(() => {
      const fits = {
        contain: 'object-contain',
        cover: 'object-cover',
        fill: 'object-fill',
        none: 'object-none',
        'scale-down': 'object-scale-down'
      }
      return fits[props.fit] || fits.cover
    })

    // if img url return 404, mark img404 as true else false
    const img404 = ref(false)
    return {
      img404
    }
  }
}
</script>

<style lang="postcss"></style>
