<template>
  <div class="feature-hotspots">
    <AspectRatio v-if="src(_item)" :ratio="aspectRatio">
      <ImageHotspots
        :src="src(_item)"
        :hotspots="_item.hotspots"
        :zoom="zoom"
        :draggable="currentDraggable"
        :target-offsets="targetOffsets"
        :current-hotspot-index="currentHotspotIndex"
        show-target
        @set-target-offset="setTargetOffsets"
        @set-draggable="setDraggable"
        @image-click="$emit('image-click')"
        @hotspot-click="handleHotspotClick"
        @add-hotspot="addHotspot"
        @update-hotspot="updateHotspot"
      />
    </AspectRatio>

    <div v-if="src(_item)" class="px-1 pb-2 flex">
      <input
        v-model.number="zoom"
        type="range"
        min="1"
        max="10"
        step="0.01"
        class="rounded-slider flex-grow"
      />
    </div>
  </div>
</template>

<script>
import { handleClones } from '@rovit/utils'
import { ref, computed } from '@vue/composition-api'

import { ImageHotspots } from '@rovit/image-hotspots'
import { AspectRatio } from '@rovit/aspect-ratio'

export default {
  name: 'FeatureHotspots',
  components: {
    ImageHotspots,
    AspectRatio
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    src: {
      type: Function,
      default: i => i.panoUrl
    },
    currentHotspotIndex: {
      type: Number,
      default: 0,
      required: true
    },
    targetPercentXAttrName: {
      type: String,
      default: 'targetPercentX'
    },
    targetPercentYAttrName: {
      type: String,
      default: 'targetPercentY'
    },
    aspectRatio: {
      type: String,
      default: '2:1'
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _item } = clones
    const { save_item } = saveHandlers

    const currentDraggable = ref(null)
    const zoom = ref(1)

    const targetOffsets = computed(() => {
      const { targetPercentXAttrName, targetPercentYAttrName } = props
      return {
        percentX: props.item[targetPercentXAttrName],
        percentY: props.item[targetPercentYAttrName]
      }
    })

    function setTargetOffsets({ offsets }) {
      const { targetPercentXAttrName, targetPercentYAttrName } = props
      Object.assign(_item.value, {
        [targetPercentXAttrName]: offsets.percentX,
        [targetPercentYAttrName]: offsets.percentY
      })
      save_item([targetPercentXAttrName, targetPercentYAttrName])
    }
    function setDraggable(draggable) {
      currentDraggable.value = draggable
    }

    function addHotspot({ offsets }) {
      // Not implemented, yet. Maybe never.
    }

    function handleHotspotClick({ hotspot, offset, event, index }) {
      context.emit('hotspot-click', { hotspot, offset, event, index })
    }

    function updateHotspot({ hotspot, offsets }) {
      const index = _item.value.hotspots.findIndex(hs => hs === hotspot)
      Object.assign(hotspot, offsets)
      save_item('hotspots')
      context.emit('update-hotspot', { hotspot, index })
    }

    return {
      ...clones,
      ...saveHandlers,
      currentDraggable,
      zoom,
      targetOffsets,

      // Handlers
      setTargetOffsets,
      setDraggable,
      addHotspot,
      handleHotspotClick,
      updateHotspot
    }
  }
}
</script>

<style lang="postcss" scoped>
.rounded-slider {
  @apply bg-gray-700 block;
  -webkit-appearance: none;
  height: 15px;
  border-radius: 5px;
  outline: none;
  opacity: 0.99;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.rounded-slider::-webkit-slider-thumb {
  @apply bg-purple-700 rounded-full;
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.rounded-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}
</style>
