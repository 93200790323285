import feathersClient, { models, makeServicePlugin, BaseModel } from '../../feathers-client'
import { makeModel } from '@rovit/models/Scene'
import shouldFilterServiceKeys from '../../utils/fetch-variable-from-env'
import { iff, discard, alterItems } from 'feathers-hooks-common'
import _pick from 'lodash/pick.js'

const servicePath = 'scenes'
const servicePlugin = makeServicePlugin({
  Model: makeModel({ BaseModel, models }),
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [
      iff(
        context => ['create', 'update', 'patch'].includes(context.method),
        discard('modules', 'childScenes', 'service', 'moduleIds', 'asset', 'pano'),
        iff(
          shouldFilterServiceKeys(),
          alterItems(i =>
            _pick(i, [
              '_id',
              'serviceId',
              'name',
              'bbox',
              'location',

              'envsMeta',
              'categoriesMeta',

              'isInfobox',
              'infoboxName',
              'parentSceneId',

              'isLicensable',
              'sceneTemplateId',
              'sceneModulesMeta',

              'badgesMeta',
              'featureType',

              'assetId',
              'assetUrl',
              'assetFit',

              'defaultHotspotIconUrl',
              'defaultHotspotIconId',

              'panoId',
              'panoName',
              'panoUrl',
              'panoTargetPercentX',
              'panoTargetPercentY',

              'hotspots',

              'users',
              'orgs',

              'createdBy',
              'createdAt',
              'updatedAt'
            ])
          )
        )
      )
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [
      // Update bbox to be an array if it's null
      context => {
        if (context.data.hasOwnProperty('bbox') && context.data.bbox == null) {
          context.data.bbox = []
        }
      }
    ],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
