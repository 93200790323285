<template>
  <div class="p-2 bg-blue-200 rounded">
    <XPaginateDates
      unit="day"
      :start-date.sync="startDate"
      :end-date.sync="endDate"
      :multiplier="multiplier"
    >
      <template #default="{ format, startDate, endDate, sub, add }">
        <div>
          <!-- The Chart -->
          <div class="bg-white flex flex-col justify-center items-center">
            <HighChart v-if="chartOptions" :options="chartOptions" />
            <!-- Loading Block, matches Chart size to prevent UI thrashing -->
            <div v-else class="w-full h-64 flex justify-center items-center">
              Loading chart data
            </div>
          </div>

          <!-- Chart controls -->
          <div class="bg-white flex flex-row items-center justify-center">
            <div>
              <select
                v-model="unit"
                class="form-select py-1"
                @change="handleDateChange($event.target.value)"
              >
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="2months">2 Months</option>
                <option value="quarter">Quarter</option>
                <option value="year">Year</option>
              </select>
            </div>

            <!-- Previous Week -->
            <button
              class="text-blue-800 text-white rounded px-2 py-1 my-1"
              @click="handleSubClick(sub)"
            >
              <ChevronLeftIcon></ChevronLeftIcon>
            </button>

            <!-- Current Dates -->
            <div class="w-32 text-center">
              {{ format(startDate, 'd MMM-yy') }} to
              {{ format(endDate, 'd MMM-yy') }}
            </div>

            <!-- Next Week -->
            <button class="text-blue-800 text-white rounded px-2 py-1" @click="handleAddClick(add)">
              <ChevronRightIcon></ChevronRightIcon>
            </button>
          </div>
        </div>
      </template>
    </XPaginateDates>

    <!-- <Chart :chartOptions="chartOptions" /> -->
  </div>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { getChartByName } from '@/use/charts'

import { Chart as HighChart } from '@rovit/chart'
import XPaginateDates from '@ionomy/x-paginate-dates'

export default {
  name: 'SceneModuleImpressionViewsChart',
  components: {
    HighChart,
    XPaginateDates
  },
  setup() {
    const unit = ref('month')
    const startDate = ref(null)
    const endDate = ref(null)
    const multiplier = ref(31)

    const multiplierOptions = {
      week: 8,
      month: 31,
      '2months': 61,
      quarter: 91,
      year: 366
    }

    const { chartOptions } = getChartByName({
      name: 'scene_module_impression',
      startDate,
      endDate
    })

    function handleAddClick(add) {
      const value = unit.value
      if (value) {
        add(multiplierOptions[value], 'day')
      }
    }

    function handleSubClick(sub) {
      const value = unit.value
      if (value) {
        sub(multiplierOptions[value], 'day')
      }
    }

    function handleDateChange(option) {
      if (option) {
        multiplier.value = multiplierOptions[option]
      }
    }
    return {
      unit,
      startDate,
      endDate,
      multiplier,

      chartOptions,

      // Handlers
      handleAddClick,
      handleSubClick,
      handleDateChange
    }
  }
}
</script>

<style></style>
