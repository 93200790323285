<template>
  <div class="admin-navbar environment-navbar mt-1.5">
    <UserMenu class="mr-1" />
    <router-link to="/" class="mr-2 back-button">
      <div>
        <ArrowLeftIcon />
      </div>
      Back
    </router-link>

    <router-link
      :to="{
        name: 'UserOrgs',
        params: {
          envId: $route.params.envId
        }
      }"
      class="link mr-2"
      :class="{ active: $route.name === 'UserOrgs' }"
    >
      <div>
        <CloudIcon />
      </div>
      Orgs
    </router-link>

    <router-link
      :to="{
        name: 'UserProfile',
        params: {
          envId: $route.params.envId
        }
      }"
      class="link mr-2"
      :class="{ active: $route.name === 'UserProfile' }"
    >
      <div>
        <UsersIcon />
      </div>
      Profile
    </router-link>

    <router-link
      :to="{
        name: 'UserSettings',
        params: {
          envId: $route.params.envId
        }
      }"
      class="link mr-2"
      :class="{ active: $route.name === 'UserSettings' }"
    >
      <div>
        <SettingsIcon />
      </div>
      Settings
    </router-link>
  </div>
</template>

<script>
import UserMenu from './../UserMenu/UserMenu'
import { SettingsIcon, CloudIcon, UsersIcon, ArrowLeftIcon } from 'vue-feather-icons'

export default {
  name: 'SettingsNavbar',
  components: {
    ArrowLeftIcon,
    UserMenu,
    CloudIcon,
    UsersIcon,
    SettingsIcon
  }
}
</script>

<style lang="postcss"></style>
