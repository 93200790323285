import { useFind, useGet, models } from 'feathers-vuex'
import { ref, computed, watch } from '@vue/composition-api'
import { unwrapRef, unwrapRefs } from './unwrap-ref'
import _get from 'lodash/get.js'

/**
 * Create an infobox for the provided service.
 */
export async function createInfobox(options) {
  const { Scene } = models.api
  const { name, service, org, pano, envsMeta } = unwrapRefs(options)
  const data = {
    name: name || 'New Infobox',
    infoboxName: name || 'New Infobox',
    isInfobox: true,
    serviceId: service._id,
    featureType: '',
    parentSceneId: null,
    sceneModulesMeta: [],
    envsMeta,
    orgs: [
      {
        orgId: org._id,
        orgName: org.name,
        accessType: 'owner',
        nameOfOwner: org.nameOfOwner
      }
    ]
  }
  if (pano && pano._id) {
    Object.assign(data, {
      panoId: pano._id,
      featureType: 'pano',
      panoName: pano.name || pano.originalFileName,
      panoUrl: pano.url
    })
  }
  const infobox = await new Scene(data).save()
  return { infobox }
}

/**
 * Create an scene for the provided infobox.
 */
export async function createSceneForInfobox(options) {
  const { Scene } = models.api
  const { infobox, name = 'New Scene', envsMeta = [] } = unwrapRefs(options)

  const scene = await new Scene({
    name,
    isInfobox: false,
    serviceId: infobox.serviceId,
    featureType: '',
    envsMeta,
    parentSceneId: infobox._id,
    sceneModulesMeta: [],
    orgs: infobox.orgs
  }).save()
  return { scene }
}

/**
 * Get a scene by id
 */
const requestedGetScene = {}
export function getScene({ id, local = false }) {
  const { Scene } = models.api
  const params = computed(() => {
    return {
      $populateParams: {
        name: 'infoboxBuilder'
      }
    }
  })
  const queryWhen = computed(() => {
    return !requestedGetScene[id.value]
  })
  const { item: scene, hasBeenRequested, hasLoaded: hasSceneLoaded } = useGet({
    model: Scene,
    id,
    params,
    queryWhen,
    local
  })
  watch(
    () => hasBeenRequested.value,
    val => {
      if (val && id.value) {
        requestedGetScene[id.value] = true
      }
    },
    { immediate: true }
  )

  return { scene, hasSceneLoaded }
}

/**
 * Find scenes for provided service
 */
export function findScenesForService({ service }) {
  const { Scene } = models.api

  const params = computed(() => {
    const _service = unwrapRef(service)
    if (!_service) return null

    return {
      query: {
        serviceId: _service._id,
        $limit: 500,
        $sort: {
          infoboxName: 1,
          name: 1
        }
      },
      debounce: 50,
      $populateParams: {
        name: 'infoboxBuilder'
      }
    }
  })
  const queryWhen = computed(() => {
    return true
  })
  const { items: scenes, haveBeenRequested, haveLoaded } = useFind({
    model: Scene,
    params,
    queryWhen
  })
  return { scenes, haveScenesBeenRequested: haveBeenRequested, haveScenesLoaded: haveLoaded }
}

export async function findInfoboxForEnv(options) {
  const { Scene } = models.api
  const { service, env } = unwrapRefs(options)
  const query = { serviceId: service._id, 'envsMeta.envId': env._id }
  const localRes = Scene.findInStore({ query }).data
  if (localRes[0]) {
    return { scene: localRes[0] }
  } else {
    const res = await Scene.find({ query })
    return { scene: res.data[0] || null }
  }
}

/**
 * Find infoboxes for provided service
 */
const requestedByServiceId = {}
export function findInfoboxesForService(options) {
  const { Scene } = models.api

  const params = computed(() => {
    const { service } = unwrapRefs(options)

    if (!service) {
      return null
    }
    return {
      query: {
        serviceId: service._id,
        isInfobox: true,
        $sort: { infoboxName: 1 }
      },
      $populateParams: {
        name: 'infoboxBuilder'
      }
    }
  })
  const queryWhen = computed(() => {
    const svc = unwrapRef(options.service)
    return svc && !requestedByServiceId[svc._id]
  })
  const { items: infoboxes, haveBeenRequested } = useFind({ model: Scene, params, queryWhen })
  watch(
    () => haveBeenRequested.value,
    val => {
      const svc = unwrapRef(options.service)
      requestedByServiceId[svc._id] = true
    }
  )
  return { infoboxes }
}

/**
 * Find child scenes that belong to the provided infobox.
 */
export function findScenesForInfobox({ infobox, local }) {
  const { Scene } = models.api

  const params = computed(() => {
    const _infobox = unwrapRef(infobox)

    if (!_infobox) {
      return null
    }
    return {
      query: {
        parentSceneId: _infobox._id
      },
      debounce: 50,
      $populateParams: {
        name: 'infoboxBuilder'
      }
    }
  })
  const { items: scenes } = useFind({ model: Scene, params, local })
  return { scenes }
}

/**
 * Find scenes that have the provided panoIds
 */
export function findScenesForPanoIds({ panoIds, sceneIds, local = false }) {
  const { Scene } = models.api

  const params = computed(() => {
    const _panoIds = _get(panoIds, 'value')
    const _sceneIds = _get(sceneIds, 'value')

    const query = {
      $limit: 5000
    }
    if (_sceneIds && _sceneIds.length) {
      query.sceneId = { $in: _sceneIds }
    }
    if (_panoIds && _panoIds.length) {
      query.sceneId = { $in: _panoIds }
    }

    return {
      query,
      debounce: 50,
      $populateParams: {
        name: 'infoboxBuilder'
      }
    }
  })
  const { items: scenes } = useFind({ model: Scene, params, local })
  return { scenes }
}

/**
 * Find scenes that have the provided panoIds
 */
const queriedForEnv = {}
export function findScenesForEnv(options) {
  const { Scene } = models.api

  const params = computed(() => {
    const { env, sceneIds, populate = 'infoboxBuilder', panoId, pagination } = unwrapRefs(options)
    if (!env) {
      return null
    }
    const query = { 'envsMeta.envId': env._id }
    if (pagination) {
      Object.assign(query, pagination)
    }
    if (sceneIds && sceneIds.length) {
      query._id = { $in: sceneIds }
    }
    if (panoId != null) {
      Object.assign(query, { panoId })
    }

    return { query, $populateParams: { name: populate } }
  })
  const queryWhen =
    options.queryWhen ||
    computed(() => {
      const { env } = unwrapRefs(options)
      return (env && !queriedForEnv[env._id]) || false
    })
  const { items: scenes, haveBeenRequested, latestQuery, haveLoaded, isPending } = useFind({
    model: Scene,
    params
  })
  watch(
    () => haveBeenRequested.value,
    val => {
      const { env } = unwrapRefs(options)
      if (val && env._id) {
        queriedForEnv[env._id]
      }
    }
  )
  return { scenes, haveLoaded, latestQuery, isPending }
}

/**
 * Infobox Selection
 */
export function infoboxSelection(props, context) {
  const selectedInfobox = ref(null)
  function selectInfobox(scene) {
    if (selectedInfobox.value === scene) {
      selectedInfobox.value = null
    } else {
      selectedInfobox.value = scene
    }
  }
  function openInfobox(scene) {
    context.root.$router.push(
      {
        name: 'InfoboxEditor',
        params: {
          serviceId: scene.serviceId,
          infoboxId: scene.parentSceneId || scene._id
        }
      },
      () => {}
    )
  }
  return {
    selectedInfobox,
    selectInfobox,
    openInfobox
  }
}

/**
 * Scene Selection (what is the different with above? wtf was I thinking)
 */
export function sceneSelection(props, context) {
  const selectedScene = ref(null)
  function selectScene(scene) {
    if (selectedScene.value === scene) {
      selectedScene.value = null
    } else {
      selectedScene.value = scene
    }
  }
  function openScene(scene) {
    context.root.$router.push(
      {
        name: 'InfoboxEditor',
        params: {
          serviceId: scene.serviceId,
          infoboxId: scene.parentSceneId || scene._id
        }
      },
      () => {}
    )
  }
  return {
    selectedScene,
    selectScene,
    openScene
  }
}

/**
 * Checks if the scene is a parent infobox and returns infobox/child pair.
 * If item is an infobox, you get back the `infobox` and a null `scene`.
 * If item is a scene, you get back the `scene` plus its parent `infobox`
 */
export async function getInfoboxScenePair(item) {
  const _item = unwrapRef(item)
  const { Scene } = models.api
  let infobox
  let scene
  if (_item.isInfobox) {
    scene = null
    infobox = _item
  } else {
    scene = _item
    infobox =
      (await Scene.getFromStore(scene.parentSceneId)) || (await Scene.get(scene.parentSceneId))
  }
  return { infobox, scene }
}

/**
 * makeLink
 */
export async function makeLink({ env, infobox, shouldSwitchEnvs = false, scene }) {
  const envId = _get(env, '_id')
  const infoboxId = _get(infobox, '_id')
  const sceneId = _get(scene, '_id')
  const serviceId = _get(infobox, 'serviceId')

  if (!serviceId) {
    throw new Error('infobox must have a serviceId')
  }

  let service = models.api.Service.getFromStore(serviceId)
  if (!service) {
    service = await models.api.Service.get(serviceId)
  }
  const isInfobox = !scene

  const link = {
    envId: envId != null ? envId : null,
    envName: _get(env, 'name') || '',
    shouldSwitchEnvs,
    infoboxId: infoboxId != null ? infoboxId : null,
    infoboxName: _get(infobox, 'infoboxName') || '',
    isInfobox,
    sceneId: sceneId != null ? sceneId : null,
    sceneName: _get(scene, 'name') || '',
    serviceId: serviceId != null ? serviceId : null,
    serviceName: _get(service, 'name') || null
  }
  return { link }
}

/**
 * Add Hotspot to Scene
 * `_scene` is prefix with `_` because it should be a clone provided by handle-clones.js
 */
export async function addHotspotToItem({ _item, save_item, link = null }) {
  _item.value.hotspots.push({
    percentX: 50,
    percentY: 50,
    iconWidth: 50,
    iconHeight: 50,
    iconScale: 1,
    iconId: null,
    iconUrl: '',
    link
  })
  return await save_item('hotspots')
}

export function updateHotspot({ _item, hotspotIndex, icon }) {
  const hotspot = _item.value.hotspots[hotspotIndex]
  Object.assign(hotspot, {
    iconId: icon._id,
    iconUrl: icon.url
  })
  const data = { hotspots: _item.value.hotspots }
  return _item.value.save({ data })
}

const infoboxRequestsMade = {}
export function getInfoboxForEnvService(options) {
  const { Scene } = models.api
  const params = computed(() => {
    const { env, service } = unwrapRefs(options)
    if (!env || !service || infoboxRequestsMade[env._id + ':' + service._id]) {
      return null
    }

    return {
      query: {
        'envsMeta.envId': env._id,
        serviceId: service._id,
        isInfobox: true
      },
      $populateParams: { name: 'infoboxBuilder' }
    }
  })
  const queryWhen = computed(() => {
    return true
  })
  const { items, haveBeenRequested, haveLoaded } = useFind({
    model: Scene,
    params,
    queryWhen
  })
  watch(
    () => haveBeenRequested,
    val => {
      const { env, service } = unwrapRefs(options)
      if (val && env && service) {
        infoboxRequestsMade[env._id + ':' + service._id] = true
      }
    },
    { immediate: true }
  )
  const infobox = computed(() => {
    return (items.value && items.value[0]) || null
  })
  return { infobox, hasLoaded: haveLoaded }
}

export async function setInfoboxForEnvService(options) {
  const { env, service, infobox } = unwrapRefs(options)
  const { Scene } = models.api

  // Remove env from any existing infoboxes for this service.
  const res = await Scene.find({
    query: {
      'envsMeta.envId': env._id,
      serviceId: service._id
    }
  })
  const existing = res.data
  console.log({ existing })
  await Promise.all(existing.map(e => removeEnvFromInfobox({ env, infobox: e })))

  // Add the env to this infobox
  const envsMeta = infobox.envsMeta.slice()
  envsMeta.push({
    envId: env._id,
    envName: env.name
  })
  await infobox.clone({ envsMeta }).save()
  return options
}

// Find any existing Infobox and remove the provided env from its envsMeta.
export async function removeEnvFromInfobox(options) {
  const { env, infobox } = unwrapRefs(options)
  const envsMeta = infobox.envsMeta.filter(em => em.envId !== env._id)
  await infobox.clone({ envsMeta }).save()
  return options
}

export async function addEnvToScene(options) {
  const { scene, env, save = true } = unwrapRefs(options)
  const alreadyExists = scene.envsMeta.find(em => em.envId === env._id)
  if (alreadyExists) {
    return scene
  }

  const data = { envsMeta: scene.envsMeta }
  const existingEnvIds = envsMeta.map(em => em.envId)
  if (!existingEnvIds.includes(env._id)) {
    data.envsMeta.push({ envId: env._id, envName: env.name })
    const _scene = scene.clone(data)
    if (save) {
      return await _scene.save({ data })
    } else {
      _scene.commit()
    }
  }
  return scene
}
