<template>
  <div class="edit-category">
    <div v-if="category">
      <CategoryEditor
        class="mt-3"
        :category="category"
        :env-id="envId"
        :org-id="currentOrg._id"
        :icon-model="models.api.Icon"
        :categories="categories"
      />

      <DangerZoneDelete @remove="() => category.remove()" />
    </div>
    <div v-else>Loading Category</div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { getCategory } from '@/use/categories'
import DangerZoneDelete from '../DangerZoneDelete/DangerZoneDelete.vue'

import CategoryEditor from '../CategoryEditor/CategoryEditor.vue'
import { models } from 'feathers-vuex'

export default {
  name: 'EnvLibCategoriesSidebarEditCategory',
  components: {
    CategoryEditor,
    DangerZoneDelete
  },
  props: {
    categories: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup(props, context) {
    // Current Category
    const categoryId = computed(() => context.root.$route.query.categoryId)
    const { category } = getCategory(categoryId)
    const envId = context.root.$route.params.envId
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    return {
      category,
      models,
      envId,
      currentOrg
    }
  }
}
</script>

<style lang="postcss"></style>
