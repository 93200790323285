<template>
  <div id="app">
    <div v-if="authState.error" class="flex w-full h-full justify-center items-center bg-gray-800">
      <div class="text-center text-white">
        <img class="m-auto" src="https://cdn.rovit.com/rovit-frog-small.png" alt="" />
        <div class="text-lg mt-3">
          Oh Snap! The server is not responding right now :(
        </div>
        <div class="text-lg">Please try again later.</div>
      </div>
    </div>
    <div v-else-if="authState.isLoading || !authState.auth0User" class="loading-screen">
      <LeapFrog />
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import { getInstance } from './auth/auth0'
import { ref, watch, computed } from '@vue/composition-api'
import { LeapFrog } from '@rovit/loading'
import { fetchRovitEnv } from '@/use/environments.js'
import LogRocket from 'logrocket'
import _get from 'lodash/get'

export default {
  name: 'App',
  components: {
    LeapFrog
  },
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | Rovit Admin'
  },
  setup(props, context) {
    const auth0 = getInstance()
    const authState = computed(() => context.root.$store.state.auth)

    watch(
      () => authState.value.isLoading,
      isLoading => {
        if (isLoading === false) {
          if (authState.value.isAuthenticated) {
            if (_get(authState.value, 'user.isRovitAdmin', false)) {
              fetchRovitEnv()
            } else {
              //re-direct user to rovit app if not rovit admin
              window.location.href = process.env.VUE_APP_ROVIT_APP_URL
            }
          } else {
            auth0.loginWithRedirect({
              appState: { targetUrl: window.location.href }
            })
          }
        }
      },
      { immediate: true }
    )
    if (authState.value && authState.value.auth0User) {
      const { sub, name, email } = authState.value.auth0User
      LogRocket.identify(sub, { name, email })
    }

    return { authState, auth0 }
  }
}
</script>

<style lang="postcss">
@import '~@rovit/styles/tailwind.postcss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body,
#app {
  @apply h-full;
  font-family: 'Dosis', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}
.loading-screen {
  @apply h-full flex flex-row items-center justify-center;
}
@screen dark {
  .loading-screen {
    @apply bg-gray-800;
  }
}
.toasted-action-success {
  background: #009100 !important;
}
.toasted-action-error {
  background: #e53e3e !important;
}
</style>
