import { getInstance } from '../auth/auth0'

export default function useAuth() {
  const auth0 = getInstance()

  function login() {
    auth0.loginWithRedirect()
  }
  function logout() {
    auth0.logout({ returnTo: window.location.origin })
  }

  return { login, logout }
}
