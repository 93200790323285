<template>
  <button
    type="button"
    class="bg-gray-300 dark:bg-gray-700 px-4 py-2 rounded flex flex-row items-center text-medium w-full justify-center"
    @click="e => $emit('click', e)"
  >
    <PlusIcon class="mr-2 -ml-1" />Add Hotspot
  </button>
</template>

<script>
import { PlusIcon } from 'vue-feather-icons'

export default {
  name: 'AddHotspotButton',
  components: {
    PlusIcon
  }
}
</script>

<style lang="postcss"></style>
