<template>
  <div>
    <!-- Infobox Layouts -->
    <EnvServiceLayoutSelector :service="service" :env-service="envService" />

    <!-- Environments where you can find this service -->
    <!-- <ServiceEnvServices :service="service" class="mt-4" /> -->
  </div>
</template>

<script>
import ServiceEnvServices from '../ServiceEnvServices/ServiceEnvServices.vue'
import EnvServiceLayoutSelector from '../EnvServiceLayoutSelector/EnvServiceLayoutSelector.vue'

export default {
  name: 'ServiceEditorInfoboxes',
  components: {
    // ServiceEnvServices,
    EnvServiceLayoutSelector
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    envService: {
      validator: val => true,
      default: null
    }
  }
}
</script>

<style lang="postcss"></style>
