<template>
  <Layout>
    <template #content>
      <NavbarEnvironment :env="currentEnv" />

      <div class="px-12">
        <h1 class="content-heading-1">Environment Analytics</h1>

        <p class="content-subheading">
          Analytics for the
          <strong class="font-bold">{{ currentEnv && currentEnv.name }}</strong>
          environment.
        </p>

        <div class="py-4 space-y-2 w-full">
          <EnvViewsChart />
          <SceneViewsChart />
          <SceneModuleImpressionViewsChart />
        </div>
      </div>
    </template>

    <template #sidebar>
      <div>
        <EnvScenesSidebarTabs
          @editor-tab-click="showMostRecentEditor"
          @add-scene-click="handleAddScene"
        />

        <EnvScenesSidebarSupport v-if="sidebarName === 'support'" />

        <EnvScenesSidebarEditorBlank
          v-if="sidebarName === 'editor' && sidebarEditorType === 'blank'"
        />
        <EnvScenesSidebarEditorEnvScene
          v-if="sidebarName === 'editor' && sidebarEditorType === 'env-scene'"
        />

        <EnvScenesSidebarAddScene
          v-if="sidebarName === 'add-scene'"
          :env-scenes="envScenes"
          @create-scene="createScene"
          @add-scene="addSceneToEnv"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import useSidebarTabState from '@/use/sidebar-tab-state'
import { getEnv } from '@/use/environments'

import Layout from '../layouts/Layout.vue'
import NavbarEnvironment from '../components/NavbarEnvironment/NavbarEnvironment.vue'

import EnvScenesSidebarTabs from '../components/EnvScenes/SidebarTabs.vue'
import EnvScenesSidebarSupport from '../components/EnvScenes/SidebarSupport.vue'
import EnvScenesSidebarEditorBlank from '../components/EnvScenes/SidebarEditorBlank.vue'
import EnvScenesSidebarEditorEnvScene from '../components/EnvScenes/SidebarEditorEnvScene.vue'
import EnvScenesSidebarAddScene from '../components/EnvScenes/SidebarAddScene.vue'
import EnvViewsChart from '../components/Charts/EnvViewsChart'
import SceneViewsChart from '../components/Charts/SceneViewsChart'
import SceneModuleImpressionViewsChart from '../components/Charts/SceneModuleImpressionViewsChart'

export default {
  name: 'EnvironmentScenes',
  metaInfo: {
    title: 'Environment Analytics'
  },
  components: {
    Layout,
    NavbarEnvironment,
    EnvScenesSidebarTabs,
    EnvScenesSidebarSupport,
    EnvScenesSidebarEditorBlank,
    EnvScenesSidebarEditorEnvScene,
    EnvScenesSidebarAddScene,
    EnvViewsChart,
    SceneModuleImpressionViewsChart,
    SceneViewsChart
  },
  setup(props, context) {
    const { Environment, Service, EnvService, Scene, EnvScene } = models.api
    const { env: currentEnv } = getEnv(context.root.$route.params.envId)

    const selectedEnvScene = ref(null)

    /* Load EnvScenes */
    const envSceneSearch = ref('')
    const envScenesParams = computed(() => {
      const query = {}
      if (envSceneSearch.value) {
        Object.assign(query, {
          name: { $regex: sceneSearch.value, $options: 'igm' }
        })
      }
      const $populateParams = { name: 'scene' }
      return { query, $populateParams }
    })
    const { items: envScenes } = useFind({
      model: EnvScene,
      params: envScenesParams
    })

    /* Load Scenes */
    const sceneSearch = ref('')
    const scenesParams = computed(() => {
      const query = {}
      if (sceneSearch.value) {
        Object.assign(query, {
          name: { $regex: sceneSearch.value, $options: 'igm' }
        })
      }
      return { query }
    })
    const { items: scenes } = useFind({
      model: Scene,
      params: scenesParams
    })

    /* Sidebar Editor Management */
    const sidebarName = computed(() => {
      return context.root.$route.query.sidebar || 'support'
    })
    const sidebarEditorType = computed(() => {
      const { $route } = context.root
      if ($route.query.envSceneId) {
        return 'env-scene'
      } else {
        return 'blank'
      }
    })
    const { current, openEditor, showMostRecentEditor } = useSidebarTabState({
      name: 'editor',
      watchInQuery: ['envSceneId'],
      context
    })

    /**
     * Create an EnvScene record for the provided scene.
     */
    async function addSceneToEnv(scene) {
      const envScene = await new EnvScene({
        envId: environment.value._id,
        envName: environment.value.name,
        sceneId: scene._id,
        sceneName: scene.name
      }).save({
        $populateParams: {
          name: 'scene'
        }
      })
      return envScene
    }
    /**
     * Show the add scene sidebar, which gives the user the option
     * to create a new scene or search existing scenes.
     */
    async function handleAddScene() {
      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'add-scene'
      })
      context.root.$router.push({ query }, () => {})
    }
    /**
     * Creates a new scene and immediately creates a matching envScene
     * record for it.
     */
    async function createScene() {
      const newScene = await new Scene({
        name: 'New Scene'
      }).save()

      const envScene = await addSceneToEnv(newScene)

      const query = Object.assign({}, context.root.$route.query, {
        sidebar: 'editor',
        envSceneId: envScene._id
      })
      context.root.$router.push({ query })
    }
    /**
     * Handles the currently selected environment(s). A different sidebar is shown for
     * arrays vs single objects.
     */
    function handleSceneClick({ item, e }) {
      if (Array.isArray(selectedEnvScene.value)) {
        if (!selectedEnvScene.value.length) return
        // TODO: handle when multiple orgs are in `selectedEnvScene` array.
      } else {
        if (!selectedEnvScene.value) return
        openEditor({ envSceneId: selectedEnvScene.value._id })
      }
    }
    /**
     * When an scene is double-clicked, open it in the SceneEditor
     */
    function handleSceneDblClick({ item, e }) {
      context.root.$router.push({
        name: 'SceneEditor',
        params: { sceneId: item._id }
      })
    }

    return {
      currentEnv,
      envSceneSearch,
      envScenes,
      scenes,
      sceneSearch,
      showMostRecentEditor,
      sidebarName,
      sidebarEditorType,
      selectedEnvScene,
      handleAddScene,
      addSceneToEnv,
      createScene,
      handleSceneClick,
      handleSceneDblClick
    }
  }
}
</script>

<style lang="postcss" scoped></style>
