<template>
  <div class="admin-navbar">
    <UserMenu class="mr-1" />

    <router-link to="/" class="link mr-2.5" :class="{ active: $route.name === 'OrgHome' }">
      <div>
        <HomeIcon />
      </div>
      Home
    </router-link>

    <router-link
      :to="{ name: 'OrgEnvironments' }"
      class="link"
      :class="{ active: $route.name === 'OrgEnvironments' }"
    >
      <div>
        <GlobeIcon />
      </div>
      Environs
    </router-link>

    <div class="text-green-900 mt-4">
      <ChevronRightIcon />
    </div>

    <!-- Environment Name -->
    <router-link
      :to="{
        name: 'EnvironmentServices',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link active-crumb"
      style="max-width: 92px"
      :class="{ active: $route.name === 'OrgProjects' }"
    >
      <div class="flex flex-row justify-center bg-green-200">
        <EditIcon />
      </div>

      <VClamp autoresize :max-lines="2" class="leading-none mt-1" tag="p">
        {{ env && env.name }}
      </VClamp>
    </router-link>

    <div class="text-green-900 mt-4">
      <ChevronRightIcon />
    </div>

    <!-- Environment Services -->
    <router-link
      :to="{
        name: 'EnvironmentServices',
        params: {
          envId: (env && env._id) || ''
        }
      }"
      class="link page no-highlight"
    >
      <div style="max-width: 52px; margin: 0 auto">
        <BriefcaseIcon />
      </div>
      Services
    </router-link>

    <div class="text-green-900 mt-4">
      <ChevronRightIcon />
    </div>

    <!-- Service -->
    <router-link
      :to="{
        name: 'EnvServiceInfoboxEditor',
        params: {
          envId: (env && env._id) || '',
          serviceId: (service && service._id) || ''
        }
      }"
      style="max-width: 92px"
      class="link page mr-2 active"
    >
      <div class="flex flex-row justify-center bg-green-200">
        <EditIcon />
      </div>

      <VClamp autoresize :max-lines="2" class="leading-none mt-1" tag="p">
        {{ service && service.name }}
      </VClamp>
    </router-link>

    <!-- Divider -->
    <div class="navbar-divider mr-2"></div>

    <!-- Infoboxes -->
    <router-link
      :to="{
        name: 'EnvServiceInfoboxEditor',
        params: {
          envId: (env && env._id) || '',
          serviceId: (service && service._id) || ''
        }
      }"
      class="link page mr-1.5"
      :class="{ active: $route.name === 'EnvServiceInfoboxEditor' }"
    >
      <div>
        <InfoIcon />
      </div>
      Infobox
    </router-link>

    <!-- Service Publish -->
    <router-link
      :to="{
        name: 'EnvServicePublish',
        params: {
          envId: (env && env._id) || '',
          serviceId: (service && service._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvServicePublish' }"
    >
      <div>
        <BookIcon />
      </div>
      <p class="leading-none mt-1">
        Publish <br />
        & Share
      </p>
    </router-link>

    <router-link
      :to="{
        name: 'EnvServiceAnalytics',
        params: {
          envId: (env && env._id) || '',
          serviceId: (service && service._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvServiceAnalytics' }"
    >
      <div>
        <BarChart2Icon />
      </div>
      Analytics
    </router-link>

    <!-- Team -->
    <router-link
      :to="{
        name: 'EnvServiceTeam',
        params: {
          envId: (env && env._id) || '',
          serviceId: (service && service._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvServiceTeam' }"
    >
      <div>
        <UsersIcon />
      </div>
      Team
    </router-link>

    <!-- Billing -->
    <router-link
      :to="{
        name: 'EnvServiceBilling',
        params: {
          envId: (env && env._id) || '',
          serviceId: (service && service._id) || ''
        }
      }"
      class="link page mr-2"
      :class="{ active: $route.name === 'EnvServiceBilling' }"
    >
      <div>
        <CreditCardIcon />
      </div>
      Billing
    </router-link>
  </div>
</template>

<script>
import UserMenu from '../UserMenu/UserMenu'
import VClamp from 'vue-clamp'
import {
  HomeIcon,
  BriefcaseIcon,
  BookIcon,
  InfoIcon,
  EditIcon,
  GlobeIcon,
  ChevronRightIcon,
  BarChart2Icon,
  UsersIcon,
  CreditCardIcon
} from 'vue-feather-icons'

export default {
  name: 'NavbarEnvironmentService',
  components: {
    UserMenu,
    HomeIcon,
    BriefcaseIcon,
    GlobeIcon,
    BookIcon,
    EditIcon,
    InfoIcon,
    ChevronRightIcon,
    BarChart2Icon,
    UsersIcon,
    CreditCardIcon,
    VClamp
  },
  props: {
    env: {
      type: Object,
      default: () => null
    },
    service: {
      type: Object,
      default: () => null
    }
  }
}
</script>

<style lang="postcss"></style>
