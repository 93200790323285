import feathersClient, { models, makeServicePlugin, BaseModel } from '../../feathers-client'
import { makeModel } from '@rovit/models/BadgeGroup.js'
import shouldFilterServiceKeys from '../../utils/fetch-variable-from-env'
import { iff, discard, alterItems } from 'feathers-hooks-common'
import _pick from 'lodash/pick.js'

const servicePath = 'badge-groups'
const servicePlugin = makeServicePlugin({
  Model: makeModel({ BaseModel, models }),
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [
      iff(
        context => ['create', 'update', 'patch'].includes(context.method),
        discard('childCategories'),
        iff(
          shouldFilterServiceKeys(),
          alterItems(i => _pick(i, ['_id', 'name', 'envId', 'createdBy', 'createdAt', 'updatedAt']))
        )
      )
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
