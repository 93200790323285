<template>
  <div>
    <FileUpload
      v-if="envId"
      :endpoint="$store.state.uploadUrlEndpoint"
      :drop-on-element="false"
      allow-multiple
      drop-on-page
      @uploaded="handleFileUpload"
      @file-click="handleUploadClick"
    />
    <div v-else>
      Waiting for current environment.
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { models } from 'feathers-vuex'

import { FileUpload, prepareUploadData } from '@rovit/file-upload'

export default {
  name: 'EnvLibIconsSidebarBulkUpload',
  components: {
    FileUpload
  },
  props: {
    envId: {
      type: String,
      validator: () => true,
      default: null
    }
  },
  setup(props, context) {
    const newFiles = ref([])
    const currentOrg = context.root.$store.getters['auth/currentOrg']

    async function handleFileUpload(data) {
      const uploadData = await prepareUploadData(data)
      Object.assign(uploadData, {
        envId: props.envId,
        orgId: currentOrg._id
      })
      const icon = await new models.api.Icon(uploadData).save()
      newFiles.value.push(icon)
    }

    /**
     * Open the icon in the sidebar when clicked.
     */
    function handleUploadClick(data) {
      const item = newFiles.value.find(file => file.originalFileName === data.filename)
      context.emit('icon-click', item)
    }

    return { handleFileUpload, handleUploadClick }
  }
}
</script>

<style lang="postcss"></style>
