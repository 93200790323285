<template>
  <div class="sidebar-tabs">
    <!-- Support -->
    <router-link
      :to="{ query: { sidebar: 'support' } }"
      :class="{
        active: $route.query.sidebar === 'support' || !$route.query.sidebar
      }"
    >
      <HelpCircleIcon />
    </router-link>

    <!-- Editor -->
    <button
      type="button"
      :class="{
        active: $route.query.sidebar === 'editor'
      }"
      @click="$emit('editor-tab-click')"
    >
      Editor
    </button>
  </div>
</template>

<script>
import { HelpCircleIcon } from 'vue-feather-icons'

export default {
  name: 'OrgProjectsSidebarTabs',
  components: {
    HelpCircleIcon
  },
  props: {}
}
</script>

<style lang="postcss"></style>
